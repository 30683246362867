import React from 'react';
import cx from 'classnames';
import styles from './styles.module.css';
import { Correction } from '../../Correction';

const promiseResolved = () => Promise.resolve();

export interface ReplacementProps {
  /** Set additional css class */
  className?: string;
  /** Set inline css styles */
  style?: React.CSSProperties;
  /** Children passed on as React child */
  children?: React.ReactNode;
  category?: string;
  isCompactMode?: boolean;
  isMultipleSuggestions?: boolean;
  correction: string[];
  highlight: string;
  onApplySuggestion?: (replacement: string) => Promise<unknown>;
  isInverted?: boolean;
}

export const Replacement: React.FC<ReplacementProps> = ({
  style,
  category,
  className,
  isCompactMode,
  isMultipleSuggestions,
  correction,
  highlight,
  onApplySuggestion,
  isInverted,
}) => {
  const _onApplySuggestion = () => {
    if (onApplySuggestion && correction) {
      onApplySuggestion(correction[0]);
    }
  };

  return (
    <div
      aria-hidden
      className={cx(styles.container, className, {
        [styles.disableContainerHighlighting]: isMultipleSuggestions,
        [styles.compact]: isCompactMode,
        [styles.containerInverted]: isInverted,
      })}
      style={style}
      onClick={!isMultipleSuggestions ? _onApplySuggestion : () => {}}
    >
      <div className={styles.replacementBlock}>
        <Correction
          correctionStyles={{ marginTop: 6, alignItems: isMultipleSuggestions ? 'center' : 'flex-start' }}
          forceArrow
          highlight={(correction[0] === '' && highlight) || ''}
          correction={correction || []}
          onApplySuggestion={onApplySuggestion || promiseResolved}
          forceDisableCorrection={!isMultipleSuggestions}
        />
      </div>
      {category && <div className={styles.category}>{category}</div>}
    </div>
  );
};

export default React.memo(Replacement);
