import { debounce } from 'lodash/fp';
import { action, observable } from 'mobx';
import type { SidebarModel, SnippetsModel } from '../../models';
import type { ISidebarEvents } from '../../types';

export class UISnippetsListModel {
  public readonly snippets: SnippetsModel;
  private readonly model: SidebarModel;
  private readonly $search = observable.box<string | string>(undefined);

  constructor(model: SidebarModel) {
    this.model = model;
    this.snippets = model.snippets;
  }

  private readonly onSearchTextUpdated = debounce(500, (search: string) => {
    this.snippets.setExtra({ search });
  });

  get loading() {
    return this.snippets.rawValueWithError === undefined;
  }

  get isEmpty() {
    return this.snippets.progress === 'empty';
  }

  get isSmallPadding() {
    return this.model.size.isSmall;
  }

  get newSnippetUrl() {
    return this.model.sidebarState.newSnippetUrl;
  }

  get hasNextPage() {
    return this.snippets.hasNext;
  }

  onLoadMore = () => this.snippets.next();

  setKeyword = action((search: string) => {
    this.onSearchTextUpdated(search);
    this.$search.set(search);
  });

  get searchValue() {
    return this.$search.get() ?? '';
  }

  get onSnippetClick() {
    return this.model.onSnippetClick;
  }

  get isAlertsCentered() {
    return this.model.sidebarState.isAlertsCentered;
  }

  hasEventSubscriber(event: keyof ISidebarEvents) {
    return this.model.hasEventSubscriber(event);
  }

  onSnippetClicked(snippet: string) {
    return this.model.eventBus.emit('onSnippetClicked', snippet);
  }
}
