/**
 * The sentence end characters to be used for checking end of sentence.
 * @type {string[]}
 */
export const SENTENCE_END_CHARACTERS = ['.', ';', '"'];

/**
 * The maximum length allowed for a string.
 * @type {number}
 */
export const MAX_LENGTH = 30;

/**
 * The custom ignored tags enum.
 * @enum {string}
 */
export enum CUSTOM_IGNORED_TAGS {
  WRITER_IGNORE = 'writer-ignore',
}

/**
 * The file name pattern to be used for file validation.
 * @type {RegExp}
 */
export const filePattern = /^[\w,\s-]+\.[A-Za-z]{3}\.?$/;

/**
 * The list of abbreviations to be used for abbreviation matching.
 * @type {string[]}
 */
export const ABBR = ['Mrs', 'Mr', 'Ms', 'Dr', 'Prof', 'Capt', 'Gen', 'Sen', 'Rev', 'Hon', 'St'];

/**
 * The regular expression pattern to match the list of abbreviations.
 * @type {RegExp}
 */
export const ABBR_RX = new RegExp(`(${ABBR.join('|')})\\.`, 'g');

/**
 * The regular expression pattern to clean the matched abbreviations.
 * @type {RegExp}
 */
export const CLEAN_ABBRS = /((\w)\.(?=[\w,]))/g;

/**
 * The regular expression pattern to clean the abbreviations followed by a space and a lowercase letter.
 * @type {RegExp}
 */
export const CLEAN_ABBRS_2 = /((\w)\.(?=( [a-z])))/g;

export const WHITESPACES_CODES = [
  8, 9, 32, 160, 8194, 8195, 8196, 8197, 8198, 8199, 8200, 8201, 8202, 8287, 12288,
] as const;

/**
 * The list of whitespace characters to be used for whitespace matching.
 * @type {string[]}
 */
export const WHITESPACES = WHITESPACES_CODES.map(e => String.fromCharCode(e));

export const WHITESPACES_REGEXP = new RegExp(`[${WHITESPACES.join('')}]`, 'g');

export const ZERO_WIDTH_CODE = [8203, 8204, 8205] as const;
export const ZERO_WIDTH = ZERO_WIDTH_CODE.map(ch => String.fromCharCode(ch));
export const [ZWS, ZWNJ, ZWJ] = ZERO_WIDTH;
export const ZERO_WIDTH_REGEXP = new RegExp(`[${ZERO_WIDTH.join('')}]`, 'g');
