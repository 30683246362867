import { Enum } from '@writerai/utils';

export const COLLECTION = new Enum('issue-v2', 'viewers', 'field');

export const FIELDS = new Enum('core', 'data');

export const CREATE_NEW_DOC_ID = 0;
export const EMPTY_EDITOR_DOC_ID = 1;
export const REDIRECT_DOC_ID = -1;
export const COLLABORATION_ACTIVE_SECONDS = 30;
export const COLLABORATION_LOCK_SECONDS = 5;
export const DEBUG_APP_CHECK_TOKEN = '39EBB9A6-EF20-4A98-BB83-5F31175EC131';
