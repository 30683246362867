import type { ISidebarCategory, IStyleguide, TSubscription } from '@writerai/types';
import urlJoin from 'url-join';
import { SETUP_ROUTES } from '@writerai/utils';
import {
  BillingProduct,
  CategoryType,
  IssueCategory,
  SidebarCategoryStatus,
  SuggestionCategoryType,
} from '@writerai/types';
import flatten from 'lodash/flatten';
import isUndefined from 'lodash/isUndefined';
import { makeObservable, computed, observable, action } from 'mobx';

import type { ICategoriesModel } from './types';
import { getLogger } from '../../utils/logger';
import { SidebarSection } from '../../types';

const LOG = getLogger('CategoriesModel');

export interface ICategoriesModelOpts {
  appRoot: () => string | undefined;
  organizationId: () => string | undefined;
  workspaceId: () => string | undefined;
  getStyleGuide: () => IStyleguide | undefined;
  getSubscription: () => TSubscription | undefined;
}

export const CUSTOMIZED_CATEGORY_LABEL = {
  [IssueCategory.BannedWords]: "DON'T USE TERM",
  [IssueCategory.CommonMistakes]: 'COMMON MISTAKE',
  [IssueCategory.MlPunctuation]: 'Punctuation',
  [IssueCategory.Spelling]: 'Spelling',
  [IssueCategory.Gec]: 'Grammar',
  [IssueCategory.GecGpt3]: 'Grammar',
  [IssueCategory.HateSpeech]: 'Content Safeguards',
  [IssueCategory.UseCarefully]: 'Use carefully term',
} as Record<IssueCategory, string>;

export class CategoriesModel implements ICategoriesModel {
  private disabledSectionRulesMap: Record<string, SuggestionCategoryType[]> = {
    [CategoryType.SPELLING_AND_GRAMMAR]: [SuggestionCategoryType.GRAMMAR_CHECKER, SuggestionCategoryType.SPELL_CHECKER],
    [CategoryType.TERMS]: [SuggestionCategoryType.TERMINOLOGY],
    [CategoryType.STYLE]: [
      SuggestionCategoryType.ACRONYM,
      SuggestionCategoryType.CAPITALIZATION,
      SuggestionCategoryType.DATE_TIME,
      SuggestionCategoryType.EMOJIS,
      SuggestionCategoryType.CUSTOM_RULES,
    ],
    [CategoryType.CLARITY]: [SuggestionCategoryType.CLARITY, SuggestionCategoryType.READABILITY],
    [CategoryType.DELIVERY]: [SuggestionCategoryType.CONFIDENCE, SuggestionCategoryType.HEALTHY_COMMUNICATION],
    [CategoryType.INCLUSIVITY]: [SuggestionCategoryType.SENSITIVITY, SuggestionCategoryType.GENDER_INCLUSIVITY],
    [CategoryType.COMPLIANCE]: [SuggestionCategoryType.DATA_LOSS_PREVENTION, SuggestionCategoryType.GLOBAL_COMPLIANCE],
    [CategoryType.PLAGIARISM]: [SuggestionCategoryType.PLAGIARISM],
  };

  private readonly $categoryType = observable.box(CategoryType.ALL);

  constructor(private opts: ICategoriesModelOpts) {
    makeObservable(this, {
      categoryType: computed,
      selectedCategory: computed,
      activeSection: computed,
      issueColorMap: computed,
      visibleCategories: computed,
      disabledSectionsMap: computed,
      categoriesList: computed,
      allIssuesCategories: computed,
      betaIssueCategories: computed,
      isPlagiarismCategorySelected: computed,
      isSnippetsSection: computed,
      styleguideUrls: computed,

      setCategory: action.bound,
    });
  }

  get categoryType() {
    return this.$categoryType.get();
  }

  get selectedCategory() {
    return this.categoriesList?.find(category => category.id === this.categoryType);
  }

  get activeSection() {
    return this.categoryType === CategoryType.SNIPPETS ? SidebarSection.SNIPPETS : SidebarSection.ISSUES;
  }

  get issueColorMap(): Record<CategoryType, string> | undefined {
    return this.categoriesList?.reduce(
      (acc, cat) => ({
        ...{ [cat.id]: cat.color },
        ...cat.categories.reduce((a, b) => {
          // eslint-disable-next-line no-param-reassign
          a[b] = cat.color;

          return a;
        }, {} as Record<IssueCategory, string>),
        ...acc,
      }),
      {} as Record<CategoryType, string>,
    );
  }

  get visibleCategories() {
    const subscription = this.opts.getSubscription();

    if (!subscription) {
      return undefined;
    }

    const styleguide = this.opts.getStyleGuide();

    return styleguide?.sections.filter(section => section.status !== SidebarCategoryStatus.INVISIBLE);
  }

  get disabledSectionsMap(): Record<string, boolean> {
    const styleguide = this.opts.getStyleGuide();

    if (!styleguide) {
      return {};
    }

    return (
      this.visibleCategories?.reduce((res, section) => {
        const categoriesToBeDisabled = this.disabledSectionRulesMap[section.id];

        // if all subsections are disabled = section is disabled
        res[section.id] = categoriesToBeDisabled.every(categoryToCheck => {
          const styleguideCategory = styleguide.categories.find(c => c.category === categoryToCheck);

          return !styleguideCategory?.enabled;
        });

        return res;
      }, {} as Record<CategoryType, boolean>) ?? {}
    );
  }

  get categoriesList(): ISidebarCategory[] {
    const subscription = this.opts.getSubscription();

    if (!subscription?.meta) {
      return [];
    }

    const { meta } = subscription;

    return (
      this.visibleCategories?.map(section => {
        if (isUndefined(meta.styleguide[section.id])) {
          LOG.warn(
            `Trying to get subscription meta for non existing section - Section: ${section.id}, meta: ${meta.styleguide}`,
          );
        }

        const isLocked = !meta.styleguide[section.id];
        const isDisabled = this.disabledSectionsMap[section.id];

        if (isLocked) {
          const { billingProduct, lockedText } = CategoriesModel.lockedCategoryMeta[section.id];

          return {
            ...section,
            billingProduct,
            lockedText,
            status: SidebarCategoryStatus.LOCKED,
          } satisfies ISidebarCategory;
        }

        if (isDisabled) {
          const styleguideSectionUrl = this.styleguideUrls[section.id];

          return {
            ...section,
            status: SidebarCategoryStatus.DISABLED,
            styleguideSectionUrl,
          } satisfies ISidebarCategory;
        }

        return section;
      }) ?? []
    );
  }

  // all available categories
  get allIssuesCategories() {
    // all visible categories + dictionary terms and claims
    return [
      ...flatten(
        this.categoriesList
          .filter(category => ![SidebarCategoryStatus.LOCKED, SidebarCategoryStatus.DISABLED].includes(category.status))
          .map(category => category.categories),
      ),
      IssueCategory.Dictionary,
      IssueCategory.Claim,
      IssueCategory.Quote,
    ];
  }

  // beta categories
  get betaIssueCategories() {
    return flatten(
      this.categoriesList
        .filter(category => category.status === SidebarCategoryStatus.BETA)
        .map(category => category.categories),
    );
  }

  get isPlagiarismCategorySelected() {
    return this.categoryType === CategoryType.PLAGIARISM;
  }

  get isSnippetsSection() {
    return this.activeSection === SidebarSection.SNIPPETS;
  }

  get styleguideUrls(): Record<string, string> {
    const organizationId = this.opts.organizationId();
    const workspaceId = this.opts.workspaceId();

    if (!organizationId || !workspaceId) {
      return {};
    }

    return {
      [CategoryType.SPELLING_AND_GRAMMAR]: this.getLinkWithRoot(SETUP_ROUTES.toGeneral(organizationId, workspaceId)),
      [CategoryType.TERMS]: this.getLinkWithRoot(SETUP_ROUTES.toGeneral(organizationId, workspaceId)),
      [CategoryType.STYLE]: this.getLinkWithRoot(SETUP_ROUTES.toWritingStyle(organizationId, workspaceId)),
      [CategoryType.CLARITY]: this.getLinkWithRoot(SETUP_ROUTES.toClarity(organizationId, workspaceId)),
      [CategoryType.DELIVERY]: this.getLinkWithRoot(SETUP_ROUTES.toDelivery(organizationId, workspaceId)),
      [CategoryType.INCLUSIVITY]: this.getLinkWithRoot(SETUP_ROUTES.toInclusivity(organizationId, workspaceId)),
      [CategoryType.COMPLIANCE]: this.getLinkWithRoot(SETUP_ROUTES.toCompliance(organizationId, workspaceId)),
      [CategoryType.PLAGIARISM]: this.getLinkWithRoot(SETUP_ROUTES.toPlagiarism(organizationId, workspaceId)),
    };
  }

  setCategory(categoryType: CategoryType) {
    this.$categoryType.set(categoryType);
  }

  getSidebarCategory = (categoryName: IssueCategory): ISidebarCategory | undefined => {
    const category = this.categoriesList?.find(item => item.categories.includes(categoryName));

    if (!category) return undefined;

    /* Todo: updating the label for TERMS subcategories until BE starts sending that */
    const label = CUSTOMIZED_CATEGORY_LABEL[categoryName] || category.label;

    return { ...category, label };
  };

  static get lockedCategoryMeta(): Record<CategoryType, { billingProduct: BillingProduct; lockedText: string }> {
    const defaultMeta = { billingProduct: BillingProduct.TEAM, lockedText: 'N/A' };

    return {
      [CategoryType.ALL]: defaultMeta,
      [CategoryType.SPELLING_AND_GRAMMAR]: defaultMeta,
      [CategoryType.SNIPPETS]: defaultMeta,
      [CategoryType.TERMS]: {
        billingProduct: BillingProduct.TEAM,
        lockedText: 'Make sure you nail your brand words, every time.',
      },
      [CategoryType.STYLE]: {
        billingProduct: BillingProduct.TEAM,
        lockedText: 'Set your own editorial style, from  punctuation to capitalization.',
      },
      [CategoryType.CLARITY]: {
        billingProduct: BillingProduct.TEAM,
        lockedText: 'Get suggestions to make your writing clear and scannable.',
      },
      [CategoryType.DELIVERY]: {
        billingProduct: BillingProduct.TEAM,
        lockedText: 'Make sure your writing is positive, confident, and polite.',
      },
      [CategoryType.INCLUSIVITY]: {
        billingProduct: BillingProduct.TEAM,
        lockedText: 'Make sure your content is inclusive and check  for outdated or offensive language.',
      },
      [CategoryType.COMPLIANCE]: {
        billingProduct: BillingProduct.TEAM,
        lockedText: 'Check content for private or sensitive information that should be redacted.',
      },
      [CategoryType.PLAGIARISM]: {
        billingProduct: BillingProduct.TEAM,
        lockedText: 'Make sure your writing is original.',
      },
    };
  }

  private getLinkWithRoot(link: string) {
    return urlJoin(this.opts?.appRoot() || '', link);
  }
}
