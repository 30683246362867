
const digest = '99e105b56a7d2cc1c81c55950deb49006cff96a724955a3a0e3a5c72dc359ca5';
const css = `.FleschKincaidScoreTooltip-module__TusKVg- {
  position: relative;
}

.FleschKincaidScoreTooltip-module__xfQRudm {
  padding: 0 !important;
}

.FleschKincaidScoreTooltip-module__-MsQeya {
  width: 300px;
  padding: 10px;
  background: var(--white);
  text-align: left;
}

.FleschKincaidScoreTooltip-module__k2Qyec7 {
  padding: 8px;
}

.FleschKincaidScoreTooltip-module__St357N5 {
  display: flex;
  margin: 0 0 14px;
  width: 100%;
}

.FleschKincaidScoreTooltip-module__wh9hy6v {
  padding: 22px 0 0;
}

@media only screen and (height <= 592px) {
  .FleschKincaidScoreTooltip-module__wh9hy6v {
    height: calc(100vh - 152px);
    overflow-y: auto;
  }
}

.FleschKincaidScoreTooltip-module__CK4hqDG {
  display: block;
  min-width: 48px;
  height: 35px;
  padding: 6px 7px;
  border-radius: 4px;
  margin: 0 10px 0 0;
  color: var(--classic-black);
  font-size: 13px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  white-space: nowrap;

  &.FleschKincaidScoreTooltip-module__SmeHJFH {
    background: var(--writer-green-3);
  }

  &.FleschKincaidScoreTooltip-module__EN6qEmR {
    background: var(--writer-blue-2);
  }

  &.FleschKincaidScoreTooltip-module__TGv-Run {
    background: var(--classic-purple-1);
  }
}

.FleschKincaidScoreTooltip-module__D-Cvt8y {
  display: flex;
  flex-direction: column;
}

.FleschKincaidScoreTooltip-module__hkcWg0M {
  margin-bottom: 2px;
}

.FleschKincaidScoreTooltip-module__rrTap4d .MuiTooltip-arrow {
  /* color: var(--white) !important; */
}
`;

export default {"tooltipContainer":"FleschKincaidScoreTooltip-module__TusKVg-","tooltipWrapper":"FleschKincaidScoreTooltip-module__xfQRudm","scoreTooltip":"FleschKincaidScoreTooltip-module__-MsQeya","emptyTooltip":"FleschKincaidScoreTooltip-module__k2Qyec7","scoreItem":"FleschKincaidScoreTooltip-module__St357N5","descriptions":"FleschKincaidScoreTooltip-module__wh9hy6v","score":"FleschKincaidScoreTooltip-module__CK4hqDG","scoreGreen":"FleschKincaidScoreTooltip-module__SmeHJFH","scoreBlue":"FleschKincaidScoreTooltip-module__EN6qEmR","scorePurple":"FleschKincaidScoreTooltip-module__TGv-Run","scoreDescription":"FleschKincaidScoreTooltip-module__D-Cvt8y","scoreDescriptionMain":"FleschKincaidScoreTooltip-module__hkcWg0M","white":"FleschKincaidScoreTooltip-module__rrTap4d"};
export { css, digest };
  