import { useEffect, useState, useRef, useCallback, useMemo } from "react";
import { connect } from "react-redux";
import ReactQuill from "react-quill";
import QuillBetterTable from "quill-better-table";
import Delta from "quill-delta";
import {
  // AnalyticsActivity,
  findSnippetsByShortcut,
  // IntegrationType,
  IssueCardType,
  IssueFlag,
  Request,
} from "@writerai/common-utils";
import {
  hasInsertOrDelete,
  normalizeAndCleanDelta,
  QL_SNIPPET_HIGHLIGHT_FORMAT_NAME
} from '@writerai/quill-delta-utils';

import initQuill from "../../utils/customQuill";
import CardDisplay from "../../components/CardDisplay";
import SnippetDisplay from "../../components/SnippetDisplay";
import SideBar from "../../components/SideBar";
import { KeepWritingButton } from "../KeepWritingButton";
import {
  changeIssuess,
  changeIssuessPositions,
  selectIssue,
  setDocumentContent,
  setDocumentDelta,
  setDocumentFragment,
  setIssues,
} from "../../redux/documents/actions";
import { formatLineBreaks, replaceSuggestion } from "../../utils/editorUtils";
import { deleteHighlight, highlightWord } from "../../utils/utils";
import { FORMATS } from "./consts";
import Header from "../../common/Header";
import CloseModal from "../../common/CloseModal";
import ControlButtons from "../../common/ControlButtons";
import { useAutoWrite } from "../../content/hooks/useAutoWrite";

import "quill/dist/quill.snow.css";
import "quill-better-table/dist/quill-better-table.css";
import styles from "./style.module.scss";
import { createRequestService } from "@writerai/network";
import { clientId } from "../../utils/constants";
import packageJson from "../../../package.json";

initQuill();

const ContentForm = ({
  fieldValue,
  setField,
  setHtml,
  dispatch,
  closeWindow,
  fieldType,
  fieldSupportsTables,
  subscription,
  personaId,
  workspaceId,
  organizationId,
  documentId,
  organizationName,
  newIssues,
  authToken,
}) => {
  const quillRef = useRef(null);
  const dataRef = useRef(null);
  const startChangePosRef = useRef(null);
  const hiddenChangedIssuesIdsRef = useRef([]);
  const mainWrapperRef = useRef(null);
  const modelRef = useRef(null);

  const [oldDelta, setDelta] = useState({});
  const [isModalShown, setShown] = useState(false);
  const [previousId, setPreviousId] = useState(null);

  const sendAllData = async () => {
    if (!quillRef.current) return;
    const maximumSize = 120000;

    const fullDelta = quillRef.current.editor.getContents();
    const contentDelta = quillRef.current.editor.getContents(0, maximumSize);

    await dispatch(setDocumentContent(contentDelta));

    if (fullDelta.length() > maximumSize) {
      setTimeout(() => {
        const deltaPart = quillRef.current.editor.getContents(maximumSize);

        dispatch(setDocumentDelta(deltaPart));
      }, 3000);
    }

    setDelta(fullDelta);
  };

  const highlightIssue = (event) => {
    const issueId = event.target.getAttribute("data-id");
    const color = event.target.style.borderBottom.split("solid")[1]?.slice(1);

    dispatch(selectIssue(issueId));
    highlightWord(issueId, color);
  };

  const onSelected = (issue) => {
    // fired on SidebarModel side now
    // modelRef.current?.analytics.track(AnalyticsActivity.suggestionViewed, {
    //   card_type: IssueCardType.INLINE,
    //   integration_type: IntegrationType.CONTENTFUL_PLUGIN,
    //   suggestion_category: issue.category,
    //   suggestion_issue_type: issue.issueType,
    // });
  };

  useEffect(() => {
    quillRef.current?.editor.root.setAttribute("spellcheck", "false");
    quillRef.current?.editor.setSelection(0, 0, "api");

    if (quillRef.current) {
      const toolBar = quillRef.current.editor.getModule("toolbar");

      toolBar?.addHandler("clean", function () {
        const editor = quillRef.current.editor;

        FORMATS.forEach((format) => {
          if (format !== "QA_TEXTHIGHLIGHT_FORMAT_NAME") {
            editor.format(format, false, "user");
          }
        });
      });
    }

    quillRef.current?.editor.clipboard.addMatcher(
      "embed",
      function (node) {
        const linkType = node.getAttribute("linktype");
        const embedType = node.getAttribute("embedtype");
        const embedId = node.getAttribute("embedid");
        const nodeType = node.getAttribute("nodetype");

        const ops = [{
          insert: ` [embed ${embedId}]`,
          attributes: { embed: { linkType, embedType, embedId, nodeType } },
        }, {
          insert: ' ',
        }];
        if (nodeType !== 'embedded-entry-inline') {
          ops.push({ insert: '\n' });
        }

        return { ops };
      }
    );
    quillRef.current?.editor.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
      delta.forEach(e => {
        if (e.insert && typeof e.insert === 'string') {
          let regExpToFormat;
          try {
            regExpToFormat = new RegExp(e.insert.replaceAll('\n', '').replace(/[.*+?^${}()|[\]\\]/g, '\\$&').replaceAll(' ', ' +'), 'g');
          } catch (e) {
            console.warn("Unable to configure regExpToFormat");
          }
          const matchInNodeContent = node?.textContent?.match(regExpToFormat)?.[0];
          const nodeContentSpaces = matchInNodeContent?.match(/ +/g);
          let replacedCount = 0;
          e.insert = matchInNodeContent ? e.insert.replaceAll(/ +/g, () => {
            replacedCount++;
            return nodeContentSpaces[replacedCount - 1];
          }) : e.insert;
        }
        if (e.attributes) {
          e.attributes.color = '';
          e.attributes.background = '';
        }
      });
      return delta;
    });
    quillRef.current?.editor.clipboard.addMatcher('th', (node, delta) => {
      let prevDeltaOperation;
      delta.forEach((e) => {
        if (e.insert && typeof e.insert === 'string' && typeof prevDeltaOperation?.attributes === 'object') {
          const prevDeltaOperationAttributes = prevDeltaOperation?.attributes;
          const containsAttributeWithValue = Object.values(prevDeltaOperationAttributes).some(attributeValue => !!attributeValue);
          if (containsAttributeWithValue && e.insert.endsWith('\n')) {
            e.insert = e.insert.slice(0, -1);
          }
        }
        prevDeltaOperation = e;
      });
      return delta;
    });

    let delta;
    if (typeof fieldValue === 'string') {
      delta = quillRef.current?.editor.clipboard.convert({ html: fieldValue });
    } else {
      delta = fieldValue;
    }

    quillRef.current?.editor.setContents(delta, "silent");
    const history = quillRef.current?.editor.getModule("history");
    history.clear();
    quillRef.current?.editor.setSelection(0, 0, "api");

    if (subscription) {
      sendAllData();
    }

    return () => clearTimeout(dataRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendData = async (startChangePos) => {
    if (!quillRef.current) return;

    const newTextDelta = quillRef.current.editor.getContents();

    if (Object.keys(oldDelta).length > 0) {
      const delta = oldDelta.diff(newTextDelta, startChangePos);

      if (delta.ops.length > 0 && subscription && subscription.status !== 'canceled') {
        if (delta.ops.length > 300) {
          sendAllData();
        } else {
          dispatch(setDocumentFragment(delta, newTextDelta));
          setDelta(newTextDelta);
        }
      } else {
        hiddenChangedIssuesIdsRef.current.forEach((hiddenChangedIssueId) => modelRef.current?.issues.setIssueVisibility(hiddenChangedIssueId, true));
        hiddenChangedIssuesIdsRef.current = [];
      }
    }
  };

  const checkIfIssueWasEdited = (delta) => {
    if (hasInsertOrDelete(delta) && quillRef.current.editor) {
      const range = quillRef.current.editor.getSelection();

      if (!range) {
        return;
      }

      const format = quillRef.current.editor.getFormat(range);

      if (typeof format["QA_TEXTHIGHLIGHT_FORMAT_NAME"] === 'string') {
        const highlightIds = (format["QA_TEXTHIGHLIGHT_FORMAT_NAME"]).split(',');
        const issuesToInvalidate = highlightIds.map((issueId) => newIssues.find(issue => issue.issueId === issueId)).filter(Boolean);
        issuesToInvalidate.forEach(issueToInvalidate => {
          modelRef.current?.issues.setIssueVisibility(issueToInvalidate.issueId, false);
          dispatch(setIssues(modelRef.current?.issues.currentIssues));
        });
        if (issuesToInvalidate.length) {
          dispatch(selectIssue(""));
        }
        hiddenChangedIssuesIdsRef.current = issuesToInvalidate.map(issueToInvalidate => issueToInvalidate.issueId);
      }
    }
  };

  const sendFragment = (text, delta, source, editor) => {
    if (!quillRef.current) return;

    if (source === "user" && delta) {
      if (delta.ops.length === 2) {
        const displacement = delta.ops[1].insert
          ? delta.ops[1].insert.length
          : -delta.ops[1].delete;

        dispatch(changeIssuess(delta.ops[0].retain, displacement));
      } else if (delta.ops.length === 1) {
        const displacement = delta.ops[0].insert
          ? delta.ops[0].insert.length
          : -delta.ops[0].delete;

        dispatch(changeIssuess(0, displacement));
      }
      checkIfIssueWasEdited(delta, editor);
    }

    if (text) {
      setHtml(text);
    }

    setField(editor.getContents());

    if (source === "user" || source === "silent") {
      if (delta?.ops?.length === 1 && delta.ops[0]?.attributes?.['QA_TEXTHIGHLIGHT_FORMAT_NAME'] === null) return;
      if (!startChangePosRef.current) {
        startChangePosRef.current = delta?.ops?.[0]?.retain || null;
      }
      clearTimeout(dataRef.current);
      dataRef.current = setTimeout(() => {
        sendData(startChangePosRef.current);
        startChangePosRef.current = null;
      }, 1000);
    }
  };

  const changeExitModal = () => {
    setShown(!isModalShown);
  };

  const onSearchSnippet = useCallback(
    async (shortcuts) =>
      findSnippetsByShortcut(organizationId, workspaceId, shortcuts),
    [organizationId, workspaceId]
  );

  const onSnippetApply = async (snippetIdOrShortcut = '', findById = false) => {
    const snippetId = findById ?
      (await findSnippetsByShortcut(organizationId, workspaceId, [snippetIdOrShortcut.replace('w.', '')]))?.[0].snippetId :
      snippetIdOrShortcut;
    await Request.getAxiosInstance().put(
      `/terminology/organization/${organizationId}/team/${workspaceId}/snippet/${snippetId}/apply`,
    );
  };

  const onKeyDownCapture = async (e) => {
    if (e.keyCode === 13 && !e.metaKey && !e.altKey) {
      const editor = quillRef.current.editor;
      const selection = editor.getSelection();
      const textBeforeLineBreak = quillRef.current.editor.getText(0, selection?.index || 0);
      const contentsBeforeLineBreak = quillRef.current.editor.getContents(0, selection?.index || 0);
      if (contentsBeforeLineBreak.ops?.length) {
        const lastOp = contentsBeforeLineBreak.ops[contentsBeforeLineBreak.ops.length - 1];
        if (lastOp?.attributes?.snippethighlight === true && lastOp.insert.includes('w.')) {
          const from = textBeforeLineBreak.lastIndexOf(lastOp.insert);
          const length = lastOp.insert.length;
          const snippetData = await findSnippetsByShortcut(organizationId, workspaceId, [lastOp.insert.replace('w.', '')]);
          if (!snippetData[0]) return;
          const { snippet, snippetId } = snippetData[0];

          let applyDelta;
          const snippetInsertDelta = editor.clipboard.convert({ html: snippet });
          editor.formatText(from, length, QL_SNIPPET_HIGHLIGHT_FORMAT_NAME, false, 'api');

          applyDelta = normalizeAndCleanDelta(snippetInsertDelta);

          const range = editor.getSelection(true);
          const [thisLeaf] = editor.getLine(range.index);
          if (thisLeaf && thisLeaf.domNode?.className === 'qlbt-cell-line') {
            const [line] = editor.getLine(from);
            const lineFormats = line.formats();
            applyDelta = formatLineBreaks(applyDelta, lineFormats);
          }

          applyDelta = new Delta().retain(from).delete(length).concat(applyDelta);
          editor.updateContents(applyDelta, 'user');
          onSnippetApply(snippetId);
        }
      }
    }
  };

  const applySuggestionInline = (replacement, issue) => {
    const { issueId } = issue;
    const { length } = newIssues.find((element) => element.issueId === issueId);
    const newTextDelta = quillRef.current.editor;
    const newDisplacement = replacement
      ? replacement.length - length
      : 0 - length;

    if (issueId !== previousId) {
      highlightWord();
      replaceSuggestion(newTextDelta, issue, replacement);
      deleteHighlight(issue);

      dispatch(changeIssuessPositions(newDisplacement, issueId));
      modelRef.current?.issues.onApplySuggestion(replacement, issue, IssueCardType.INLINE);

      sendFragment(null, null, "user", quillRef.current.editor);
      setPreviousId(issueId);
    }
  };

  const applySuggestion = (replacement, issue) => {
    const { issueId } = issue;
    const { length } = newIssues.find((element) => element.issueId === issueId);
    const newTextDelta = quillRef.current.editor;
    const newDisplacement = replacement
      ? replacement.length - length
      : 0 - length;

    if (issueId !== previousId) {
      highlightWord();
      replaceSuggestion(newTextDelta, issue, replacement);
      deleteHighlight(issue);

      dispatch(changeIssuessPositions(newDisplacement, issueId));

      sendFragment(null, null, "user", quillRef.current.editor);
      setPreviousId(issueId);
    }
  };

  const flagSuggestion = (issue, flagType, comment) => {
    if (flagType === IssueFlag.WRONG) {
      return modelRef.current?.issues.onMarkIssueAsWrong(issue, IssueCardType.INLINE, comment);
    } else {
      return modelRef.current?.issues.onDeleteIssueClick(issue, IssueCardType.INLINE);
    }
  };

  const baseConfig = useMemo(
    () => ({
      personaId: `${personaId}`,
      documentId: `${documentId}`,
      workspaceId: `${workspaceId}`,
      organizationId: `${organizationId}`,
      authToken,
      appRoot: `https://${
        process.env.NODE_ENV !== 'production'
          ? process.env.REACT_APP_API_HOST
          : window.env.REACT_APP_API_HOST
      }`,
      organizationName: organizationName,
    }),
    [
      personaId,
      workspaceId,
      authToken,
      organizationId,
      documentId,
      organizationName,
    ]
  );

  const requestService = useMemo(() => createRequestService({
    clientId,
    clientVersion: packageJson.version,
    baseUrl: `https://${
      process.env.NODE_ENV !== 'production'
        ? process.env.REACT_APP_API_HOST
        : window.env.REACT_APP_API_HOST
    }`,
    middleware: [
      (url, init, next) =>
        next(url, {
          ...init,
          headers: new Headers({
            ...(init.headers instanceof Headers ? Object.fromEntries(init.headers) : init.headers),
            'X-Auth-Token': baseConfig.authToken || '',
            'X-Client': clientId,
            'X-Client-Ver': packageJson.version,
          }),
        }),
    ],
  }), [baseConfig.authToken]);

  const { onClickAutoWrite, isAutoWriteAvailable, autoWriteLoading, autoWriteToolTipText } = useAutoWrite(quillRef, modelRef, subscription, organizationId, workspaceId, requestService);
  const onCmdEnterClick = useCallback(() => onClickAutoWrite(true), [onClickAutoWrite]);
  const keyboardCallbacks = useRef({ onCmdEnterClick });

  const modules = useMemo(() => {
    const apiRoot = `https://${
      process.env.NODE_ENV !== 'production'
        ? process.env.REACT_APP_API_HOST
        : window.env.REACT_APP_API_HOST
    }/api`;

    if (fieldType === 'RichText' || fieldType === 'Text') {
      return {
        toolbar: [
          [{ header: [false, 1, 2, 3, 4, 5, 6] }],
          fieldType === 'Text' ? ["bold", "italic", "code"] : ["bold", "italic", "underline", "code"],
          ["link"],
          [{ list: "bullet" }, { list: "ordered" }, "blockquote"],
          ["clean"],
        ],
        table: false,
        'better-table': fieldSupportsTables,
        cursors: true,
        autowrite: {
          streamUrl: `${apiRoot}/generation/organization/${organizationId}/team/${workspaceId}/autowrite/stream`,
          documentId,
          organizationId,
          authToken: authToken,
          useToken: true,
        },
        keyboard: {
          bindings: {
            ...QuillBetterTable.keyboardBindings,
            cmdEnter: {
              key: 'Enter',
              metaKey: true,
              handler: () => keyboardCallbacks.current?.onCmdEnterClick(),
            },
            ctrlEnter: {
              key: 'Enter',
              ctrlKey: true,
              handler: () => keyboardCallbacks.current?.onCmdEnterClick(),
            },
          },
        },
        snippets: {
          onSearchSnippet,
        },
      };
    }

    return {
      toolbar: false,
      cursors: true,
      autowrite: {
        streamUrl: `${apiRoot}/generation/organization/${organizationId}/team/${workspaceId}/autowrite/stream`,
        documentId,
        organizationId,
        authToken: authToken,
        useToken: true,
      },
      keyboard: {
        bindings: {
          cmdEnter: {
            key: 'Enter',
            metaKey: true,
            handler: () => keyboardCallbacks.current?.onCmdEnterClick(),
          },
          ctrlEnter: {
            key: 'Enter',
            ctrlKey: true,
            handler: () => keyboardCallbacks.current?.onCmdEnterClick(),
          },
        },
      },
      snippets: {
        onSearchSnippet,
      },
    };
  }, [fieldType, onSearchSnippet, fieldSupportsTables, organizationId, workspaceId, documentId, authToken]);

  useEffect(() => {
    keyboardCallbacks.current = { onCmdEnterClick };
  }, [onCmdEnterClick]);

  return (
    <div className={styles.wrapper}>
      <CloseModal
        closeDialog={closeWindow}
        isModalShown={isModalShown}
        changeExitModal={changeExitModal}
      />
      <Header closeWindow={changeExitModal} />
      <div className={styles.wrapper__main} ref={mainWrapperRef}>
        <div
          className={styles.main__content}
          onKeyDownCapture={onKeyDownCapture}
        >
          <ReactQuill
            ref={quillRef}
            className={`${styles.content__field}${fieldType === 'RichText' ? ` ${styles.richText}` : ''}`}
            onChange={sendFragment}
            modules={modules}
            formats={FORMATS}
          />
          <CardDisplay quillRef={quillRef} highlightIssue={highlightIssue} onSelected={onSelected} applySuggestion={applySuggestionInline} flagSuggestion={flagSuggestion} />
          <SnippetDisplay quillRef={quillRef} mainWrapperRef={mainWrapperRef} onSnippetApply={onSnippetApply} />
          <div className={styles.content__control}>
            <ControlButtons closeDialog={closeWindow} />
          </div>
          <KeepWritingButton
            className={styles.containerToolButtonContainer}
            onClickAutoWrite={onClickAutoWrite}
            isAutoWriteAvailable={isAutoWriteAvailable}
            autoWriteLoading={autoWriteLoading}
            autoWriteToolTipText={autoWriteToolTipText}
          />
        </div>
        <SideBar
          quillRef={quillRef}
          modelRef={modelRef}
          applySuggestion={applySuggestion}
          baseConfig={baseConfig}
          requestService={requestService}
        />
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth, app, documents }) => {
  return {
    fieldType: app.fieldType,
    fieldSupportsTables: app.fieldSupportsTables,
    subscription: auth.subscription,
    newIssues: documents.issues,
    authToken: auth.authToken,
  };
};

export default connect(mapStateToProps)(ContentForm);
