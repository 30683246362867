import { makeObservable, computed } from 'mobx';
import type Delta from 'quill-delta';
import type { IFirebaseIssue, ICollaborationUserWithMeta, IStyleguide } from '@writerai/types';
import type { DocumentData, DocumentReference } from 'firebase/firestore';
import { Subscriber } from '@writerai/mobx';
import type { IFirebaseClient, IFirebaseApi, IPipelineContentHashes, IStyleguideMapping } from '@writerai/firebase';
import { getLogger } from '../../utils/logger';

const LOG = getLogger('FirebaseModel');

interface IFirebaseModelOpts {
  client: IFirebaseClient;
}

export class FirebaseModel {
  private readonly client: IFirebaseModelOpts['client'];

  constructor({ client }: IFirebaseModelOpts) {
    LOG.debug('constructor');

    this.client = client;

    makeObservable(this, {
      documentViewers: computed,
      currentFirebaseUser: computed,
      documentContentData: computed,
      firebaseIssuesData: computed,
    });
  }

  readonly firebaseIssues = new Subscriber<IFirebaseApi, IFirebaseIssue[]>({
    name: 'firebaseIssuesSubscriber',
    autoclear: true,
    getId: () => this.client.api,
    subscribe: (api, onDataUpdate) => api.onFirebaseIssuesSubscriber?.(onDataUpdate),
  });

  readonly viewers = new Subscriber<IFirebaseApi, ICollaborationUserWithMeta[]>({
    name: 'viewerSubscriber',
    autoclear: true,
    getId: () => this.client.api,
    subscribe: (api, onDataUpdate) => api.onViewerSubscriber?.(onDataUpdate),
  });

  readonly documentContent = new Subscriber<IFirebaseApi, string>({
    name: 'documentContentSubscriber',
    autoclear: true,
    getId: () => this.client.api,
    subscribe: (api, onDataUpdate) => api.onDocumentContentSubscriber?.(onDataUpdate),
  });

  readonly styleguideMapping = new Subscriber<IFirebaseApi, IStyleguideMapping>({
    name: 'styleguideMappingSubscriber',
    autoclear: true,
    getId: () => this.client.api,
    subscribe: (api, onDataUpdate) => api.onStyleguideMappingSubscriber?.(onDataUpdate),
  });

  readonly styleguide = new Subscriber<[IFirebaseApi, DocumentReference<DocumentData>], IStyleguide>({
    name: 'styleguideSubscriber',
    autoclear: true,
    getId: () => {
      const { api } = this.client;

      if (!api) {
        return undefined;
      }

      if (!this.styleguideMapping.data) {
        return undefined;
      }

      return [api, this.styleguideMapping.data.styleguideRef];
    },
    subscribe: ([api, styleguideRef], onDataUpdate) => api.onStyleguideSubscriber?.(styleguideRef, onDataUpdate),
  });

  readonly contentHashes = new Subscriber<IFirebaseApi, IPipelineContentHashes>({
    name: 'contentHashes',
    autoclear: true,
    getId: () => this.client.api,
    subscribe: (api, onDataUpdate) => api.onContentHashes?.(onDataUpdate),
  });

  readonly documentDelta = new Subscriber<IFirebaseApi, Delta>({
    name: 'documentDelta',
    autoclear: true,
    getId: () => this.client.api,
    subscribe: (api, onDataUpdate) => api.onDocumentDelta?.(onDataUpdate),
  });

  get styleguideData() {
    return this.styleguide.data;
  }

  get documentViewers() {
    return this.viewers.data || [];
  }

  get currentFirebaseUser() {
    return this.client.user;
  }

  get api(): Pick<IFirebaseApi, 'getDocumentDelta' | 'updateDocumentUser' | 'getDocumentPipeline'> {
    return this.client.api;
  }

  get documentContentData() {
    const documentId = this.client.params.documentId();

    if (!documentId) {
      return undefined;
    }

    return this.documentContent.data;
  }

  get firebaseIssuesData() {
    const documentId = this.client.params.documentId();

    if (!documentId) {
      return undefined;
    }

    return this.firebaseIssues.data;
  }
}
