
const digest = '30fb208a53ad71d4df66aade11076bfb74909897cfcbc3e3877bbed75d805888';
const css = `.styles-module__RBCl3Nm {
  max-height: 100%;
  flex: 1 1 auto;
  padding: 2px var(--sidebar-horizontal-spacing);
  -ms-overflow-style: none !important;
  overflow-y: scroll;
}

.styles-module__bWx8G3P {
  padding: 2px var(--sidebar-horizontal-spacing-extra-small);
}

.styles-module__RBCl3Nm::-webkit-scrollbar {
  width: 0;
}

.styles-module__nKSpMqB {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.styles-module__3I-PHfm {
  text-align: center;
}
`;

export default {"issuesList":"styles-module__RBCl3Nm","issuesListWide":"styles-module__bWx8G3P","listScrollEmptySection":"styles-module__nKSpMqB","listScrollEmptySectionText":"styles-module__3I-PHfm"};
export { css, digest };
  