import { Quill } from 'react-quill';


const Embed = Quill.import('blots/embed');

export class CustomEmojiFormat extends Embed {
  static tagName = 'span';
  static blotName = 'emoji';
  static className = 'ql-emoji';

  static create(value) {
    const node = super.create(value);

    node.dataset.emoji = value;
    node.innerHTML = value;
    node.setAttribute('contentEditable', 'false');

    return node;
  }

  static value(node) {
    return node.dataset.emoji;
  }
}
