export const QA_TEXTHIGHLIGHT_FORMAT_NAME = 'texthighlight';
export const QL_TEXTHIGHLIGHT_CLASS_PREFIX = 'ql-writer-texthighlight';
export const QL_SNIPPET_HIGHLIGHT_FORMAT_NAME = 'snippethighlight';
export const QL_SNIPPET_CLASS_PREFIX = 'ql-writer-snippethighlight';
export const QL_COMMANDS_CLASS_PREFIX = 'ql-writer-commandshighlight';
export const QL_COMMANDS_LOADING_CLASS_PREFIX = 'ql-writer-commandsloading';
export const QA_REWRITE_FORMAT_NAME = 'textrewrite';
export const QA_REWRITE_CLASS_PREFIX = 'ql-writer-rewrite';
export const QA_MAGIC_FORMAT_NAME = 'textmagic';
export const QA_MAGIC_CLASS_PREFIX = 'ql-writer-magic';
export const QA_COMMANDS_FORMAT_NAME = 'textcommands';
export const QA_COMMANDS_LOADING_FORMAT_NAME = 'textcommandsloading';
export const TRIGGER_SNIPPET_EVENT = 'trigger-snippet';

export enum QUILL_FORMAT {
  RESOLUTION = 'resolution',
  FILE_NAME = 'filename',
  WIDTH = 'width',
  HEIGHT = 'height',
  MARGIN = 'margin',
  HEADER = 'header',
  FONT = 'font',
  BOLD = 'bold',
  ITALIC = 'italic',
  UNDERLINE = 'underline',
  STRIKE = 'strike',
  CODE = 'code',
  COLOR = 'color',
  BACKGROUND = 'background',
  LINK = 'link',
  LIST = 'list',
  ALIGN = 'align',
  INDENT = 'indent',
  IMAGE = 'image',
  VIDEO = 'video',
  TABLE = 'table',
  BLOCKQUOTE = 'blockquote',
  CLEAN = 'clean',
}

export const ALLOWED_DELTA_FORMATS = [
  QUILL_FORMAT.HEADER,
  QUILL_FORMAT.FONT,
  QUILL_FORMAT.BOLD,
  QUILL_FORMAT.ITALIC,
  QUILL_FORMAT.UNDERLINE,
  QUILL_FORMAT.STRIKE,
  QUILL_FORMAT.CODE,
  QUILL_FORMAT.COLOR,
  QUILL_FORMAT.BACKGROUND,
  QUILL_FORMAT.LINK,
  QUILL_FORMAT.LIST,
  QUILL_FORMAT.ALIGN,
  QUILL_FORMAT.INDENT,
  QUILL_FORMAT.VIDEO,
  // QUILL_FORMAT.IMAGE, // We don't support images now
  QUILL_FORMAT.BLOCKQUOTE,
  QUILL_FORMAT.CLEAN,
  QUILL_FORMAT.WIDTH,
  QUILL_FORMAT.HEIGHT,
  QUILL_FORMAT.MARGIN,
  QUILL_FORMAT.RESOLUTION,
  QUILL_FORMAT.FILE_NAME,
];

export const BLOCKED_SERVER_INSERTS = [QA_COMMANDS_FORMAT_NAME];

export const ALLOWED_QUILL_FORMATS = [
  QL_SNIPPET_HIGHLIGHT_FORMAT_NAME,
  QA_TEXTHIGHLIGHT_FORMAT_NAME,
  QA_REWRITE_FORMAT_NAME,
  QA_MAGIC_FORMAT_NAME,
  QA_COMMANDS_FORMAT_NAME,
  QA_COMMANDS_LOADING_FORMAT_NAME,
  ...ALLOWED_DELTA_FORMATS,
];
