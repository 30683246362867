import cloneDeep from 'lodash/cloneDeep';
import { normalizeAndCleanDelta, calculateChecksumOverDelta } from '@writerai/quill-delta-utils';

import {
  getOrganizationId,
  getWorkspaceId,
  getDocumentId,
} from '../../utils/getter';
import API from '../../utils/api';
import { clientId } from '../../utils/constants';
import types from './constants';

export function setDocumentContent(content) {
  return async (dispatch, getState) => {
    const state = getState();

    const organizationId = getOrganizationId(state);
    const workspaceId = getWorkspaceId(state);
    const documentId = getDocumentId(state);
    const contentClone = cloneDeep(content);
    const normalizedDelta = normalizeAndCleanDelta(contentClone);

    API.setDocumentContent({
      workspaceId,
      organizationId,
      personaId: 94,
      documentId,
      content: normalizedDelta,
      clientId: clientId,
    });
  }
}

export function setDocumentFragment(delta, allDelta) {
  return async (dispatch, getState) => {
    const state = getState();
    const organizationId = getOrganizationId(state);
    const workspaceId = getWorkspaceId(state);
    const documentId = getDocumentId(state);
    const deltaClone = cloneDeep(delta);
    const normalizedDelta = normalizeAndCleanDelta(deltaClone);

    const { data: { checksum } } = await API.setDocumentFragment({
      workspaceId,
      organizationId,
      personaId: 94,
      documentId,
      delta: normalizedDelta,
      clientId: clientId,
    });

    const allDeltaClone = cloneDeep(allDelta);
    const normalizedAllDelta = normalizeAndCleanDelta(allDeltaClone);
    const localChecksum = calculateChecksumOverDelta(normalizedAllDelta);

    if (!checksum === localChecksum) {
      setDocumentContent(allDelta);
    }
  };
}

export function setDocumentDelta(delta) {
  return async (dispatch, getState) => {
    const state = getState();
    const organizationId = getOrganizationId(state);
    const workspaceId = getWorkspaceId(state);
    const documentId = getDocumentId(state);

    await API.setDocumentDelta({
      workspaceId,
      organizationId,
      personaId: 94,
      documentId,
      delta: normalizeAndCleanDelta(delta),
      clientId: clientId,
    });
  };
}

export function setIssues(issues) {
  return (dispatch) => {
    dispatch({
      type: types.SET_ISSUES,
      payload: {
        issues
      },
    });
  }
}

export function selectIssue(selectedIssueId) {
  return (dispatch) => {
    dispatch({
      type: types.SELECT_ISSUE,
      payload: {
        selectedIssueId,
      },
    });
  }
}

export function changeIssuessPositions(displacement, issueId) {
  return (dispatch, getState) => {
    const { documents: { issues } } = getState();

    const issueIndex = issues.findIndex((issue) => {
      return issue.issueId === issueId;
    });

    const newIssues = issues.map((issue, index) => {
      if (issueIndex < index) {
        return {
          ...issue,
          from: issue.from + displacement
        }
      } else {
        return issue;
      }
    });

    dispatch({
      type: types.SET_ISSUES,
      payload: {
        issues: newIssues
      },
    });
  }
}

export function changeIssuess(changePosition, displacement) {
  return (dispatch, getState) => {

    const { documents: { issues } } = getState();

    if (displacement) {
      const newIssues = issues.map((issue) => {
        if (issue.from > changePosition) {
          return {
            ...issue,
            from: issue.from + displacement
          }
        } else {
          return issue;
        }
      });

      dispatch({
        type: types.SET_ISSUES,
        payload: {
          issues: newIssues
        },
      });
    }
  }
}

export function setCategoryUpdate(categoryChanged) {
  return (dispatch) => {
    dispatch({
      type: types.SET_CATEGORY,
      payload: {
        categoryChanged
      },
    });
  }
}
