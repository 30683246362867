export const languageSelectOptions = [
  {
    id: 'en-us',
    name: 'English - United States',
    className: "flag unitedStatesFlag",
  },
  {
    id: 'en-au',
    name: 'English - Australia',
    className: "flag australiaFlag",
  },
  {
    id: 'en-ca',
    name: 'English - Canada',
    className: "flag canadaFlag",
  },
  {
    id: 'en-gb',
    name: 'English - United Kingdom',
    className: "flag unitedKingdomFlag",
  },
];
