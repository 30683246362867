import { computed } from 'mobx';

import { initializeFirestore } from 'firebase/firestore';

import { PromisedModel } from '@writerai/mobx';
import type { RequestServiceInitialize } from '@writerai/network';

import { createFirebaseApi, initApp, loadUser } from './FirebaseApi';
import type { IFirebaseClient, IFirebaseConfig, IFirebaseParams, IFirebaseSettings } from './types';

interface IFirebaseClientOptions {
  config: IFirebaseConfig;
  settings: IFirebaseSettings;
  request: RequestServiceInitialize['api'];
  params: IFirebaseParams;
}

export function createFirebaseClient({ config, request, settings, params }: IFirebaseClientOptions) {
  const app = initApp(config, settings);

  const user = new PromisedModel({
    name: 'user',
    load: async () => {
      const organizationId = params.organizationId();

      if (!organizationId) {
        return undefined;
      }

      return loadUser(app, request, settings, organizationId);
    },
  });

  const api = computed(() => {
    if (user.status !== 'fulfilled') {
      return undefined;
    }

    const firestore = initializeFirestore(app, settings.firestoreSettings || {}, config.databaseId);

    return createFirebaseApi(firestore, params);
  });

  return Object.defineProperties({} as IFirebaseClient, {
    params: {
      enumerable: true,
      configurable: true,
      get() {
        return params;
      },
    },
    user: {
      enumerable: true,
      configurable: true,
      get() {
        return user.value;
      },
    },
    api: {
      enumerable: true,
      configurable: true,
      get() {
        return api.get();
      },
    },
  });
}
