import get from 'lodash/get';

export const formatOrganizations = organizations => {
  return organizations.reduce((acc, currOrg) => {
    const customerType = get(currOrg, 'billingData.customerType');

    if (customerType !== 'legacy') {
      acc.push({
        ...currOrg,
        customerType,
        organizationId: currOrg.id,
        organizationName: currOrg.name,
      });
    }

    return acc;
  }, []);
};
