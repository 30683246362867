import types from './constants';

export default function appReducer(
  state = {
    loginType: 'main',
    fieldType: 'RichText',
    fieldSupportsTables: true,
  },
  { type, payload },
) {
  switch (type) {
    case types.SET_LOGIN: {
      const {
        loginType
      } = payload;

      return {
        ...state,
        loginType
      };
    }
    case types.SET_FIELD: {
      const {
        fieldType
      } = payload;

      return {
        ...state,
        fieldType
      };
    }
    case types.SET_FIELD_SUPPORTS_TABLES: {
      const {
        fieldSupportsTables
      } = payload;

      return {
        ...state,
        fieldSupportsTables
      };
    }
    default:
      return state;
  }
};
