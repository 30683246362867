import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import differenceBy from "lodash/differenceBy";

import Card from "../../components/Card";
import { selectIssue } from "../../redux/documents/actions";
import { getCardPosition } from "./utils";
import { highlightWord, changeIssue, deleteHighlight } from "../../utils/utils";

import styles from "./style.module.scss";

const CardDisplay = ({
  issues,
  selectedIssueId,
  quillRef,
  dispatch,
  highlightIssue,
  applySuggestion,
  flagSuggestion,
  onSelected,
}) => {
  const cardRef = useRef(null);
  const [cardShow, setCardShow] = useState(false);
  const [issueIdValidHeight, setIssueIdValidHeight] = useState();
  const [issueIdValidPosition, setIssueIdValidPosition] = useState();
  const [oldIssues, setOldIssues] = useState([]);
  const [selectedIssue, setIssue] = useState({});
  const [isScroll, setScroll] = useState(false);

  useEffect(() => {
    if (quillRef) {
      const qullEditor = quillRef.current.editor.root;

      const handler = (event) => {
        const isSpan = event.target.hasAttribute("data-id");
        const isEditor = event.target.hasAttribute("data-gramm");

        if (!isSpan && !isEditor) {
          setCardShow(false);
          dispatch(selectIssue(""));
          highlightWord();
        }

        if (isSpan) {
          highlightIssue(event);
          setCardShow(true);
        }
      };

      const clickOutSideListener = (e) => {
        if (!qullEditor || qullEditor.contains(e.target) || cardRef.current?.contains(e.target)) {
          return;
        }

        setCardShow(false);
      };

      qullEditor.addEventListener("mouseup", handler);
      document.addEventListener("mousedown", clickOutSideListener);

      return () => {
        qullEditor.removeEventListener("mouseup", handler);
        document.removeEventListener("mousedown", clickOutSideListener);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (quillRef && quillRef.current.editor.root) {
      const qullEditor = quillRef.current.editor.root;

      const handler = () => {
        setScroll(!isScroll);
      };

      qullEditor.addEventListener("scroll", handler);

      return () => qullEditor.removeEventListener("scroll", handler);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScroll]);

  useEffect(() => {
    const qullEditor = quillRef?.current.editor;
    const deletedIssues = differenceBy(oldIssues, issues, "issueId");
    const issuesDifference = differenceBy(issues, oldIssues, "issueId");

    deletedIssues.forEach((deletedIssue) => {
      deleteHighlight(deletedIssue, qullEditor);
    });

    issuesDifference.forEach((issue, index) => {
      let shouldSelectFirst = deletedIssues.length;
      setTimeout(() => {
        changeIssue(issue, "position", quillRef);
        const {
          issueId,
          sidebarCategory,
        } = issues[0];
        if (issue.issueId === selectedIssueId) {
          setTimeout(() => {
            highlightWord(issue.issueId, sidebarCategory?.color);
          }, 300);
          shouldSelectFirst = false;
        }
        if (index - 1 === issuesDifference.length && shouldSelectFirst) {
          setTimeout(() => {
            highlightWord(issueId, sidebarCategory?.color);
          }, 300);
        }
      }, 0);
    });

    setOldIssues(issues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(issues)]);

  useEffect(() => {
    issues.some((issue) => {
      if (issue.issueId === selectedIssueId) {
        setIssue(issue);
        onSelected(issue);
        return true;
      }
      return false;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIssueId]);

  useEffect(() => {
    setIssueIdValidHeight(selectedIssue?.issueId);
  }, [selectedIssue]);

  useEffect(() => {
    setIssueIdValidPosition(selectedIssue?.issueId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [issueIdValidHeight]);

  const { style, visibility } = getCardPosition(
    selectedIssueId,
    cardRef,
    cardShow && issueIdValidHeight === selectedIssueId,
    issueIdValidPosition === selectedIssueId,
    selectedIssue,
  );

  return (
    <React.Fragment>
      <div
        ref={cardRef}
        className={styles.decoration__card}
        style={style}
      >
        <Card
          cardShow={visibility === "invert"}
          issue={selectedIssue}
          cardClicked={setCardShow}
          applySuggestion={applySuggestion}
          flagSuggestion={flagSuggestion}
        />
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ documents }) => {
  return {
    issues: documents.issues,
    selectedIssueId: documents.selectedIssueId,
    categoryChanged: documents.categoryChanged,
  };
};

export default connect(mapStateToProps)(CardDisplay);
