import { createAtomSubscriber } from '@writerai/mobx';
import { IntegrationType, IssuesPipeline, SidebarViewMode } from '@writerai/types';
import type { IIssue, IssueFlag } from '@writerai/types';
import { autorun } from 'mobx';
import { UICardsPanelModel } from '../CardsPanel';
import { UICategoriesPanelModel } from '../CategoriesPanel';
import { UIIssuesListModel } from '../IssuesList';
import { UIVisibleIssuesListModel } from '../VisibleIssuesList';
import { UIDocumentStatsModel } from '../DocumentStats';
import { UIIssueModel } from '../../models/UIIssueModel';
import type { SidebarModel } from '../../models';

export interface UISidebarModelOptions {
  model: SidebarModel;
  modelUICardsPanelModel?: UICardsPanelModel;
  modelUICategoriesPanelModel?: UICategoriesPanelModel;
  modelUIDocumentStats?: UIDocumentStatsModel;
  modelUIIssuesListModel?: UIIssuesListModel;
  modelVisibleIssuesList?: UIVisibleIssuesListModel;
}

export class UISidebarModel {
  readonly model: SidebarModel;
  readonly modelUICardsPanelModel: UICardsPanelModel;
  readonly modelUICategoriesPanelModel: UICategoriesPanelModel;
  readonly modelUIDocumentStats: UIDocumentStatsModel;
  readonly modelUIIssuesListModel: UIIssuesListModel;
  readonly modelVisibleIssuesList: UIVisibleIssuesListModel;
  readonly modelUIIssues: UIIssueModel;
  constructor({
    model,
    modelUICardsPanelModel,
    modelUICategoriesPanelModel,
    modelUIDocumentStats,
    modelUIIssuesListModel,
    modelVisibleIssuesList,
  }: UISidebarModelOptions) {
    this.modelUIIssues = new UIIssueModel({
      issues: model.issues,
      categories: model.categories,
      analytics: model.analytics,
      eventBus: model.eventBus,
      documentContentData: () => model.firebase.documentContentData,
      documentId: () => model.documentId,
      workspaceId: () => model.workspaceId,
      apiFlagSuggestionAsWrong: async (issue: IIssue, state: IssueFlag, segment: string, comment?: string | null) =>
        model.requestClient.api?.flagSuggestionAsWrong(issue, state, segment, comment),
      apiBulkFlagSuggestionsAsWrong: async flagSuggestionsResults =>
        model.requestClient.api?.flagSuggestionsAsWrong(flagSuggestionsResults),
      apiReportOnAcceptSuggestion: async (replacement: string, issue: IIssue, segment: string) =>
        model.requestClient.api?.reportOnAcceptSuggestion(replacement, issue, segment) as Promise<unknown>,
      apiBulkReportOnAcceptSuggestions: async (replacement: string, issues: IIssue[], segment: string) =>
        model.requestClient.api?.reportOnAcceptSuggestions(replacement, issues, segment) as Promise<unknown>,
      apiSuggestionComment: async (issue: IIssue, comment: string) =>
        model.requestClient.api?.suggestionComment(issue, comment),
    });
    model.setImplementation({ issues: this.modelUIIssues });

    this.modelUICategoriesPanelModel = modelUICategoriesPanelModel ?? new UICategoriesPanelModel(model);
    this.modelVisibleIssuesList =
      modelVisibleIssuesList ??
      new UIVisibleIssuesListModel({
        sidebarModel: model,
        uiIssueModel: this.modelUIIssues,
      });
    this.modelUIIssuesListModel =
      modelUIIssuesListModel ??
      new UIIssuesListModel({
        model,
        modelVisibleIssuesList: this.modelVisibleIssuesList,
        analytics: model.analytics,
      });
    this.modelUICardsPanelModel =
      modelUICardsPanelModel ??
      new UICardsPanelModel({
        model,
        modelUICardsPanelModel: this.modelUIIssuesListModel,
      });
    this.modelUIDocumentStats = modelUIDocumentStats ?? new UIDocumentStatsModel({ model });
    this.model = model;
  }

  get isScoreHidden() {
    const { sidebarState, documentStats } = this.model;

    return sidebarState.isEmptyState || !documentStats.score.available;
  }

  get isPreviewMode() {
    return this.model.sidebarState.isPreviewMode;
  }

  readonly onSidebarModeChange = () => {
    this.model.eventBus.emit('onSidebarModeChange', SidebarViewMode.OPEN);
  };

  readonly onSidebarContainerResize = (width: number) => {
    this.model.size.setSidebarWidth(width);
  };

  get isLoading() {
    return this.model.sidebarState.isLoading;
  }

  get scoreValue() {
    return this.model.documentStats.score.value;
  }

  get isMedium() {
    return this.model.size.isMedium;
  }

  get isSmall() {
    return this.model.size.isSmall;
  }

  get isStandard() {
    return this.model.size.isStandard;
  }

  get isStatsCollapsed() {
    return [IntegrationType.WORD_PLUGIN, IntegrationType.OUTLOOK_PLUGIN].includes(
      this.model.sidebarState.integrationType,
    );
  }

  get statsAreVisible() {
    return this.model.sidebarState.statsAreVisible;
  }

  useReactiveData() {
    this.atom.reportObserved();

    return this;
  }

  private readonly atom = createAtomSubscriber('atom', () =>
    autorun(() => {
      const { $initialPipeline, collaboration, setPipeline, sidebarState, categories, documentId } = this.model;

      if (!documentId) {
        return;
      }

      if ($initialPipeline.status === 'pending') {
        return;
      }

      if (collaboration.isCollaborative) {
        setPipeline(IssuesPipeline.FULL);

        return;
      }

      if (sidebarState.enforcedPipeline) {
        setPipeline(sidebarState.enforcedPipeline);

        return;
      }

      if (categories.isPlagiarismCategorySelected) {
        setPipeline(IssuesPipeline.PLAGIARISM);
      } else {
        setPipeline(IssuesPipeline.BASIC);
      }
    }),
  );
}
