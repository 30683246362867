import React, { useEffect } from 'react';
import Modal from 'react-modal';
import type { LearnMoreModalType } from '@writerai/types';

import { LearnMoreContent } from '../LearnMoreContent';

export interface ILearnMoreModal {
  appElement?: string | HTMLElement;
  modalType?: LearnMoreModalType;
  onCloseModal?: (event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>) => void;
  hideCloseButton?: boolean;
  noUsePortal?: boolean;
}

const customStyles = {
  content: {
    fontFamily: 'Poppins',
    width: '680px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    border: 'none',
    boxShadow: 'var(--modal-box-shadow)',
    borderRadius: '6px',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    maxHeight: '80vh',
    color: 'black',
  },
  overlay: {
    background: 'rgba(0, 20, 53, 0.1)',
    zIndex: 1000,
  },
};

export const LearnMoreModal: React.FC<ILearnMoreModal> = ({
  onCloseModal,
  modalType,
  hideCloseButton,
  noUsePortal,
  appElement,
}) => {
  useEffect(() => {
    if (!noUsePortal && appElement) {
      Modal.setAppElement(appElement);
    } else {
      Modal.setAppElement('body');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appElement]);

  if (noUsePortal) {
    return <LearnMoreContent hideCloseButton={hideCloseButton} onCloseModal={onCloseModal} modalType={modalType} />;
  } else {
    return (
      <Modal isOpen onRequestClose={onCloseModal} style={customStyles} contentLabel="Learn more">
        <LearnMoreContent hideCloseButton={hideCloseButton} onCloseModal={onCloseModal} modalType={modalType} />
      </Modal>
    );
  }
};
