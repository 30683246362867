export enum AVATAR_COLOR {
  AVATAR_1 = 'var(--avatar-1)',
  AVATAR_2 = 'var(--avatar-2)',
  AVATAR_3 = 'var(--avatar-3)',
  AVATAR_4 = 'var(--avatar-4)',
  AVATAR_5 = 'var(--avatar-5)',
  AVATAR_6 = 'var(--avatar-6)',
  AVATAR_7 = 'var(--avatar-7)',
  AVATAR_8 = 'var(--avatar-8)',
}
