import type { ISidebarCategory } from '@writerai/types';
import { CategoryType } from '@writerai/types';
import { createFactory } from '@writerai/utils';
import type { IEqualsComparer } from 'mobx';
import { computed, makeObservable } from 'mobx';
import { UICategoryModel } from '../Category/UICategoryModel';
import type { SidebarModel } from '../../models';
import { SidebarSection } from '../../types';

function equals<T>(a: T[], b: T[]) {
  if (a.length !== b.length) {
    return false;
  }

  for (let i = 0, iLen = a.length; i < iLen; i++) {
    if (a[i] !== b[i]) {
      return false;
    }
  }

  return true;
}

export class UICategoriesPanelModel {
  constructor(readonly model: SidebarModel) {
    makeObservable(this, {
      categoriesList: computed({ equals: equals as IEqualsComparer<unknown> }),
    });
  }

  private readonly factoryCategories = createFactory<UICategoryModel, ISidebarCategory>({
    create: category => new UICategoryModel(this.model, category),
    recreate: (inst, category) => {
      inst.setCategory(category);
    },
  });

  get categoriesList() {
    return this.model.categories.categoriesList.map(category => this.factoryCategories.create(category));
  }

  get isSnippetsSection() {
    return this.model.categories.isSnippetsSection;
  }

  get totalSidebarIssuesCount() {
    return this.model.issues.totalSidebarIssuesCount;
  }

  get isLoading() {
    return this.model.sidebarState.isLoading;
  }

  get isStyleguideLoading() {
    return this.model.sidebarState.isStyleguideLoading;
  }

  get hideCategoriesHeader() {
    return this.model.sidebarState.hideCategoriesHeader;
  }

  get hideSnippets() {
    return this.model.sidebarState.hideSnippets || this.model.sidebarState.isSnippetsCategoryHidden;
  }

  get isStandard() {
    return this.model.size.isStandard;
  }

  get isSmall() {
    return this.model.size.isSmall;
  }

  get onRefreshScoreClick(): SidebarModel['onRefreshScoreClick'] {
    return this.model.onRefreshScoreClick;
  }

  get isSelected() {
    const { activeSection } = this.model.categories;
    const { selectedCategory } = this.model.categories;

    return !selectedCategory && activeSection === SidebarSection.ISSUES;
  }

  get hideScoreValue() {
    const { isEmptyState } = this.model.issues;

    return isEmptyState || !this.model.documentStats.score.available;
  }

  get hideCounter() {
    return this.model.issues.isEmptyState || this.isLoading;
  }

  get scoreValue() {
    return this.model.documentStats.score.value;
  }

  readonly onSelectSnippets = () => this.model.onSelectCategory(CategoryType.SNIPPETS);

  readonly onSelectAll = () => this.model.onSelectCategory(CategoryType.ALL);
}
