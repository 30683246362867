import { action, computed, createAtom, makeObservable, runInAction, untracked, when, comparer } from 'mobx';
import { getLogger } from '@writerai/utils';
import type { PromisedError } from '@writerai/errors';
import type { IPromisedModelParams, TPromisedModelOpts } from './PromisedModel';
import { PromisedModel } from './PromisedModel';

const logger = getLogger('PromisedModel');

/**
 * Interface representing the parameters for a paginated model.
 *
 * @template T - The type of the model.
 * @template TItem - The type of the items in the model.
 * @template TArgs - The type of the arguments.
 * @template TExtraArgs - The type of the extra arguments.
 */
export interface IPaginatedModelParams<T, TItem, TArgs, TExtraArgs extends object>
  extends Omit<IPromisedModelParams<T>, 'load'> {
  /**
   * The default arguments.
   */
  argsDefault: TArgs;

  /**
   * Loads the model with the given options.
   *
   * @param {TPromisedModelOpts & { args: TArgs; extra: TExtraArgs; }} opts - The options for loading the model.
   * @returns {Promise<T>} - A promise that resolves with the loaded model.
   */
  load(
    opts: TPromisedModelOpts & {
      args: TArgs;
      extra: TExtraArgs;
    },
  ): Promise<T>;

  /**
   * Extracts the items from the model object.
   *
   * @param {T} obj - The model object.
   * @returns {TItem[]} - An array of extracted items.
   */
  extract(obj: T): TItem[];

  /**
   * Extracts the meta data from the model object.
   *
   * @param {T} obj - The model object.
   * @returns {TArgs | undefined} - The extracted meta data or undefined if not available.
   */
  extractMeta(obj: T): TArgs | undefined;

  /**
   * The extra arguments.
   */
  argsExtra: TExtraArgs;
}

const def = <T>(get: () => T) =>
  ({
    enumerable: true,
    configurable: true,
    get,
  } as const);

/**
 * A generic paginated model
 *
 * @template T - The type of the paginated model
 * @template TItem - The type of items in the paginated model
 * @template TArgs - The type of arguments for the model
 * @template TExtraArgs - The type of extra arguments for the model
 */
export class PaginatedModel<T, TItem, TArgs, TExtraArgs extends object> {
  /**
   * Determines if there is a next page available
   *
   * @param {Object} args - Additional configuration options
   * @param {boolean} args.reset - Whether to reset the pagination state
   * @param {number} args.timeout - Timeout duration for the next page request
   * @returns {Promise<boolean>} - A promise that resolves to true if there is a next page, false otherwise
   */
  public readonly next: (args?: { reset?: boolean; timeout?: number }) => Promise<boolean>;

  /**
   * Sets the arguments for the model
   *
   * @param {TArgs} args - The arguments to set for the model
   */
  public readonly setArgs: (args: TArgs) => void;

  /**
   * Sets the extra arguments for the model
   *
   * @param {Partial<TExtraArgs>} args - The extra arguments to set for the model
   * @param {boolean} replace - This means that you overload args
   */
  public setExtra(args: Partial<TExtraArgs>, replace: void): void;
  // eslint-disable-next-line no-dupe-class-members
  public setExtra(args: TExtraArgs, replace: true): void;
  // eslint-disable-next-line no-dupe-class-members, class-methods-use-this
  public setExtra(_args: unknown, _replace: unknown): void {
    // rebind in constructor
  }

  /**
   * Indicates whether there is a next page available
   *
   * @type {boolean}
   */
  public readonly hasNext!: boolean;

  /**
   * The extra arguments for the model
   *
   * @type {TExtraArgs}
   */
  public readonly extra!: TExtraArgs;

  /**
   * The arguments for the model
   *
   * @type {TArgs}
   */
  public readonly args!: TArgs;

  /**
   * A promise that resolves to the items of the paginated model
   *
   * @type {Promise<TItem[]>}
   */
  public readonly promise!: Promise<TItem[]>;

  /**
   * The status of the paginated model
   *
   * @type {'rejected' | 'pending' | 'fulfilled'}
   */
  public readonly status!: 'rejected' | 'pending' | 'fulfilled';

  /**
   * The raw value of the paginated model with error information
   *
   * @type {PromisedError | T | undefined}
   */
  public readonly rawValueWithError!: PromisedError | T | undefined;

  /**
   * The raw value of the paginated model
   *
   * @type {T | undefined}
   */
  public readonly rawValue!: T | undefined;

  /**
   * The value of the paginated model with error information
   *
   * @type {PromisedError | TItem[] | undefined}
   */
  public readonly valueWithError!: PromisedError | TItem[] | undefined;

  /**
   * The value of the paginated model
   *
   * @type {TItem[] | undefined}
   */
  public readonly value!: TItem[] | undefined;

  public readonly reload: (keepArgs?: boolean) => void;

  constructor({
    argsExtra,
    name,
    argsDefault,
    extract,
    load,
    extractMeta,
    equals = comparer.structural,
  }: IPaginatedModelParams<T, TItem, TArgs, TExtraArgs>) {
    const $ref = (init => {
      let version = 0;
      let value = init;
      const atom = createAtom('atom');
      const externalAtom = createAtom('atom');

      const ret = {
        externalAtom,
        reportChanged: () => {
          atom.reportChanged();
          externalAtom.reportChanged();
        },
        reportObserved: () => atom.reportObserved(),
        get: () => value,
        version: () => version,
        value: init,
        set: (v: Partial<typeof init>, step: 0 | 1) => {
          version += step;
          value = { ...value, ...v };
        },
      };

      return ret;
    })({
      extra: argsExtra,
      args: argsDefault,
      list: [] as T[],
    });

    let latestVersion = -2;
    /**
     * Raw request
     */
    const requestModel = new PromisedModel<T>({
      name: `request-${name}`,
      equals,
      load: async opts => {
        $ref.reportObserved();
        const version = $ref.version();

        // if latestVersion equals current version
        // that means that this model was reloaded by load
        // method and we should reset list and args values
        // extra props we keep in actual state
        if (latestVersion === version) {
          $ref.set(
            {
              args: argsDefault,
              list: [],
            },
            0,
          );

          // we notify that args was changed on the next tick of the event-loop
          Promise.resolve().then(() => runInAction(() => $ref.externalAtom.reportChanged()));
        }

        const { args, extra, list } = $ref.get();

        const data = await load({
          ...opts,
          args,
          extra,
        });

        // save current version of update
        latestVersion = version;

        // we just only mutate list without notification
        // because it's bad idea to subscribe on chanche cache values
        list.push(data);

        return logger.return(data, `raw:${name}`);
      },
    });

    /**
     * Transformed request
     */
    const model = new PromisedModel<TItem[]>({
      name,
      load: async () => {
        await requestModel.promise;
        // we know that requestModel resolved previous async task and data
        // right now in the cache->list
        const items = $ref.get().list.flatMap(r => extract(r));

        return logger.return(items, `items:${name}`);
      },
    });

    /**
     * Update params
     */
    const setParams = action((params: { extra: TExtraArgs; args: TArgs; list: T[] }) => {
      const prev = $ref.get();

      const isArgsEqual = comparer.structural(prev.args, params.args);
      const isExtraEqual = comparer.structural(prev.extra, params.extra);

      if (!isArgsEqual || !isExtraEqual) {
        $ref.set(params, 1);
        runInAction(() => $ref.reportChanged());
      }
    });

    /**
     * Calculate next params which we would use
     */
    const $nextArgs = computed(() => {
      $ref.reportObserved();

      if (!$ref.get().list.length) {
        return argsDefault;
      } else if (requestModel.value) {
        return extractMeta(requestModel.value);
      }

      return argsDefault;
    });

    /**
     * Calculate does this args allow to make next request
     */
    const $hasNext = computed(() => {
      const nextArgs = $nextArgs.get();

      if (nextArgs === undefined) {
        return false;
      }

      const { args } = this;

      const result = comparer.structural(args, nextArgs);

      return !result;
    });

    this.setArgs = args => {
      logger.debug('setArgs');

      const { extra } = $ref.get();

      setParams({ extra, args, list: [] });
    };

    this.setExtra = (extraArgs, replace) => {
      logger.debug('setExtra', extraArgs, replace);
      const extra = replace === true ? (extraArgs as TExtraArgs) : { ...$ref.get().extra, ...extraArgs };

      setParams({
        extra,
        // we update extra that means that list and args should be reseted
        args: argsDefault,
        list: [],
      });
    };

    this.next = async ({ reset, timeout } = {}) => {
      logger.debug('next', { reset });

      await when(() => this.status !== 'pending', { timeout });

      if (reset) {
        setParams({
          args: argsDefault,
          list: [],
          extra: $ref.get().extra,
        });
      }

      const [hasNext, args] = untracked(() => [$hasNext.get(), $nextArgs.get()]);

      if (!args || !hasNext) {
        return false;
      }

      setParams({
        ...$ref.get(),
        args,
      });

      return true;
    };

    this.reload = action((keepArgs?: boolean) => {
      if (keepArgs) {
        const params = $ref.get();
        $ref.set(
          {
            ...params,
            list: params.list.slice(0, params.list.length - 1),
          },
          1,
        );
      }

      requestModel.reload();
    });

    Object.defineProperties(this, {
      hasNext: def(() => {
        if (this.status === 'pending') {
          return false;
        }

        const hasNext = $hasNext.get();

        return hasNext;
      }),
      extra: def(() => {
        $ref.externalAtom.reportObserved();

        return $ref.get().extra;
      }),
      args: def(() => {
        $ref.externalAtom.reportObserved();

        return $ref.get().args;
      }),
      promise: def(() => model.promise),
      status: def(() => model.status),
      rawValueWithError: def(() => requestModel.valueWithError),
      rawValue: def(() => requestModel.value),
      valueWithError: def(() => model.valueWithError),
      value: def(() => model.value),
    });

    makeObservable(this, {
      hasNext: computed,
      extra: computed,
      args: computed,
      promise: computed,
      status: computed,
      rawValueWithError: computed,
      rawValue: computed,
      valueWithError: computed,
      value: computed,
    });
  }
}
