import { Quill } from 'react-quill';
import Delta from 'quill-delta';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { AUTH_TOKEN_NAME, getSentences } from '@writerai/common-utils';
import { QUILL_FORMAT } from '@writerai/quill-delta-utils';
import { requiredObject } from '@writerai/utils';
import { last, first } from 'lodash';

const Module = Quill.import('core/module');

export class AutoWriteModule extends Module {
  options;

  constructor(quill, options) {
    super(quill, options);
    this.options = options;

    const isOptionsValid = requiredObject(options);

    if (!isOptionsValid) {
      throw new Error('Autowrite module requires correct options.');
    }

    const cursorModule = this.quill.getModule('cursors');

    if (!cursorModule) {
      throw new Error('Autowrite module requires "quill-cursors" to be registered.');
    }
  }

  keepWriting() {
    return new Promise((resolve, reject) => {
      const editor = this.quill;
      let hasContent = false;
      let initialPosition = editor.getLength();

      const selection = editor.getSelection();
      const cursors = this.quill.getModule('cursors');
      const cursorId = 'autowrite-cursor';
      const cursorName = 'AutoWrite';
      const takeSentencesForSuffix = 3;
      const takeSentencesForPrompt = 6;

      if (selection) {
        initialPosition = selection?.index;
      }

      let currentPosition = initialPosition;
      let currentMessageIndex = 0;

      const text = editor.getText(0);

      let prompt = null;
      let suffix = null;

      // Use suffix when the cursor at the document beginning and take max of 3 sentences (text after cursor)
      // Use prompt when the cursor is NOT at the document beginning, take max of 6 sentences (text before cursor)
      // Always trim whitespace chars from prompt and suffix
      if (initialPosition === 0) {
        const sentences = getSentences(text);
        const sentencesForSuffix = sentences.slice(0, takeSentencesForSuffix);

        suffix = sentencesForSuffix
          .map(s => s.text)
          .join('')
          .trim();
      } else {
        const textBeforeCursor = text.slice(0, initialPosition);
        const sentences = getSentences(textBeforeCursor);

        if (sentences.length < takeSentencesForPrompt) {
          prompt = textBeforeCursor;
        } else {
          const sentencesBefore = sentences.slice(sentences.length - takeSentencesForPrompt);
          prompt = sentencesBefore
            .map(s => s.text)
            .join('')
            .trim();
        }
      }

      const whitespaceRegex = /\s/;
      const insertSpaceAtStart =
        initialPosition !== 0 && !whitespaceRegex.test(editor.getText({ index: initialPosition - 1, length: 1 }));
      const insertSpaceAtEnd = !whitespaceRegex.test(editor.getText({ index: initialPosition, length: 1 }));
      let lastChunk;

      const cleanUp = () => {
        cursors?.clearCursors();
      };

      const onMessage = (msg) => {
        try {
          currentMessageIndex++;

          const data = JSON.parse(msg.data);
          let chunk = data.suggestion.replace(/\n\n/g, '\n');

          if (chunk.length) {
            // if this is the first message, and there is not space already in stream
            if (currentMessageIndex === 1 && insertSpaceAtStart && chunk[0] !== ' ') {
              chunk = ` ${chunk}`; // add space at the beginning
            }

            if (last(lastChunk) === '\n' && first(chunk) === '\n') {
              chunk = chunk.trimStart();
            }

            const newFrom = currentPosition + chunk.length;
            cursors?.createCursor(cursorId, cursorName, '#9B51E0');
            const applyDelta = new Delta().retain(currentPosition).insert(chunk, { [QUILL_FORMAT.COLOR]: '#828282' });
            editor.updateContents(applyDelta, 'user');
            editor.setSelection(newFrom, 0, 'silent');
            editor.scrollIntoView();
            currentPosition = newFrom;
            cursors?.moveCursor(cursorId, { index: newFrom, length: 0 });
            hasContent = true;
            lastChunk = chunk;
          }
        } catch (e) {
        }
      };

      const onClose = () => {
        if (hasContent) {
          if (insertSpaceAtEnd) {
            editor.insertText(currentPosition, ' ', 'user'); // add space at the end
            editor.setSelection(++currentPosition, 0, 'silent'); // and shift cursor
          }

          editor.formatText(initialPosition, currentPosition - initialPosition, QUILL_FORMAT.COLOR, false, 'user');
        }

        resolve({ hasContent });
        cleanUp();
      };

      const onError = (err) => {
        reject(err);
        cleanUp();
      };

      this.requestAutoWriteAsStream(
        {
          prompt,
          suffix,
        },
        onMessage,
        onClose,
        onError,
      );
    });
  }

  requestAutoWriteAsStream = (
    { prompt, suffix },
    onMessage,
    onClose,
    onError,
  ) => {
    const tokenHeader = this.options.useToken ? { [AUTH_TOKEN_NAME]: this.options.authToken } : {};

    fetchEventSource(this.options.streamUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...tokenHeader,
      },
      body: JSON.stringify({
        documentId: this.options.documentId,
        prompt,
        suffix,
      }),
      onmessage(msg) {
        onMessage(msg);
      },
      onclose() {
        onClose?.();
      },
      onerror(err) {
        onError?.(err);

        throw err;
      },
    });
  };
}
