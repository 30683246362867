import { cleanFromNewLines } from './cleanFromNewLines';
import { isWord } from './isWord';
import { removeUnicodeSymbols } from './removeUnicodeSymbols';
/**
 * Returns an array of words from the given text.
 * @param text - The text to extract the words from.
 * @returns An array of words from the given text.
 */
export function getWords(text: string): string[] {
  const textWihtoutUnicode = removeUnicodeSymbols(text);
  const textCleanNewLines = cleanFromNewLines(textWihtoutUnicode);

  const words = textCleanNewLines.split(' ').reduce((memo, w) => {
    if (isWord(w)) {
      memo.push(w);
    }

    return memo;
  }, [] as string[]);

  return words;
}
