import { useRef, useEffect } from 'react';

export function useMemoCompare(next, compare, formatter = value => value) {
  const previousRef = useRef();
  const formattedRef = useRef();
  const previous = previousRef.current;

  const isEqual = compare(previous, next);

  useEffect(() => {
    if (!isEqual) {
      previousRef.current = next;
      formattedRef.current = formatter(next);
    }
  });

  return isEqual ? formattedRef.current : formatter(next);
}
