import { richTextFromMarkdown } from '@contentful/rich-text-from-markdown';
import { BLOCKS } from '@contentful/rich-text-types';
import TurndownService from 'turndown';

const deltaToRich = async (htmlValue, fieldType) => {
  const turndownService = new TurndownService({ strongDelimiter: '__', emDelimiter: '*', headingStyle: 'atx', bulletListMarker: '-' });
  turndownService.addRule('strikethrough', {
    filter: ['u'],
    replacement: function (content) {
      return `<ins>${content}</ins>`;
    },
  });

  if (fieldType === 'RichText') {
    turndownService.keep(['table']);
    turndownService.addRule('indents', {
      filter: ['br'],
      replacement: function () {
        return '<br />';
      },
    });

    turndownService.addRule('indents', {
      filter: ['source'],
      replacement: function () {
        return '<source />';
      },
    });

    turndownService.addRule('referenceLink', {
      filter: ['a'],
      replacement: function (content, node) {
        const href = node.getAttribute('href');
        const id = node.getAttribute('id');
        const nodeType = node.getAttribute('data-node-type');
        if (id && nodeType) {
          return `<hyper id=${id} data-node-type=${nodeType} content="${content}" />`
        }

        return `[${content}](${encodeURI(href)})`;
      },
    });

    turndownService.addRule('indents', {
      filter: ['embed'],
      replacement: function (content, node) {
        const embedId = node.getAttribute('embedid');
        const type = node.getAttribute('type');
        const linkType = node.getAttribute('linktype');
        const nodeType = node.getAttribute('embedtype');

        return `<embed embedId=${embedId} embedType=${type} embedLink=${linkType} nodeType=${nodeType} />`;
      },
    });
  } else {
    turndownService.addRule('indents', {
      filter: ['p'],
      replacement: function (content) {
        return `${content}\n`;
      },
    });
    turndownService.addRule('indents', {
      filter: ['br'],
      replacement: function () {
        return '\n';
      },
    });
    turndownService.addRule('indents', {
      filter: ['embed'],
      replacement: function (content, node) {
        const embedId = node.getAttribute('embedid');

        return decodeURIComponent(embedId);
      },
    });
    turndownService.addRule('indents', {
      filter: ['ul', 'ol'],
      replacement: function (content, node) {
        const parent = node.parentNode;
        if (parent.nodeName === 'LI' && parent.lastElementChild === node) {
          return '\n' + content;
        } else {
          return content + '\n';
        }
      },
    });
  }

  if (fieldType !== 'RichText') {
    TurndownService.prototype.escape = (text) => text;
    let formattedHtmlValue = htmlValue
      .replaceAll(/<ol><li data-list="bullet">(.*?)<\/ol>/g, substr => `<ul>${substr.slice(4, -5)}</ul>`)
      .replaceAll(/<li data-list="bullet">(((?!<li).)*?)<\/li><li data-list="ordered">/g, substr => `${substr.slice(0, -24)}</li></ul><ol><li data-list="ordered">`)
      .replaceAll(/<li data-list="ordered">(((?!<li).)*?)<\/li><li data-list="bullet">/g, substr => `${substr.slice(0, -23)}</li></ul><ul><li data-list="bullet">`)
      .replaceAll('<p><br></p>', '![new-line:1]')
      .replaceAll(/ {2,}/g, (m) => m.replace(/ /g, '\u00a0'));
    const turndownResult = turndownService.turndown(formattedHtmlValue || '');
    return turndownResult
      .replaceAll(/\n\n(!\[new-line:1])+\n\n/g, substr => substr.slice(1, -2))
      .replaceAll('![new-line:1]', '\n')
      .replaceAll(/\u00a0/g, ' ');
  }

  const formattedHtml = htmlValue
    .replaceAll(`<ol><li data-list="bullet">`, '<ul><li data-list="bullet">')
    .replaceAll(/<li data-list="ordered" class="ql-indent-1">(.*?)<\/li>(?!.*<li data-list="ordered" class="ql-indent-1")/g, (substr) => `<ol>${substr}</ol>`)
    .replaceAll(/ {2,}/g, (m) => m.replace(/ /g, '\u00a0'));

  const markdownFormatted = turndownService.turndown(formattedHtml || '')
    .replaceAll("</table>\n\n<br />", '</table>')
    .replaceAll(/<embed embedId=.*nodeType=embedded-asset-block/g, (substr) => `\n\n${substr}`)
    .replaceAll(/<embed embedId=.*nodeType=embedded-entry-block/g, (substr) => `\n\n${substr}`)
    .replaceAll("nodeType=embedded-asset-block />", 'nodeType=embedded-asset-block />\n\n')
    .replaceAll("nodeType=embedded-entry-block />", 'nodeType=embedded-entry-block />\n\n')
    .replaceAll(/\u00a0/g, ' ');

  const richTextFromMarkdownResolver = async (node, inTable = false) => {
    const { value, type } = node;

    if (value === '<ins>' || value === '</ins>') {
      return {
        nodeType: value,
        content: [],
        data: {},
      };
    }

    if (value?.startsWith('<hyper')) {
      const id = value.split('id=')[1].split(' data-node-type')[0];
      const hyperlinkType = value.split('data-node-type=')[1].split(' content')[0];
      const hyperlinkContent = value.split('content="')[1].split('" />')[0];

      return ({
        nodeType: hyperlinkType,
        content: [{
          data: {},
          marks: [],
          nodeType: 'text',
          value: hyperlinkContent,
        }],
        data: {
          target: {
            sys: { id, type: 'Link', linkType: hyperlinkType === 'entry-hyperlink' ? 'Entry' : 'Asset' },
          },
        },
      });
    }

    if (value === '</a>') {
      return null;
    }

    if (value?.startsWith('<embed')) {
      const embedId = value.split('embedId=')[1].split(' embedType')[0];
      const embedType = value.split('embedType=')[1].split(' embedLink')[0];
      const embedLink = value.split('embedLink=')[1].split(' nodeType')[0];
      const nodeType = value.split('nodeType=')[1].split(' />')[0];

      if (nodeType !== 'undefined' && nodeType !== 'null') {
        return ({
          nodeType: `${nodeType}`,
          content: [],
          data: {
            target: {
              sys: { id: embedId, type: embedType, linkType: embedLink },
            },
          },
        });
      }
    }

    if (!inTable && value?.startsWith('<table')) {
      const parsedTableHTML = new DOMParser().parseFromString(value, 'text/html');

      const tableElement = parsedTableHTML.querySelector('table');
      const rows = Array.from(tableElement?.querySelectorAll('tr') || []);

      return {
        nodeType: BLOCKS.TABLE,
        content: await Promise.all(rows.map(async (rowElement) => {
          const cells = Array.from(rowElement?.querySelectorAll('td') || []);

          return {
            nodeType: BLOCKS.TABLE_ROW,
            content: await Promise.all(cells.map(async (cellElement) => {
              const innerMarkdown = turndownService.turndown(cellElement.innerHTML || '');
              const innerRichText = await richTextFromMarkdown(innerMarkdown, (node) => richTextFromMarkdownResolver(node, true));
              return {
                nodeType: cellElement.getAttribute('data-cell-bg') ? BLOCKS.TABLE_HEADER_CELL : BLOCKS.TABLE_CELL,
                content: innerRichText.content?.length ? innerRichText.content.map(node => {
                  if (node.nodeType === 'embedded-entry-inline') {
                    return {
                      content: [node],
                      data: {},
                      nodeType: BLOCKS.PARAGRAPH,
                    };
                  } else {
                    return node;
                  }
                }) : [{
                  content: [{
                    data: {},
                    marks: [],
                    nodeType: 'text',
                    value: '',
                  }],
                  data: {},
                  nodeType: BLOCKS.PARAGRAPH,
                }],
                data: {},
              };
            })),
            data: {},
          };
        })),
        data: {},
      };
    }

    if (value === '<br />') {
      return {
        nodeType: BLOCKS.PARAGRAPH,
        content: [],
        data: {},
      };
    }

    if (type === 'html') {
      return {
        data: {},
        marks: [],
        nodeType: 'text',
        value: value || '',
      };
    }
  };

  let document = await richTextFromMarkdown(markdownFormatted, richTextFromMarkdownResolver);

  document.content.forEach((node) => {
    if (['embedded-entry-inline'].includes(node.nodeType)) {
      const newLineObject = {
        data: {},
        marks: [],
        nodeType: 'text',
        value: '',
      };
      const embedObject = { ...node, content: [] };

      node.content.push(newLineObject);
      node.content.push(embedObject);
      node.content.push(newLineObject);
      node.nodeType = BLOCKS.PARAGRAPH;
      node.data = {};
    }
  });

  document.content.forEach((node) => {
    if (['hyperlink', 'entry-hyperlink', 'asset-hyperlink'].includes(node.nodeType)) {
      const embedObject = { ...node, content: node.content || [] };

      node.content = [embedObject];
      node.nodeType = BLOCKS.PARAGRAPH;
      node.data = {};
    }

    if (['unordered-list', 'ordered-list'].includes(node.nodeType)) {
      node.content.forEach(listItemNode => {
        listItemNode.content.forEach(listItemContentNode => {
          if (['text', 'hyperlink', 'entry-hyperlink', 'asset-hyperlink'].includes(listItemContentNode?.nodeType)) {
            listItemContentNode.content = [{ ...listItemContentNode }];
            listItemContentNode.data = {};
            listItemContentNode.nodeType = BLOCKS.PARAGRAPH;
            Object.keys(listItemContentNode).forEach(nodeFieldKey => {
              if (!['content', 'data', 'nodeType'].includes(nodeFieldKey)) {
                delete listItemContentNode[nodeFieldKey];
              }
            });
          }
        });
      });
    }
  });

  document.content.forEach((node) => {
    if (node.nodeType === 'text') {
      node.content = [{ ...node }];
      node.data = {};
      node.nodeType = BLOCKS.PARAGRAPH;
      Object.keys(node).forEach(nodeFieldKey => {
        if (!['content', 'data', 'nodeType'].includes(nodeFieldKey)) {
          delete node[nodeFieldKey];
        }
      });
    }
  });

  const updateUnderlinesOfContent = (content) => {
    const startInsIndexes = [];
    const endInsIndexes = [];
    content.forEach((node, idx) => {
      if (node.nodeType === '<ins>') {
        startInsIndexes.push(idx);
      }

      if (node.nodeType === '</ins>') {
        endInsIndexes.push(idx);
      }

      if (node.content?.length) {
        node.content = updateUnderlinesOfContent(node.content);
      }
    });

    startInsIndexes.forEach((startPos, index) => {
      const endPos = endInsIndexes[index] || startPos + 2;
      for (let i = startPos + 1; i < endPos; i++) {
        content[i].marks?.push({ type: 'underline' });
      }
    });
    return content.filter(node => node.nodeType !== '<ins>' && node.nodeType !== '</ins>');
  };
  document.content = updateUnderlinesOfContent(document.content);

  return document;
};

export default deltaToRich;
