import { comparer, computed, makeObservable } from 'mobx';

import type { ISubscriptionFeatureLock } from '@writerai/types';
import { BillingProduct, BillingStatus, CancelledReason, SubscriptionFeatureType } from '@writerai/types';

import { isEmpty } from 'lodash/fp';
import { PromisedModel, Subscriber } from '@writerai/mobx';
import type { components, RequestServiceInitialize } from '@writerai/network';
import { getNumberOfDays } from '@writerai/date-utils';
import { LimitsModel } from '../limits/LimitsModel';

export interface AiAssistantSubscriptionModelParams {
  api: RequestServiceInitialize['api'];
  organizationId: () => number | undefined;
  teamId: () => number | undefined;
}

export class AiAssistantSubscriptionModel {
  constructor(private opts: AiAssistantSubscriptionModelParams) {
    makeObservable(this, {
      isModelReady: computed,
      isLoaded: computed,
      isTrial: computed,
      hasValidSubscription: computed,
      isActive: computed,
      isExpired: computed,
      isCancelled: computed,
      isFree: computed,
      isTeam: computed,
      isEnterprise: computed,
      isMultiTeam: computed,
      access: computed,
      isTrialExpired: computed,
      trialExpirationDate: computed,
      trialStartDate: computed,
      trialEndDays: computed,
      trialStartDays: computed,
      isLocked: computed,
      price: computed,
      isEnterpriseCustomer: computed,
      isSelfServeCustomer: computed,
      isSsoAvailable: computed,
    });
  }

  private readonly $limits = new Subscriber<
    {
      organizationId: number;
      teamId: number | undefined;
    },
    LimitsModel | undefined
  >({
    equals: comparer.structural,
    getId: () => {
      const organizationId = this.opts.organizationId();
      const teamId = this.opts.teamId();

      return organizationId !== undefined ? { organizationId, teamId } : undefined;
    },
    subscribe: ({ organizationId, teamId }, push) => {
      push(
        new LimitsModel({
          api: this.opts.api,
          organizationId,
          teamId,
        }),
      );

      return () => push(undefined);
    },
  });

  currentDate = Date.now();

  get trialExpirationDate() {
    return this.$subscription.value?.trialEnd
      ? new Date(this.$subscription.value.trialEnd).getTime()
      : new Date().getTime();
  }

  get trialStartDate() {
    return this.$subscription.value?.trialStart
      ? new Date(this.$subscription.value.trialStart).getTime()
      : new Date().getTime();
  }

  get isTrialExpired() {
    return this.$subscription.value?.cancelledReason === CancelledReason.TRIAL_EXPIRED;
  }

  get trialEndDays() {
    return this.isTrialExpired ? 0 : getNumberOfDays(this.trialExpirationDate - this.currentDate);
  }

  get trialStartDays() {
    return this.isTrialExpired ? 0 : getNumberOfDays(this.currentDate - this.trialStartDate);
  }

  get isLocked() {
    return this.isTrialExpired;
  }

  get price() {
    return this.$subscription.value?.price?.amount || 0;
  }

  get enterpriseMeta() {
    if (!this.$subscription.value) {
      return undefined;
    }

    return {
      tierOne: this.$subscription.value.meta?.tier === 'enterprise-1',
      tierTwo: this.$subscription.value.meta?.tier === 'enterprise-2',
      tierThree: this.$subscription.value.meta?.tier === 'enterprise-3',
      tierFour: this.$subscription.value.meta?.tier === 'enterprise-4',
    };
  }

  get limits(): LimitsModel | undefined {
    return this.$limits.data;
  }

  get productName() {
    return this.$subscription.value?.productName;
  }

  $subscription = new PromisedModel({
    name: '$subscription',
    load: async () => {
      const organizationId = this.opts.organizationId();

      if (!organizationId) {
        return undefined;
      }

      return this.opts.api
        .get('/api/billing/organizations/{organizationId}/subscription', {
          params: {
            path: {
              organizationId,
            },
          },
        })
        .then(r => r.data);
    },
  });

  $teamFeatureLocks = new PromisedModel({
    name: '$teamFeatureLocks',
    load: async () => {
      const teamId = this.opts.teamId();
      const organizationId = this.opts.organizationId();

      if (!organizationId || !teamId) {
        return undefined;
      }

      return this.opts.api
        .get('/api/billing/organizations/{organizationId}/teams/{teamId}/feature', {
          params: {
            path: {
              organizationId,
              teamId,
            },
          },
        })
        .then(r => r.data);
    },
  });

  get isEnterpriseCustomer() {
    return this.$subscription.value?.customerType === 'enterprise';
  }

  get isSelfServeCustomer() {
    return this.$subscription.value?.customerType === 'self_service';
  }

  get isSsoAvailable() {
    return this.$subscription.value?.meta && this.$subscription.value?.meta.ssoAccess;
  }

  // Checks if minimum requests list is done
  get isLoaded(): boolean {
    return !!this.$subscription.value && !!this.limits?.isLoaded;
  }

  // Checks if subscription model is ready to use (requests are done and access flags are calculated)
  get isModelReady() {
    return this.isLoaded && !isEmpty(this.access);
  }

  get isTrial() {
    return this.$subscription.value?.status === BillingStatus.TRIALING;
  }

  get isActive() {
    if (!this.opts.organizationId() || this.$subscription.status === 'pending') {
      return undefined;
    }

    return this.$subscription.value?.status === BillingStatus.ACTIVE;
  }

  get hasValidSubscription() {
    if (!this.opts.organizationId() || this.$subscription.status === 'pending' || !this.$subscription.value) {
      return undefined;
    }

    return !this.isCancelled;
  }

  get isExpired() {
    return this.$subscription.value?.status === BillingStatus.PAST_DUE;
  }

  get isCancelled() {
    return this.$subscription.value?.status === BillingStatus.CANCELED;
  }

  get isFree() {
    return this.$subscription.value?.productName === BillingProduct.FREE;
  }

  get isTeam() {
    return this.$subscription.value?.productName === BillingProduct.TEAM;
  }

  get isEnterprise() {
    return this.$subscription.value?.productName === BillingProduct.ENTERPRISE;
  }

  get isMultiTeam() {
    return (this.$subscription.value?.meta?.teamCount || 0) > 1;
  }

  get access() {
    if (!this.isLoaded) {
      return undefined;
    }

    const teamId = this.opts.teamId();

    if (teamId && this.$teamFeatureLocks.status !== 'fulfilled') {
      return undefined;
    }

    const featuresList = (teamId ? this.$teamFeatureLocks.value?.features : this.$subscription.value?.featureLocks) as
      | ISubscriptionFeatureLock[]
      | undefined;

    return Object.values(SubscriptionFeatureType).reduce((res, feature) => {
      res[feature] = AiAssistantSubscriptionModel.isFeatureAvailable(feature, featuresList || []);

      return res;
    }, {} as Record<SubscriptionFeatureType, boolean>);
  }

  static isFeatureAvailable = (
    featureType: SubscriptionFeatureType,
    locks?: components['schemas']['billing_model_FeatureLock'][],
  ) => !!locks?.find(lock => lock.featureType === featureType)?.active;
}

/**
 * @deprecated use AiAssistantSubscriptionModel
 */
export const SubscriptionModel = AiAssistantSubscriptionModel;
