import React, { useState, useEffect, useRef } from "react";
import * as Sentry from "@sentry/react";
import ReactTimeout from "react-timeout";
import { connect } from "react-redux";
import { CookieStorage } from "cookie-storage";
import { richTextFromMarkdown } from "@contentful/rich-text-from-markdown";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";

import SignOutModal from "../../common/SignOutModal";
import { LoginForm } from "../../components/LoginForm";
import ContentForm from "../../components/ContentForm";
import { NoTeams } from "../../components/NoTeams";
import { SplashScreen } from "../../components/SplashScreen";
import { checkLogin, getUserData, logout, stopLoading } from "../../redux/auth/actions";
import { changeField, changeFieldSupportsTables, changeLogin } from "../../redux/app/actions";
import deltaToRich from "../../utils/richTextEditor";
import Header from "../../common/Header";
import { useInitRequestUtility } from "../../content/hooks/useInitRequestUtility";

import styles from "./style.module.scss";
import settings from "../../assets/icons/settings.svg";

const cookieStorage = new CookieStorage({
  secure: true,
  sameSite: 'None',
});

const DialogContent = ({
  isLogged,
  isLoading,
  fieldType,
  sdk,
  dispatch,
  setInterval,
  clearInterval,
  loginType,
  workspaceId,
  organizationId,
  organizationName,
  documentId,
  personaId,
  profileData,
}) => {
  const [isModal, setModal] = useState(false);
  const [fieldValue, setField] = useState({});
  const [htmlValue, setHtml] = useState('');
  const [isReloading, setIsReloading] = useState(false);
  const [savedDoc, setSavedDoc] = useState();
  const reloadTimerRef = useRef(null);
  const prevWorkspaceId = useRef();
  const prevOrganizationId = useRef();

  useInitRequestUtility();

  useEffect(() => {
    const sentryUserInfo = profileData?.id ? {
      id: profileData.id,
      email: profileData.email,
      timezone: profileData.timezone,
      organizationId,
      workspaceId,
      documentId,
      personaId,
    } : null;

    Sentry.setUser(sentryUserInfo);
  }, [profileData, workspaceId, organizationId, documentId, personaId]);

  useEffect(() => {
    if (workspaceId && organizationId && isLogged && !isLoading && prevWorkspaceId.current && prevOrganizationId.current) {
      deltaToRich(htmlValue, fieldType).then((document) => {
        setSavedDoc(document);
      });
      setIsReloading(true);
      clearTimeout(reloadTimerRef.current);
      reloadTimerRef.current = setTimeout(() => {
        setIsReloading(false);
      }, 500);
      return () => {
        clearTimeout(reloadTimerRef.current);
      };
    }
    prevWorkspaceId.current = workspaceId;
    prevOrganizationId.current = organizationId;
  }, [workspaceId, organizationId]);

  useEffect(() => {
    const { fieldContent, fieldFormat } = sdk.parameters.invocation;

    const init = async () => {
      dispatch(changeField(fieldFormat));
      dispatch(changeFieldSupportsTables(fieldFormat === 'RichText'));
      let formattedContent = fieldContent;

      if (savedDoc) {
        formattedContent = savedDoc;
      }

      if (fieldFormat === 'Text') {
        formattedContent = formattedContent.replaceAll(/\n{2,}/g, (substr => `\n![new-line:${substr.length - 1}]`));
        formattedContent = formattedContent.replaceAll(/([0-9]+\.|-) {1,99}[^\n\r]+\n!\[new-line:[0-9]+]/g, (substr => `${substr.replace(/!\[new-line:[0-9]+]/g, substr => `\n${substr}`)}`));
        formattedContent = formattedContent.replaceAll(/\n!\[new-line:[0-9]+]([0-9]+\.|-)/g, (substr => `${substr.replace(/!\[new-line:[0-9]+]/g, substr => `\n${substr}\n`)}`));
        formattedContent = formattedContent.replaceAll(/([0-9]+\.|-) {1,99}[^\n\r]+\n[A-Za-z_~*'"!(){}? +]/g, substr => `${substr.slice(0, -2)}\n\n${substr.slice(-1)}`);
        formattedContent = formattedContent.replaceAll(/!\[new-line:[0-9]+]\[/g, (substr => `${substr.slice(0, -1).replace(/[0-9]+/, substr => +substr - 1)}\n[`));
        formattedContent = formattedContent.replaceAll(/>!\[new-line:[0-9]+]/g, (substr => `>${substr.slice(1).replace(/[0-9]+/, substr => +substr - 2)}`));

        formattedContent = await richTextFromMarkdown(formattedContent, (node) => {
          const isBreakLines = node.referenceType === 'shortcut' && node.identifier?.startsWith('new-line:') && Number.isSafeInteger(+node.identifier.replace('new-line:', ''));
          if (isBreakLines) {
            const amount = +node.identifier.replace('new-line:', '');
            return Array(amount).fill({
              content: [{ data: {}, marks: [], nodeType: 'text', value: '' }],
              data: {},
              nodeType: 'paragraph',
            });
          }

          const isMediaAsset = node.type === 'image';
          if (isMediaAsset) {
            return {
              nodeType: 'embedded-entry-inline',
              content: [],
              data: {
                target: {
                  sys: {
                    id: encodeURIComponent(`![${node.alt}](${node.url})`),
                    linkType: 'Asset',
                    type: 'Link',
                  },
                },
              },
            };
          }

          const isHtmlElement = node.type === 'html';
          if (isHtmlElement) {
            return {
              data: {},
              marks: [],
              nodeType: 'text',
              value: node.value,
            };
          }

          return {
            nodeType: 'hyperlink',
            content: [{
              data: {},
              marks: [],
              nodeType: 'text',
              value: node.alt,
            }],
            data: { uri: node.url },
          };
        });
      }

      if (fieldFormat === 'RichText' || fieldFormat === 'Text') {
        const options = {
          renderNode: {
            [BLOCKS.EMBEDDED_ASSET]: (node) => {
              const { id, type, linkType } = node.data.target.sys;

              return `<embed nodeType=${node.nodeType} embedId=${id} embedType=${type} linkType=${linkType} />`;
            },
            [BLOCKS.EMBEDDED_ENTRY]: (node) => {
              const { id, type, linkType } = node.data.target.sys;

              return `<embed nodeType=${node.nodeType} embedId=${id} embedType=${type} linkType=${linkType} />`;
            },
            [INLINES.EMBEDDED_ENTRY]: (node) => {
              const { id, type, linkType } = node.data.target.sys;

              return `<embed nodeType=${node.nodeType} embedId=${id} embedType=${type} linkType=${linkType} />`;
            },
            [INLINES.ENTRY_HYPERLINK]: (node, next) => {
              const { id } = node?.data?.target?.sys;

              return `<a href="" data-node-type="${node.nodeType}" id="${id}">${next(node.content)}</a>`;
            },
            [INLINES.ASSET_HYPERLINK]: (node, next) => {
              const { id } = node?.data?.target?.sys;

              return `<a href="" data-node-type="${node.nodeType}" id="${id}">${next(node.content)}</a>`;
            },
            [BLOCKS.TABLE]: (node, next) => {
              return `<table>${next(node.content)}</table><br>`;
            },
            [BLOCKS.TABLE_HEADER_CELL]: (node, next) => {
              return `<th data-cell-bg="#E7EBEE">${next(node.content)}</th>`;
            },
          },
        };

        formattedContent = documentToHtmlString(formattedContent, options);

        for (let i = 0; i < formattedContent.length; i++) {
          const currentCharCode = formattedContent.charAt(i).charCodeAt(0);

          if (currentCharCode === 10) {
            formattedContent =
              formattedContent.substring(0, i) +
              '<br>' +
              formattedContent.substring(i + 1);
          }
        }

        formattedContent = formattedContent.replace(/<p><\/p>/g, '<br>');
      }

      setField(formattedContent);
      setHtml(formattedContent);
    };
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedDoc]);

  useEffect(() => {
    dispatch(checkLogin());
  }, [dispatch]);

  useEffect(() => {
    if (isLoading) {
      const oldToken = cookieStorage.getItem("qToken") || localStorage.getItem('qToken');
      if (!oldToken) {
        const cookieCheck = setInterval(async () => {
          const cookieStorage = new CookieStorage({
            secure: true,
            sameSite: "None",
          });

          const token = cookieStorage.getItem("qToken") || localStorage.getItem('qToken');
          const loginError = cookieStorage.getItem("loginError");

          if (loginError) {
            cookieStorage.removeItem("loginError");
            dispatch(stopLoading());
            dispatch(changeLogin("main"));
            clearInterval(cookieCheck);
          }

          if (token) {
            dispatch(getUserData(false, token));
            clearInterval(cookieCheck);
          }
        }, 2000);

        return () => clearInterval(cookieCheck);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const closeWindow = async (isSave) => {
    if (isSave === true) {
      const document = await deltaToRich(htmlValue, fieldType);

      sdk.close(document || " ");
    } else {
      sdk.close();
    }
  };

  const changeSettingsModal = () => {
    setModal(!isModal);
  };

  const logOut = () => {
    setModal(false);
    dispatch(logout());
  };

  if (isLoading) {
    return <SplashScreen />;
  }

  return (
    <>
      {isReloading ? (
        <SplashScreen />
      ) : (
        <div className={styles.dialog}>
          {isLogged ? (workspaceId ? (
            <ContentForm
              fieldValue={fieldValue}
              setField={setField}
              setHtml={setHtml}
              closeWindow={closeWindow}
              personaId={personaId}
              workspaceId={workspaceId}
              organizationId={organizationId}
              documentId={documentId}
              organizationName={organizationName}
            />
          ) : (
            <>
              <Header closeWindow={closeWindow} />
              <NoTeams />
            </>
          )) : (
            <>
              <Header closeWindow={closeWindow} loginType={loginType} />
              <LoginForm />
            </>
          )}
        </div>
      )}
      {isLogged ? (
        <>
          <button onClick={changeSettingsModal} className={styles.dialog__settings}>
            <img src={settings} alt="setting" />
          </button>
          {isModal && (
            <SignOutModal
              logOut={logOut}
              changeModal={changeSettingsModal}
            />
          )}
        </>
      ) : null}
    </>
  );
};

const mapStateToProps = ({ auth, app }) => {
  return {
    isLogged: auth.isLogged,
    isLoading: auth.isLoading,
    fieldType: app.fieldType,
    loginType: app.loginType,
    workspaceId: auth.currentWorkspace?.id,
    organizationId: auth.currentOrganization?.id,
    organizationName: auth.currentOrganization?.name,
    documentId: auth.documentId,
    personaId: auth.personaId,
    profileData: auth.profileData,
  };
};

export default connect(mapStateToProps)(ReactTimeout(DialogContent));
