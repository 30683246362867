import React from 'react';

import { Enum } from '@writerai/utils';
import cx from 'classnames';
import styles from './InlineToast.module.css';
import { Text, TextSize } from '../typography/Text/Text';
import { Icon, IconVariant } from '../Icon';

export const TInlineToastVariant = new Enum('info', 'warning', 'success');

interface InlineToastProps {
  className?: string;
  variant?: typeof TInlineToastVariant.type;
  children?: React.ReactNode;
  message?: string;
  messageVariant?: TextSize;
}

export const InlineToast: React.FC<InlineToastProps> = ({
  className,
  variant = TInlineToastVariant.enum.info,
  messageVariant = TextSize.XL,
  message,
  children,
}) => {
  const icon = TInlineToastVariant.match(
    variant,
    {
      info: () => <Icon name={IconVariant.INFO_LETTER} width={10} height={10} />,
      warning: () => <Icon name={IconVariant.EXCLAMATION} width={10} height={10} />,
      success: () => <Icon name={IconVariant.CHECK} width={10} height={10} />,
    },
    IconVariant.INFO,
  );

  const typeClassName = TInlineToastVariant.match(
    variant,
    {
      info: () => styles.containerWrapperInfo,
      warning: () => styles.containerWrapperWarning,
      success: () => styles.containerWrapperSuccess,
    },
    IconVariant.INFO,
  );

  return (
    <div className={cx(className, styles.container)}>
      <div className={cx(styles.containerWrapper, typeClassName)}>
        <div className={styles.containerIcon}>{icon}</div>
        {children && <div className={styles.containerInner}>{children}</div>}
        {message && (
          <div className={styles.containerInnerText}>
            <Text variant={messageVariant}>{message}</Text>
          </div>
        )}
      </div>
    </div>
  );
};
