
const digest = '7457d64a34758f865478de15965a8f0f5473132ebbfee82c2655942530810615';
const css = `.styles-module__1Y0uPYH {
  display: block;
  width: 100%;
  color: var(--classic-black);
  font-family: var(--font-default);
  font-style: normal;
  font-weight: var(--font-bold-weight);
  letter-spacing: 0;
  text-align: left;
}

.styles-module__4Te4eSz {
  font-size: var(--heading-h1-size);
  font-weight: 600;
  line-height: 110%;
}

.styles-module__ujKVauz {
  font-size: var(--heading-h2-size);
  font-weight: 600;
  line-height: 35px;
}

.styles-module__RjahrOI {
  font-size: var(--heading-h3-size);
  font-weight: 500;
  line-height: 27px;
}

.styles-module__KMNdDGp {
  font-size: var(--heading-h4-size);
  font-weight: 600;
  line-height: 24px;
}

.styles-module__Tn6vBmI {
  font-size: var(--heading-h5-size);
  font-weight: 500;
  line-height: 24px;
}

.styles-module__nG7Uo4J {
  font-size: var(--heading-h6-size);
  font-weight: 500;
  line-height: 21px;
}

.styles-module__EUVas4i {
  color: var(--classic-black);
}

.styles-module__bPQJTaW {
  color: var(--writer-grey-1);
}

.styles-module__rUwi-t0 {
  color: var(--writer-grey-2);
}

.styles-module__S4CtXSR {
  font-weight: var(--font-bold-weight);
}

.styles-module__JHiK6VQ {
  font-weight: var(--font-medium-weight);
}

.styles-module__TytDJKe {
  letter-spacing: 3px;
  text-transform: uppercase;
}
`;

export default {"container":"styles-module__1Y0uPYH","variantH1":"styles-module__4Te4eSz","variantH2":"styles-module__ujKVauz","variantH3":"styles-module__RjahrOI","variantH4":"styles-module__KMNdDGp","variantH5":"styles-module__Tn6vBmI","variantH6":"styles-module__nG7Uo4J","colorBlack":"styles-module__EUVas4i","colorGrey":"styles-module__bPQJTaW","colorGrey2":"styles-module__rUwi-t0","bold":"styles-module__S4CtXSR","medium":"styles-module__JHiK6VQ","uppercase":"styles-module__TytDJKe"};
export { css, digest };
  