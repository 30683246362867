
const digest = 'fa9c0b7b71fcacc348a5f0b4cfc39fa0d0d7b4cf0eaa7bda67291ef8a2b6411d';
const css = `.styles-module__K7gGt54 {
  width: 100%;
}

.styles-module__K7gGt54 > label {
  margin-top: -3px;
  margin-bottom: -5px;
}

.styles-module__pBAW5EL {
  display: flex;
  align-items: center;
}

.styles-module__pBAW5EL i {
  margin-left: 6px;
}

.styles-module__RjMmtOa {
  display: flex;
  flex-direction: column;
}

.styles-module__RjMmtOa > div {
  display: flex;
  align-items: center;
  gap: 8px;
}

.styles-module__RjMmtOa > div > div:last-child:hover {
  cursor: pointer;
}

.styles-module__CSsTIBz {
  margin-bottom: 2px;
  cursor: pointer;
}

.styles-module__Y9y7-mt {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  background: var(--writer-green-3);
}

.styles-module__Y9y7-mt > span {
  color: var(--writer-grey-2);
  font-size: 11px;
}

.styles-module__vrMMzwC {
  position: relative;
}

.styles-module__KwW-ZrZ {
  position: absolute;
  z-index: 1;
  width: 349px;
  max-height: 352px;
  padding: 5px;
  border-radius: 8px;
  background: var(--white);
  box-shadow: var(--modal-box-shadow);
  overflow-y: hidden;
}

.styles-module__KwW-ZrZ > div {
  position: relative;
  padding: 12px 13px;
  border-bottom: var(--gray-border);
}

.styles-module__KwW-ZrZ > div:first-child {
  padding: 0;
}

.styles-module__KwW-ZrZ > div:last-child {
  padding: 0 13px;
  border-bottom-width: 0;
}

.styles-module__slZmLvg {
  border: 0 !important;
  box-shadow: 0 0 0 0 !important;
  outline: 0 !important;
}

.styles-module__slZmLvg + div {
  position: absolute;
  top: 2px;
  right: 7px;
  display: flex;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--writer-graynu-2);
}

.styles-module__slZmLvg + div:hover {
  background-color: var(--writer-graynu-3);
  cursor: pointer;
}

.styles-module__hKd-Q2q {
  width: 100% !important;
}

.styles-module__mqNvJ-p {
  min-width: 50px !important;
  height: 29px !important;
  padding: 0 !important;
}

.styles-module__6fVX0ec {
  display: flex;
  flex-direction: column;
  padding: 12px;
  border-bottom: var(--gray-border);
  gap: 12px;
}

.styles-module__6fVX0ec .styles-module__9pkUir1 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.styles-module__6fVX0ec > p {
  margin-left: 2px;
}

.styles-module__6fVX0ec > div {
  display: flex;
  flex-direction: row;
  align-content: center;
  gap: 12px;
}

.styles-module__h3nYTjF {
  max-height: 296px;
  overflow-y: scroll;
}

.styles-module__QkHlbM- {
  max-height: 230px;
}

.styles-module__w4O3I9G {
  width: 200px;
}
`;

export default {"formControl":"styles-module__K7gGt54","header":"styles-module__pBAW5EL","termsWrapper":"styles-module__RjMmtOa","termOption":"styles-module__CSsTIBz","term":"styles-module__Y9y7-mt","dropDownWrapper":"styles-module__vrMMzwC","menuItemsContainer":"styles-module__KwW-ZrZ","input":"styles-module__slZmLvg","loading":"styles-module__hKd-Q2q","loadingCreatedTerm":"styles-module__mqNvJ-p","createTerm":"styles-module__6fVX0ec","noPaddings":"styles-module__9pkUir1","showTerms":"styles-module__h3nYTjF","showTermsSmaller":"styles-module__QkHlbM-","infoTooltipContainer":"styles-module__w4O3I9G"};
export { css, digest };
  