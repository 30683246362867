import React, { forwardRef } from 'react';
import cx from 'classnames';
import { isEmpty } from 'lodash/fp';

import { Enum } from '@writerai/utils';

import styles from './Input.module.css';

export const TInputType = new Enum('text', 'password', 'email', 'number', 'tel', 'url');
export const TInputVariant = new Enum('small', 'medium');

const TInputTextTypes: Partial<typeof TInputType.type>[] = [
  TInputType.enum.text,
  TInputType.enum.email,
  TInputType.enum.password,
  TInputType.enum.number,
  TInputType.enum.tel,
  TInputType.enum.url,
];

// Extend standard input props to allow all attributes
interface IInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  variant?: typeof TInputVariant.type;
  type?: typeof TInputType.type;
  error?: boolean;
}

export const Input = forwardRef<HTMLInputElement, IInputProps>(
  ({ className, type = TInputType.enum.text, variant = TInputVariant.enum.small, error, ...props }, ref) => (
    <div
      className={cx({
        [styles.disabled]: props.disabled,
      })}
    >
      <input
        className={cx(className, {
          [styles.hasError]: error,
          [styles.inputText]: TInputTextTypes.includes(type),
          [styles.empty]: isEmpty(props.value || ''),
          [styles.small]: variant === TInputVariant.enum.small,
          [styles.medium]: variant === TInputVariant.enum.medium,
        })}
        type={type}
        ref={ref}
        autoCapitalize="off"
        autoComplete="off"
        autoCorrect="off"
        spellCheck="false"
        {...props}
      />
    </div>
  ),
);
