import { Buffer } from 'buffer';
import { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { sha256 } from 'js-sha256';

import API from '../../../utils/api';
import { getRedirectUrl, TIME_INTERVAL_FOR_BUFFER_READ, TIME_UNTIL_KEEP_CHECKING_BUFFER, } from './consts';
import { readBuffer } from './authBuffer';

const base64Encode = (str) => str.toString('base64')
  .replace(/\+/g, '-')
  .replace(/\//g, '_')
  .replace(/=/g, '');

const getSHA256 = (buffer) => sha256.update(buffer).digest();

export const useAuthBuffer = (onSuccess) => {
  const [checkStatus, setCheckStatus] = useState(0);
  const [bufferValues, setBufferValues] = useState({
    readKey: null,
    writeKey: null,
    codeVerifier: null,
    initialRequestTime: null,
  });
  const [errorState, setErrorState] = useState(false);

  const readBufferCallback = useCallback(({ readKey, writeKey, codeVerifier, initialRequestTime }) => {
    setBufferValues({
      readKey,
      writeKey,
      codeVerifier,
      initialRequestTime,
    });
  }, []);

  const submit = useCallback(
    async (windowRef) => {
      try {
        setErrorState(false);
        const uuid = uuidv4();
        const initialRequestTime = Date.now();
        const codeVerifier = base64Encode(Buffer.from(uuid.slice(0, 32)));
        const codeChallenge = base64Encode(Buffer.from((getSHA256(codeVerifier))));
        const res = await API.createBuffer();
        const { readKey, writeKey } = res.data || {};

        windowRef.location = getRedirectUrl(writeKey, codeChallenge);
        readBufferCallback({ readKey, writeKey, codeVerifier, initialRequestTime });
      } catch (e) {
        setErrorState(true);
      }
    },
    [readBufferCallback],
  );

  useEffect(() => {
    let timer;

    if (bufferValues.initialRequestTime) {
      const currentTime = Date.now();
      const timeElapsed = currentTime - bufferValues.initialRequestTime;

      if (timeElapsed < TIME_UNTIL_KEEP_CHECKING_BUFFER) {
        readBuffer(bufferValues.readKey, bufferValues.codeVerifier, onSuccess).catch((err) => {
          if (['Still pooling user auth info', 'Request failed with status code 404'].includes(err.message)) {
            timer = setTimeout(() => {
              setCheckStatus(prevCheckStatus => prevCheckStatus + 1);
            }, TIME_INTERVAL_FOR_BUFFER_READ);
          } else {
            console.error('Auth error:', err);
            setErrorState(true);
          }
        });
      } else {
        setErrorState(true);
        console.error('Auth status pooling was stopped after 2 minutes');
      }
    }

    return () => clearTimeout(timer);
  }, [bufferValues, checkStatus, onSuccess]);

  return { submit, errorState };
};
