import React from 'react';
import cx from 'classnames';
import { Icon, IconVariant, Text, TextSize } from '@writerai/ui-atoms';
import styles from './styles.module.css';

export interface AddNewRowButtonProps {
  id?: string;
  onClick: () => void;
  label: string;
  className?: string;
}

export const AddNewRowButton = ({ id, onClick, label, className }: AddNewRowButtonProps) => (
  <span aria-hidden id={id} className={cx(styles.addRowButton, className)} onClick={onClick}>
    <Icon name={IconVariant.ADD} />
    <Text variant={TextSize.XS} className={styles.addRowButtonText}>
      {label}
    </Text>
  </span>
);
