import { useEffect } from 'react';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import AOS from 'aos';
import { renderMarkdownDialog } from '@contentful/field-editor-markdown';
import { SnackbarsProvider } from '@writerai/ui-atoms';

import "./setupGlobals";
import DialogContent from "./DialogContent";
import configureStore from "../../redux";
import packageJson from "../../../package.json";

import '@writerai/ui-atoms/index.css';
import '@writerai/ui-molecules/index.css';
import '@writerai/ui-organisms/index.css';
import '@writerai/ui-sidebar/index.css';
import 'aos/dist/aos.css';

const customStore = configureStore();

const Dialog = ({ sdk }) => {
  useEffect(() => {
    console.info(`writer-contentful Version ${packageJson.version}`);

    Sentry.init({
      dsn: "https://7c5ce62a353246139ce2bfa4994f5b77@o1026471.ingest.sentry.io/6597968",
      integrations: [new BrowserTracing()],
      tracesSampleRate: 1.0,
      // @ts-ignore
      environment: process.env.NODE_ENV !== 'production' ? process.env.REACT_APP_NODE_ENV : window.env.REACT_APP_NODE_ENV,
      release: `${packageJson.name}@${packageJson.version}`,
    });

    AOS.init({
      duration: 800,
      once: true,
      offset: 100,
    });
  }, []);

  switch (sdk.parameters.invocation?.type) {
    case 'markdown-cheatsheet':
    case 'markdown-confirmInsertAsset':
    case 'markdown-embedExternalContent':
    case 'markdown-insertLink':
    case 'markdown-insertTable':
    case 'markdown-insertSpecialCharacter':
    case 'markdown-zenMode':
      return renderMarkdownDialog(sdk);

    default:
      return (
        <Provider store={customStore}>
          <SnackbarsProvider>
            <DialogContent sdk={sdk} />
          </SnackbarsProvider>
        </Provider>
      );
  }
};

export default Dialog;
