import { Subscriber } from '@writerai/mobx';
import { AnalyticsService } from '@writerai/analytics';
import { computeSegmentFromPositions } from '@writerai/text-utils';
import {
  CategoryType,
  type IIssue,
  type ISidebarCategory,
  IssueCardType,
  IssueCategory,
  IssueFlag,
} from '@writerai/types';
import type { Emitter } from 'nanoevents';
import { action, comparer, computed, makeObservable, observable, reaction } from 'mobx';
import type { IIssuesModel, TSelectedIssueContext } from './issues/types';
import { IssueUpdateType } from './issues/types';
import type { ICategoriesModel } from './categories';
import { inRange } from '../utils/inRange';
import {
  CATEGORIES_IGNORED_FROM_SIDEBAR,
  type ISidebarEvents,
  ISSUE_TYPES_IGNORED_FROM_SIDEBAR,
  NUMBER_OF_WORDS_AROUND_HIGHLIGHT_AS_SEGMENT,
} from '../types';

type TIssueModelImpl = Pick<IIssuesModel, 'allIssues' | 'isEmptyState'>;
type TCategoriesModel = Pick<ICategoriesModel, 'selectedCategory' | 'categoriesList' | 'isPlagiarismCategorySelected'>;
type TEventBus = Emitter<
  Pick<
    ISidebarEvents,
    'onFlagSuggestionCallback' | 'onSelectSuggestion' | 'onApplySuggestionCallback' | 'onBulkApplyCallback'
  >
>;

/**
 * Represents the options for the UIIssueModel.
 */
/**
 * Represents the options for the UI issue model.
 */
export interface IUIIssueModelOptions {
  issues: TIssueModelImpl;
  categories: TCategoriesModel;
  analytics: AnalyticsService;
  eventBus: TEventBus;

  documentContentData(): string | undefined;

  documentId: () => string | undefined;
  workspaceId: () => string | undefined;
  /**
   * Flags a suggestion as wrong using the API.
   * @param issue - The issue to flag.
   * @param state - The flag state.
   * @param segment - The segment of the suggestion.
   * @param comment - The comment to add to the issue.
   * @returns A promise that resolves when the API call is complete.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  apiFlagSuggestionAsWrong: (issue: IIssue, state: IssueFlag, segment: string, comment?: string | null) => Promise<any>;
  apiBulkFlagSuggestionsAsWrong: (
    flagSuggestionsResults: { issue: IIssue; state: IssueFlag; segment: string; comment?: string | null }[],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) => Promise<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  apiSuggestionComment: (issue: IIssue, comment: string) => Promise<any>;
  /**
   * Reports an accepted suggestion using the API.
   * @param replacement - The replacement string.
   * @param issue - The issue to report on.
   * @param segment - The segment of the suggestion.
   * @returns A promise that resolves when the API call is complete.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  apiReportOnAcceptSuggestion: (replacement: string, issue: IIssue, segment: string) => Promise<any>;
  /**
   * Reports an accepted suggestions using the API.
   * @param replacement - The replacement string.
   * @param issues - The issues to report on.
   * @param segment - The segment of the suggestion.
   * @returns A promise that resolves when the API call is complete.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  apiBulkReportOnAcceptSuggestions: (replacement: string, issues: IIssue[], segment: string) => Promise<any>;
}

/**
 * Represents a UI issue model.
 */
/**
 * Represents a model for UI issues.
 */
export class UIIssueModel implements IIssuesModel {
  private readonly documentContentData!: string;
  private readonly documentId!: string | undefined;
  private readonly workspaceId!: string | undefined;

  constructor({
    issues,
    categories,
    analytics,
    eventBus,
    documentContentData,
    documentId,
    workspaceId,
    apiFlagSuggestionAsWrong,
    apiBulkFlagSuggestionsAsWrong,
    apiReportOnAcceptSuggestion,
    apiBulkReportOnAcceptSuggestions,
    apiSuggestionComment,
  }: IUIIssueModelOptions) {
    this.issues = issues;
    this.categories = categories;
    this.analytics = analytics;
    this.eventBus = eventBus;
    this.apiFlagSuggestionAsWrong = apiFlagSuggestionAsWrong;
    this.apiBulkFlagSuggestionsAsWrong = apiBulkFlagSuggestionsAsWrong;
    this.apiReportOnAcceptSuggestion = apiReportOnAcceptSuggestion;
    this.apiBulkReportOnAcceptSuggestions = apiBulkReportOnAcceptSuggestions;
    this.apiSuggestionComment = apiSuggestionComment;
    Object.defineProperties(this, {
      documentContentData: {
        enumerable: true,
        configurable: true,
        get: () => documentContentData() ?? '',
      },
      documentId: {
        enumerable: true,
        configurable: true,
        get: documentId,
      },
      workspaceId: {
        enumerable: true,
        configurable: true,
        get: workspaceId,
      },
    });

    makeObservable(this, {
      selectedIssueContext: computed.struct,
      selectedIssue: computed,
      expandedIssueId: computed,
      isEmptyState: computed,
      list: computed({ equals: comparer.shallow }),
      currentIssues: computed({ equals: comparer.shallow }),
      sidebarIssues: computed({ equals: comparer.shallow }),
      currentSidebarIssues: computed({ equals: comparer.shallow }),
      visibleSidebarIssues: computed({ equals: comparer.shallow }),
      issuesByCategory: computed.struct,
      selectedSidebarIssue: computed,
      totalSidebarIssuesCount: computed,
    });
  }

  /**
   * Return context of selected issue id and source
   */
  get selectedIssueContext(): TSelectedIssueContext {
    const { expandedIssueId: issueId } = this;
    const ref = this.$selectedIssueContext.get();

    if (ref && ref.issueId === issueId) {
      return ref;
    }

    return {
      issueId,
      source: issueId ? IssueUpdateType.enum.api : IssueUpdateType.enum.unknown,
    };
  }

  /**
   * Gets the selected issue.
   * @returns The selected issue, or undefined if no issue is selected.
   */
  get selectedIssue(): IIssue | undefined {
    const ref = this.$selectedIssue.get();

    // we need to start it earliar
    const { prev, replacement } = this.$issueHistory.get();

    if (ref.item) {
      return ref.item;
    }

    if (!this.currentSidebarIssues.length) {
      return undefined;
    }

    // element could be invisible, but available
    const firstElement = this.currentSidebarIssues[0];

    // if category was changed we have to show first item
    if (this.$selectedCategory.get() !== this.categories.selectedCategory) {
      return firstElement;
    }

    const nextIssueId = this.$nextIssueId.get();

    // try to find next issues
    const nextIssue = nextIssueId ? this.currentSidebarIssues.find(item => item.issueId === nextIssueId) : undefined;

    if (nextIssue) {
      return nextIssue;
    }

    // try to find next issue from offset of previous plus length of it's replacement
    const nextOffset = prev ? prev.from + (replacement?.length ?? 0) : -1;
    const nextOffsetItem = nextOffset > 0 ? this.currentSidebarIssues.find(item => item.from >= nextOffset) : undefined;

    if (nextOffsetItem) {
      return nextOffsetItem;
    }

    // if we can't find next element, try to find prev element
    const prevOffset = prev ? prev.from : -1;
    const prevOffsetItem =
      prevOffset < 0
        ? undefined
        : this.currentSidebarIssues.reduce((memo, item) => {
            if (item.from <= prevOffset) {
              if (!memo || item.from >= memo.from) {
                return item;
              }
            }

            return memo;
          }, undefined as IIssue | undefined);

    // return next offset issue or first element
    return prevOffsetItem ?? firstElement;
  }

  /**
   * Returns currently selected issue id
   */
  get expandedIssueId() {
    return this.selectedIssue?.issueId;
  }

  /**
   * Checks if the state is empty.
   * @returns {boolean} True if the state is empty, false otherwise.
   */
  get isEmptyState() {
    return this.issues.isEmptyState;
  }

  /**
   * Return full list of issues without deleted
   */
  get allIssues(): IIssue[] {
    return this.issues.allIssues;
  }

  /**
   * Return full list of issues
   */
  get list(): IIssue[] {
    return this.allIssues?.filter(issue => !this.$deletedIssuesMap.has(issue.issueId));
  }

  /**
   * Returns full list of issues filtered by category
   */
  get currentIssues() {
    return UIIssueModel.getIssuesByCategory(this.list, this.categories.selectedCategory);
  }

  /**
   * Returns full list of sidebar issues (excluding dictionary etc.)
   */
  get sidebarIssues() {
    return UIIssueModel.filterNoNSidebarIssues(this.list);
  }

  /**
   * Returns issues that is currently displayed in the sidebar (filtered by category)
   */
  get currentSidebarIssues() {
    return UIIssueModel.filterNoNSidebarIssues(this.currentIssues);
  }

  /**
   * Returns the list of sidebar issues that are currently visible based on the visible range and visible issues map.
   * If the visible issues map is undefined and the visible range is empty, the function returns the current sidebar issues.
   * If the visible issues map is defined and not empty, the function returns the current sidebar issues filtered by the issue IDs in the visible issues map.
   * If the visible range is defined and not empty, the function returns the current sidebar issues filtered by the range of positions specified in the visible range.
   * If neither the visible issues map nor the visible range is defined, the function returns an empty array.
   * @returns {Array<IIssue>} The list of visible sidebar issues.
   */
  get visibleSidebarIssues() {
    const { currentSidebarIssues } = this;

    const visibleRange = this.$visibleRange.get();

    /*
     * If visibleIssuesMap is undefined, sidebar issues list should be full
     * */
    if (!visibleRange) {
      return currentSidebarIssues;
    }

    /*
     * If visibleRange is defined, but empty, sidebar issues list should be empty too
     * */
    if (visibleRange) {
      if (visibleRange.length) {
        return currentSidebarIssues.filter(item => inRange(item.from, item.until, visibleRange));
      }
    }

    return [];
  }

  /**
   * Returns number of issues by category
   */
  get issuesByCategory(): Record<CategoryType, number> {
    const { categoriesList } = this.categories;

    if (!categoriesList) {
      return {} as Record<CategoryType, number>;
    }

    return categoriesList.reduce((res, category) => {
      res[category.id] = UIIssueModel.getIssuesByCategory(this.sidebarIssues, category).length;

      return res;
    }, {} as Record<CategoryType, number>);
  }

  /**
   * Returns issue that is currently selected in the sidebar
   */
  get selectedSidebarIssue(): IIssue | undefined {
    const issueId = this.$selectedIssueContext.get()?.issueId;
    const issue = issueId ? this.currentSidebarIssues.find(issue => issue.issueId === issueId) : undefined;

    return issue;
  }

  /**
   * Returns total number of issues in the sidebar
   */
  get totalSidebarIssuesCount() {
    if (this.categories.isPlagiarismCategorySelected) {
      return this.sidebarIssues.length;
    }

    return this.sidebarIssues.length - this.issuesByCategory[CategoryType.PLAGIARISM];
  }

  /**
   * Set's currently selected issue in sidebar
   * @param issue
   * @param source
   */
  readonly setSelectedIssue = action(
    (issue: IIssue, source: typeof IssueUpdateType.type = IssueUpdateType.enum.unknown) => {
      const issueId = this.$selectedIssueContext.get()?.issueId;

      // if issue is not selected or selected issue is not the same as the new one
      if (issueId !== issue.issueId) {
        // call analytics
        this.analytics.track(AnalyticsService.EV.suggestionViewed, {
          suggestion_category: issue.category,
          suggestion_issue_type: issue.issueType,
          card_type: IssueCardType.SIDEBAR,
          team_id: Number(this.workspaceId),
        });

        // set selected issue
        this.$selectedIssueContext.set({
          issueId: issue.issueId,
          source,
        });

        this.$selectedCategory.set(this.categories.selectedCategory);
      }

      this.eventBus.emit('onSelectSuggestion', issue, source);
    },
  );

  /**
   * Map that stores deleted issues.
   * The key is the issue ID and the value is a boolean indicating if the issue is deleted.
   */
  private readonly $deletedIssuesMap = observable.map<string, boolean>();

  private readonly $nextIssueId = observable.box<string>();

  /**
   * @deprecated
   * please use setIssuesVisibility instead of this method
   * Shows\Hides issue by id in the sidebar
   * @param issue
   * @param isVisible
   */
  readonly setIssueVisibility = action((issue: IIssue, isVisible: boolean) => {
    this.$deletedIssuesMap.set(issue.issueId, !isVisible);
  });

  /**
   * Shows\Hides issues by id in the sidebar
   * @param issues
   * @param isVisible
   */
  readonly setIssuesVisibility = action((issues: IIssue[], isVisible: boolean) => {
    issues.forEach(issue => this.$deletedIssuesMap.set(issue.issueId, !isVisible));
  });

  /**
   * @deprecated
   */
  // eslint-disable-next-line class-methods-use-this
  setVisibleIssuesMap(_map: Map<string, boolean> | undefined) {
    // pass
  }

  /**
   * Sets the visible range of the UIIssueModel.
   * @param range - An array of start and end positions representing the visible range.
   */
  readonly setVisibleRange = action((range: Array<readonly [start: number, end: number]> | undefined) => {
    this.$visibleRange.set(range);
  });

  /**
   * Handles the flagging of an issue.
   *
   * @param state - The state of the issue flag.
   * @param issue - The issue to be flagged.
   * @param comment - The comment to add to the issue.
   * @param cardType - The type of the issue card.
   */
  private async onFlagIssue(state: IssueFlag, issue: IIssue, cardType: IssueCardType, comment?: string | null) {
    const segment = computeSegmentFromPositions(
      this.documentContentData,
      issue.from,
      issue.until,
      NUMBER_OF_WORDS_AROUND_HIGHLIGHT_AS_SEGMENT,
    );

    this.setIssuesVisibility([issue], false);

    try {
      this.eventBus.emit('onFlagSuggestionCallback', issue, state, comment);
      await this.apiFlagSuggestionAsWrong(issue, state, segment, comment);
    } catch (e) {
      this.setIssuesVisibility([issue], true);
    }

    const analyticsEvent =
      state === IssueFlag.IGNORE ? AnalyticsService.EV.suggestionIgnored : AnalyticsService.EV.suggestionFlagged;

    this.analytics.track(analyticsEvent, {
      suggestion_category: issue.category,
      suggestion_issue_type: issue.issueType,
      card_type: cardType,
      team_id: Number(this.workspaceId),
      suggestions_count: 1,
    });
  }

  /**
   * Handles the delete issue click event.
   * @param issue - The issue to be deleted.
   * @param cardType - The type of the issue card.
   */
  readonly onDeleteIssueClick = (issue: IIssue, cardType: IssueCardType) =>
    this.onFlagIssue(IssueFlag.IGNORE, issue, cardType);

  /**
   * Bulk delete similar issues.
   * @param issues - Issues to delete.
   * @param cardType - The type of the issue card.
   */
  readonly onBulkDeleteIssues = async (issues: IIssue[], cardType: IssueCardType) => {
    const deleteResults = await Promise.all(
      issues.map(async issue => {
        const segment = computeSegmentFromPositions(
          this.documentContentData || '',
          issue.from,
          issue.until,
          NUMBER_OF_WORDS_AROUND_HIGHLIGHT_AS_SEGMENT,
        );

        this.eventBus.emit('onFlagSuggestionCallback', issue, IssueFlag.IGNORE);

        return {
          issue,
          state: IssueFlag.IGNORE,
          segment,
        };
      }),
    );

    this.setIssuesVisibility(issues, false);

    await this.apiBulkFlagSuggestionsAsWrong(deleteResults);

    const firstIssue = issues[0];
    this.analytics.track(AnalyticsService.EV.suggestionIgnored, {
      suggestion_category: firstIssue.category,
      suggestion_issue_type: firstIssue.issueType,
      card_type: cardType,
      team_id: Number(this.workspaceId),
      suggestions_count: issues.length,
    });
  };

  /**
   * Marks an issue as wrong.
   * @param issue - The issue to mark as wrong.
   * @param cardType - The type of issue card.
   * @param comment - The comment to add to the issue.
   */
  readonly onMarkIssueAsWrong = (issue: IIssue, cardType: IssueCardType, comment?: string | null) =>
    this.onFlagIssue(IssueFlag.WRONG, issue, cardType, comment);

  /**
   * Applies a suggestions to the issues and performs necessary actions.
   * @param issues - The issues objects.
   * @param replacement - The replacement string for the suggestions.
   * @param cardType - The type of the issue card.
   */
  readonly onBulkAcceptSuggestions = async (issues: IIssue[], replacement: string, cardType: IssueCardType) => {
    const firstIssue = issues[0];
    this.analytics.track(AnalyticsService.EV.suggestionAccepted, {
      suggestion_category: firstIssue.category,
      suggestion_issue_type: firstIssue.issueType,
      card_type: cardType,
      team_id: Number(this.workspaceId),
      suggestions_count: issues.length,
    });

    const segment = computeSegmentFromPositions(
      this.documentContentData || '',
      firstIssue.from,
      firstIssue.until,
      NUMBER_OF_WORDS_AROUND_HIGHLIGHT_AS_SEGMENT,
    );

    this.setIssuesVisibility(issues, false);

    await this.apiBulkReportOnAcceptSuggestions(replacement, issues, segment);

    this.eventBus.emit('onBulkApplyCallback', issues, replacement, this.documentId);
  };

  /**
   * Handles the resolution of a claimed issue.
   * @param issue - The issue to be resolved.
   */
  readonly onClaimResolve = (issue: IIssue) => this.onFlagIssue(IssueFlag.IGNORE, issue, IssueCardType.INLINE);

  /**
   * Handles the claim decline action for an issue.
   * @param issue - The issue to be claimed or declined.
   */
  readonly onClaimDecline = (issue: IIssue) => this.onFlagIssue(IssueFlag.WRONG, issue, IssueCardType.INLINE);

  /**
   * Marks the specified issues as deleted by setting their visibility to false.
   * @param issues - The issues to mark as deleted.
   */
  readonly markIssuesAsDeleted = (issues: IIssue[]): void => this.setIssuesVisibility(issues, false);

  /**
   * Applies a suggestion to the issue and performs necessary actions.
   * @param replacement - The replacement string for the suggestion.
   * @param issue - The issue object.
   * @param cardType - The type of the issue card.
   */
  readonly onApplySuggestion = async (replacement: string, issue: IIssue, cardType: IssueCardType) => {
    this.analytics.track(AnalyticsService.EV.suggestionAccepted, {
      suggestion_category: issue.category,
      suggestion_issue_type: issue.issueType,
      card_type: cardType,
      team_id: Number(this.workspaceId),
      suggestions_count: 1,
    });

    const segment = computeSegmentFromPositions(
      this.documentContentData || '',
      issue.from,
      issue.until,
      NUMBER_OF_WORDS_AROUND_HIGHLIGHT_AS_SEGMENT,
    );

    this.setIssuesVisibility([issue], false);

    try {
      this.eventBus.emit('onApplySuggestionCallback', replacement, issue, this.documentId);
      await this.apiReportOnAcceptSuggestion(replacement, issue, segment);
    } catch (e) {
      this.setIssuesVisibility([issue], true);
    }
  };

  /**
   * @deprecated
   */
  readonly visibleIssuesMap = undefined;

  // private

  /**
   * Represents the selected issue in the UI.
   * @readonly
   */
  private readonly $selectedIssue = computed(
    () => {
      const ctx = this.$selectedIssueContext.get();

      // we are looking for on all available, but maybe invisible elements
      const list = this.currentSidebarIssues;
      const iLen = list.length;

      if (ctx) {
        for (let i = 0; i < iLen; i++) {
          const item = list[i];

          if (item.issueId === ctx.issueId) {
            return { index: i, item } as const;
          }
        }
      }

      return { index: -1, item: undefined } as const;
    },
    { equals: comparer.default },
  );

  /**
   * Represents the index history of the UIIssueModel.
   * It keeps track of the previous and current index values.
   */
  private readonly $issueHistory = (() => {
    const subscriber = new Subscriber<
      boolean,
      { cur: IIssue | undefined; prev: IIssue | undefined; replacement: undefined | string }
    >({
      autoclear: true,
      getId: () => true,
      subscribe: (_, push) => {
        let cur: IIssue | undefined;
        let prev: IIssue | undefined;
        push({ cur, prev, replacement: undefined });

        const cancel = [
          this.eventBus.on('onApplySuggestionCallback', replacement => {
            push({ cur, prev, replacement });
          }),
          this.eventBus.on('onFlagSuggestionCallback', issue => {
            this.setupNextIssue(issue);
          }),
          this.eventBus.on('onSelectSuggestion', issue => {
            this.setupNextIssue(issue);
          }),
          reaction(
            () => this.$selectedIssue.get().item,
            item => {
              prev = cur;
              cur = item;
              push({ prev, cur, replacement: undefined });
            },
            { equals: comparer.shallow },
          ),
        ];

        return () => cancel.forEach(cb => cb());
      },
    });

    return computed(() => subscriber.data ?? { prev: undefined, cur: undefined, replacement: undefined }, {
      equals: comparer.structural,
    });
  })();

  private readonly setupNextIssue = action((issue: IIssue | undefined) => {
    const issueId = issue?.issueId;

    if (!issueId) {
      this.$nextIssueId.set(issueId);

      return;
    }

    const index = this.visibleSidebarIssues.findIndex(issue => issue.issueId === issueId);

    if (index < 0) {
      this.$nextIssueId.set(undefined);

      return;
    }

    const nextIndex = index + 1;

    if (nextIndex < this.visibleSidebarIssues.length) {
      const item = this.visibleSidebarIssues[nextIndex];
      this.$nextIssueId.set(item.issueId);
    } else if (index - 1 > 0) {
      const item = this.visibleSidebarIssues[index - 1];
      this.$nextIssueId.set(item.issueId);
    } else {
      this.$nextIssueId.set(undefined);
    }
  });

  private readonly analytics: AnalyticsService;
  private readonly issues: TIssueModelImpl;
  private readonly categories: TCategoriesModel;
  private readonly eventBus: TEventBus;
  private readonly $selectedIssueContext = observable.box<TSelectedIssueContext>(undefined);
  private readonly $selectedCategory = observable.box<ISidebarCategory>(undefined, {
    deep: false,
    equals: comparer.identity,
  });

  private readonly $visibleRange = observable.box<Array<readonly [start: number, end: number]>>(undefined, {
    deep: false,
  });

  private readonly apiFlagSuggestionAsWrong: IUIIssueModelOptions['apiFlagSuggestionAsWrong'];
  private readonly apiBulkFlagSuggestionsAsWrong: IUIIssueModelOptions['apiBulkFlagSuggestionsAsWrong'];
  private readonly apiSuggestionComment: IUIIssueModelOptions['apiSuggestionComment'];
  private readonly apiReportOnAcceptSuggestion: IUIIssueModelOptions['apiReportOnAcceptSuggestion'];
  private readonly apiBulkReportOnAcceptSuggestions: IUIIssueModelOptions['apiBulkReportOnAcceptSuggestions'];

  // static

  /**
   * Retrieves issues from a source array based on the specified category.
   * If a category is provided, it filters the issues that belong to that category.
   * If no category is provided, it filters out issues with the category "Plagiarism".
   *
   * @param source - The array of issues to filter.
   * @param category - The category to filter the issues by.
   * @returns An array of issues that match the specified category.
   */
  static getIssuesByCategory(source: IIssue[], category?: ISidebarCategory) {
    if (category) {
      return source.filter(issue => category.categories.includes(issue.category));
    }

    return source.filter(issue => issue.category !== IssueCategory.Plagiarism);
  }

  /**
   * Filters out issues that should not be displayed in the sidebar.
   *
   * @param list - The list of issues to filter.
   * @returns The filtered list of issues.
   */
  static filterNoNSidebarIssues(list: IIssue[]) {
    return (
      list
        // some of the categories\issue types must be ignored in the sidebar
        .filter(issue => !CATEGORIES_IGNORED_FROM_SIDEBAR.includes(issue.category))
        .filter(issue => !ISSUE_TYPES_IGNORED_FROM_SIDEBAR.includes(issue.issueType))
    );
  }
}
