import React from 'react';

import styles from './styles.module.css';

/**
 * Alert template for react-alert
 * - the style contains only the margin given as offset
 * - options contains all alert given options
 * - message is the alert message
 * - close is a function that closes the alert
 */
export const AlertTemplate = ({
  style,
  message,
  close,
}: {
  style: React.CSSProperties;
  message?: React.ReactNode;
  close?: (e: React.MouseEvent) => void;
}) => (
  <div aria-hidden className={styles.container} style={style} onClick={close}>
    {message}
  </div>
);
