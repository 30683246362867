
const digest = '04eb45c668c54c6b97192ad71dda229012fccdaddb4aa74c0130363523e16718';
const css = `.styles-module__CMDOvzS {
  display: flex;
  width: 100%;
  align-items: center;
  padding-right: 8px;
  padding-left: 8px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 11px;
  line-height: 1.27;
  transition: var(--background-color-transition);
}

.styles-module__Xk6RoGr {
  position: relative;
  width: var(--sidebar-category-container-small-width);
  min-height: 0;
  max-height: none;
  box-sizing: border-box;
  padding: 4px 7px;
  margin-bottom: 6px;
  border-bottom-left-radius: var(--default-border-radius);
  border-top-left-radius: var(--default-border-radius);
}

.styles-module__Xk6RoGr .styles-module__D-pWrH4 {
  flex: 1 1 auto;
  font-size: 10px;
  line-height: 1.1;
}

.styles-module__Xk6RoGr .styles-module__bZBG90S {
  margin: 2px 0 0;
  font-size: 10px;
  font-weight: 600;
  line-height: 1.2;
  text-align: left;
}

.styles-module__aHaFvti {
  display: flex;
  flex: 1;
}

.styles-module__7VL3RQz {
  flex-direction: column;
}

.styles-module__JfLM6F1 {
  background-color: var(--classic-grey-1);
  font-weight: 500;
}

.styles-module__CMDOvzS:not(.styles-module__RHHkfC1):hover {
  background-color: var(--writer-graynu-1);
}

.styles-module__dJCHMzw {
  margin: 14px 11px 14px 0;
}

.styles-module__zsIuAWE {
  width: 3px;
  min-width: 3px !important;
  max-width: 3px;
  min-height: 18px !important;
  max-height: 18px;
  border-radius: 0 !important;
}

.styles-module__D-pWrH4 {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: space-between;
}

.styles-module__nPPWXb- {
  font-size: 14px;
  font-weight: 500;
}

.styles-module__RHHkfC1 .styles-module__D-pWrH4 {
  color: var(--writer-grey-3);
}

.styles-module__bZBG90S {
  min-width: 17px;
  margin: 14px 0;
  font-weight: 500;
  text-align: center;
}

.styles-module__-6G353e {
  visibility: hidden;
}

.styles-module__SKZFtDY {
  position: relative;
  top: -2px;
}

.styles-module__SKZFtDY path {
  fill: var(--classic-grey-2);
}

.styles-module__Xk6RoGr .styles-module__dJCHMzw {
  align-self: stretch;
  margin: 0 6px 0 0;
}

.styles-module__Xk6RoGr .styles-module__aHaFvti {
  align-items: baseline;
}

.styles-module__Xk6RoGr .styles-module__nPPWXb- {
  flex: 0;
  font-size: 19px;
}

.styles-module__Xk6RoGr .styles-module__zsIuAWE {
  height: 100% !important;
  min-height: 0 !important;
  max-height: none;
  border-radius: var(--default-border-radius) !important;
}

.styles-module__Xk6RoGr .styles-module__jU81vEt {
  padding: 0 4px;
  font-size: 11px;
  font-weight: 500;
  line-height: 1.5;
}

.styles-module__ETWecf- .styles-module__D-pWrH4 {
  font-size: 12px;
}
`;

export default {"category":"styles-module__CMDOvzS","small":"styles-module__Xk6RoGr","text":"styles-module__D-pWrH4","value":"styles-module__bZBG90S","categoryContent":"styles-module__aHaFvti","categoryContentColumn":"styles-module__7VL3RQz","selected":"styles-module__JfLM6F1","disabled":"styles-module__RHHkfC1","prefix":"styles-module__dJCHMzw","smallColorDot":"styles-module__zsIuAWE","heading":"styles-module__nPPWXb-","valueHidden":"styles-module__-6G353e","lockIcon":"styles-module__SKZFtDY","headingValue":"styles-module__jU81vEt","smallWithoutValue":"styles-module__ETWecf-"};
export { css, digest };
  