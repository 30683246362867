import { Quill } from 'react-quill';
import Delta from 'quill-delta';
import { normalizeAndCleanDelta } from '@writerai/quill-delta-utils';
import Parchment from 'parchment';

const Clipboard = Quill.import('modules/clipboard');

const fontWeightAttributor = new Parchment.Attributor.Style('font-weight', 'font-weight');

export class CustomQuillClipboard extends Clipboard {
  onPaste(range, { text, html }) {
    const formats = this.quill.getFormat(range.index);
    const pastedDelta = normalizeAndCleanDelta(
      this.convert(
        {
          text,
          html: this.process(html),
        },
        formats,
      ),
    );
    const delta = new Delta().retain(range.index).delete(range.length).concat(pastedDelta);
    this.quill.updateContents(delta, 'user');

    this.quill.setSelection(delta.length() - range.length, 'silent');
    this.quill.scrollIntoView();
  }

  process(html) {
    const container = document.createElement('div');

    container.innerHTML = html;

    const newChildren = Array.from(container.childNodes).map(this.normalizeBoldElements);

    container.innerHTML = '';
    newChildren.forEach(child => container.appendChild(child));

    return container.innerHTML;
  }

  normalizeBoldElements(element) {
    const tags = ['B', 'STRONG'];

    if (
      element instanceof HTMLElement &&
      fontWeightAttributor.value(element) === 'normal' &&
      tags.includes(element.tagName)
    ) {
      const span = document.createElement('span');
      span.innerHTML = element.innerHTML;

      return span;
    }

    return element;
  }
}
