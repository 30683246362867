import { filePattern } from './constants';

/**
 * Checks if the given string is a word.
 * @param word - The string to check.
 * @returns True if the string is a word, false otherwise.
 */
export function isWord(word: string): boolean {
  if (word.length > 100) {
    return false;
  }

  if (word.includes('.') && word.includes('/')) {
    return false;
  }

  if (filePattern.test(word)) {
    return false;
  }

  return /[a-zA-Z]/.test(word);
}
