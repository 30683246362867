import type { RequestServiceInitialize, components } from '@writerai/network';
import type { AnalyticEvents, IGtmActivityParams, TActivityParamsOrAny } from '@writerai/types';
import { E_CLIENT_ID, E_INTEGRATION_TYPE, H_INTEGRATION_TYPE_TO_CLIENT_ID, AnalyticsActivity } from '@writerai/types';

import { getLogger } from './utils/logger';

const LOG = getLogger('AnalyticsService');

export interface IAnalytics {
  track<T extends AnalyticEvents>(activity: T, activityProperties: TActivityParamsOrAny[T]): Promise<void>;
  trackAnonymousEvent<T extends AnalyticEvents>(eventName: T): Promise<void>;
  trackOrganizationEvent<T extends AnalyticEvents>(
    organizationId: number,
    eventName: T,
    activityProperties: TActivityParamsOrAny[T],
  ): Promise<void>;

  trackGtmActivity(params: IGtmActivityParams): Promise<void>;
}

// replaces the analytics url with ad safe alias but keeps the typing for network service
const encodeUrl = <T extends string>(url: T): T => url.replace('/analytics', '/anlytcs') as T;

export class AnalyticsService implements IAnalytics {
  static EV = AnalyticsActivity;
  readonly api: RequestServiceInitialize['api'];
  public readonly integrationType: string;
  public readonly clientId: string;

  constructor(
    api: RequestServiceInitialize['api'],
    integrationType: string,
    clientId = E_INTEGRATION_TYPE.is(integrationType)
      ? H_INTEGRATION_TYPE_TO_CLIENT_ID[integrationType]
      : E_CLIENT_ID.enum.Unknown,
  ) {
    this.api = api;
    this.clientId = clientId;
    this.integrationType = integrationType;
  }

  async track<T extends AnalyticEvents>(eventName: T, activityProperties: TActivityParamsOrAny[T]): Promise<void> {
    LOG.debug('track', { eventName, activityProperties });
    await this.api.post(encodeUrl('/api/analytics/track'), {
      params: {},
      body: {
        eventName,
        properties: {
          ...activityProperties,
          integration_type: this.integrationType,
          client_id: this.clientId,
        },
      },
    });
  }

  async trackAnonymousEvent<T extends AnalyticEvents>(eventName: T): Promise<void> {
    LOG.debug('trackAnonymousEvent', { eventName });
    await this.api.post(encodeUrl('/api/analytics/anonymous/track'), {
      params: {},
      body: { eventName, properties: {} },
    });
  }

  async trackOrganizationEvent<T extends AnalyticEvents>(
    organizationId: number,
    eventName: T,
    activityProperties: TActivityParamsOrAny[T],
  ): Promise<void> {
    LOG.debug('trackOrganizationEvent', { organizationId, eventName, activityProperties });
    await this.api.post(encodeUrl('/api/analytics/organization/{organizationId}/track'), {
      params: { path: { organizationId } },
      body: {
        eventName,
        properties: {
          ...activityProperties,
          integration_type: this.integrationType,
          client_id: this.clientId,
        },
      },
    });
  }

  async trackGtmActivity(
    params: IGtmActivityParams,
    dataLayer = typeof window !== 'undefined'
      ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ((window as any).dataLayer as { push: (e: unknown) => void })
      : undefined,
  ): Promise<void> {
    dataLayer?.push(params);
    LOG.debug('trackGtmActivity', { params, integrationType: this.integrationType, clientId: this.clientId });
  }

  async identify({ traits }: components['schemas']['analytics_dto_IdentifyRequest']) {
    LOG.debug('identify', traits);
    await this.api.post('/api/analytics/identify', {
      body: { traits },
    });
  }
}
