
const digest = '7bc07327b9c5ab19bfd04614de134732fb54a5de702044365ee416cfdd5ef3d8';
const css = `.styles-module__TkNU1g9 {
  display: block;
}

.styles-module__QDknpJA {
  display: flex;
}
`;

export default {"container":"styles-module__TkNU1g9","wrapper":"styles-module__QDknpJA"};
export { css, digest };
  