import type { ChangeEvent, ReactElement } from 'react';
import React, { useCallback, useRef, useState } from 'react';

import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';
import truncate from 'lodash/truncate';

import {
  Icon,
  IconVariant,
  SearchBar,
  Text,
  TextSize,
  useHandleOutsideMouseDown,
  NoSearchResultsBanner,
} from '@writerai/ui-atoms';
import type { Metadata } from '@writerai/text-utils';
import { getMetadataByFileType } from '@writerai/text-utils';

import styles from './FilePicker.module.css';
import { FileBox } from '../FileBox';

type File = {
  id: string;
  name: string;
  fileType: string;
  meta?: Metadata | null;
  status: string;
};

interface IFilePickerProps {
  className?: string;
  selectedIndex?: number;
  files: File[] | null | undefined;
  onClick?: (id: string) => void;
  onClickOutside?: () => void;
}

export function generateSessionFileDescription(data: File): string {
  if (data.fileType && data.meta) {
    const metaData = getMetadataByFileType(data.fileType, data.meta);

    return metaData ? `${metaData} | ${data.fileType}` : data.fileType;
  }

  return '';
}

export const FilePicker: React.FC<IFilePickerProps> = ({
  className,
  selectedIndex,
  files,
  onClick,
  onClickOutside,
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [fileContainerScrollPosition, setFileContainerScrollPosition] = useState(0);
  const scrollableFileContainer = useRef<HTMLDivElement | null>(null);

  const filesEmptyState = (
    <div className={styles.emptyState}>
      <Icon name={IconVariant.CRYSTAL_BALL} width={40} height={40} />
      <Text variant={TextSize.XXXL} medium>
        No sources added yet
      </Text>
      <div className={styles.emptyFileBoxWrapper}>
        <FileBox className={styles.file} entityType="url" isLoading />
        <FileBox className={styles.file} entityType="txt" isLoading />
      </div>
    </div>
  );

  const parentRef = useRef<HTMLDivElement | null>(null);
  useHandleOutsideMouseDown(parentRef, onClickOutside);

  const handleSearchQuery = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e?.target?.value);
  }, []);

  const handleClearSearch = useCallback(() => {
    setSearchQuery('');
  }, []);

  const fileElements: ReactElement[] = [];

  if (!isEmpty(files)) {
    files?.forEach((file: File, i: number) => {
      const filterQuery = new RegExp(searchQuery, 'i');

      if (filterQuery.test(file.name)) {
        fileElements.push(
          <FileBox
            key={file.id}
            status={file.status}
            className={cx(styles.file, { [styles.fileFocused]: selectedIndex === i })}
            entityName={truncate(file.name, { length: 24 })}
            entityMetaInfo={generateSessionFileDescription(file)}
            entityType={file.fileType}
            isLoading={false}
            onClick={() => onClick?.(file.id)}
          />,
        );
      }
    });
  }

  const handleFileContainerScroll = useCallback(() => {
    const node = scrollableFileContainer.current;

    if (node) {
      setFileContainerScrollPosition(node.scrollTop);
    }
  }, []);

  return (
    <div className={cx(className, styles.container)} ref={parentRef}>
      <header className={cx(styles.header, { [styles.headerWithShadow]: fileContainerScrollPosition > 0 })}>
        <SearchBar
          placeholder="Search"
          onChange={handleSearchQuery}
          handleClearInput={handleClearSearch}
          value={searchQuery}
        />
      </header>
      <main ref={scrollableFileContainer} onScroll={handleFileContainerScroll} className={styles.contentWrapper}>
        <div className={styles.divider} />
        {fileElements.length > 0 && (
          <Text className={styles.sourcesTitle} variant={TextSize.XS} upperCase medium>
            Sources
          </Text>
        )}
        {fileElements.length > 0 && <div className={styles.fileWrapper}>{fileElements}</div>}
        {fileElements.length === 0 && searchQuery && <NoSearchResultsBanner className={styles.noResultsBanner} />}
        {fileElements.length === 0 && !searchQuery && filesEmptyState}
      </main>
    </div>
  );
};
