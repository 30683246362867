import cx from 'classnames';
import React, { useState } from 'react';
import { replaceVariableWithinStringWithWord, stripHtml } from '@writerai/text-utils';
import { ColorDot } from '@writerai/ui-atoms';

import { IssueLabel } from '@writerai/ui-molecules';
import styles from './styles.module.css';

interface ICardHeaderProps {
  title?: string;
  titleColor?: string;
  isBeta?: boolean;
  highlight?: string;
  description?: string;
  simpleDescription?: string;
}

const randomLabelColor = () =>
  `#${Math.floor(Math.random() * 16777215)
    .toString(16)
    .padStart(6, '0')}`;

export const CardHeader = ({
  title,
  titleColor,
  isBeta,
  highlight,
  description,
  simpleDescription,
}: ICardHeaderProps) => {
  const [_titleColor] = useState(randomLabelColor());

  const BetaTag = () => <div className={styles.betaTag}>BETA</div>;

  return (
    <div className={styles.cardHeader}>
      <ColorDot className={styles.labelColor} color={titleColor || _titleColor} />
      <div className={cx(styles.preview)}>
        {highlight?.length ? (
          <IssueLabel className={styles.collapsedLabel} style={{ overflow: 'hidden' }}>
            {highlight}
          </IssueLabel>
        ) : null}
        <div className={styles.collapsedDescription}>
          {replaceVariableWithinStringWithWord(simpleDescription, '') || stripHtml(description) || ''}
        </div>
      </div>
      <div className={styles.categoryLabel}>
        <span className={styles.cardTitle}>{title}</span>
        {isBeta && <BetaTag />}
      </div>
    </div>
  );
};
