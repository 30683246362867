import type { CategoryType, IIssue, IIssueMeta, LearnMoreModalType } from '@writerai/types';
import { IntegrationType, IssueCardType, IssueCategory } from '@writerai/types';
import { computed, type IObservableValue, makeObservable, observable, runInAction } from 'mobx';
import type { SidebarModel } from '../../models';

export const getDescription = (category: IssueCategory, description = '', term = '', commonMistake = '') => {
  if (description && description.length) return description;

  if (category === IssueCategory.CommonMistakes) {
    return `The word <b><i>${term}</i></b> is a common mistake for <b><i>${commonMistake}</i></b>.`;
  }

  if (category === IssueCategory.BannedWords) {
    return `Don't use the term <b><i>${term}</i></b>`;
  }

  if (category === IssueCategory.UseCarefully) {
    return `Use caution when applying the term <b><i>${term}</i></b>. You may want to use a different term in some contexts.`;
  }

  return description;
};

export class UIIssueCardModel {
  private readonly $issue: IObservableValue<IIssue>;
  private readonly model: SidebarModel;

  readonly onApplySuggestion: (replacement: string) => Promise<void>;

  constructor(issue: IIssue, model: SidebarModel) {
    this.$issue = observable.box<IIssue>(issue, { deep: false });
    this.model = model;

    this.onApplySuggestion = replacement =>
      Promise.resolve(this.model.issues.onApplySuggestion(replacement, this.issue, IssueCardType.SIDEBAR));

    makeObservable(this, {
      id: computed,
      addTermAvailable: computed,
      description: computed,
      isBulkActionsAllowed: computed,
      similarIssues: computed,
      isBulkAcceptAvailable: computed,
      isBulkIgnoreAvailable: computed,
      title: computed,
      titleColor: computed,
      highlight: computed,
      suggestions: computed,
      isBeta: computed,
      simpleDescription: computed,
      meta: computed,
      segment: computed,
      category: computed,
      issueType: computed,
      collapsed: computed,
    });
  }

  recreate(issue: IIssue) {
    if (this.$issue.get() !== issue) {
      runInAction(() => {
        this.$issue.set(issue);
      });
    }
  }

  private get issue() {
    return this.$issue.get();
  }

  get id() {
    return this.issue.issueId;
  }

  get highlight() {
    return this.issue.highlight;
  }

  get suggestions() {
    return this.issue.suggestions;
  }

  get isBeta() {
    return this.issue.isBeta;
  }

  get simpleDescription() {
    return this.issue.simpleDescription;
  }

  get meta(): IIssueMeta | undefined {
    return this.issue.meta;
  }

  get segment() {
    return this.issue.segment;
  }

  get category() {
    return this.issue.category;
  }

  get issueType() {
    return this.issue.issueType;
  }

  get addTermAvailable() {
    return (
      ![
        IssueCategory.CommonMistakes,
        IssueCategory.BannedWords,
        IssueCategory.UseCarefully,
        IssueCategory.PassiveVoice,
        IssueCategory.SentenceComplexity,
        IssueCategory.HealthyCommunication,
        IssueCategory.Plagiarism,
      ].includes(this.issue.category) && this.canWriteToTerms
    );
  }

  get description() {
    const { issue } = this;
    const { description, highlight, suggestions } = issue;

    return getDescription(issue.category, description, highlight, suggestions[0]);
  }

  get canWriteToTerms() {
    return this.model.sidebarState.canWriteToTerms;
  }

  get isBulkActionsAllowed() {
    return [IntegrationType.FIGMA_PLUGIN].includes(this.model.sidebarState.integrationType);
  }

  get similarIssues() {
    if (!this.isBulkActionsAllowed) {
      return [];
    }

    return this.model.issues.sidebarIssues.filter(listIssue => {
      if (listIssue.issueId === this.id) {
        return false;
      }

      if (
        listIssue.category !== this.issue.category ||
        listIssue.description !== this.issue.description ||
        listIssue.highlight !== this.issue.highlight
      )
        return false;

      return listIssue.suggestions.every(
        (suggestion: string, pos: number) => listIssue.suggestions[pos] === suggestion,
      );
    });
  }

  get isBulkAcceptAvailable() {
    return (
      this.isBulkActionsAllowed &&
      Boolean(this.similarIssues.length && this.suggestions.length < 2 && !this.meta?.learnMore)
    );
  }

  get isBulkIgnoreAvailable() {
    return this.isBulkActionsAllowed && !!this.similarIssues.length;
  }

  readonly onIssueCardClick = () => this.model.issues.setSelectedIssue(this.issue, 'sidebar');

  readonly onMarkIssueAsWrong = (comment?: string | null) =>
    this.model.issues.onMarkIssueAsWrong(this.issue, IssueCardType.SIDEBAR, comment);

  readonly onBulkAcceptSuggestions = (replacement: string) => {
    const issues = [this.issue, ...this.similarIssues];
    this.model.issues.onBulkAcceptSuggestions(issues, replacement, IssueCardType.SIDEBAR);
  };

  readonly onDeleteIssueClick = () => this.model.issues.onDeleteIssueClick(this.issue, IssueCardType.SIDEBAR);

  readonly onBulkDeleteIssues = () => {
    const issues = [this.issue, ...this.similarIssues];
    this.model.issues.onBulkDeleteIssues(issues, IssueCardType.SIDEBAR);
  };

  readonly onAddToTermBankClick = (term: string) => {
    this.model.eventBus.emit('onAddToTermBankClick', term);
  };

  private readonly onLearnMoreClickedInternal = (modalType: LearnMoreModalType) => {
    this.model.eventBus.emit('onLearnMoreClicked', modalType);
  };

  get onLearnMoreClicked() {
    return this.model.hasEventSubscriber('onLearnMoreClicked') ? this.onLearnMoreClickedInternal : undefined;
  }

  get title() {
    const { category } = this.issue;

    return this.model.categories.getSidebarCategory(category)?.label || category;
  }

  get titleColor() {
    const { category } = this.issue;

    return this.model.categories.issueColorMap?.[category as unknown as CategoryType];
  }

  // This is actually expanded, not collapsed, I cant rename it because it will break the interface
  get collapsed() {
    return this.issue.issueId === this.model.issues.expandedIssueId;
  }

  get useDropdownPortal() {
    return this.model.sidebarState.useDropdownPortal;
  }
}
