
const digest = '5ce35a0fec4233bef032ee636447ff3e4d1532bd512f7fbb06238da47a64681d';
const css = `.styles-module__c-b9hcO {
  max-height: 100%;
  flex: 1 1 auto;
  padding: 0 var(--sidebar-horizontal-spacing);
  -ms-overflow-style: none !important;
  overflow-y: scroll;
}

.styles-module__5rh0LiC {
  padding: 0 var(--sidebar-horizontal-spacing-extra-small);
}

.styles-module__c-b9hcO::-webkit-scrollbar {
  /* width: 15px; */
  width: 0;
}

.styles-module__oOEjEPx {
  margin: 7px 0;
}

.styles-module__1-G0Ud4 {
  margin: 12px 0;
}

.styles-module__mpQtMSk {
  max-width: 104px;
  padding: 6px 8px 6px 13px !important;
  border-radius: 4px !important;
  font-size: var(--font-size-m) !important;
  font-weight: 600;
  line-height: 19px;
  opacity: 1 !important;
}

.styles-module__xh4rCNG {
  padding: 18px;
}

.styles-module__-8GBKxs {
  position: absolute;
  left: -17px;
}

.styles-module__-8GBKxs path {
  fill: var(--classic-grey-3);
}

.styles-module__YO0bG1l {
  background: #00ca8e;
}
`;

export default {"scrollContainer":"styles-module__c-b9hcO","scrollContainerWide":"styles-module__5rh0LiC","searchbar":"styles-module__oOEjEPx","snippetcard":"styles-module__1-G0Ud4","tooltip":"styles-module__mpQtMSk","containerBanner":"styles-module__xh4rCNG","snippetsCardHeaderIcon":"styles-module__-8GBKxs","educationalBanner":"styles-module__YO0bG1l"};
export { css, digest };
  