import React, { useRef } from 'react';
import { observer } from 'mobx-react-lite';
import cx from 'classnames';

import { Tooltip, Button, ButtonTypes, Icon, IconVariant, Score, useResizeObserver } from '@writerai/ui-atoms';

import styles from './styles.module.css';
import type { UISidebarModel } from './UISidebarModel';
import { CardsPanel } from '../CardsPanel';
import { DocumentGradeLevel } from '../../molecules/DocumentGradeLevel';
import { DocumentStatsDropdown } from '../../molecules/DocumentStatsDropdown';
import { DocumentStats } from '../DocumentStats';
import { CategoriesPanel } from '../CategoriesPanel';

export interface IUiMixinProps {
  DocumentStatsComponent?: typeof DocumentStats;
}

export const Sidebar = observer<
  {
    className?: string;
    classNameTooltip?: string;
    model: UISidebarModel;
  } & IUiMixinProps
>(({ className, classNameTooltip, model, DocumentStatsComponent = DocumentStats }) => {
  const {
    modelUICategoriesPanelModel,
    isScoreHidden,
    isPreviewMode,
    isLoading,
    scoreValue,
    isSmall,
    isMedium,
    isStandard,
    statsAreVisible,
    isStatsCollapsed,
    modelUIDocumentStats,
    modelUICardsPanelModel,
    onSidebarModeChange,
    onSidebarContainerResize,
  } = model.useReactiveData();

  const sidebarContainerRef = useRef<HTMLDivElement>(null);
  useResizeObserver(sidebarContainerRef, ({ width }) => onSidebarContainerResize(width));

  if (isPreviewMode) {
    return (
      <Tooltip title="Show suggestions" placement="left">
        <Button
          className={cx(styles.sidebarPreviewButton, {
            [styles.sidebarPreviewButtonWithScoreHidden]: isScoreHidden,
          })}
          type={ButtonTypes.TRANSPARENT_HOVER}
          icon={<Icon name={IconVariant.BACK_ARROW} />}
          onClick={onSidebarModeChange}
        >
          <Score isLoading={isLoading} value={scoreValue} hideScoreValue={isScoreHidden} />
        </Button>
      </Tooltip>
    );
  }

  const isDocumentStatsDropdown = statsAreVisible && !isStandard;

  return (
    <div
      ref={sidebarContainerRef}
      className={cx(styles.sidebar, className, { [styles.sidebarDropdown]: isDocumentStatsDropdown })}
    >
      <React.Fragment>
        <CardsPanel model={modelUICardsPanelModel} />
        <div
          className={cx(styles.categoriesAndSnippets, {
            [styles.categoriesMiniAndSnippetsMedium]: isMedium,
            [styles.categoriesMiniAndSnippetsSmall]: isSmall,
          })}
        >
          <div className={styles.categoriesContainer}>
            <CategoriesPanel model={modelUICategoriesPanelModel} />
          </div>

          {statsAreVisible && (
            <div className={styles.actionsAndStats}>
              {isStandard ? (
                <DocumentStatsComponent model={modelUIDocumentStats} classNameTooltip={classNameTooltip} />
              ) : null}
            </div>
          )}
        </div>

        {isDocumentStatsDropdown ? (
          <div className={styles.dropdown}>
            <DocumentStatsDropdown model={modelUIDocumentStats} withGradeLevelOption={isStatsCollapsed} />
            {!isStatsCollapsed && (
              <DocumentGradeLevel model={modelUIDocumentStats} classNameTooltip={classNameTooltip} />
            )}
          </div>
        ) : null}
      </React.Fragment>
    </div>
  );
});
