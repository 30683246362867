import React, { useCallback } from 'react';
import cx from 'classnames';
import { isEmpty } from 'lodash/fp';
import type { IconVariant } from '@writerai/ui-atoms';
import {
  Text,
  TextColor,
  TextSize,
  Icon,
  Tooltip,
  TooltipAlignment,
  LabelledTags,
  Button,
  ButtonTypes,
  SizeTypes,
} from '@writerai/ui-atoms';
import styles from './styles.module.css';

export interface IActionIconCTA {
  title?: string;
  onClick?: () => void;
}

export interface IActionIconLockReason {
  tag?: string;
  description?: string;
  contentAlign?: TooltipAlignment;
  cta?: IActionIconCTA;
}

export interface ActionIconProps {
  /** Route to navigate to */
  navTo?: string;
  /** set <a> target */
  navTarget?: string;
  /** Set additional id */
  id?: string;
  /** Set additional css class */
  className?: string;
  /** Set additional class for icon container */
  containerClassName?: string;
  /** Set additional class for Text */
  textClassName?: string;
  /** Set the handler to handle click event */
  onClick?: (e: React.MouseEvent) => void;
  /** Set the handler to handle enter event */
  onMouseEnter?: (e: React.MouseEvent) => void;
  /** Set the handler to handle leave event */
  onMouseLeave?: (e: React.MouseEvent) => void;
  /** Set content in block */
  label?: string;
  /** Set icon in block */
  iconName?: IconVariant;
  /** Enable hovers for block */
  hoverable?: boolean;
  /** Set hover color for block */
  hoverColor?: HoverColor;
  /** Set hover color for block */
  active?: boolean;
  /** Use Small Caps typography */
  smallCaps?: boolean;
  /** Use bold typography */
  bold?: boolean;
  /** Use medium typography */
  medium?: boolean;
  /** Use smaller vertical size */
  small?: boolean;
  /** Use Upper-case Text label */
  upperCase?: boolean;
  /** Use ellipsis Text */
  ellipsisOverflow?: boolean;
  /** Set label text size */
  textSize?: TextSize;
  /** disabled state for the action icon */
  disabled?: boolean;
  /** lock state for the action icon */
  locked?: boolean;
  /** lock descriptions for action icon */
  lockReason?: IActionIconLockReason;
  /** action icon indicated */
  indicated?: boolean;
}

export enum HoverColor {
  GREY = 'grey',
  BLUE = 'blue',
  DARK_BLUE = 'dark-blue',
  TRANSPARENT_BLUE = 'transparent-blue',
}

const Tag: React.FC<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  lockReason: any;
}> = ({ lockReason }) => (
  <>
    {lockReason && lockReason.tag && (
      <LabelledTags bgColor="var(--classic-orange-1)" color="var(--classic-black)" className={styles.lockReasonTag}>
        {lockReason.tag}
      </LabelledTags>
    )}
  </>
);

const Cta: React.FC<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  lockReason: any;
}> = ({ lockReason }) =>
  lockReason && lockReason.cta ? (
    <Button
      size={SizeTypes.XS}
      type={ButtonTypes.PRIMARY}
      className={styles.containerCtaButton}
      onClick={() => lockReason.cta.onClick && lockReason.cta.onClick()}
    >
      <Text variant={TextSize.L} color={TextColor.WHITE}>
        {lockReason.cta.title}
      </Text>
    </Button>
  ) : null;

export const ActionIcon = React.forwardRef<HTMLDivElement & HTMLAnchorElement, ActionIconProps>(
  (
    {
      id,
      hoverColor = HoverColor.GREY,
      className,
      containerClassName,
      textClassName,
      onClick,
      onMouseEnter,
      onMouseLeave,
      label,
      iconName,
      hoverable,
      smallCaps,
      small,
      active,
      disabled,
      navTo,
      locked,
      lockReason,
      medium,
      bold,
      upperCase = true,
      ellipsisOverflow,
      textSize = TextSize.XS,
      indicated,
      ...props
    },
    ref,
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const render = ({ lockReason }: { lockReason: any }) => {
      const tooltipDescription = lockReason && lockReason.description ? lockReason.description : '';
      const contentAlign = lockReason && lockReason.contentAlign ? lockReason.contentAlign : TooltipAlignment.LEFT;

      return (
        <Tooltip
          title={tooltipDescription}
          tooltipWidth={167}
          cta={<Cta lockReason={lockReason} />}
          tag={<Tag lockReason={lockReason} />}
          align={contentAlign}
          disabled={!locked || !tooltipDescription}
          tooltipTitleClassName={styles.tooltipTitle}
        >
          <div className={styles.containerDescription}>
            {iconName && (
              <div
                className={cx(styles.containerIcon, containerClassName, {
                  [styles.containerIcon]: !small,
                  [styles.containerIconSmall]: small,
                  [styles.iconIndicator]: indicated,
                })}
              >
                <Icon name={iconName} />
              </div>
            )}
            {label && (
              <Text
                ellipsisOverflow={ellipsisOverflow}
                upperCase={upperCase}
                smallCaps={smallCaps}
                medium={medium}
                bold={bold}
                variant={textSize}
                color={active ? TextColor.BLACK : TextColor.GREY}
                className={textClassName}
              >
                {label}
              </Text>
            )}
          </div>
        </Tooltip>
      );
    };

    const elClass = cx(className, styles.container, {
      [styles.active]: active,
      [styles.hover]: !!hoverable,
      [styles.hoverGrey]: !!hoverable && hoverColor === HoverColor.GREY,
      [styles.hoverBlue]: !!hoverable && hoverColor === HoverColor.BLUE,
      [styles.hoverDarkBlue]: !!hoverable && hoverColor === HoverColor.DARK_BLUE,
      [styles.hoverTransparentBlue]: !!hoverable && hoverColor === HoverColor.TRANSPARENT_BLUE,
      [styles.containerLabelEmpty]: isEmpty(label),
      [styles.disabled]: disabled,
    });

    const handleLinkClick = (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      onClick && onClick(e);
    };

    const handleMouseEnter = useCallback(
      e => {
        if (disabled) return;

        e.preventDefault();
        onMouseEnter && onMouseEnter(e);
      },
      [disabled, onMouseEnter],
    );

    const handleMouseLeave = useCallback(
      e => {
        if (disabled) return;

        e.preventDefault();
        onMouseLeave && onMouseLeave(e);
      },
      [disabled, onMouseLeave],
    );

    if (navTo && !disabled) {
      return (
        <a
          id={id}
          ref={ref}
          className={cx(elClass, {
            [styles.locked]: locked,
          })}
          href={navTo}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={(e: React.MouseEvent<HTMLElement>) => (!disabled ? handleLinkClick(e) : undefined)}
          {...props}
        >
          {render({ lockReason })}
        </a>
      );
    }

    return (
      <div
        aria-hidden
        id={id}
        ref={ref}
        className={cx(elClass, {
          [styles.locked]: locked,
        })}
        onClick={!disabled ? onClick : undefined}
        {...props}
      >
        {render({ lockReason })}
      </div>
    );
  },
);
