import React, { useMemo, useState } from 'react';
import cx from 'classnames';
import { Icon, IconVariant } from '@writerai/ui-atoms';
import { Enum } from '@writerai/utils';

import styles from './styles.module.css';

export const CollapsedPosition = new Enum('left', 'right');

export interface CollapsedProps {
  className?: string;
  label: string | React.ReactNode;
  onChange?: (isOpen: boolean) => void;
  alwaysCollapsed?: boolean;
  alwaysExpanded?: boolean;
  disabled?: boolean;
  defaultOpen?: boolean;
  triggerIcon?: React.ReactNode;
  position?: typeof CollapsedPosition.type;
  classNameTriggerIcon?: string;
  classNameBody?: string;
}

export const Collapsed: React.FC<CollapsedProps> = ({
  children,
  label,
  className,
  defaultOpen = false,
  disabled,
  alwaysCollapsed,
  alwaysExpanded,
  onChange,
  triggerIcon,
  position = CollapsedPosition.enum.right,
  classNameTriggerIcon,
  classNameBody,
}) => {
  const [currentOpen, setCurrentOpen] = useState(defaultOpen);

  const onClick = () => {
    if (disabled) return;

    setCurrentOpen(!currentOpen);
    onChange?.(!currentOpen);
  };

  const isOpen = alwaysExpanded || (!alwaysCollapsed && currentOpen);

  const renderTriggerIcon = useMemo(
    () => (
      <div
        className={cx(styles.triggerIcon, classNameTriggerIcon, {
          [styles.triggerIconOpen]: isOpen,
          [styles.disabled]: disabled,
        })}
      >
        {triggerIcon || <Icon name={IconVariant.DROP_DOWN_ARROW} />}
      </div>
    ),
    [triggerIcon, isOpen, classNameTriggerIcon, disabled],
  );

  return (
    <div className={cx(styles.container, className)}>
      <div
        aria-hidden
        className={cx(styles.trigger, {
          [styles.disabled]: disabled,
        })}
        onClick={onClick}
      >
        {position === 'left' && renderTriggerIcon}
        {React.isValidElement(label) ? label : label}
        {position === 'right' && renderTriggerIcon}
      </div>
      {isOpen && (
        <div
          className={cx(styles.body, classNameBody, {
            [styles.disabled]: disabled,
          })}
        >
          {children}
        </div>
      )}
    </div>
  );
};
