import isEmpty from 'lodash/isEmpty';
import qs from 'qs';
import type { ISnippetCreate } from '@writerai/types';
import type { ListResponse } from './_types';
import type { BatchSubmitFailHandlingTypes, ICreateSnippetWithFails, RequestQueryParams } from '../../types';
import Request from '../../utils/requestUtils';
import type { ISnippet } from '../../types/Snippets';

/**
 * @deprecated use @writerai/network
 */
export const getSnippetsListRequest = async (
  orgId: string,
  teamId: string,
  params: RequestQueryParams,
): Promise<ListResponse<ISnippet>> => {
  const _requestUrl = `/terminology/organization/${orgId}/team/${teamId}/snippet`;
  const _requestParams = { ...params };

  if (isEmpty(params.search)) {
    delete _requestParams.search;
  }

  const { data } = await Request.getAxiosInstance().get<ListResponse<ISnippet>>(_requestUrl, {
    params: _requestParams,
    paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
  });

  return data;
};

/**
 * @deprecated use @writerai/network
 */
export const createSnippetsRequest = async (
  orgId: string,
  teamId: string,
  models: ISnippetCreate[],
): Promise<ISnippet[]> => {
  const _requestUrl = `/terminology/organization/${orgId}/team/${teamId}/snippet`;

  const { data } = await Request.getAxiosInstance().post<ISnippet[]>(_requestUrl, {
    models,
  });

  return data;
};

/**
 * @deprecated use @writerai/network
 */
export const createMultipleSnippetsRequest = async (
  orgId: string,
  teamId: string,
  models: ISnippetCreate[],
  failHandling?: BatchSubmitFailHandlingTypes,
): Promise<ICreateSnippetWithFails> => {
  const _requestUrl = `/terminology/organization/${orgId}/team/${teamId}/snippet/v2`;

  const { data } = await Request.getAxiosInstance().post<ICreateSnippetWithFails>(_requestUrl, {
    models,
    failHandling,
  });

  return data;
};

/**
 * @deprecated use @writerai/network
 */
export const updateSnippetsRequest = async (
  orgId: string,
  teamId: string,
  models: ISnippetCreate[],
): Promise<ISnippet[]> => {
  const _requestUrl = `/terminology/organization/${orgId}/team/${teamId}/snippet`;

  const { data } = await Request.getAxiosInstance().put<ISnippet[]>(_requestUrl, {
    models,
  });

  return data;
};

/**
 * @deprecated use @writerai/network
 */
export const downloadSnippetsListAsCsv = async (
  orgId: string,
  teamId: string,
  params: RequestQueryParams,
): Promise<Blob> => {
  const _requestUrl = `/terminology/organization/${orgId}/team/${teamId}/snippet/csv`;

  const { data } = await Request.getAxiosInstance().get<Blob>(_requestUrl, {
    params,
    paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
    responseType: 'blob',
  });

  return data;
};

/**
 * @deprecated use @writerai/network
 */
export const downloadSnippetsListAsXlsx = async (
  orgId: string,
  teamId: string,
  params: RequestQueryParams,
): Promise<Blob> => {
  const _requestUrl = `/terminology/organization/${orgId}/team/${teamId}/snippet/xlsx`;

  const { data } = await Request.getAxiosInstance().get<Blob>(_requestUrl, {
    params,
    paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
    responseType: 'blob',
  });

  return data;
};

/**
 * @deprecated use @writerai/network
 */
export const deleteSnippets = async (orgId: string, teamId: string, ids: string[]): Promise<ISnippet[]> => {
  const _requestUrl = `/terminology/organization/${orgId}/team/${teamId}/snippet`;
  const params = new URLSearchParams();
  ids.forEach(d => params.append('ids', d));
  const { data } = await Request.getAxiosInstance().delete<ISnippet[]>(_requestUrl, {
    params,
  });

  return data;
};

/**
 * @deprecated use @writerai/network
 */
export const getSnippetsTagsRequest = async (orgId: string, teamId: string): Promise<{ tags: string[] }> => {
  const _requestUrl = `/terminology/organization/${orgId}/team/${teamId}/snippet/tag`;
  const { data } = await Request.getAxiosInstance().get<{ tags: string[] }>(_requestUrl);

  return data;
};

/**
 * @deprecated use @writerai/network
 */
export const findSnippetsByShortcut = async (
  orgId: string,
  teamId: string,
  shortcuts: string[],
): Promise<ISnippet[]> => {
  const _requestUrl = `/terminology/organization/${orgId}/team/${teamId}/snippet/shortcut/get`;
  const { data } = await Request.getAxiosInstance().put<ISnippet[]>(_requestUrl, {
    shortcuts,
  });

  return data;
};

/**
 * @deprecated use @writerai/network
 */
export const reportSnippetApply = async (orgId: string, teamId: string, snippetId: string): Promise<any> => {
  const { data } = await Request.getAxiosInstance().put(
    `/terminology/organization/${orgId}/team/${teamId}/snippet/${snippetId}/apply`,
  );

  return data;
};
