/**
 * Adds a suffix to the end of a word based on the count
 * @param count The number of items
 * @param word The singular form of the word
 * @param suffix The suffix to add to the end of the word. Default is "s"
 * @returns The pluralized word
 */
export function wordPluralize(count: number, word: string, suffix = 's') {
  return `${word}${Math.abs(count) === 1 ? '' : suffix}`;
}
