import { useEffect, useState } from "react";
import { Button } from "@contentful/forma-36-react-components";
import { SegmentedControl } from "segmented-control";

import getField from "./utils";
import logo from "../../assets/icons/logo.svg";

import styles from "./style.module.scss";
import "codemirror/lib/codemirror.css";
import "@contentful/forma-36-react-components/dist/styles.css";

const Field = ({ sdk }) => {
  const [isMarkdown, setMarkdown] = useState("multi");
  const [externalValueUpdateKey, setExternalValueUpdateKey] = useState("default");

  useEffect(() => {
    sdk.window.startAutoResizer();
    return () => sdk.window.stopAutoResizer();
  }, [sdk.window]);

  const openDialog = async () => {
    const fieldFormat = sdk.field.type;
    const answer = await sdk.dialogs.openCurrentApp({
      shouldCloseOnOverlayClick: false,
      shouldCloseOnEscapePress: true,
      minHeight: "85vh",
      width: 1200,
      parameters: {
        fieldContent: sdk.field.getValue() || '',
        fieldFormat: fieldFormat,
        invocation: { type: 'writer' },
      },
    });

    if (answer) {
      sdk.field.setValue(answer);
      const isRichText = fieldFormat === 'RichText';
      if (isRichText) {
        const newKey = new Date().getTime().toString();
        setExternalValueUpdateKey(newKey);
      }
    }
  };

  const changeMarkdown = (state) => {
    setMarkdown(state);
  };

  return (
    <div className={styles.main}>
      <div className={styles.main__control}>
        <Button buttonType="muted" onClick={openDialog}>
          <div className="flexVerticalCenter">
            <img src={logo} alt="logo" />
            Check content with Writer
          </div>
        </Button>
        {sdk.field.type === "Text" && (
          <SegmentedControl
            name="type"
            className={styles.control__switch}
            options={[
              { label: "Multiple line", value: "multi", default: true },
              { label: "Markdown", value: "markdown" },
            ]}
            setValue={changeMarkdown}
            style={{ width: 400 }}
          />
        )}
      </div>
      <div className={styles.main__content} key={externalValueUpdateKey}>{getField(sdk, isMarkdown)}</div>
    </div>
  );
};

export default Field;
