import React from 'react';
import cx from 'classnames';

import styles from './styles.module.css';

export interface ITagProps {
  className?: string;
  size?: TagSize;
  color?: TagColor;
  textAlign?: TagTextAlign;
  upperCase?: boolean;
}

export enum TagSize {
  M = 'medium',
  S = 'small',
}

export enum TagColor {
  GREY = 'grey',
  GREY1 = 'grey1',
  GREY2 = 'grey2',
  GREEN = 'green',
  GREEN3 = 'green3',
  LIGHT_GREEN = 'lightGreen',
  ORANGE = 'orange',
  BLACK = 'black',
  BLUE = 'blue',
  LIGHT_BLUE = 'lightBlue',
  LIGHT_PEACH = 'lightPeach',
  LIGHT_ORANGE = 'lightOrange',
  YELLOW_1 = 'yellow1',
}

export enum TagTextAlign {
  LEFT = 'left',
  CENTER = 'center',
}

export const Tag: React.FC<ITagProps> = ({
  size = TagSize.M,
  color = TagColor.GREY,
  textAlign = TagTextAlign.LEFT,
  className,
  children,
  upperCase,
  ...props
}) => (
  <div
    className={cx(styles.tag, styles[size], styles[color], className, {
      [styles.upperCase]: upperCase,
      [styles.alignCenter]: textAlign === TagTextAlign.CENTER,
      [styles.alignLeft]: textAlign === TagTextAlign.LEFT,
    })}
    {...props}
  >
    {children}
  </div>
);

export default Tag;
