import { useEffect } from 'react';
import { IntegrationType, Request } from '@writerai/common-utils';
import { useDispatch, useSelector } from 'react-redux';
import { CookieStorage } from 'cookie-storage';
import { logout } from "../../redux/auth/actions";
import packageJson from "../../../package.json";

const cookieStorage = new CookieStorage({
  secure: true,
  sameSite: 'None'
});

export const useInitRequestUtility = () => {
  const dispatch = useDispatch();
  const isLogged = useSelector(state => state.auth.isLogged);

  useEffect(() => {
    const baseUrl = `https://${
      process.env.NODE_ENV !== 'production'
        ? process.env.REACT_APP_API_HOST
        : window.env.REACT_APP_API_HOST
    }/api`;
    Request.getInstance().setBaseUrl(baseUrl);
    Request.getInstance().setIntegrationType(IntegrationType.CONTENTFUL_PLUGIN);
    Request.getInstance().setVersion(packageJson.version);
  }, []);

  useEffect(() => {
    const accessToken = cookieStorage.getItem('qToken') || localStorage.getItem('qToken');
    Request.getInstance().setAuthorizationHeaders(accessToken);

    if (isLogged) {
      const axiosInstance = Request.getAxiosInstance();

      const onResponse = (response) => response;

      const onResponseError = (error) => {
        if (error.response?.status === 401) {
          dispatch(logout());
        }

        return Promise.reject(error);
      };

      const interceptor = axiosInstance.interceptors.response.use(onResponse, onResponseError);
      return () => axiosInstance.interceptors.response.eject(interceptor);
    }
  }, [dispatch, isLogged]);
};
