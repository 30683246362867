
const digest = '42a6a281630413ebfd1366e4a46a9fb94b933ad5c5fb433655f79cbccf52b872';
const css = `.styles-module__55TcGwi {
  width: 267px;
}

.styles-module__uathImf {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.styles-module__uathImf p {
  padding: 5px;
  text-align: center;
}

.styles-module__0BDJc0u {
  padding-bottom: 10px;
  margin-top: -12px;
  transform: scale(1.4);
}

.styles-module__0xr07tZ {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}

.styles-module__0xr07tZ button:last-of-type {
  margin-left: 10px;
}

.styles-module__1ivyVD6 {
  width: auto;
  align-self: flex-end;
  padding: 4px 20px;
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
}

.styles-module__1Rt0W-C {
  margin-top: 10px;
}
`;

export default {"deleteModal":"styles-module__55TcGwi","styledDeleteContent":"styles-module__uathImf","icon":"styles-module__0BDJc0u","controlButtons":"styles-module__0xr07tZ","submitButton":"styles-module__1ivyVD6","styledInputGroup":"styles-module__1Rt0W-C"};
export { css, digest };
  