
const digest = '6c485d769cc22fac1b2cebe09b951b67d0036dedf81150ba5467e64eedb20c7d';
const css = `.styles-module__kUnnPkX {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 8px;
  user-select: none;

  &.styles-module__3UeKfVy {
    color: var(--writer-grey-1);
    pointer-events: none;
  }

  &:hover {
    .styles-module__boxufjF {
      border-color: var(--classic-grey-3);
      color: var(--classic-grey-1-5);
    }
  }

  /* taken from https://css-tricks.com/inclusively-hidden/ */
  & input {
    position: absolute;
    overflow: hidden;
    width: 1px;
    height: 1px;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    white-space: nowrap;
  }

  .styles-module__boxufjF {
    position: relative;
    display: flex;
    width: 18px;
    height: 18px;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--writer-grey-3);
    border-radius: 4px;
    background-color: var(--classic-white);

    &.styles-module__WjtBCVG {
      border-color: var(--classic-blue-4);
      background-color: var(--classic-blue-4);
      color: var(--classic-white);
    }

    &.styles-module__3UeKfVy {
      border-color: var(--writer-graynu-3);

      &.styles-module__WjtBCVG {
        border-color: var(--writer-graynu-3);
        background-color: var(--writer-graynu-3);
        color: var(--classic-white);
      }

      &.styles-module__WthYetD {
        border-color: var(--writer-graynu-3);
        background-color: var(--writer-graynu-3);
        color: var(--classic-white);
      }
    }

    &.styles-module__WthYetD {
      border-color: var(--classic-blue-4);
      background-color: var(--classic-blue-4);
      color: var(--classic-white);
    }
  }

  .styles-module__ctmRIIF {
    display: block;
    width: 6px;
    height: 1px;
    background-color: currentColor;
  }
}
`;

export default {"container":"styles-module__kUnnPkX","disabled":"styles-module__3UeKfVy","checkbox":"styles-module__boxufjF","checked":"styles-module__WjtBCVG","indeterminate":"styles-module__WthYetD","dash":"styles-module__ctmRIIF"};
export { css, digest };
  