import React from 'react';
import cx from 'classnames';

import styles from './styles.module.css';

export const PeopleFirstInfo: React.FC = () => (
  <div className={styles.container}>
    <div className={styles.paddedContent}>
      <h2 className={cx(styles.header)}>Disability</h2>
      <h2 className={cx(styles.header, styles.withBottomMargin, styles.gray)}>Use People-First Language</h2>

      <h3 className={styles.blockHeader}>General tips for writing about disability</h3>
      <div className={cx(styles.block, styles.paddedLeft, styles.withBottomMargin)}>
        <span className={styles.blockItem}>
          <b>Is it relevant?</b> Refer to a person&apos;s disability only when it&apos;s relevant and necessary.
        </span>
        <span className={styles.blockItem}>
          <b>Is it sensitive?</b> Don&apos;t disclose information about a disability unless the person is comfortable
          with it.
        </span>
        <span className={styles.blockItem}>
          <b>What&apos;s their preference?</b> Ask people which terms they themselves prefer to use. When in doubt, use
          people-first language.
        </span>
      </div>

      <h3 className={styles.blockHeader}>What is people-first language?</h3>
      <div className={cx(styles.block, styles.paddedLeft, styles.withBottomMargin)}>
        <p className={cx(styles.blockParagraph, styles.withBottomMargin)}>
          People-first language prioritizes the person before the disability and describes what a person has, not who a
          person is. As opposed to identity-first language, people-first literally puts the person first.
        </p>
        <div className={styles.whatIs}>
          <div className={styles.whatIsSection}>
            <h4 className={styles.whatIsHeader}>People-first language</h4>
            <div className={styles.whatIsDescription}>“Autism” is a noun</div>
            <div className={styles.whatIsExample}>
              people with <span className={cx(styles.coloredText, styles.coloredGreen)}>autism</span>
            </div>
          </div>
          <div className={styles.whatIsSection}>
            <h4 className={styles.whatIsHeader}>Identity-first language</h4>
            <div className={styles.whatIsDescription}>“Autistic” is an adjective</div>
            <div className={styles.whatIsExample}>
              <span className={cx(styles.coloredText, styles.coloredBlue)}>autistic</span> people
            </div>
          </div>
        </div>
      </div>

      <h3 className={styles.blockHeader}>Using people-first language</h3>
      <div className={cx(styles.block, styles.paddedLeft)}>
        <p className={styles.blockParagraph}>
          People-first vs. identity-first language can be a matter of preference. Some communities prefer identity-first
          wording. For this reason, it&apos;s best to ask the people you&apos;re writing about exactly how they would
          like to be referred to.
        </p>
        <p className={styles.blockParagraph}>
          If you&apos;re unable to find out, default to using people-first language, which is a standard that many news
          organizations, advocacy groups, and governments have adopted. And, as a general rule, it&apos;s a good idea to
          avoid language that strips out &quot;people&quot; entirely by turning the disability into a noun.
        </p>

        <div className={styles.doDont}>
          <div className={styles.doDontSection}>
            <div className={styles.doDontHeader}>
              <span className={cx(styles.coloredText, styles.coloredGreen)}>Do</span>
              <span> use people-first language</span>
            </div>
            <div className={styles.doDontItem}>women with autism</div>
            <div className={styles.doDontItem}>folks who are blind</div>
            <div className={styles.doDontItem}>people who are deaf and mute</div>
            <div className={styles.doDontItem}>customers with epilepsy</div>
            <div className={styles.doDontItem}>employees with disabilities</div>
          </div>
          <div className={styles.doDontSection}>
            <div className={styles.doDontHeader}>
              <span className={cx(styles.coloredText)}>Don&apos;t</span>
              <span> turn disabilities into nouns</span>
            </div>
            <div className={styles.doDontItem}>autistics</div>
            <div className={styles.doDontItem}>the blind</div>
            <div className={styles.doDontItem}>deaf-mutes</div>
            <div className={styles.doDontItem}>epileptics</div>
            <div className={styles.doDontItem}>the disabled</div>
          </div>
        </div>
      </div>
    </div>
    <div className={styles.fullWidthContent}>
      <h3 className={cx(styles.blockHeader, styles.whiteHeader)}>Resources</h3>
      <div className={cx(styles.block, styles.paddedLeft)}>
        <div className={styles.title}>National Center on Disability and Journalism</div>
        <div className={styles.link}>
          <a href="https://ncdj.org/style-guide/" target="_blank">
            https://ncdj.org/style-guide/
          </a>
        </div>
        <div className={styles.title}>Society of Professional Journalists</div>
        <div className={styles.link}>
          <a href="https://www.spj.org/diversity.asp" target="_blank">
            spj.org
          </a>
        </div>
        <div className={styles.title}>The Diversity Style Guide</div>
        <div className={styles.link}>
          <a href="https://www.diversitystyleguide.com" target="_blank">
            diversitystyleguide.com
          </a>
        </div>
        <div className={styles.title}>EARN</div>
        <div className={styles.link}>
          <a
            href="https://askearn.org/topics/retention-advancement/disability-etiquette/people-first-language/"
            target="_blank"
          >
            https://askearn.org/topics/retention-advancement/disability-etiquette/people-first-language/
          </a>
        </div>
      </div>
    </div>
  </div>
);

export default PeopleFirstInfo;
