
const digest = '59c7bb48831100e366d0532b405eaa133f923e5d6b07806f38bb54ba336a7d06';
const css = `.styles-module__V3PhfSE {
  text-align: left;
}

.styles-module__V3PhfSE a,
.styles-module__V3PhfSE a:visited,
.styles-module__V3PhfSE a:active {
  color: var(--white);
}
`;

export default {"tooltipLink":"styles-module__V3PhfSE"};
export { css, digest };
  