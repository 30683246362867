// port from https://github.com/prosegrinder/python-syllables

/**
 * Syllable Estimator
 *
 * Syllables is a fast, simple syllable estimator intended for use
 * in places where speed matters. The module has a single function, estimate(),
 * which accepts an English-language word and returns the estimated number of
 * syllables in the word.
 *
 * @see https://github.com/prosegrinder/python-syllables
 * @module syllableEstimator
 */

const subSyllables = [
  'cial',
  'tia',
  'cius',
  'cious',
  'uiet',
  'gious',
  'geous',
  'priest',
  'giu',
  'dge',
  'ion',
  'iou',
  'sia$',
  '.che$',
  '.ched$',
  '.abe$',
  '.ace$',
  '.ade$',
  '.age$',
  '.aged$',
  '.ake$',
  '.ale$',
  '.aled$',
  '.ales$',
  '.ane$',
  '.ame$',
  '.ape$',
  '.are$',
  '.ase$',
  '.ashed$',
  '.asque$',
  '.ate$',
  '.ave$',
  '.azed$',
  '.awe$',
  '.aze$',
  '.aped$',
  '.athe$',
  '.athes$',
  '.ece$',
  '.ese$',
  '.esque$',
  '.esques$',
  '.eze$',
  '.gue$',
  '.ibe$',
  '.ice$',
  '.ide$',
  '.ife$',
  '.ike$',
  '.ile$',
  '.ime$',
  '.ine$',
  '.ipe$',
  '.iped$',
  '.ire$',
  '.ise$',
  '.ished$',
  '.ite$',
  '.ive$',
  '.ize$',
  '.obe$',
  '.ode$',
  '.oke$',
  '.ole$',
  '.ome$',
  '.one$',
  '.ope$',
  '.oque$',
  '.ore$',
  '.ose$',
  '.osque$',
  '.osques$',
  '.ote$',
  '.ove$',
  '.pped$',
  '.sse$',
  '.ssed$',
  '.ste$',
  '.ube$',
  '.uce$',
  '.ude$',
  '.uge$',
  '.uke$',
  '.ule$',
  '.ules$',
  '.uled$',
  '.ume$',
  '.une$',
  '.upe$',
  '.ure$',
  '.use$',
  '.ushed$',
  '.ute$',
  '.ved$',
  '.we$',
  '.wes$',
  '.wed$',
  '.yse$',
  '.yze$',
  '.rse$',
  '.red$',
  '.rce$',
  '.rde$',
  '.ily$',
  '.ely$',
  '.des$',
  '.gged$',
  '.kes$',
  '.ced$',
  '.ked$',
  '.med$',
  '.mes$',
  '.ned$',
  '.[sz]ed$',
  '.nce$',
  '.rles$',
  '.nes$',
  '.pes$',
  '.tes$',
  '.res$',
  '.ves$',
  'ere$',
];
const addSyllables = [
  'ia',
  'riet',
  'dien',
  'ien',
  'iet',
  'iu',
  'iest',
  'io',
  'ii',
  'ily',
  '.oala$',
  '.iara$',
  '.ying$',
  '.earest',
  '.arer',
  '.aress',
  '.eate$',
  '.eation$',
  '[aeiouym]bl$',
  '[aeiou]{3}',
  '^mc',
  'ism',
  '^mc',
  'asm',
  '([^aeiouy])1l$',
  '[^l]lien',
  '^coa[dglx].',
  '[^gq]ua[^auieo]',
  'dnt$',
];

const reSubSyllables = subSyllables.map(syllable => new RegExp(`^${syllable.replace('.', '\\.')}`));

const reAddSyllables = addSyllables.map(syllable => new RegExp(`^${syllable.replace('.', '\\.')}`));

/**
 * Estimates the number of syllables in an English-language word.
 *
 * @param {string} word - The English-language word to estimate syllables for.
 * @returns {number} The estimated number of syllables in the word.
 */
export function estimate(word: string): number {
  /* Estimates the number of syllables in an English-langauge word
  Parameters:
  ----------
  word : str
  The English-langauge word to estimate syllables for
  Returns:
  -------
  int
  the estimated number of syllables in the word
  */

  const parts = word.split(/[^aeiouy]+/);

  const validParts = parts.reduce((memo, part) => {
    if (part !== '') {
      memo.push(part);
    }

    return memo;
  }, [] as string[]);

  let syllables = 0;

  for (let i = 0, len = reSubSyllables.length; i < len; i += 1) {
    const pattern = reSubSyllables[i];

    if (pattern.test(word)) {
      syllables -= 1;
    }
  }

  for (let i = 0, len = reAddSyllables.length; i < len; i += 1) {
    const pattern = reAddSyllables[i];

    if (pattern.test(word)) {
      syllables += 1;
    }
  }

  syllables += validParts.length;

  if (syllables <= 0) {
    syllables = 1;
  }

  return syllables;
}
