import type { UIIssuesListModel } from '../IssuesList/UIIssuesListModel';
import { UISnippetsListModel } from '../SnippetsList/UISnippetsListModel';
import type { SidebarModel } from '../../models';

export interface UICardsPanelModelOptions {
  modelUICardsPanelModel: UIIssuesListModel;
  model: SidebarModel;
}

export class UICardsPanelModel {
  readonly modelUIIssuesListModel: UIIssuesListModel;
  private readonly model: SidebarModel;
  public readonly modelSnippetsList: UISnippetsListModel;

  constructor({ modelUICardsPanelModel, model }: UICardsPanelModelOptions) {
    this.modelUIIssuesListModel = modelUICardsPanelModel;
    this.model = model;
    this.modelSnippetsList = new UISnippetsListModel(model);
  }

  get sidebarSectionTitle() {
    return this.model.size.isSmall ? 'All' : 'Suggestions';
  }

  get isSnippets() {
    if (this.model.sidebarState.hideSnippets) {
      return false;
    }

    return this.model.categories.isSnippetsSection;
  }

  get totalCardsCount() {
    const { snippets, issues } = this.model;

    return this.isSnippets ? snippets.rawValue?.totalCount || 0 : issues.currentSidebarIssues.length;
  }

  get title() {
    const {
      isSnippets,
      model: { categories },
    } = this;

    return isSnippets ? 'Snippets' : categories.selectedCategory?.label || this.sidebarSectionTitle;
  }

  get isStandard() {
    return this.model.size.isStandard;
  }

  get isEmptyState() {
    return this.model.sidebarState.isEmptyState;
  }

  get showTeamName() {
    return this.model.sidebarState.showTeamName;
  }

  get teamName() {
    return this.model.sidebarState.teamName;
  }

  get isLoading() {
    return this.model.sidebarState.isLoading;
  }

  get color() {
    return this.model.categories.selectedCategory?.color;
  }

  get subTitle() {
    const { isSnippets, totalCardsCount } = this;

    if (!isSnippets) {
      return undefined;
    }

    return totalCardsCount > 0 ? 'Click a Snippet to copy to clipboard' : '';
  }
}
