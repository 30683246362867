import { Quill } from 'react-quill';

const QuillLink = Quill.import('formats/link');

export class Link extends QuillLink {
  static create(value) {
    if (typeof value === 'object') {
      const { id, href, nodeType } = value || {};
      const node = super.create(href);
      if (id && nodeType) {
        node.setAttribute('id', id);
        node.setAttribute('data-node-type', nodeType);
      }
      return node;
    } else {
      return super.create(value);
    }
  }

  static formats(domNode) {
    return {
      id: domNode.getAttribute('id'),
      nodeType: domNode.getAttribute('data-node-type'),
      href: domNode.getAttribute('href'),
    };
  }

  format(name, value) {
    if (typeof value === 'object' && name === 'link') {
      const { href } = value || {};
      super.format(name, href);
    } else {
      super.format(name, value);
    }
  }
}
