import React, { useCallback, useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import { TermsPopupFields, TermType } from '@writerai/types';
import { Text, TextSize, Icon, IconVariant, useVisible } from '@writerai/ui-atoms';

import styles from './styles.module.css';

export interface ITermToggleGroupProps {
  /** Additional class name */
  className?: string;
  /** Set default value for TermToggle */
  value: TermType;
  /** Callback for onChangeTermType */
  onChange?: (val: string, fieldType?: TermsPopupFields) => void;
}

interface ITermTypeLabel {
  className?: string;
  type: TermType;
  label: string | TermType;
  icon?: IconVariant;
}

const TermTypeLabel: React.FC<ITermTypeLabel> = ({ className, type, label, icon }) => (
  <div className={styles.termTypeLabelWrapper}>
    <Text variant={TextSize.XL} smallCaps className={cx(styles.termTypeLabel, styles[type], className)}>
      <span>{label}</span>
      {icon && <Icon className={styles.termTypeLabelIcon} name={icon} />}
    </Text>
  </div>
);

const termsTypesOptions = [
  {
    id: TermType.APPROVED,
    label: TermType.APPROVED,
    description: (
      <Text variant={TextSize.M}>
        <Text variant={TextSize.M} bold>
          Terms that are ok to use.
        </Text>{' '}
        We'll show a definition card whenever these appear. You can add common mistakes, and we'll correct to the
        approved term.
      </Text>
    ),
  },
  {
    id: TermType.BANNED,
    label: "Don't use",
    description: (
      <Text variant={TextSize.M}>
        <Text variant={TextSize.M} bold>
          Terms that are not ok to use.
        </Text>{' '}
        We'll flag any instance of this word. You can select an approved term that you'd rather your team use instead.
      </Text>
    ),
  },
  {
    id: TermType.USE_CAREFULLY,
    label: 'use carefully',
    description: (
      <Text variant={TextSize.M} bold>
        Terms that you can use in some contexts.
        <Text variant={TextSize.M}>
          We’ll flag any instance of this term so your team knows to double check if they’re using it appropriately.
        </Text>
      </Text>
    ),
  },
  {
    id: TermType.PENDING,
    label: TermType.PENDING,
    description: (
      <Text variant={TextSize.M}>
        <Text variant={TextSize.M} bold>
          Terms that are under review by your team.
        </Text>{' '}
        Pending terms won't affect any suggestions your team sees as they await review.
      </Text>
    ),
  },
];

export const TermTypeSwitcher: React.FC<ITermToggleGroupProps> = ({ value, onChange, className }) => {
  const [termType, setTermType] = useState<TermType>(value);
  const { triggerRef, dropdownRef, isVisible, setIsVisible } = useVisible(false);
  const rootClass: string = cx(styles.container, className, { [styles.active]: isVisible });
  const toggleDropDown = useCallback(() => setIsVisible(prev => !prev), [setIsVisible]);
  const onToggleChange = (newTermType: TermType) => {
    onChange && onChange(newTermType, TermsPopupFields.TERM_TYPE);
  };

  useEffect(() => setTermType(value), [value]);

  const termTypeLabel = useMemo(
    () => termsTypesOptions.find(opt => opt.id === termType)?.label || termType,
    [termType],
  );

  return (
    <div className={rootClass}>
      {isVisible && (
        <div aria-hidden ref={dropdownRef} className={styles.popup} onClick={toggleDropDown}>
          {termsTypesOptions.map(opt => (
            <div className={styles.termTypeSection} key={opt.id}>
              <div aria-hidden className={styles.termTypeOption} onClick={() => onToggleChange(opt.id)}>
                <div className={styles.termTypeOptionText}>
                  <TermTypeLabel type={opt.id} label={opt.label} />
                  <>{opt.description}</>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      <div aria-hidden ref={triggerRef} onClick={toggleDropDown}>
        <TermTypeLabel
          className={styles.dropdownPlate}
          type={termType}
          label={termTypeLabel}
          icon={IconVariant.ARROW_DOWN}
        />
      </div>
    </div>
  );
};
export default React.memo(TermTypeSwitcher);
