import React from 'react';
import cx from 'classnames';
import { observer } from 'mobx-react-lite';
import { useVirtualizer } from '@tanstack/react-virtual';
import { Heading, HeadingColor, HeadingVariant } from '@writerai/ui-atoms';
import { UIVisibleIssuesListModel } from './UIVisibleIssuesListModel';

import styles from './styles.module.css';
import { VisibleIssuesScrollNoticeCard } from '../../molecules/VisibleIssuesScrollNoticeCard';
import { IssueCard } from '../IssueCard';

export { UIVisibleIssuesListModel };

export const VisibleIssuesList = observer<{ model: UIVisibleIssuesListModel; isSmallPadding?: boolean }>(
  ({ model, isSmallPadding = false }) => {
    const { list, scrollNoticedMarkers } = model;
    const parentRef = React.useRef<HTMLDivElement>(null);

    const virtualizer = useVirtualizer({
      count: list.length,
      getScrollElement: () => parentRef.current,
      estimateSize: () => 72,
      paddingEnd: 150,
      overscan: 3,
    });

    const items = virtualizer.getVirtualItems();
    const firstItem = items[0] as (typeof items)[number] | undefined;

    model.useController(virtualizer);

    const { hasPrev, hasNext } = scrollNoticedMarkers;

    return (
      <>
        {hasPrev && <VisibleIssuesScrollNoticeCard isSmallPadding={isSmallPadding} />}

        {list.length > 0 ? (
          <div
            ref={parentRef}
            className={cx(styles.issuesList, { [styles.issuesListWide]: isSmallPadding })}
            style={{
              height: '100%',
              contain: 'strict',
            }}
          >
            <div
              style={{
                height: virtualizer.getTotalSize(),
                position: 'relative',
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  transform: `translateY(${firstItem?.start ?? 0}px)`,
                }}
              >
                {items.map(virtualRow => (
                  <IssueCard key={virtualRow.key} model={list[virtualRow.index]} />
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.listScrollEmptySection}>
            <Heading
              variant={HeadingVariant.H3}
              color={HeadingColor.GREY2}
              className={styles.listScrollEmptySectionText}
            >
              Looking good
              <br />
              in this section!
            </Heading>
          </div>
        )}

        {hasNext && <VisibleIssuesScrollNoticeCard isSmallPadding={isSmallPadding} isBottom />}
      </>
    );
  },
);
