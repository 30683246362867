import { Enum } from '@writerai/utils';

export const E_INTEGRATION_TYPE = new Enum(
  'DEFAULT',
  'CHROME_EXTENSION',
  'EDGE_EXTENSION',
  'GOOGLE_DOCS_PLUGIN',
  'OUTLOOK_PLUGIN',
  'POWER_POINT_PLUGIN',
  'WORD_PLUGIN',
  'FIGMA_PLUGIN',
  'CONTENTFUL_PLUGIN',
);

export const E_CLIENT_ID = new Enum(
  'ChromePlugin',
  'GoogleDocsPlugin',
  'OutlookPlugin',
  'PowerPointPlugin',
  'WordPlugin',
  'FigmaPlugin',
  'Contentful',
  'Edge',
  'QordobaFE',
  'Unknown',
);

export const H_INTEGRATION_TYPE_TO_CLIENT_ID = E_INTEGRATION_TYPE.hash({
  CHROME_EXTENSION: E_CLIENT_ID.enum.ChromePlugin,
  GOOGLE_DOCS_PLUGIN: E_CLIENT_ID.enum.GoogleDocsPlugin,
  OUTLOOK_PLUGIN: E_CLIENT_ID.enum.OutlookPlugin,
  POWER_POINT_PLUGIN: E_CLIENT_ID.enum.PowerPointPlugin,
  WORD_PLUGIN: E_CLIENT_ID.enum.WordPlugin,
  FIGMA_PLUGIN: E_CLIENT_ID.enum.FigmaPlugin,
  CONTENTFUL_PLUGIN: E_CLIENT_ID.enum.Contentful,
  EDGE_EXTENSION: E_CLIENT_ID.enum.Edge,
  DEFAULT: E_CLIENT_ID.enum.QordobaFE,
});

/**
 * @deprecated
 * when modify it please update E_INTEGRATION_TYPE
 * or better use it instead
 */
export enum IntegrationType {
  DEFAULT = 'DEFAULT',
  CHROME_EXTENSION = 'CHROME_EXTENSION',
  EDGE_EXTENSION = 'EDGE_EXTENSION',
  GOOGLE_DOCS_PLUGIN = 'GOOGLE_DOCS_PLUGIN',
  OUTLOOK_PLUGIN = 'OUTLOOK_PLUGIN',
  POWER_POINT_PLUGIN = 'POWER_POINT_PLUGIN',
  WORD_PLUGIN = 'WORD_PLUGIN',
  FIGMA_PLUGIN = 'FIGMA_PLUGIN',
  CONTENTFUL_PLUGIN = 'CONTENTFUL_PLUGIN',
}

export enum SidebarViewMode {
  OPEN = 'open',
  PREVIEW = 'preview',
}

/**
 * @deprecated This type is deprecated and will be removed in future versions.
 */
export interface ITableFilter {
  id: string;
  name: string;
  isSelected: boolean;
  tagColor?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon?: any;
}

export interface IRephraseGrades {
  id: string;
  name: string;
  description?: string;
  enabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  visibility?: any;
}
