import type {
  IIssue,
  IssueFlag,
  ISidebarCategory,
  LearnMoreModalType,
  IntegrationType,
  SidebarViewMode,
} from '@writerai/types';
import type { FirestoreSettings } from 'firebase/firestore';
import type { Persistence } from 'firebase/auth';
import { Enum } from '@writerai/utils';
import type { IssueUpdateType } from '../models';

export interface IFirestoreSettings extends FirestoreSettings {
  useFetchStreams?: boolean;
}

export interface IFirebaseSettings {
  firebaseStatePersistenceType?: Persistence;
  firestoreSettings?: IFirestoreSettings;
  recaptchaEnterpriseKey?: string;
  useAppCheck?: boolean;
}

export const DocumentStatTypes = new Enum('words', 'characters', 'sentenceLength', 'readingTime', 'gradeLevel');

export interface ISidebarEvents {
  /** Callback function to refresh score. Invoked when refresh button is clicked */
  onRefreshScore?: () => void;
  /** Callback with replacement string and issue. It will apply the replacement string when a correction
      on Issue card is clicked */
  onApplySuggestionCallback?: (replacement: string, issue: IIssue, documentId?: string) => void;
  onFlagSuggestionCallback?: (issue: IIssue, flagType: IssueFlag, comment?: string | null) => void;
  /** Callback with replacement string and issues. It will apply the replacement string when a Bulk apply option
      on Issue card is clicked */
  onBulkApplyCallback?: (issuesToApply: IIssue[], replacement: string, documentId?: string) => void;
  /** Callback to highlight the related issue on CE. Callback will be fired when a closed issue card
      is clicked and opened */
  onSelectSuggestion?: (issue: IIssue, source: typeof IssueUpdateType.type) => void;
  /** Callback to show highlights only for a certain category. It will be fired when a category is
      selected from the sidebar */
  onSelectCategory?: (category?: ISidebarCategory) => void;
  /** Callback when add to term bank button clicked */
  onAddToTermBankClick?: (term: string) => void;
  /** Callback when clicked to learn more button on issue */
  onLearnMoreClicked?: (modalType: LearnMoreModalType) => void;
  /** Callback when user choose snippet */
  onSnippetClicked?: (snippet: string) => void;
  /** Callback when sidebar view mode changed */
  onSidebarModeChange?: (mode: SidebarViewMode) => void;
  /** Callback when document stat option changed (only in non-Standard mode) */
  onChangeStatOption?: (statType: typeof DocumentStatTypes.type) => void;
  /** Callback when submit comment for issue */
  onSuggestionCommentCallback?: (issue: IIssue, comment: string) => void;
}

export interface IFirebaseConfig {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
}

export interface ISidebarParams {
  /**
   * @deprecated sidebar can calculate container width on its own now
   */
  width?: number;
  sidebarMode?: SidebarViewMode;
  integrationType?: IntegrationType;
  defaultStatItem?: typeof DocumentStatTypes.type;
  useDropdownPortal?: boolean;
  isSnippetsCategoryHidden?: boolean;
  isTeamAdmin: boolean;
  isOrgAdmin: boolean;
  appRoot: string;
  teamName: string;
  organizationId: string;
  workspaceId: string;
  documentId: string | undefined;
  personaId: string;
}

export enum SidebarSection {
  ISSUES = 'issues',
  SNIPPETS = 'snippets',
}

export * from './shared';
