import type { CategoryType, IIssue, IssueCardType } from '@writerai/types';
import { Enum } from '@writerai/utils';

export const IssueUpdateType = new Enum('sidebar', 'editor', 'api', 'unknown');

export type TSelectedIssueContext = {
  issueId: string | undefined;
  source: typeof IssueUpdateType.type;
};

export interface IIssuesModel {
  /**
   * Return context of selected issue id and source
   */
  readonly selectedIssueContext: TSelectedIssueContext;

  /**
   * Returns currently selected issue id
   */
  readonly expandedIssueId: string | undefined;

  /**
   * Checks if the state is empty.
   * @returns {boolean} True if the state is empty, false otherwise.
   */
  readonly isEmptyState: boolean;

  /**
   * Return full list of issues
   */
  readonly list: IIssue[];

  /**
   * Return full list of issues without deleted
   */
  readonly allIssues: IIssue[];

  /**
   * Returns full list of issues filtered by category
   */
  readonly currentIssues: IIssue[];

  /**
   * Returns full list of sidebar issues (excluding dictionary etc.)
   */
  readonly sidebarIssues: IIssue[];

  /**
   * Returns issues that is currently displayed in the sidebar (filtered by category)
   */
  readonly currentSidebarIssues: IIssue[];

  /**
   * Returns the list of sidebar issues that are currently visible based on the visible range and visible issues map.
   * If the visible issues map is undefined and the visible range is empty, the function returns the current sidebar issues.
   * If the visible issues map is defined and not empty, the function returns the current sidebar issues filtered by the issue IDs in the visible issues map.
   * If the visible range is defined and not empty, the function returns the current sidebar issues filtered by the range of positions specified in the visible range.
   * If neither the visible issues map nor the visible range is defined, the function returns an empty array.
   * @returns {Array<IIssue>} The list of visible sidebar issues.
   */
  readonly visibleSidebarIssues: IIssue[];
  /**
   * Returns number of issues by category
   */
  readonly issuesByCategory: Record<CategoryType, number>;
  /**
   * Returns issue that is currently selected in the sidebar
   */
  readonly selectedSidebarIssue: IIssue | undefined;
  /**
   * Returns total number of issues in the sidebar
   */
  readonly totalSidebarIssuesCount: number;
  /**
   * Set's currently selected issue in sidebar
   * @param issue
   * @param source
   */
  setSelectedIssue(issue: IIssue, source?: typeof IssueUpdateType.type): void;
  /**
   * @deprecated
   * please use setIssuesVisibility instead of this method
   * Shows\Hides issue by id in the sidebar
   * @param issue
   * @param isVisible
   */
  setIssueVisibility(issue: IIssue, isVisible: boolean): void;
  /**
   * Shows\Hides issues by id in the sidebar
   * @param issues
   * @param isVisible
   */
  setIssuesVisibility(issues: IIssue[], isVisible: boolean): void;
  /**
   * @deprecated
   * please use setVisibleRange inteadof this method
   */
  setVisibleIssuesMap: (map: Map<string, boolean> | undefined) => void;

  /**
   * Sets the visible range of issues.
   *
   * @param range - An array of tuples representing the start and end indices of the range.
   * @returns void
   */
  setVisibleRange(range: Array<readonly [start: number, end: number]> | undefined): void;

  /**
   * Handles the delete issue click event.
   * @param issue - The issue to be deleted.
   * @param cardType - The type of the issue card.
   */
  onDeleteIssueClick: (issue: IIssue, cardType: IssueCardType) => Promise<void>;

  /**
   * Bulk delete similar issues.
   * @param issues - Issues to delete.
   * @param cardType - The type of the issue card.
   */
  onBulkDeleteIssues: (issues: IIssue[], cardType: IssueCardType) => Promise<void>;

  /**
   * Marks an issue as wrong.
   * @param issue - The issue to mark as wrong.
   * @param cardType - The type of issue card.
   */
  onMarkIssueAsWrong: (issue: IIssue, cardType: IssueCardType, comment?: string | null) => Promise<void>;

  /**
   * Accept multiple issues.
   * @param issues - The issues objects.
   * @param replacement - The replacement string for the suggestion.
   * @param cardType - The type of the issue card.
   */
  onBulkAcceptSuggestions: (issues: IIssue[], replacement: string, cardType: IssueCardType) => Promise<void>;

  /**
   * Handles the resolution of a claimed issue.
   * @param issue - The issue to be resolved.
   */
  onClaimResolve: (issue: IIssue) => Promise<void>;

  /**
   * Handles the claim decline action for an issue.
   * @param issue - The issue to be claimed or declined.
   */
  onClaimDecline: (issue: IIssue) => Promise<void>;

  /**
   * Marks the specified issues as deleted by setting their visibility to false.
   * @param issues - The issues to mark as deleted.
   */
  markIssuesAsDeleted: (issues: IIssue[]) => void;

  /**
   * Applies a suggestion to the issue and performs necessary actions.
   * @param replacement - The replacement string for the suggestion.
   * @param issue - The issue object.
   * @param cardType - The type of the issue card.
   */
  onApplySuggestion: (replacement: string, issue: IIssue, cardType: IssueCardType) => Promise<void>;
  /**
   * @deprecated
   * please use compare currentSidebarIssues and visibleSidebarIssues
   */
  readonly visibleIssuesMap: Map<string, boolean> | undefined;
}
