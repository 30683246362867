export const FIREBASE_CONFIG = {
  apiKey: process.env.NODE_ENV !== "production" ? process.env.REACT_APP_FB_API_KEY : window.env.REACT_APP_FB_API_KEY,
  authDomain: process.env.NODE_ENV !== "production" ? process.env.REACT_APP_FB_AUTH_DOMAIN : window.env.REACT_APP_FB_AUTH_DOMAIN,
  databaseId: process.env.NODE_ENV !== "production" ? process.env.REACT_APP_FB_DB_ID : window.env.REACT_APP_FB_DB_ID,
  databaseURL: process.env.NODE_ENV !== "production" ? process.env.REACT_APP_FB_DATABASE_URL : window.env.REACT_APP_FB_DATABASE_URL,
  projectId: process.env.NODE_ENV !== "production" ? process.env.REACT_APP_FD_PROJECT_ID : window.env.REACT_APP_FD_PROJECT_ID,
  storageBucket: process.env.NODE_ENV !== "production" ? process.env.REACT_APP_FD_STORAGE_BUCKET : window.env.REACT_APP_FD_STORAGE_BUCKET,
  messagingSenderId: process.env.NODE_ENV !== "production" ? process.env.REACT_APP_FB_MESSAGING_SENDER_ID : window.env.REACT_APP_FB_MESSAGING_SENDER_ID,
  appId: process.env.NODE_ENV !== "production" ? process.env.REACT_APP_FB_APP_ID : window.env.REACT_APP_FB_APP_ID,
};

export const clientId = 'Contentful';

export const QA_TEXTHIGHLIGHT_FORMAT_NAME = 'QA_TEXTHIGHLIGHT_FORMAT_NAME';

export const QL_TEXTHIGHLIGHT_CLASS_PREFIX = 'QL_TEXTHIGHLIGHT_CLASS_PREFIX';
