import React from 'react';

import { IssueLabel } from '@writerai/ui-molecules';

interface CorrectedWordProps {
  index?: number;
  text: string;
  onApplySuggestion: (text: string) => void;
  forceDisableCorrection?: boolean;
  correctionStyles?: React.CSSProperties;
}

export const CorrectedWord: React.FC<CorrectedWordProps> = ({
  index = 0,
  text,
  onApplySuggestion,
  forceDisableCorrection,
  correctionStyles,
}) => {
  const onCorrectWordClick = () => {
    onApplySuggestion(text);
  };

  return (
    <IssueLabel
      key={index}
      correction={!forceDisableCorrection}
      arrow
      style={{ marginTop: '9px', ...correctionStyles }}
      onClick={onCorrectWordClick}
    >
      {text}
    </IssueLabel>
  );
};
