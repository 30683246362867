import { makeObservable, computed } from 'mobx';
import type { ICollaborationUser, ICollaborationUserWithMeta } from '@writerai/types';
import { generateUUID } from '@writerai/text-utils';
import { viewerIsTyping } from '@writerai/firebase';
import debounce from 'lodash/debounce';
// eslint-disable-next-line no-restricted-syntax
import type { DebouncedFuncLeading } from 'lodash';
import type { FirebaseModel } from '../firebase';
import { getLogger } from '../../utils/logger';

const LOG = getLogger('CollaborationModel');

export interface ICollaborationModelOptions {
  firebase: Pick<FirebaseModel, 'documentViewers' | 'api' | 'currentFirebaseUser'>;
  sessionId?: string;
}

const defaultSessionId = generateUUID();

export class CollaborationModel {
  private readonly sessionId: string;
  protected readonly firebase: ICollaborationModelOptions['firebase'];
  constructor({ firebase, sessionId = defaultSessionId }: ICollaborationModelOptions) {
    this.firebase = firebase;
    this.sessionId = sessionId;

    makeObservable(this, {
      currentlyTypingUser: computed,
      documentIsLocked: computed,
      viewers: computed,
      isCollaborative: computed,
    });
  }

  get documentIsLocked() {
    return !!this.currentlyTypingUser && this.sessionId !== this.currentlyTypingUser.sessionId;
  }

  get isCollaborative() {
    return this.viewers.length > 0;
  }

  get currentlyTypingUser(): ICollaborationUserWithMeta | undefined {
    const user = this.firebase.documentViewers.find(({ lastTyping }) => viewerIsTyping(lastTyping));

    return user;
  }

  get viewers(): ICollaborationUserWithMeta[] {
    if (!this.firebase.documentViewers) {
      return [];
    }

    return this.firebase.documentViewers?.filter(
      viewer => viewer.id.toString() !== this.firebase.currentFirebaseUser?.uid,
    );
  }

  public setCollaborationLock: DebouncedFuncLeading<(user?: ICollaborationUser, isEditing?: boolean) => void> =
    debounce(
      (user?: ICollaborationUser, isEditing?: boolean) => {
        if (!user || !this.firebase.api) {
          return;
        }

        const lastViewed = Date.now();
        const userWithMeta: ICollaborationUserWithMeta = {
          ...user,
          lastViewed,
          sessionId: this.sessionId,
        };

        LOG.info(
          `${
            this.currentlyTypingUser
              ? `Document is locked by ${this.currentlyTypingUser.id}, session ${this.currentlyTypingUser.sessionId}`
              : 'Document is free.'
          }`,
        );

        if (!this.currentlyTypingUser && isEditing) {
          LOG.info('Document locked by current user.', user);
          userWithMeta.lastTyping = lastViewed;
        }

        this.firebase.api.updateDocumentUser?.(userWithMeta);
      },
      300,
      { leading: true, maxWait: 2000 },
    );
}
