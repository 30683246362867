import React, { useCallback, useMemo, useRef } from 'react';
import cx from 'classnames';
import type { IIssueCard } from '@writerai/types';
import { IssueType, IssueCategory, IssueCardTypes } from '@writerai/types';
import { convertToEllipsis } from '@writerai/text-utils';

import styles from './styles.module.css';
import { IssueHolder } from './IssueHolder';
import { Options } from './Options';
import { CardHeader } from './CardHeader';
import { Plagiarism } from './Plagiarism';

import { Controls } from '../Controls';
import { Description } from '../Description';

export const PLAGIARISM_DEFAULT_HEADER = 'Unoriginal text';

export interface IssueCardProps extends IIssueCard {
  segment?: string;
  issueType: IssueType;
  useDropdownPortal?: boolean;
}

const Segment = ({ cardType, highlight = '' }: { cardType: IssueCardTypes; highlight?: string }) =>
  cardType === IssueCardTypes.HEADER_ONLY ? (
    <p className={styles.segmentHighlight}>“{convertToEllipsis(highlight, 115, 70)}”</p>
  ) : null;

const getSidebarIssueCardType = ({
  meta,
  category,
  correctionLength,
}: {
  meta?: {
    acceptAllChanges?: boolean;
    paragraph?: boolean;
    header?: string;
  };
  category?: string;
  correctionLength: number;
}) => {
  if (meta?.acceptAllChanges) {
    return IssueCardTypes.ACCEPT_ALL_CHANGES;
  } else if (meta?.paragraph) {
    return IssueCardTypes.SUGGESTION;
  } else if (meta?.header) {
    return IssueCardTypes.HEADER_ONLY;
  } else if (!meta?.paragraph && correctionLength) {
    /* Correction card */
    return IssueCardTypes.CORRECTION;
  } else if (category === IssueCategory.Emojis || category === IssueCategory.BannedWords) {
    return IssueCardTypes.STRIKETHROUGH_HIGHLIGHT;
  }

  return IssueCardTypes.DEFAULT;
};

export const IssueCardMolecule: React.FC<IssueCardProps> = ({
  title,
  titleColor,
  category,
  issueType,
  header,
  isBeta,
  highlight,
  subHighlight,
  correction,
  description,
  examples,
  simpleDescription,
  learnMore,
  collapsed,
  paragraph,
  acceptAllChanges,
  plagiarismMatches,
  isBulkAcceptAvailable,
  isBulkIgnoreAvailable,
  similarIssues,
  onClick,
  onDeleteIssueClick,
  onBulkDeleteIssues,
  onApplySuggestion,
  onLearnMoreClicked,
  onMarkIssueAsWrong,
  onBulkAcceptSuggestions,
  segment,
  useDropdownPortal,
}) => {
  const promiseResolved = () => Promise.resolve();
  const isDontUseTermsCategory = category === IssueCategory.BannedWords;
  const isUseCarefullyCategory = category === IssueCategory.UseCarefully;
  const isPlagiarismCategory = category === IssueCategory.Plagiarism;
  const isReadabilityIssue = issueType === IssueType.READABILITY;
  const isPassiveVoiceIssue = issueType === IssueType.PASSIVE_VOICE;

  const containerRef = useRef<HTMLDivElement | null>(null);

  // We cant rename it in the model, but actually collapsed its incorrect name and its should be renamed to expanded
  const expanded = collapsed;
  const _onMarkIssueAsWrong = useCallback(
    (comment: string | null) => onMarkIssueAsWrong?.(comment),
    [onMarkIssueAsWrong],
  );

  const cardType = getSidebarIssueCardType({
    meta: { acceptAllChanges, paragraph, header },
    category,
    correctionLength: correction?.length || 0,
  });

  const cardHeader = useMemo(() => {
    if (isPlagiarismCategory) {
      return PLAGIARISM_DEFAULT_HEADER;
    }

    if (isReadabilityIssue) {
      return 'Simplify this sentence';
    }

    if (isPassiveVoiceIssue) {
      return 'Use active voice';
    }

    return header;
  }, [header, isPlagiarismCategory, isReadabilityIssue, isPassiveVoiceIssue]);

  return (
    <div
      id={!expanded ? 'collapsedContainer' : 'expandedContainer'}
      className={cx(styles.animationContainer, styles.mainContainer, {
        [styles.dontUseCard]: isDontUseTermsCategory || isUseCarefullyCategory,
      })}
    >
      <div
        onClick={onClick as React.MouseEventHandler}
        onKeyDown={onClick as React.KeyboardEventHandler}
        className={cx(styles.container, {
          [styles.expandedContainer]: expanded,
          [styles.collapsedContainer]: !expanded,
        })}
        ref={containerRef}
        role="button"
        tabIndex={-1}
      >
        <CardHeader
          titleColor={titleColor}
          highlight={highlight}
          title={title}
          description={description}
          simpleDescription={simpleDescription}
          isBeta={isBeta}
        />
        {expanded && (
          <div className={styles.content}>
            <div className={styles.shiftedContent}>
              <IssueHolder
                cardType={cardType}
                showDiff={isReadabilityIssue}
                header={cardHeader}
                highlight={highlight || ''}
                subHighlight={subHighlight}
                correction={correction || []}
                onApplySuggestion={onApplySuggestion || promiseResolved}
              />
              <Segment cardType={cardType} highlight={segment || highlight} />
              {!isReadabilityIssue && !isPassiveVoiceIssue && (
                <Description description={description} examples={examples} />
              )}
              <Plagiarism matches={plagiarismMatches} />
              <Options cardType={cardType} onApplySuggestion={onApplySuggestion} correction={correction} />
            </div>
            <Controls
              learnMore={learnMore}
              onLearnMoreClicked={onLearnMoreClicked}
              onMarkIssueAsWrong={_onMarkIssueAsWrong}
              onDeleteIssueClick={onDeleteIssueClick}
              useDropdownPortal={useDropdownPortal}
              suggestions={correction}
              cardType={cardType}
              isBulkAcceptAvailable={isBulkAcceptAvailable}
              isBulkIgnoreAvailable={isBulkIgnoreAvailable}
              similarIssues={similarIssues}
              onBulkAcceptSuggestions={onBulkAcceptSuggestions}
              onBulkDeleteIssues={onBulkDeleteIssues}
            />
          </div>
        )}
      </div>
    </div>
  );
};
