import { CookieStorage } from 'cookie-storage';
import API from '../../../utils/api';
import { SUCCESS_SERVER_CODE } from './consts';

export const readBuffer = async (readKey, codeVerifier, onSuccess) => {
  const cookieStorage = new CookieStorage();
  const { data, status } = await API.readBuffer(readKey);

  if (status === SUCCESS_SERVER_CODE) {
    const res = await API.exchangeAuthCode(codeVerifier, data.value);

    if (res.data?.token) {
      cookieStorage.setItem('qToken', res.data.token, {
        secure: true,
        sameSite: 'None',
      });
      const cookieWasSet = cookieStorage.getItem('qToken') === res.data.token;
      if (!cookieWasSet) {
        localStorage.setItem('qToken', res.data.token);
      }
      if (cookieStorage.getItem('qToken') !== res.data.token && localStorage.getItem('qToken') !== res.data.token) {
        throw new Error('Token can not be stored');
      }
      onSuccess(res.data.token);
    }
  } else {
    throw new Error('Still pooling user auth info');
  }
};
