import { formatError } from './formatError';

export class ApiError extends Error {
  public readonly error: unknown;
  public readonly response: Response;

  constructor(err: unknown, response: Response) {
    super(`ApiError`);
    this.name = 'ApiError';
    this.message = formatError(err);
    this.error = err instanceof ApiError ? err.error : err;
    this.response = err instanceof ApiError ? err.response : response;
    Object.setPrototypeOf(this, ApiError.prototype);
  }
}
