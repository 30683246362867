import React from 'react';
import cx from 'classnames';
import { observer } from 'mobx-react-lite';
import { Icon, IconVariant } from '@writerai/ui-atoms';
import { SelectedCategoryHeader } from '@writerai/ui-molecules';
import styles from './styles.module.css';
import { UICardsPanelModel } from './UICardsPanelModel';
import { IssuesList } from '../IssuesList';
import { SnippetsList } from '../SnippetsList';

export { UICardsPanelModel };

export interface CardsPanelProps {
  model: UICardsPanelModel;
}

export const CardsPanel = observer<CardsPanelProps>(({ model }) => {
  const {
    modelUIIssuesListModel,
    modelSnippetsList,
    isSnippets,
    totalCardsCount,
    title,
    isStandard,
    isEmptyState,
    showTeamName,
    teamName,
    isLoading,
    color,
    subTitle,
  } = model;

  return (
    <div className={styles.container}>
      <SelectedCategoryHeader
        className={cx({ [styles.headerMini]: !isStandard })}
        showHeader={!isEmptyState || isSnippets}
        showTeamName={showTeamName}
        teamName={teamName}
        label={title}
        count={totalCardsCount}
        icon={isSnippets ? <Icon name={IconVariant.LIGHTNING} className={styles.snippetsCardHeaderIcon} /> : null}
        subTitle={subTitle}
        color={color}
        isLoading={isLoading}
      />
      {isSnippets ? <SnippetsList model={modelSnippetsList} /> : <IssuesList model={modelUIIssuesListModel} />}
    </div>
  );
});
