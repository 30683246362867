
const digest = '1609ef73d2392b19d5d1591f6ad4f25134e8e840ed1c5f3db303d9465305631e';
const css = `.styles-module__6LAEWQv {
  overflow: scroll;
  height: 100%;
}

.styles-module__6LAEWQv::-webkit-scrollbar {
  width: 0;
}

.styles-module__BUtLuMJ {
  padding: 36px 0 50px;
}

.styles-module__--2Mgsb {
  position: relative;
  z-index: 2;
  background: var(--classic-white);
}

.styles-module__--2Mgsb th:first-child {
  width: 31px !important;
}

.styles-module__XRMqxOe {
  width: 21px;
  height: 16px;
  opacity: 0;
}

.styles-module__XRMqxOe input {
  margin: 0;
}

.styles-module__Es-3AkK {
}

.styles-module__D8bkCat {
  min-height: 200px;
  overflow-x: initial !important;
}

.styles-module__Es-3AkK .styles-module__A4uuJUI {
  z-index: 1;
  padding: 3px 0;
  border-bottom: var(--gray-border);
  background-color: var(--white);
  vertical-align: baseline;
}

.styles-module__3h4JgdU:hover {
  background-color: var(--writer-blue-1-transparent-40);
}

.styles-module__3h4JgdU:hover .styles-module__XRMqxOe {
  opacity: 1;
}

.styles-module__3h4JgdU.styles-module__bQ9Kqz3 {
  background-color: var(--writer-blue-2);
}

.styles-module__3h4JgdU.styles-module__bQ9Kqz3 .styles-module__XRMqxOe {
  opacity: 1;
}

.styles-module__3h4JgdU.styles-module__YCCL1y7 {
  pointer-events: none;
}

.styles-module__3h4JgdU .styles-module__7YC9Yp3,
.styles-module__3h4JgdU .styles-module__7YC9Yp3 > p {
  cursor: pointer;
}

.styles-module__3h4JgdU .styles-module__7YC9Yp3 {
  padding: 6px 0;
  border-bottom: 0.5px solid var(--writer-graynu-2);
}

.styles-module__7YC9Yp3 .styles-module__ni8kTQB {
  position: relative;
  top: -3px;
  display: inline-block;
}

.styles-module__7YC9Yp3 .styles-module__ni8kTQB p {
  padding: 3px 2px 2px;
  line-height: 8px;
  white-space: nowrap;
}

.styles-module__7YC9Yp3 .styles-module__SK2bhvV {
  position: relative;
  top: -1px;
  display: inline-block;
  border-radius: 26px;
  margin-right: 4px;
}

.styles-module__7YC9Yp3 .styles-module__SK2bhvV p {
  padding: 3px 6px 2px;
  line-height: 8px;
  white-space: nowrap;
}

.styles-module__HisGxsA {
}

.styles-module__QWF-NpM {
  max-width: 416px;
  margin: 40px auto 0;
}

.styles-module__ebiSdoQ {
  background-color: var(--writer-green-1);
}

.styles-module__As0oLSs {
  cursor: default !important;
}
`;

export default {"container":"styles-module__6LAEWQv","footerContainer":"styles-module__BUtLuMJ","baseTable":"styles-module__--2Mgsb","checkbox":"styles-module__XRMqxOe","header":"styles-module__Es-3AkK","tableContainer":"styles-module__D8bkCat","headerColumn":"styles-module__A4uuJUI","tableRow":"styles-module__3h4JgdU","selectedRow":"styles-module__bQ9Kqz3","rowDisabled":"styles-module__YCCL1y7","tableCell":"styles-module__7YC9Yp3","tableCellLabel":"styles-module__ni8kTQB","tableCellTag":"styles-module__SK2bhvV","baseTableCellDefaultText":"styles-module__HisGxsA","emptyStateText":"styles-module__QWF-NpM","matchFound":"styles-module__ebiSdoQ","firstCell":"styles-module__As0oLSs"};
export { css, digest };
  