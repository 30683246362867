import React, { memo, useEffect, useMemo } from "react";
import { inMemoryPersistence } from "firebase/auth";
import { reaction } from "mobx";
import { IntegrationType, IssueType, SidebarViewMode } from "@writerai/common-utils";
import { createFirebaseClient } from '@writerai/firebase';
import { DocumentStatTypes, FirebaseModel, SidebarPure, SidebarModel } from "@writerai/ui-sidebar";
import { FIREBASE_CONFIG } from "../../utils/constants";

export const SidebarMemo = memo(({ baseConfig, access, teamName, showEmailConfirmationLock, selectedIssue, modelRef, callbacks, requestService }) => {
  const firebaseModel = useMemo(() => {
    const firebaseClient = createFirebaseClient({
      config: FIREBASE_CONFIG,
      settings: {
        firebaseStatePersistenceType: inMemoryPersistence,
      },
      request: requestService.api,
      params: {
        organizationId: () => baseConfig.organizationId,
        teamId: () => baseConfig.workspaceId,
        documentId: () => baseConfig.documentId,
        personaId: baseConfig.personaId,
        userId: () => undefined,
      },
    });
    return new FirebaseModel({ client: firebaseClient });
  }, [
    requestService.api,
    baseConfig.organizationId,
    baseConfig.workspaceId,
    baseConfig.documentId,
    baseConfig.personaId,
  ]);

  const model = useMemo(
    () => {
      const sidebarModel = new SidebarModel({
        firebase: {
          config: FIREBASE_CONFIG,
          settings: {
            firebaseStatePersistenceType: inMemoryPersistence,
          },
        },
        firebaseModel: firebaseModel,
        params: {
          appRoot: baseConfig.appRoot || '',
          integrationType: IntegrationType.CONTENTFUL_PLUGIN,
        },
        requestService,
      });
      modelRef.current = sidebarModel;
      return sidebarModel;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [baseConfig.appRoot, firebaseModel, modelRef],
  );

  useEffect(() => {
    model.init({
      integrationType: IntegrationType.CONTENTFUL_PLUGIN,
      defaultStatItem: DocumentStatTypes.enum.words,
      isTeamAdmin: access.isTeamAdmin,
      isOrgAdmin: access.isOrgAdmin,
      authToken: baseConfig.authToken || '',
      appRoot: baseConfig.appRoot || '',
      organizationId: baseConfig.organizationId,
      workspaceId: baseConfig.workspaceId,
      documentId: baseConfig.documentId,
      personaId: baseConfig.personaId,
      sidebarMode: SidebarViewMode.OPEN,
      teamName: teamName || '',
      showEmailConfirmationLock: showEmailConfirmationLock,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    baseConfig,
    teamName,
    showEmailConfirmationLock,
  ]);

  useEffect(
    () => model.eventBus.on('onSelectSuggestion', callbacks.onSelectSuggestion),
    [model.eventBus, callbacks.onSelectSuggestion],
  );
  useEffect(
    () => model.eventBus.on('onApplySuggestionCallback', callbacks.onApplySuggestionCallback),
    [model.eventBus, callbacks.onApplySuggestionCallback],
  );

  useEffect(() => {
    const currentIssueIdRunCancel = reaction(
      () => ({
        selected: model.issues.selectedSidebarIssue,
        list: model.issues.currentSidebarIssues,
      }),
      ({ selected, list }, { selected: prevSelected, list: prevList }) => {
        if (!selected && list.length) {
          const prevIndex = prevList.findIndex(issue => issue.issueId === prevSelected?.issueId);

          if (prevIndex === -1) {
            callbacks.onSelectSuggestion(list[0]);
          } else {
            const newIndex = prevIndex < list.length ? prevIndex : list.length - 1;
            callbacks.onSelectSuggestion(list[newIndex]);
          }
        }
      },
    );

    return () => currentIssueIdRunCancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model, callbacks.onSelectSuggestion]);

  useEffect(() => {
    const currentIssueIdRunCancel = reaction(
      () => ({ list: model.issues.currentIssues }),
      ({ list }) => {
        callbacks.onIssuesUpdated(list, model.firebase.documentContent?.data || '', model.categories.selectedCategory?.id);
      },
    );

    return () => currentIssueIdRunCancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model, callbacks.onIssuesUpdated]);

  useEffect(() => {
    if (selectedIssue && selectedIssue.issueType !== IssueType.DICTIONARY) {
      model.issues.setSelectedIssue(selectedIssue, 'editor');
    }
  }, [model, selectedIssue]);

  return (
    <SidebarPure model={model} />
  );
});
