import React, { useCallback } from 'react';
import cx from 'classnames';
import type { OptionsObject } from 'notistack';
import { useSnackbar } from 'notistack';
import styles from './styles.module.css';
import { Icon, IconVariant } from '../Icon';
import { DotLoader } from '../MiscElements';
import { Text, TextColor, TextSize } from '../typography/Text/Text';
import { MuiFade } from '../mui';

export interface IEnqueueCustomSnackbarOptions {
  icon: IconVariant;
  iconClass?: string;
}

export function useCustomSnackbar() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const enqueueBasicSnackbar = useCallback(
    (message: string | React.ReactNode, options?: OptionsObject) =>
      enqueueSnackbar(message, {
        TransitionComponent: MuiFade as never,
        ...options,
      }),
    [enqueueSnackbar],
  );

  const enqueueDeleteSnackbar = useCallback(
    (message: string | React.ReactNode, options?: OptionsObject) => {
      const Message = () => (
        <span>
          <Icon name={IconVariant.TRASH} className={styles.icon} /> {message}
        </span>
      );

      return enqueueBasicSnackbar(<Message />, options);
    },
    [enqueueBasicSnackbar],
  );

  const enqueueAlertSnackbar = useCallback(
    (header: string, message: string | React.ReactNode, includeIcon = true, options?: OptionsObject) => {
      const Message = () => (
        <span className={styles.alertMessage}>
          {includeIcon && <Icon name={IconVariant.ALERT} className={styles.icon} />}
          <div className={styles.alertContent}>
            <Text variant={TextSize.L} className={styles.alertHeader} bold color={TextColor.WHITE}>
              {header}
            </Text>
            <Text variant={TextSize.L} className={styles.alertBody} color={TextColor.WHITE}>
              {message}
            </Text>
          </div>
        </span>
      );

      return enqueueBasicSnackbar(<Message />, { preventDuplicate: true, key: header, persist: true, ...options });
    },
    [enqueueBasicSnackbar],
  );

  const enqueueCustomSnackbar = useCallback(
    (
      message: string | React.ReactNode,
      { icon, iconClass }: IEnqueueCustomSnackbarOptions,
      options?: OptionsObject,
    ) => {
      const Message = () => (
        <span>
          <Icon name={icon} className={cx(styles.icon, iconClass)} /> {message}
        </span>
      );

      return enqueueBasicSnackbar(<Message />, options);
    },
    [enqueueBasicSnackbar],
  );

  const enqueueSuccessSnackbar = useCallback(
    (message: string | React.ReactNode, options?: OptionsObject) => {
      const Message = () => (
        <div className={styles.successSnackbar}>
          <span className={styles.validIconContainer}>
            <Icon name={IconVariant.CHECK} width={10} height={10} className={styles.validIcon} />
          </span>
          {message}
        </div>
      );

      return enqueueBasicSnackbar(<Message />, options);
    },
    [enqueueBasicSnackbar],
  );

  const enqueueErrorSnackbar = useCallback(
    (message: string | React.ReactNode, options?: OptionsObject) =>
      enqueueBasicSnackbar(message, { variant: 'error', ...options }),
    [enqueueBasicSnackbar],
  );

  const enqueueLoadingSnackbar = useCallback(
    (message: string | React.ReactNode, options?: OptionsObject) => {
      const Message = () => (
        <span className={styles.loadingSnackbar}>
          <DotLoader /> {message}
        </span>
      );

      return enqueueBasicSnackbar(<Message />, { key: 'loading', ...options });
    },
    [enqueueBasicSnackbar],
  );

  return {
    enqueueBasicSnackbar,
    enqueueDeleteSnackbar,
    closeSnackbar,
    enqueueErrorSnackbar,
    enqueueAlertSnackbar,
    enqueueLoadingSnackbar,
    enqueueCustomSnackbar,
    enqueueSuccessSnackbar,
  };
}
