/**
 * Extracts the first letter from each word in a string and returns a concatenation of these letters.
 * @example Test Writer -> TW
 * @param {string} sourceString - The string from which to extract the first letters.
 * @returns {string} The concatenated first letters from each word in the source string.
 */
export function extractFirstLetters(sourceString: string) {
  return sourceString
    ? sourceString
        .split(' ')
        .map(n => n[0])
        .join('')
    : '';
}
