
const digest = '629ce353f4d9ae50252ce14544386a0f6ca72aa7a75cb779a0468166ddfe3933';
const css = `@keyframes styles-module__yERxd-X {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes styles-module__ywPjB-K {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
`;

export default {"fadeIn":"styles-module__yERxd-X","fadeOut":"styles-module__ywPjB-K"};
export { css, digest };
  