import { v4 as uuid } from 'uuid';
import { CookieStorage } from 'cookie-storage';
import { isUserEmailUnconfirmed } from "@writerai/common-utils";

import { formatOrganizations } from './utils';
import { changeLogin } from '../app/actions';
import { setIssues } from '../documents/actions';
import API from '../../utils/api';
import types from './constants';

const cookieStorage = new CookieStorage({
  secure: true,
  sameSite: 'None'
});

export function getUserData(isCheck, token) {
  return async (dispatch) => {
    try {
      if (token) {
        dispatch({
          type: types.SET_TOKEN,
          payload: token,
        });
      }
      let {
        data: { results: organizations },
      } = await API.getMyOrganization();
      const savedOrgsId = localStorage.getItem('organizationId');
      organizations = formatOrganizations(organizations);
      const currentOrganization = organizations.find(({ id }) => id === Number(savedOrgsId)) || organizations[0];

      const payload = {
        personaId: 94,
        documentId: uuid(),
        currentOrganization,
        organizations,
        isLogged: true,
      };

      const { data: profileData } = await API.profile();
      const isEmailUnconfirmed = isUserEmailUnconfirmed(profileData);
      if (isEmailUnconfirmed) {
        payload.currentWorkspace = { id: '0' };
      } else {
        const {
          data: workspaces = []
        } = await API.fetchWorkspacesNew(currentOrganization.id);
        const savedTeamId = localStorage.getItem('teamId');

        const currentWorkspace = workspaces.find(({ id }) => id === Number(savedTeamId)) || workspaces[0];

        localStorage.setItem('organizationId', currentOrganization.id);
        if (currentWorkspace) {
          localStorage.setItem('teamId', currentWorkspace.id);
        } else {
          localStorage.removeItem('teamId');
        }

        const { data: subscription } = await API.getSubscription(currentOrganization.id);
        payload.currentWorkspace = currentWorkspace;
        payload.workspaces = workspaces;
        payload.subscription = subscription;
        payload.profileData = profileData;
      }

      dispatch({
        type: types.LOGIN_FULLFILED,
        payload,
      });
    } catch (error) {
      try {
        await API.invalidateToken();
      } catch (e) {
        console.error('Token invalidation failed', e);
      }
      cookieStorage.removeItem('qToken');
      localStorage.removeItem('qToken');

      dispatch({
        type: types.LOGIN_FULLFILED,
        payload: {
          authToken: '',
          isLogged: false,
        },
      });
    }
  }
}

export function checkLogin() {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.LOGIN_PENDING,
      });
      const token = cookieStorage.getItem('qToken') || localStorage.getItem('qToken');

      if (token) {
        dispatch(getUserData(true, token));
      } else {
        cookieStorage.removeItem('qToken');
        localStorage.removeItem('qToken');

        dispatch({
          type: types.LOGIN_FULLFILED,
          payload: {
            authToken: '',
            isLogged: false,
          },
        });
      }
    } catch (error) {
      cookieStorage.removeItem('qToken');
      localStorage.removeItem('qToken');

      dispatch({
        type: types.LOGIN_FULLFILED,
        payload: {
          authToken: '',
          isLogged: false,
        },
      });
    }
  };
}

export function updateProfileData(profileData) {
  return {
    type: types.UPDATE_PROFILE_DATA,
    payload: { profileData }
  };
}

export function logout() {
  return async dispatch => {
    try {
      await API.invalidateToken();
    } catch (e) {
      console.error('Token invalidation failed', e);
    }
    cookieStorage.removeItem('qToken');
    localStorage.removeItem('qToken');
    dispatch(changeLogin('main'));
    dispatch(setIssues([]));

    dispatch({
      type: types.LOGOUT,
    });
  };
}

export function changeOrg(organization) {
  localStorage.setItem('organizationId', organization.id);

  return async dispatch => {
    const { data: subscription } = await API.getSubscription(organization.id);
    const {
      data: workspaces = []
    } = await API.fetchWorkspacesNew(organization.id);

    const savedTeamId = localStorage.getItem('teamId');
    const currentWorkspace = workspaces.find(({ id }) => id === Number(savedTeamId)) || workspaces[0];

    if (!currentWorkspace) {
      localStorage.removeItem('teamId');
    }

    dispatch({
      type: types.SET_CURRENT_ORGANIZATION,
      payload: {
        workspaces,
        currentWorkspace: currentWorkspace,
        subscription: subscription,
        currentOrganization: organization,
      },
    });

    if (subscription.status === "canceled") {
      dispatch(setIssues([]));
    }
  };
}

export function changeTeam(team) {
  localStorage.setItem('teamId', team.id);

  return async dispatch => {
    dispatch({
      type: types.SET_CURRENT_TEAM,
      payload: {
        currentWorkspace: team,
      },
    });
  };
}

export function stopLoading() {
  return async dispatch => {
    dispatch({
      type: types.LOGIN_STOP
    });
  };
}
