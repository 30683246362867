import React, { useCallback, useEffect, useMemo, useState } from 'react';
import cx from 'classnames';

import {
  Button,
  ButtonTypes,
  Dropdown,
  DropdownPlacement,
  Icon,
  IconVariant,
  ItemsTypes,
  SizeTypes,
  Tooltip,
  useModalContext,
} from '@writerai/ui-atoms';

import type { IIssue } from '@writerai/types';
import { IssueCardTypes, LearnMoreModalType } from '@writerai/types';
import { LearnMoreModal } from '@writerai/ui-molecules';
import { Enum } from '@writerai/utils';
import styles from './styles.module.css';
import { WrongSuggestionComment } from '../WrongSuggestionComment';

interface IControlsProps {
  learnMore?: LearnMoreModalType;
  onDeleteIssueClick?: () => void;
  onMarkIssueAsWrong: (comment: string | null) => void;
  onLearnMoreClicked?: (modalType: LearnMoreModalType) => void;
  className?: string;
  isInverted?: boolean;
  useDropdownPortal?: boolean;
  isCompact?: boolean;
  useMarkIssueAsWrongComment?: boolean;
  onMarkIssueAsWrongCommentStateChange?: (isOpen: boolean) => void;
  suggestions?: string[];
  cardType?: IssueCardTypes;
  isBulkAcceptAvailable?: boolean;
  isBulkIgnoreAvailable?: boolean;
  similarIssues?: IIssue[];
  onBulkAcceptSuggestions?: (replacement: string) => void;
  onBulkDeleteIssues?: () => void;
}

const TIssueDeleteActionId = new Enum('delete', 'bulkDelete');
const TIssueOptionId = new Enum('wrongSuggestion', 'wrongSuggestionComment');

const menuOptions = [
  {
    id: TIssueOptionId.enum.wrongSuggestion,
    name: 'Mark as wrong',
    icon: <Icon name={IconVariant.OUTLINED_FLAG} width={18} height={18} />,
    active: false,
  },
  {
    id: TIssueOptionId.enum.wrongSuggestionComment,
    name: 'Mark as wrong with a comment',
    icon: <Icon name={IconVariant.MESSAGE_BUBBLE} width={18} height={18} />,
    active: false,
  },
];

const getIgnoreOptions = (bulkActionAmount: number) => [
  {
    id: TIssueDeleteActionId.enum.delete,
    name: 'Ignore suggestion',
    icon: <Icon name={IconVariant.TRASH} width={14} height={14} />,
  },
  {
    id: TIssueDeleteActionId.enum.bulkDelete,
    name: `Ignore all ${bulkActionAmount} suggestions`,
    icon: <Icon name={IconVariant.TRASH} width={14} height={14} />,
  },
];

export const Controls = ({
  learnMore,
  onDeleteIssueClick,
  onMarkIssueAsWrong,
  onLearnMoreClicked,
  className,
  isInverted,
  isCompact,
  onMarkIssueAsWrongCommentStateChange,
  useDropdownPortal = true,
  useMarkIssueAsWrongComment = true,
  suggestions = [],
  cardType,
  isBulkAcceptAvailable = false,
  isBulkIgnoreAvailable = false,
  similarIssues = [],
  onBulkAcceptSuggestions,
  onBulkDeleteIssues,
}: IControlsProps) => {
  const getModalsRootContainer = useModalContext();
  const dropdownContainer = useDropdownPortal ? getModalsRootContainer() : undefined;
  const [learnMoreModalType, setLearnMoreModalType] = useState<LearnMoreModalType | undefined>();
  const [wrongSuggestionCommentVisible, setWrongSuggestionCommentVisible] = useState(false);

  const onLearnMoreButtonClick = useCallback(() => {
    if (learnMore) {
      if (onLearnMoreClicked) {
        onLearnMoreClicked(learnMore);
      } else {
        setLearnMoreModalType(learnMore);
      }
    }
  }, [learnMore, onLearnMoreClicked, setLearnMoreModalType]);
  const onCloseLearnMore = useCallback(() => setLearnMoreModalType(undefined), [setLearnMoreModalType]);
  const preventBubbling = useCallback((event: React.MouseEvent | React.KeyboardEvent) => {
    event.stopPropagation();
  }, []);

  const shouldShowBulkApply = useMemo(() => {
    const isCardTypeSupportsBulkApply =
      !!cardType &&
      [
        IssueCardTypes.ACCEPT_ALL_CHANGES,
        IssueCardTypes.CORRECTION,
        IssueCardTypes.SUGGESTION,
        IssueCardTypes.STRIKETHROUGH_HIGHLIGHT,
      ].includes(cardType);

    return isBulkAcceptAvailable && isCardTypeSupportsBulkApply;
  }, [cardType, isBulkAcceptAvailable]);

  const handleDropdownOptionClick = (id: string) => {
    TIssueOptionId.match(
      id as typeof TIssueOptionId.type,
      {
        wrongSuggestion: () => {
          onMarkIssueAsWrong(null);
        },
        wrongSuggestionComment: () => {
          if (useMarkIssueAsWrongComment) {
            setWrongSuggestionCommentVisible(true);
          } else {
            onMarkIssueAsWrong(null);
          }
        },
      },
      null,
    );
  };

  const handleIgnoreOptionClick = (id: string) => {
    TIssueDeleteActionId.match(
      id as typeof TIssueDeleteActionId.type,
      {
        delete: () => {
          onDeleteIssueClick?.();
        },
        bulkDelete: () => {
          onBulkDeleteIssues?.();
        },
      },
      null,
    );
  };

  const bulkActionAmount = similarIssues.length + 1;

  useEffect(() => {
    onMarkIssueAsWrongCommentStateChange?.(wrongSuggestionCommentVisible);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrongSuggestionCommentVisible]);

  const handleSubmitWrongIssueComment = (comment: string | null) => {
    onMarkIssueAsWrong(comment);
    setWrongSuggestionCommentVisible(false);
  };

  const handleBulkApply = () => {
    onBulkAcceptSuggestions?.(suggestions[0] ?? '');
  };

  return (
    <div
      className={cx(styles.controls, className, {
        [styles.controlsInverted]: isInverted,
      })}
      onClick={preventBubbling}
      onKeyDown={preventBubbling}
      role="button"
      tabIndex={-1}
    >
      <div className={styles.controlButtonsWithLabelContainer}>
        {shouldShowBulkApply && (
          <Button
            className={styles.acceptAllBtn}
            onClick={handleBulkApply}
            onKeyDown={handleBulkApply}
            size={SizeTypes.XXS}
            type={ButtonTypes.TRANSPARENT}
          >
            Accept all {bulkActionAmount}
          </Button>
        )}

        {learnMore && Object.values(LearnMoreModalType).includes(learnMore) && (
          <div
            className={styles.learnMoreButton}
            onClick={onLearnMoreButtonClick}
            onKeyDown={onLearnMoreButtonClick}
            role="button"
            tabIndex={-1}
          >
            Learn More
          </div>
        )}
      </div>

      <div className={styles.actions}>
        <div className={cx(styles.ignoreActions, isBulkIgnoreAvailable && styles.ignoreActionsWithPadding)}>
          <Tooltip
            title="Ignore suggestion"
            placement="top"
            slotProps={{
              popper: { modifiers: [{ name: 'offset', options: { offset: [0, -4] } }] },
            }}
          >
            <div
              aria-label="Ignore suggestion"
              className={cx(styles.controlButton, !isBulkIgnoreAvailable && styles.ignoreButtonFitsContainer)}
              onClick={onDeleteIssueClick}
              onKeyDown={onDeleteIssueClick}
              role="button"
              tabIndex={-1}
            >
              <Icon name={IconVariant.TRASH} width={14} height={14} />
            </div>
          </Tooltip>

          {isBulkIgnoreAvailable && (
            <Dropdown
              placement={DropdownPlacement.BOTTOM_RIGHT}
              trigger={
                <div className={styles.ignoreArrow}>
                  <Icon name={IconVariant.ARROW_DOWN} />
                </div>
              }
              itemsType={ItemsTypes.ACTION}
              options={getIgnoreOptions(bulkActionAmount)}
              onPrimaryOptionClickAction={handleIgnoreOptionClick}
              dropDownContainerClassName={styles.ignoreDropdownContainer}
            />
          )}
        </div>

        <Dropdown
          options={menuOptions}
          placement={DropdownPlacement.BOTTOM_RIGHT}
          trigger={
            <div className={cx(styles.controlButton, styles.moreButton)}>
              <Icon name={IconVariant.MORE_HORIZ} />
            </div>
          }
          onPrimaryOptionClickAction={handleDropdownOptionClick}
          domContainer={dropdownContainer}
          dropDownContainerClassName={styles.dropdownContainer}
        />
      </div>

      {learnMoreModalType && <LearnMoreModal modalType={learnMoreModalType} onCloseModal={onCloseLearnMore} />}

      {wrongSuggestionCommentVisible && (
        <WrongSuggestionComment
          className={cx(styles.wrongSuggestionComment, { [styles.compact]: isCompact })}
          onCancel={() => setWrongSuggestionCommentVisible(false)}
          onSubmit={handleSubmitWrongIssueComment}
        />
      )}
    </div>
  );
};

export default React.memo(Controls);
