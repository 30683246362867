import { SENTENCE_END_CHARACTERS } from './constants';

/**
 * Creates a segment of text with a specified number of words before and after given positions.
 * The segment is then trimmed of excess whitespace and returned.
 * - check if this can be shortened with regexp
 *    - https://regex101.com/r/TbS5LU/1
 *    - https://regex101.com/r/JE0zDX/1
 *
 * @param {string} content - The text content to extract a segment from.
 * @param {number} from - The starting position of the segment.
 * @param {number} until - The ending position of the segment.
 * @param {number} [numOfWords=25] - The number of words to include in the segment.
 * @returns {string} - The extracted segment of text.
 */
export function computeSegmentFromPositions(content: string, from: number, until: number, numOfWords = 25) {
  const res = [] as string[];
  let i = from - 1;
  let count = numOfWords;
  let subStr = '';

  // process words before `from`
  while (i >= -1 && count !== 0) {
    if (
      content[i] === '\n' ||
      content[i] === undefined ||
      (/^\s$/.test(content[i]) && SENTENCE_END_CHARACTERS.includes(content[i - 1]))
    ) {
      res.unshift(subStr);
      break;
    } else if (/^\s$/.test(content[i])) {
      subStr.length && res.unshift(subStr);
      subStr = '';
      count--;
    } else {
      subStr = content[i] + subStr;
    }

    i--;
  }

  i = from;
  count = numOfWords + 1;
  subStr = '';

  // process words after and including `from`
  while (i < content.length + 1 && count !== 0) {
    if (
      content[i] === '\n' ||
      content[i] === undefined ||
      (/^\s$/.test(content[i]) && SENTENCE_END_CHARACTERS.includes(content[i - 1]))
    ) {
      res.push(subStr);
      break;
    } else if (/^\s$/.test(content[i])) {
      subStr.length && res.push(subStr);
      subStr = '';
      count--;
    } else {
      subStr += content[i];
    }

    i++;
  }

  return res.join(' ').trim();
}
