import type { IFirebaseIssue, IIssue, IIssueMeta, ISidebarCategory } from '@writerai/types';
import { CategoryType, IssueCardType, IssueCategory, IssueFlag, IssueType } from '@writerai/types';
import { computeSegmentFromPositions } from '@writerai/text-utils';
import { AnalyticsService } from '@writerai/analytics';
import { createAtomSubscriber } from '@writerai/mobx';
import type { Emitter } from 'nanoevents';
import { action, computed, type IObservableValue, makeObservable, observable, reaction } from 'mobx';

import type { IIssuesModel, TSelectedIssueContext } from './types';
import { IssueUpdateType } from './types';
import type { ICategoriesModel } from '../categories';
import {
  CATEGORIES_IGNORED_FROM_SIDEBAR,
  type ISidebarEvents,
  ISSUE_TYPES_IGNORED_FROM_SIDEBAR,
  NUMBER_OF_WORDS_AROUND_HIGHLIGHT_AS_SEGMENT,
} from '../../types';
import { getLogger } from '../../utils/logger';
import { PLAGIARISM_MIN_MATCH_PERCENT } from '../../molecules/Issue/IssueCard/constants';

const LOG = getLogger('IssuesModel');

export const notEmpty = <TValue>(value: TValue | null | undefined): value is TValue =>
  value !== null && value !== undefined;

export type TState = {
  selected: IIssue | undefined;
  list: IIssue[];
  category: ISidebarCategory | undefined;
};

export interface IIssuesModelParams {
  apiFlagSuggestionAsWrong: (
    issue: IIssue,
    state: IssueFlag,
    segment: string,
    comment?: string | null,
  ) => Promise<void>;
  apiBulkFlagSuggestionsAsWrong: (
    flagSuggestionsResults: {
      issue: IIssue;
      state: IssueFlag;
      segment: string;
      comment?: string | null;
    }[],
  ) => Promise<void>;
  apiSuggestionComment: (issue: IIssue, comment: string) => Promise<void>;
  apiReportOnAcceptSuggestion: (replacement: string, issue: IIssue, segment: string) => Promise<void>;
  apiBulkReportOnAcceptSuggestions: (replacement: string, issues: IIssue[], segment: string) => Promise<void>;
  analytics: AnalyticsService;
  documentId: () => string | undefined;
  workspaceId: () => string | undefined;
  eventBus: Emitter<
    Pick<
      ISidebarEvents,
      'onFlagSuggestionCallback' | 'onSelectSuggestion' | 'onApplySuggestionCallback' | 'onBulkApplyCallback'
    >
  >;
  firebaseIssuesData: () => IFirebaseIssue[] | undefined;
  documentContentData: () => string | undefined;
  categories: () => Pick<
    ICategoriesModel,
    | 'categoriesList'
    | 'betaIssueCategories'
    | 'allIssuesCategories'
    | 'getSidebarCategory'
    | 'selectedCategory'
    | 'isPlagiarismCategorySelected'
  >;
}

/**
 * Represents the model for managing issues in the sidebar.
 */
export class IssuesModel implements IIssuesModel {
  /**
   * Set selected issue and source
   */
  private readonly $selectedIssueContext = observable.box<TSelectedIssueContext>(
    {
      issueId: undefined,
      source: IssueUpdateType.enum.unknown,
    },
    { deep: false },
  );

  /**
   * @deprecated
   * please use $visibleRange instead of $visibleIssuesMap
   */
  protected readonly $visibleIssuesMap = observable.box<Map<string, boolean>>(undefined, {
    deep: false,
  });

  /**
   * Represents the visible range of issues.
   * @remarks
   * The visible range is defined as an array of tuples, where each tuple represents the start and end indices of a visible range.
   * @typeParam start - The start index of the visible range.
   * @typeParam end - The end index of the visible range.
   */
  protected readonly $visibleRange = observable.box<Array<readonly [start: number, end: number]>>(undefined, {
    deep: false,
  });

  // Forced visible sidebar issues ids
  private issuesCache: Record<string, IIssue> = {};

  /**
   * Represents a map of deleted issues.
   */
  protected deletedIssuesMap: IObservableValue<Record<string, boolean>> = observable.box({}, { deep: false });

  constructor(private opts: IIssuesModelParams) {
    makeObservable<IssuesModel, 'setIssuesVisibility'>(this, {
      isEmptyState: computed,
      expandedIssueId: computed,
      allIssues: computed,
      list: computed,
      sidebarIssues: computed,
      currentIssues: computed,
      currentSidebarIssues: computed,
      visibleSidebarIssues: computed,
      issuesByCategory: computed,
      selectedSidebarIssue: computed,
      totalSidebarIssuesCount: computed,
      visibleIssuesMap: computed,
      setSelectedIssue: action.bound,
      setIssuesVisibility: action.bound,
    });
  }

  /**
   * Subscribe to selectedIssueContext changes
   */
  private readonly atomSelectedIssue = createAtomSubscriber('atomSelectedIssue', () => {
    const reactionDisposer = reaction(
      () => ({
        selected: this.selectedSidebarIssue,
        list: this.currentSidebarIssues,
        category: this.opts.categories().selectedCategory,
      }),
      (newState, prevState) => {
        this.updateSelectedIssue(newState, prevState);
      },
    );

    return () => reactionDisposer();
  });

  /**
   * Return context of selected issue id and source
   */
  get selectedIssueContext() {
    return this.$selectedIssueContext.get();
  }

  /**
   * Returns currently selected issue id
   */
  get expandedIssueId(): string | undefined {
    // report observed to make sure that reaction will be triggered
    this.atomSelectedIssue.reportObserved();
    const currId = this.$selectedIssueContext.get().issueId;

    return currId !== undefined ? currId : this.currentSidebarIssues[0]?.issueId;
  }

  /**
   * Update selected issue in sidebar when init and when we delete selected issue (apply/mark/delete)
   * @param selected
   * @param list
   * @param category
   * @param prevSelected
   * @param prevList
   * @param prevCategory
   */
  protected updateSelectedIssue(
    { selected, list, category }: TState,
    { selected: prevSelected, list: prevList, category: prevCategory }: TState,
  ): void {
    // in this case we need to check if is we have selected issue in sidebar or no, this need only in case when we delete selected issue (apply/mark/delete)
    if (!selected && list.length) {
      const prevIndex = prevList.findIndex((issue: IIssue) => issue.issueId === prevSelected?.issueId);
      const issueToSelect =
        prevIndex === -1 || category !== prevCategory ? list[0] : list[Math.min(prevIndex, list.length - 1)];

      this.setSelectedIssue(issueToSelect, 'api');
    }
  }

  /**
   * Checks if the state is empty.
   * @returns {boolean} True if the state is empty, false otherwise.
   */
  get isEmptyState() {
    const documentId = this.opts.documentId();

    if (!documentId) {
      return true;
    }

    const editorContent = this.opts.documentContentData();

    return !editorContent || !editorContent.length || (editorContent.length === 1 && editorContent[0] === '\n');
  }

  /**
   * Returns an array of all issues.
   *
   * @returns {IIssue[]} The array of all issues.
   */
  get allIssues(): IIssue[] {
    LOG.warn('[start] Building issues list:');
    LOG.info('Deleted issues map:', this.deletedIssuesMap);
    LOG.info('Issues cache:', this.issuesCache);

    if (!this.opts.categories().categoriesList) {
      LOG.warn('Categories list is still loading.');

      return [];
    }

    if (!this.opts.firebaseIssuesData()) {
      LOG.warn('Firebase issues list is still loading.');

      return [];
    }

    const result = this.opts
      .firebaseIssuesData()
      ?.map(firebaseIssue => {
        // hide issues with no category (including invisible categories that won't exist in CategoriesContext)
        if (!this.opts.categories().allIssuesCategories.includes(firebaseIssue.category)) {
          LOG.warn('Cant process, category not allowed, locked or disabled', firebaseIssue);

          return undefined;
        }

        const cachedIssue = this.issuesCache[firebaseIssue.issueId];

        if (cachedIssue) {
          LOG.info('Trying to take from cache', cachedIssue);

          if (IssuesModel.hasIssueChanged(cachedIssue, firebaseIssue)) {
            this.issuesCache[firebaseIssue.issueId] = {
              ...cachedIssue,
              from: firebaseIssue.from,
              until: firebaseIssue.until,
            };

            LOG.warn('Issue has changed. Updating cache. New issue:', this.issuesCache[firebaseIssue.issueId]);
          } else {
            LOG.info('Issue didnt change. Taking from cache:', this.issuesCache[firebaseIssue.issueId]);
          }

          return this.issuesCache[firebaseIssue.issueId];
        }

        const issue = this.processIssue(firebaseIssue);

        if (issue) {
          LOG.info('Successfull processing of issue:', issue);
          this.issuesCache[issue.issueId] = issue;
        }

        return issue;
      })
      .filter(notEmpty)
      .sort((a, b) => a.from - b.from) as IIssue[];

    LOG.info('[end] Building issues list:', result);

    return result;
  }

  /**
   * Gets the list of issues.
   * @returns An array of IIssue objects.
   */
  get list(): IIssue[] {
    return this.allIssues?.filter(issue => !this.deletedIssuesMap.get()[issue.issueId]);
  }

  /**
   * Returns full list of issues filtered by category
   */
  get currentIssues() {
    return IssuesModel.getIssuesByCategory(this.list, this.opts.categories().selectedCategory);
  }

  /**
   * Returns full list of sidebar issues (excluding dictionary etc.)
   */
  get sidebarIssues() {
    return IssuesModel.filterNoNSidebarIssues(this.list);
  }

  /**
   * Returns issues that is currently displayed in the sidebar (filtered by category)
   */
  get currentSidebarIssues() {
    return IssuesModel.filterNoNSidebarIssues(this.currentIssues);
  }

  /**
   * Returns the list of sidebar issues that are currently visible based on the visible range and visible issues map.
   * If the visible issues map is undefined and the visible range is empty, the function returns the current sidebar issues.
   * If the visible issues map is defined and not empty, the function returns the current sidebar issues filtered by the issue IDs in the visible issues map.
   * If the visible range is defined and not empty, the function returns the current sidebar issues filtered by the range of positions specified in the visible range.
   * If neither the visible issues map nor the visible range is defined, the function returns an empty array.
   * @returns {Array<IIssue>} The list of visible sidebar issues.
   */
  get visibleSidebarIssues(): IIssue[] {
    const { currentSidebarIssues } = this;

    const visibleRange = this.$visibleRange.get();
    const visibleIssuesMap = this.$visibleIssuesMap.get();

    /*
     * If visibleIssuesMap is undefined, sidebar issues list should be full
     * */
    if (!visibleIssuesMap && !visibleRange) {
      return currentSidebarIssues;
    }

    /*
     * If visibleIssuesMap is defined, but empty, sidebar issues list should be empty too
     * */
    if (visibleIssuesMap) {
      if (visibleIssuesMap.size) {
        return currentSidebarIssues.filter(item => visibleIssuesMap.has(item.issueId));
      }
    } else if (visibleRange) {
      if (visibleRange.length) {
        return currentSidebarIssues.filter(item => {
          for (const [from, until] of visibleRange) {
            if (from <= item.from && item.until <= until) {
              return true;
            }
          }

          return false;
        });
      }
    }

    return [];
  }

  /**
   * Returns number of issues by category
   */
  get issuesByCategory(): Record<CategoryType, number> {
    const { categoriesList } = this.opts.categories();

    if (!categoriesList) {
      return {} as Record<CategoryType, number>;
    }

    return categoriesList.reduce((res, category) => {
      res[category.id] = IssuesModel.getIssuesByCategory(this.sidebarIssues, category).length;

      return res;
    }, {} as Record<CategoryType, number>);
  }

  /**
   * Returns issue that is currently selected in the sidebar
   */
  get selectedSidebarIssue(): IIssue | undefined {
    const issue = this.currentSidebarIssues.find(issue => issue.issueId === this.$selectedIssueContext.get().issueId);

    return issue;
  }

  /**
   * Returns total number of issues in the sidebar
   */
  get totalSidebarIssuesCount() {
    if (this.opts.categories().isPlagiarismCategorySelected) {
      return this.sidebarIssues.length;
    }

    return this.sidebarIssues.length - this.issuesByCategory[CategoryType.PLAGIARISM];
  }

  /**
   * Set's currently selected issue in sidebar
   * @param issue
   * @param source
   */
  setSelectedIssue(issue: IIssue, source: typeof IssueUpdateType.type = 'unknown') {
    // if issue is not selected or selected issue is not the same as the new one
    if (this.selectedIssueContext.issueId !== issue.issueId) {
      // call analytics
      this.opts.analytics.track(AnalyticsService.EV.suggestionViewed, {
        suggestion_category: issue.category,
        suggestion_issue_type: issue.issueType,
        card_type: IssueCardType.SIDEBAR,
        team_id: Number(this.opts.workspaceId()),
      });

      // set selected issue
      this.$selectedIssueContext.set({
        issueId: issue.issueId,
        source,
      });
    }

    // emit event
    this.opts.eventBus.emit('onSelectSuggestion', issue, source);
  }

  /**
   * @deprecated
   * please use setIssuesVisibility instead of this method
   * Shows\Hides issue by id in the sidebar
   * @param issue
   * @param isVisible
   */
  public setIssueVisibility(issue: IIssue, isVisible: boolean) {
    this.deletedIssuesMap.set({
      ...this.deletedIssuesMap.get(),
      [issue.issueId]: !isVisible,
    });
  }

  /**
   * Shows\Hides issues by id in the sidebar
   * @param issues
   * @param isVisible
   */
  public setIssuesVisibility(issues: IIssue[], isVisible: boolean) {
    const updatedIssues: Record<string, boolean> = {};
    issues.forEach(issue => {
      updatedIssues[issue.issueId] = !isVisible;
    });
    this.deletedIssuesMap.set({
      ...this.deletedIssuesMap.get(),
      ...updatedIssues,
    });
  }

  /**
   * @deprecated
   * please use setVisibleRange inteadof this method
   */
  readonly setVisibleIssuesMap = action((map: Map<string, boolean> | undefined) => {
    this.$visibleIssuesMap.set(map);
    this.$visibleRange.set(undefined);
  });

  /**
   * Sets the visible range of issues.
   *
   * @param range - An array of tuples representing the start and end indices of the range.
   * @returns void
   */
  readonly setVisibleRange = action((range: Array<readonly [start: number, end: number]> | undefined) => {
    this.$visibleRange.set(range);
    this.$visibleIssuesMap.set(undefined);
  });

  /**
   * Retrieves issues from a source array based on the specified category.
   * If a category is provided, it filters the issues that belong to that category.
   * If no category is provided, it filters out issues with the category "Plagiarism".
   *
   * @param source - The array of issues to filter.
   * @param category - The category to filter the issues by.
   * @returns An array of issues that match the specified category.
   */
  static getIssuesByCategory(source: IIssue[], category?: ISidebarCategory) {
    if (category) {
      return source.filter(issue => category.categories.includes(issue.category));
    }

    return source.filter(issue => issue.category !== IssueCategory.Plagiarism);
  }

  /**
   * Checks if an issue has changed by comparing the properties of the cached issue and the new issue.
   * @param cachedIssue The cached issue object.
   * @param newIssue The new issue object.
   * @returns True if the issue has changed, false otherwise.
   */
  static hasIssueChanged(cachedIssue: IIssue, newIssue: IFirebaseIssue) {
    return cachedIssue.from !== newIssue.from || cachedIssue.until !== newIssue.until;
  }

  /**
   * Processes the meta object of an issue.
   *
   * @param meta - The meta object to be processed.
   * @returns The processed meta object.
   */
  static processMetaObject(meta: IIssueMeta) {
    /* todo: need to remove this logic */
    let metaObj = {} as IIssueMeta;

    if (typeof meta === 'string') {
      // BE sends meta object as string so parsing is needed until BE gets updated
      try {
        metaObj = JSON.parse(meta);
      } catch (e) {
        LOG.warn('Cant parse issue meta object', meta);
      }
    } else {
      metaObj = meta;
    }

    if (Array.isArray(metaObj)) {
      // handles old plagiarism issues where meta used to be an object
      metaObj = { matches: metaObj.map(item => ({ ...item, similarity: 95 })) };
    }

    return metaObj;
  }

  /**
   * Processes an issue and returns an IIssue object.
   *
   * @param issue - The IFirebaseIssue object to be processed.
   * @returns The processed IIssue object, or undefined if the issue cannot be processed.
   */
  private processIssue(issue: IFirebaseIssue): IIssue | undefined {
    let meta = IssuesModel.processMetaObject(issue.meta || {});
    const isBeta = this.opts.categories().betaIssueCategories.includes(issue.category) || meta?.isBeta;

    LOG.info('New issue. Trying process the issue:', issue);
    const documentContent = this.opts.documentContentData();

    if (!documentContent) {
      LOG.warn('Cant process, no content', issue);

      return undefined;
    }

    // ignore plagiarisms with similarity less then threshold
    if (
      issue.category === IssueCategory.Plagiarism &&
      issue.meta?.matches?.every(match => match.similarity < PLAGIARISM_MIN_MATCH_PERCENT)
    ) {
      LOG.warn('Cant process, wrong plagiarism', issue);

      return undefined;
    }

    // hide all dictionary (approved) term without description
    if (issue.issueType === IssueType.DICTIONARY && !issue.description?.trim()) {
      LOG.warn('Cant process, dictionary term with empty description', issue);

      return undefined;
    }

    const sidebarCategory = this.opts.categories().getSidebarCategory(issue.category);

    if (!sidebarCategory) {
      LOG.warn('Not able to get sidebarCategory (old styleguide?)', issue);
    }

    const { from, until, description } = issue;

    let segment = '';

    if ([IssueType.UNCLEAR_REFERENCES, IssueType.READABILITY].includes(issue.issueType)) {
      segment = computeSegmentFromPositions(documentContent, from, until);
    }

    if (issue.issueType === IssueType.READABILITY) {
      meta = { ...meta, header: 'Readability' };
    }

    return {
      ...issue,
      isBeta: !!isBeta,
      length: until - from,
      sidebarCategory,
      highlight: documentContent.slice(from, until),
      description,
      segment,
      meta,
    };
  }

  /**
   * Handles the flagging of an issue.
   *
   * @param state - The state of the issue flag.
   * @param issue - The issue to be flagged.
   * @param cardType - The type of the issue card.
   * @param comment - The comment to be added to the issue.
   * @returns A Promise that resolves when the flagging process is complete.
   */
  private async onFlagIssue(state: IssueFlag, issue: IIssue, cardType: IssueCardType, comment?: string | null) {
    const segment = computeSegmentFromPositions(
      this.opts.documentContentData() || '',
      issue.from,
      issue.until,
      NUMBER_OF_WORDS_AROUND_HIGHLIGHT_AS_SEGMENT,
    );

    this.setIssuesVisibility([issue], false);

    try {
      this.opts.eventBus.emit('onFlagSuggestionCallback', issue, state);
      await this.opts.apiFlagSuggestionAsWrong(issue, state, segment, comment);
    } catch (e) {
      LOG.warn(e);
      this.setIssuesVisibility([issue], true);
    }

    const analyticsEvent =
      state === IssueFlag.IGNORE ? AnalyticsService.EV.suggestionIgnored : AnalyticsService.EV.suggestionFlagged;

    this.opts.analytics.track(analyticsEvent, {
      suggestion_category: issue.category,
      suggestion_issue_type: issue.issueType,
      card_type: cardType,
      team_id: Number(this.opts.workspaceId()),
      suggestions_count: 1,
    });
  }

  /**
   * Filters out issues that should not be displayed in the sidebar.
   *
   * @param list - The list of issues to filter.
   * @returns The filtered list of issues.
   */
  static filterNoNSidebarIssues(list: IIssue[]) {
    return (
      list
        // some of the categories\issue types must be ignored in the sidebar
        .filter(issue => !CATEGORIES_IGNORED_FROM_SIDEBAR.includes(issue.category))
        .filter(issue => !ISSUE_TYPES_IGNORED_FROM_SIDEBAR.includes(issue.issueType))
    );
  }

  /**
   * Handles the delete issue click event.
   * @param issue - The issue to be deleted.
   * @param cardType - The type of the issue card.
   */
  readonly onDeleteIssueClick = (issue: IIssue, cardType: IssueCardType) =>
    this.onFlagIssue(IssueFlag.IGNORE, issue, cardType);

  /**
   * Bulk delete similar issues.
   * @param issues - Issues to delete.
   * @param cardType - The type of the issue card.
   */
  readonly onBulkDeleteIssues = async (issues: IIssue[], cardType: IssueCardType) => {
    const deleteResults = await Promise.all(
      issues.map(async issue => {
        const segment = computeSegmentFromPositions(
          this.opts.documentContentData() || '',
          issue.from,
          issue.until,
          NUMBER_OF_WORDS_AROUND_HIGHLIGHT_AS_SEGMENT,
        );

        this.opts.eventBus.emit('onFlagSuggestionCallback', issue, IssueFlag.IGNORE);

        return {
          issue,
          state: IssueFlag.IGNORE,
          segment,
        };
      }),
    );

    this.setIssuesVisibility(issues, false);

    await this.opts.apiBulkFlagSuggestionsAsWrong(deleteResults);

    const firstIssue = issues[0];
    this.opts.analytics.track(AnalyticsService.EV.suggestionIgnored, {
      suggestion_category: firstIssue.category,
      suggestion_issue_type: firstIssue.issueType,
      card_type: cardType,
      team_id: Number(this.opts.workspaceId()),
      suggestions_count: issues.length,
    });
  };

  /**
   * Marks an issue as wrong.
   * @param issue - The issue to mark as wrong.
   * @param cardType - The type of issue card.
   */
  readonly onMarkIssueAsWrong = (issue: IIssue, cardType: IssueCardType, comment?: string | null) =>
    this.onFlagIssue(IssueFlag.WRONG, issue, cardType, comment);

  /**
   * Applies a suggestions to the issues and performs necessary actions.
   * @param issues - The issues objects.
   * @param replacement - The replacement string for the suggestions.
   * @param cardType - The type of the issue card.
   */
  readonly onBulkAcceptSuggestions = async (issues: IIssue[], replacement: string, cardType: IssueCardType) => {
    const firstIssue = issues[0];
    this.opts.analytics.track(AnalyticsService.EV.suggestionAccepted, {
      suggestion_category: firstIssue.category,
      suggestion_issue_type: firstIssue.issueType,
      card_type: cardType,
      team_id: Number(this.opts.workspaceId()),
      suggestions_count: issues.length,
    });

    const segment = computeSegmentFromPositions(
      this.opts.documentContentData() || '',
      firstIssue.from,
      firstIssue.until,
      NUMBER_OF_WORDS_AROUND_HIGHLIGHT_AS_SEGMENT,
    );

    this.setIssuesVisibility(issues, false);

    await this.opts.apiBulkReportOnAcceptSuggestions(replacement, issues, segment);

    this.opts.eventBus.emit('onBulkApplyCallback', issues, replacement, this.opts.documentId());
  };

  /**
   * Handles the resolution of a claimed issue.
   * @param issue - The issue to be resolved.
   */
  readonly onClaimResolve = (issue: IIssue) => this.onFlagIssue(IssueFlag.IGNORE, issue, IssueCardType.INLINE);

  /**
   * Handles the claim decline action for an issue.
   * @param issue - The issue to be claimed or declined.
   */
  readonly onClaimDecline = (issue: IIssue) => this.onFlagIssue(IssueFlag.WRONG, issue, IssueCardType.INLINE);

  /**
   * Marks the specified issues as deleted by setting their visibility to false.
   * @param issues - The issues to mark as deleted.
   */
  public readonly markIssuesAsDeleted = (issues: IIssue[]): void => this.setIssuesVisibility(issues, false);

  /**
   * Applies a suggestion to the issue and performs necessary actions.
   * @param replacement - The replacement string for the suggestion.
   * @param issue - The issue object.
   * @param cardType - The type of the issue card.
   */
  onApplySuggestion = async (replacement: string, issue: IIssue, cardType: IssueCardType) => {
    this.opts.analytics.track(AnalyticsService.EV.suggestionAccepted, {
      suggestion_category: issue.category,
      suggestion_issue_type: issue.issueType,
      card_type: cardType,
      team_id: Number(this.opts.workspaceId()),
      suggestions_count: 1,
    });

    const segment = computeSegmentFromPositions(
      this.opts.documentContentData() || '',
      issue.from,
      issue.until,
      NUMBER_OF_WORDS_AROUND_HIGHLIGHT_AS_SEGMENT,
    );

    this.setIssuesVisibility([issue], false);

    try {
      this.opts.eventBus.emit('onApplySuggestionCallback', replacement, issue, this.opts.documentId());
      await this.opts.apiReportOnAcceptSuggestion(replacement, issue, segment);
    } catch (e) {
      LOG.debug('emitted onApplySuggestionCallback', e);
      LOG.warn(e);
      this.setIssuesVisibility([issue], true);
    }
  };

  /**
   * @deprecated
   * please use compare currentSidebarIssues and visibleSidebarIssues
   */
  public get visibleIssuesMap() {
    return this.$visibleIssuesMap.get();
  }
}
