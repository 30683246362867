import debounce from 'lodash/debounce';
import Delta from 'quill-delta';

import { type FirebaseApp, getApp, initializeApp } from 'firebase/app';
import { ReCaptchaEnterpriseProvider, initializeAppCheck } from 'firebase/app-check';
import { browserSessionPersistence, getAuth, setPersistence, signInWithCustomToken } from 'firebase/auth';
import {
  type CollectionReference,
  type DocumentData,
  type DocumentReference,
  type DocumentSnapshot,
  type Firestore,
  type QuerySnapshot,
  collection,
  doc,
  getDoc,
  onSnapshot,
  setDoc,
} from 'firebase/firestore';

import type { ICollaborationUserWithMeta, IFirebaseIssue, IStyleguide, IssuesPipeline } from '@writerai/types';
import { type RequestServiceInitialize } from '@writerai/network';
import { deltaToString, processIncomingDelta } from '@writerai/quill-delta-utils';
import { getLogger } from '@writerai/utils';

import { COLLECTION, DEBUG_APP_CHECK_TOKEN, FIELDS } from './constants';
import type {
  IFirebaseApi,
  IFirebaseConfig,
  IFirebaseParams,
  IFirebaseSettings,
  IPipelineContentHashes,
  IStyleguideMapping,
} from './types';
import { isDocumentIsValid, viewerIsActive } from './utils';

const LOG = getLogger('FirebaseApi', 'firebase');

export async function loadUser(
  app: FirebaseApp,
  request: RequestServiceInitialize['api'],
  settings: IFirebaseSettings,
  organizationId: string,
) {
  const auth = getAuth(app);

  try {
    await setPersistence(auth, settings.firebaseStatePersistenceType || browserSessionPersistence);
    const { token } = await request
      .put('/api/auth/organization/{organizationId}/firebase/login', {
        params: {
          path: {
            organizationId: Number(organizationId),
          },
        },
      })
      .then(r => r.data);

    const response = await signInWithCustomToken(auth, token);

    LOG.info('Sign in to firebase ok. User: ', response.user);

    return response.user;
  } catch (error) {
    LOG.error('Error while firebaseSignInWPersistence', error);
  }

  return undefined;
}

export function initApp(config: IFirebaseConfig, settings: IFirebaseSettings, firebaseAppName = 'writer'): FirebaseApp {
  try {
    const app = getApp(firebaseAppName);

    return app;
  } catch (e) {
    const app = initializeApp(config, firebaseAppName);

    if (settings.useAppCheck && settings.recaptchaEnterpriseKey) {
      if (window.location.hostname === 'localhost') {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).FIREBASE_APPCHECK_DEBUG_TOKEN = DEBUG_APP_CHECK_TOKEN;
      }

      initializeAppCheck(app, {
        provider: new ReCaptchaEnterpriseProvider(settings.recaptchaEnterpriseKey),
        isTokenAutoRefreshEnabled: true, // Set to true to allow auto-refresh
      });
    }

    return app;
  }
}

export function createFirebaseApi(firestore: Firestore, params: IFirebaseParams) {
  const organizationId = params.organizationId();
  const teamId = params.teamId();
  const documentId = params.documentId();
  const userId = params.userId();
  const { personaId } = params;

  const documentPath = `/organization/${organizationId}/workspace/${teamId}/persona/${personaId}`;

  const validateDocumentParams = <T>(args: T) =>
    organizationId && teamId && isDocumentIsValid(documentId) ? args : undefined;

  const validateStyleguideMappingParams = <T>(args: T) => (organizationId && teamId ? args : undefined);

  const validateNotificationsParams = <T>(args: T) => (userId && organizationId ? args : undefined);
  const ctrl = getController(firestore, documentPath, documentId || '');

  const ret: IFirebaseApi = {
    updateDocumentUser: validateDocumentParams(async (user: ICollaborationUserWithMeta) => {
      LOG.debug('updateDocumentUser', user);

      const viewersCollection = collection(ctrl.getPersonaDocument(), COLLECTION.enum.viewers);
      const currentUserDoc = doc(viewersCollection, user.sessionId);

      await setDoc(currentUserDoc, user, { merge: true });
    }),
    onFirebaseIssuesSubscriber: validateDocumentParams((fn: (issues: IFirebaseIssue[]) => void) =>
      onSnapshot(
        ctrl.getIssueCollection(),
        debounce((snapshot: QuerySnapshot<IFirebaseIssue>) => {
          const issues = snapshot.docs.map(doc => doc.data());
          fn(issues);
        }, 500),
      ),
    ),
    getDocumentDelta: validateDocumentParams(async () => {
      const dataField = ctrl.getDocumentFieldRef(FIELDS.enum.data);
      const document = await getDoc(dataField);
      const delta = new Delta(document.get('data')) as Delta;

      return processIncomingDelta(delta);
    }),
    getDocumentPipeline: validateDocumentParams(async () => {
      const coreField = ctrl.getDocumentFieldRef(FIELDS.enum.core);
      const meta = await getDoc(coreField);
      const pipelineId = meta.get('pipelineId');

      return pipelineId ? (pipelineId as IssuesPipeline) : undefined;
    }),
    onContentHashes: validateDocumentParams((fn: (data: IPipelineContentHashes) => void) => {
      const coreField = ctrl.getDocumentFieldRef(FIELDS.enum.core);

      return onSnapshot(coreField, coreDocument => {
        const pipelineContentHashes = coreDocument.get('pipelineContentHashes') as IPipelineContentHashes;
        fn(pipelineContentHashes);
      });
    }),
    onDocumentDelta: validateDocumentParams((fn: (d: Delta) => void) => {
      const coreField = ctrl.getDocumentFieldRef(FIELDS.enum.data);

      return onSnapshot(coreField, document => {
        const delta = new Delta(document.get('data')) as Delta;
        fn(delta);
      });
    }),
    onStyleguideMappingSubscriber: validateStyleguideMappingParams((fn: (data: IStyleguideMapping) => void) =>
      onSnapshot(doc(firestore, documentPath), (persona: DocumentSnapshot<DocumentData>) =>
        fn(persona.get('mapping') as IStyleguideMapping),
      ),
    ),
    onDocumentContentSubscriber: validateDocumentParams((fn: (data: string) => void) =>
      onSnapshot(ctrl.getDocumentFieldRef(FIELDS.enum.data), (documentSnapshot: DocumentSnapshot<DocumentData>) =>
        fn(deltaToString(documentSnapshot.get('data'))),
      ),
    ),
    onViewerSubscriber: validateDocumentParams((fn: (data: ICollaborationUserWithMeta[]) => void) =>
      onSnapshot(
        ctrl.getViewersCollection(),
        debounce((snapshot: QuerySnapshot<ICollaborationUserWithMeta>) => {
          const viewers = snapshot.docs.map(doc => doc.data()).filter(({ lastViewed }) => viewerIsActive(lastViewed));
          fn(viewers);
        }, 500),
      ),
    ),
    onStyleguideSubscriber: (styleguideRef: DocumentReference<DocumentData>, fn: (data: IStyleguide) => void) =>
      onSnapshot(styleguideRef, styleguideRef => {
        const styleguide = styleguideRef.data() as IStyleguide;
        fn(styleguide);
      }),
    onFirebaseNotificationsSubscriber: validateNotificationsParams((fn: (notifications: DocumentData) => void) =>
      onSnapshot(
        collection(
          doc(firestore, `/organization/${organizationId}/user/`, userId || ''),
          'notification',
        ) as CollectionReference<DocumentData>,
        (snapshot: QuerySnapshot<DocumentData>) => {
          const notifications = snapshot.docs.map(doc => doc.data());
          fn(notifications);
        },
      ),
    ),
  };

  return ret;
}

export function getController(firestore: Firestore, documentPath: string, documentId: string) {
  const getPersonaDocument = () => doc(firestore, `${documentPath}/document/`, documentId);

  const getDocumentInfoCollection = () =>
    collection(getPersonaDocument(), COLLECTION.enum.field) as CollectionReference;

  const getViewersCollection = () =>
    collection(getPersonaDocument(), COLLECTION.enum.viewers) as CollectionReference<ICollaborationUserWithMeta>;

  const getIssueCollection = () =>
    collection(getPersonaDocument(), COLLECTION.enum['issue-v2']) as CollectionReference<IFirebaseIssue>;

  const getDocumentFieldRef = (field: typeof FIELDS.type) => doc(getDocumentInfoCollection(), field);

  return {
    getPersonaDocument,
    getDocumentInfoCollection,
    getViewersCollection,
    getIssueCollection,
    getDocumentFieldRef,
  };
}
