import { useEffect, useCallback } from 'react';
import { Enum } from '@writerai/utils';

export const KEYBOARD_EVENT_KEYS = new Enum('Escape', 'Enter');

export const useKeypress = (targetKey: typeof KEYBOARD_EVENT_KEYS.type, callback: () => void) => {
  const downHandler = useCallback(
    ({ key }: KeyboardEvent) => {
      if (key === targetKey) {
        callback();
      }
    },
    [callback, targetKey],
  );

  useEffect(() => {
    window.addEventListener('keydown', downHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, [downHandler]);
};
