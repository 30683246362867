import get from 'lodash/get';

export const getOrganizationId = state => {
  return get(state, 'auth.currentOrganization.id');
};

export const getWorkspaceId = state => {
  return get(state, 'auth.currentWorkspace.id');
};

export const getDocumentId = state => {
  return get(state, 'auth.documentId');
};
