import type { Op as DeltaOperation } from 'quill-delta';
import Delta from 'quill-delta';
import isEmpty from 'lodash/isEmpty';

import { ALLOWED_DELTA_FORMATS, BLOCKED_SERVER_INSERTS } from '../constants';
import type { QUILL_FORMAT } from '../constants';

export const transformNbsp = (str: string) => str.replace(/[\u00A0\u2007\u202F\u2060\uFEFF]/gu, ' ');
export const transformBr = (str: string): string => str.replace('<p><br></p>', '<br>');

export const deltaToString = (delta?: Delta) =>
  delta?.ops?.reduce((res: string, next) => {
    if (next.insert && typeof next.insert === 'string') {
      return res + next.insert;
    } else {
      return `${res} `;
    }
  }, '') || '';

export const doTextNormalization = (op: DeltaOperation) => {
  if (typeof op.insert === 'string') {
    return {
      ...op,
      insert: transformNbsp(op.insert),
    } as DeltaOperation;
  }

  return op;
};

export const filterBlockedServerInserts = (op: DeltaOperation) => {
  if (typeof op.insert === 'object') {
    const format = Object.keys(op.insert)[0];

    return !BLOCKED_SERVER_INSERTS.includes(format);
  }

  return true;
};

export const filterUnsupportedQuillFormats = (op: DeltaOperation) => {
  if (op.insert && (op.insert as { image: string }).image) {
    return { insert: '' };
  }

  if (op.attributes) {
    const { attributes, ...operation } = op;

    const unsupportedKeys = Object.keys(attributes).filter(key => !ALLOWED_DELTA_FORMATS.includes(key as QUILL_FORMAT));

    // all unsopportedKeys
    unsupportedKeys.forEach(key => delete attributes[key]);

    if (isEmpty(attributes)) {
      return operation;
    }

    return {
      ...operation,
      attributes,
    };
  }

  return op;
};

// apply all transformations to outcoming delta (e.g. save delta)
export const normalizeAndCleanDelta = (delta: Delta): Delta => {
  const normalized = new Delta(delta.ops?.map(op => filterUnsupportedQuillFormats(doTextNormalization(op))));

  return new Delta().compose(normalized);
};

// apply all transformation to incoming delta (e.g. fetch from Firebase)
export const processIncomingDelta = (delta: Delta) =>
  delta ? new Delta(delta.ops?.filter(filterBlockedServerInserts).map(op => doTextNormalization(op))) : new Delta();
