
const digest = '462fabec68f0d053364f325546e5635ab5c3390c3d1b67346d9dc1b533ab45ac';
const css = `@keyframes styles-module__AhBByez {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.styles-module__f-majTn {
  animation-duration: 0.4s;
  animation-iteration-count: 1;
  animation-name: styles-module__AhBByez;
  animation-timing-function: linear;
}
`;

export default {"fadeIn":"styles-module__f-majTn","fadein":"styles-module__AhBByez"};
export { css, digest };
  