import type { ReactNode } from 'react';

export interface DropdownOption {
  /** Set option name */
  name: string;
  /** Set option id */
  id: string;
  /** Item's description */
  description?: string;
  /** Set option state */
  active?: boolean;
  /** Set option icon */
  icon?: ReactNode;
  /** Set option highlight */
  warning?: boolean;
  /** Set indicator color */
  color?: string;
  /** Set Optional action for an Item */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action?: (id?: any) => void;
  /** Set Optional label */
  optionLabel?: string;
  /** Set additional label color */
  optionLabelColor?: string;
  /** Set custom option name */
  customOptionName?: ReactNode;
  /** Set additional css class */
  className?: string;
}

export interface DropdownActionOption {
  /** Set option name */
  name: string;
  /** Set option id */
  id: string;
  /** Set option action */
  action: () => void;
  /** Set whether the option is highlighted */
  highlighted?: boolean;
}

export enum ItemsTypes {
  SELECT = 'selectType',
  ACTION = 'actionType',
  ICON_INLINE = 'iconInlineType',
}

export enum DropdownPlacement {
  BOTTOM_LEFT = 'bottomLeft',
  BOTTOM_RIGHT = 'bottomRight',
  TOP_LEFT = 'topLeft',
  TOP = 'top',
  TOP_RIGHT = 'topRight',
  RIGHT = 'right',
}
