import { formatError } from './formatError';

export class PromisedError extends Error {
  public readonly error: unknown;

  constructor(err: unknown) {
    super('PromisedError');
    this.name = 'PromisedError';
    this.message = formatError(err);

    this.error = err instanceof PromisedError ? err.error : err;
    Object.setPrototypeOf(this, PromisedError.prototype);
  }
}
