import React from 'react';

import { observer } from 'mobx-react-lite';
import cx from 'classnames';
import { Text, TextSize } from '@writerai/ui-atoms';

import styles from './DocumentStats.module.css';
import { UIDocumentStatsModel } from './UIDocumentStatsModel';
import { DocumentGradeLevel } from '../../molecules/DocumentGradeLevel';

export { UIDocumentStatsModel };

const StatItem = ({ label, value }: { label?: React.ReactNode; value?: React.ReactNode }) => (
  <div className={styles.statItem}>
    <div className={styles.title}>
      <Text variant={TextSize.XS}>{label}</Text>
    </div>
    <div className={styles.value}>
      <Text variant={TextSize.XS} bold>
        {value}
      </Text>
    </div>
  </div>
);

interface DocumentStatProps {
  model: Omit<UIDocumentStatsModel, 'defaultOption' | 'onDocumentStatOptionChange'>;
  className?: string;
  classNameTooltip?: string;
}

export const DocumentStats = observer<DocumentStatProps>(({ model, className, classNameTooltip }) => {
  const { characterCount, wordCount, avgSentenceLength, readingTimeReadable } = model;

  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.baseStatsContainer}>
        <StatItem label="Words" value={wordCount} />
        <StatItem label="Characters" value={characterCount} />
        <StatItem label="Sentence length" value={avgSentenceLength} />
        <StatItem label="Reading time" value={readingTimeReadable} />
      </div>
      <DocumentGradeLevel model={model} classNameTooltip={classNameTooltip} />
    </div>
  );
});
