/* eslint-disable no-undef */
export type TMiddleware = (
  url: RequestInfo | URL,
  init: RequestInit,
  next: (url: RequestInfo | URL, init: RequestInit) => void,
) => void;

export function applyMiddleware(
  list: Array<TMiddleware>,
  url: RequestInfo | URL,
  init: RequestInit | undefined,
  ffetch: typeof fetch,
): Promise<Response> {
  return new Promise<Response>((resolve, reject) => {
    let index = 0;

    const next = (url: RequestInfo | URL, init: RequestInit): void => {
      if (index < list.length) {
        const fn = list[index];
        index++;
        fn(url, init, next);
      } else {
        ffetch(url, init).then(resolve, reject);
      }
    };

    next(url, init ?? {});
  });
}
