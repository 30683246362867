import React from 'react';
import cx from 'classnames';
import { Tooltip, TooltipBackgroundColor, Text, TextSize, Heading, HeadingVariant } from '@writerai/ui-atoms';

import styles from './FleschKincaidScoreTooltip.module.css';

export enum ScoreValueColors {
  green = 'green',
  blue = 'blue',
  purple = 'purple',
}

export interface FleschKincaidScoreItem {
  score: string;
  description: string;
  addition: string;
  scoreColor: ScoreValueColors;
}

export interface FleschKincaidScoreTooltipProps {
  className?: string;
  classNameTitle?: string;
  isEmpty: boolean;
  disabled?: boolean;
  children: React.ReactElement;
  open?: boolean;
}

const SCORE_ITEMS: FleschKincaidScoreItem[] = [
  {
    score: '0-5',
    description: 'Very easy to read',
    addition: 'Good for people with limited literacy',
    scoreColor: ScoreValueColors.green,
  },
  {
    score: '6',
    description: 'Easy to read',
    addition: 'Considered conversational English',
    scoreColor: ScoreValueColors.green,
  },
  {
    score: '7',
    description: 'Fairly easy to read',
    addition: 'Meets plain language guidelines',
    scoreColor: ScoreValueColors.green,
  },
  {
    score: '8',
    description: 'Good for the average adult reader',
    addition: 'Meets plain language guidelines',
    scoreColor: ScoreValueColors.blue,
  },
  {
    score: '10',
    description: 'Fairly difficult to read',
    addition: 'More difficult than plain language guidelines suggest',
    scoreColor: ScoreValueColors.blue,
  },
  {
    score: '13',
    description: 'Difficult to read',
    addition: 'More difficult than plain language guidelines suggest',
    scoreColor: ScoreValueColors.purple,
  },
  {
    score: '14+',
    description: 'Very difficult to read',
    addition: 'Best understood by people with high literacy',
    scoreColor: ScoreValueColors.purple,
  },
];

const ScoreItem: React.FC<FleschKincaidScoreItem> = ({ score, description, addition, scoreColor }) => (
  <div className={cx(styles.scoreItem)} key={score}>
    <div
      className={cx(styles.score, {
        [styles.scoreGreen]: scoreColor === ScoreValueColors.green,
        [styles.scoreBlue]: scoreColor === ScoreValueColors.blue,
        [styles.scorePurple]: scoreColor === ScoreValueColors.purple,
      })}
    >
      {score}
    </div>
    <div className={styles.scoreDescription}>
      <Text className={styles.scoreDescriptionMain} medium variant={TextSize.M}>
        {description}
      </Text>
      <Text variant={TextSize.XS}>{addition}</Text>
    </div>
  </div>
);

const EmptyTooltip: React.FC = () => (
  <div className={styles.emptyTooltip}>
    <span>Add at least 10 words</span>
    <br />
    <span>to see grade level</span>
  </div>
);

const DescriptionTooltip: React.FC = () => (
  <div className={cx(styles.scoreTooltip)}>
    <Heading variant={HeadingVariant.H4}>Readability</Heading>
    <Text variant={TextSize.XS} smallCaps bold>
      Grade level
    </Text>
    <div className={cx(styles.descriptions)}>
      {SCORE_ITEMS.map(score => (
        <ScoreItem key={score.score} {...score} />
      ))}
    </div>
  </div>
);

export const FleschKincaidScoreTooltip: React.FC<FleschKincaidScoreTooltipProps> = ({
  isEmpty,
  open,
  className,
  classNameTitle,
  children,
  disabled,
}) => (
  <Tooltip
    open={open}
    tooltipWrapperClassname={cx(styles.tooltipWrapper, className, {
      [styles.white]: !isEmpty,
    })}
    disabled={disabled}
    tooltipBackgroundColor={isEmpty ? TooltipBackgroundColor.BLACK : TooltipBackgroundColor.WHITE}
    title={
      <div className={cx(styles.tooltipContainer, classNameTitle)}>
        {isEmpty ? <EmptyTooltip /> : <DescriptionTooltip />}
      </div>
    }
  >
    {children}
  </Tooltip>
);
