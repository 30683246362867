import { ZERO_WIDTH_REGEXP } from './constants';
import { replaceWhitespaces } from './replaceWhitespaces';

/**
 * Removes Unicode symbols and multiple whitespace characters from the given text.
 * @param textContent - The text to remove Unicode symbols and multiple whitespace characters from.
 * @param {string} [replace=' '] The string used for replacing whitespace characters. Defaults to a space character if not provided.
 * @param {boolean} removeDoubleSeq [removeDoubleSeq=true] - A flag indicating whether to remove sequences of more than one 'replace' character. Defaults to `false`.
 * @returns The text with Unicode symbols and multiple whitespace characters removed.
 */
export function removeUnicodeSymbols(textContent: string, replace = ' ', removeDoubleSeq = true): string {
  const removeUtf8 = textContent
    .replace(
      /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
      '',
    )
    .replace(ZERO_WIDTH_REGEXP, '');

  return replaceWhitespaces(removeUtf8, replace, removeDoubleSeq);
}
