const returnPageHeader = page => {
  switch (page) {
    case 'main':
      return 'Sign in';

    default:
      return 'Check content';
  }
}

export default returnPageHeader;
