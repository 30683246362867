export type OrganizationId = number | null;

export enum OrganizationsDeleteRequestStatus {
  PENDING = 'pending',
}

export interface IOrganizationsDeleteRequest {
  id: number;
  userId: number;
  organizationId: number;
  email: string;
  executionTime: null;
  hash: string;
  status: OrganizationsDeleteRequestStatus;
  creationTime: string;
  resentTime: string | null;
  cancellationTime: string | null;
}

export interface IOrganizationsDeleteRequestResponse {
  request: IOrganizationsDeleteRequest;
}

export interface IOrganization {
  id: number;
  name: string;
  type: OrganizationType;
  createdUserId?: number;
  modifiedUserId?: number;
  approvedByInviter: boolean;
}

export enum OrganizationType {
  NEW = 'new',
  OLD = 'old',
}
