import React from 'react';
import cx from 'classnames';
import { sanitize } from '@writerai/text-utils';
import { Text, TextSize, Icon, IconVariant } from '@writerai/ui-atoms';

import styles from './styles.module.css';

export interface SnippetInlineCardProps {
  className?: string;
  text: string;
  onClick: () => void;
}

export const SnippetInlineCard: React.FC<SnippetInlineCardProps> = ({ text, className, onClick }) => (
  <div aria-hidden className={cx(className, styles.snippet)} onClick={onClick}>
    <div className={styles.container}>
      <div className={styles.heading}>
        <Icon className={styles.snippetIcon} name={IconVariant.LIGHTNING}></Icon>
        <Text className={styles.headingText} variant={TextSize.XS} bold smallCaps>
          Insert snippet
        </Text>
      </div>
      <Text
        variant={TextSize.M}
        dangerouslySetInnerHTML={{ __html: sanitize(text) }}
        className={styles.snippetBody}
      ></Text>
    </div>
  </div>
);
