import { QL_SNIPPET_HIGHLIGHT_FORMAT_NAME } from '@writerai/quill-delta-utils';

export const FORMATS = [
  'header',
  'bold', 'italic', 'underline', 'code',
  'link',
  'bullet', 'list', 'blockquote',
  'QA_TEXTHIGHLIGHT_FORMAT_NAME',
  'embed',
  QL_SNIPPET_HIGHLIGHT_FORMAT_NAME
];
