import React, { useMemo, useState, useCallback, useRef, useEffect } from "react";
import { connect } from "react-redux";
import ReactTimeout from "react-timeout";
import { DotLoader } from "@writerai/ui-atoms";
import { UserRole } from "@writerai/common-utils";

import {
  selectIssue,
  setCategoryUpdate,
  setIssues,
} from "../../redux/documents/actions";
import { changeIssue } from "../../utils/utils";
import API from "../../utils/api";
import { useEmailConfirmation } from "./useEmailConfirmation";
import { SidebarMemo } from "./SidebarMemo";

import styles from "./style.module.scss";

const SideBar = ({
  dispatch,
  authToken,
  workspaceId,
  workspaceName,
  organizationId,
  subscription,
  selectedIssueId,
  newIssues,
  categoryChanged,
  quillRef,
  modelRef,
  applySuggestion,
  baseConfig,
  requestService,
}) => {
  const [prevCategoryId, setPrevCategoryId] = useState(undefined);
  const [permissions, setPermissions] = useState({ organizations: {} });
  const [access, setAccess] = useState({ isTeamAdmin: false, isOrgAdmin: false });

  const issuesRef = useRef(null);
  const justAppliedIssueTimerRef = useRef(null);

  const [showEmailConfirmationLock, isLoading] = useEmailConfirmation(authToken);

  useEffect(() => {
    API.getPermissions().then(({ data }) => setPermissions(data));
  }, [organizationId]);

  useEffect(() => {
    if (Object.keys(permissions.organizations).length === 0) return;

    const orgPermissions = permissions.organizations[organizationId];

    if (orgPermissions) {
      setAccess({
        isTeamAdmin: orgPermissions?.teams[workspaceId] === UserRole.ADMIN,
        isOrgAdmin: orgPermissions?.role === UserRole.ADMIN,
      });
    }
  }, [organizationId, permissions, workspaceId]);

  useEffect(() => {
    return () => {
      clearTimeout(issuesRef.current);
      clearTimeout(justAppliedIssueTimerRef.current);
    };
  }, []);

  const onIssuesUpdated = useCallback(
    (issues = [], editorContent, categoryId) => {
      const currentText = quillRef.current.editor.getText();

      clearTimeout(issuesRef.current);
      if (subscription && subscription.status !== 'canceled' && !showEmailConfirmationLock) {
        if (newIssues.length === 0) {
          dispatch(setIssues(issues));
        }

        if (prevCategoryId !== categoryId) {
          dispatch(setIssues(issues));
          dispatch(setCategoryUpdate(!categoryChanged));
          setPrevCategoryId(categoryId);
        } else {
          if (editorContent.length === currentText.length) {
            issuesRef.current = setTimeout(() => {
              dispatch(setIssues(issues));
            }, 100);
          }
        }
      }
    },
    [quillRef, subscription?.status, showEmailConfirmationLock, newIssues, prevCategoryId, dispatch, categoryChanged]
  );

  const onApplySuggestionCallback = useCallback((replacement, issue) => {
    justAppliedIssueTimerRef.current = setTimeout(() => justAppliedIssueTimerRef.current = null, 400);
    applySuggestion(replacement, issue);
  }, [applySuggestion]);

  const onSelectSuggestion = useCallback((issue) => {
    dispatch(selectIssue(issue.issueId));
    const newIssue = newIssues.find(
      (element) => element.issueId === issue.issueId
    );

    const changeToIssue = newIssue || issue;
    setTimeout(() => {
      changeIssue(changeToIssue, "scroll", quillRef, !!justAppliedIssueTimerRef.current);
    }, 200);
  }, [dispatch, newIssues, quillRef]);

  const selectedIssue = useMemo(() => (
    newIssues.find((issue) => issue.issueId === selectedIssueId)
  ), [newIssues, selectedIssueId]);

  if (isLoading) {
    return (
      <DotLoader className={styles.sidebar_loading} />
    );
  }

  return (
    <div className={styles.sidebar__wrapper}>
      <SidebarMemo
        baseConfig={baseConfig}
        access={access}
        teamName={workspaceName}
        showEmailConfirmationLock={showEmailConfirmationLock}
        selectedIssue={selectedIssue}
        modelRef={modelRef}
        callbacks={{
          onApplySuggestionCallback,
          onIssuesUpdated,
          onSelectSuggestion,
        }}
        requestService={requestService}
      />
    </div>
  );
};

const mapStateToProps = ({ auth, documents }) => {
  return {
    authToken: auth.authToken,
    workspaceId: auth.currentWorkspace?.id,
    workspaceName: auth.currentWorkspace?.name,
    organizationId: auth.currentOrganization.id,
    subscription: auth.subscription,
    selectedIssueId: documents.selectedIssueId,
    newIssues: documents.issues,
    categoryChanged: documents.categoryChanged,
  };
};

export default connect(mapStateToProps)(ReactTimeout(SideBar));
