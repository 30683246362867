import { type IObservableValue, observable, runInAction } from 'mobx';

/**
 * Options for configuring an observable field.
 *
 * @template T - The type of the observable field.
 */
export interface IObservableFieldOptions<T> {
  /**
   * The initial value of the observable field.
   * Can be a value of type T or a function that returns a value of type T or undefined.
   */
  init?: T | (() => T | undefined);

  /**
   * A function that recomputes the value of the observable field.
   * It takes an optional value of type T and returns a value of type T or undefined.
   */
  recompute?: (val?: T) => T | undefined;

  /**
   * A callback function that is called whenever the observable field is updated.
   */
  onUpdate?: () => void;
}

/**
 * Represents an observable field that holds a value of type T.
 * Extends the IObservableValue interface.
 */
export interface IObservableField<T> extends IObservableValue<T | undefined> {
  /**
   * Returns the raw value of the observable field.
   * @returns The raw value of type T or undefined.
   */
  getRaw(): T | undefined;

  /**
   * Returns the init value of the observable field.
   * @returns The init value of type T or undefined.
   */
  getInit(): T | undefined;
}

/**
 * Creates an observable field with getter, setter, and raw value access.
 *
 * @template T - The type of the observable field value.
 * @param {IObservableFieldOptions<T>} options - The options for creating the observable field.
 * @returns {IObservableField<T>} - The observable field object.
 */
export function observableField<T>({ init, recompute, onUpdate }: IObservableFieldOptions<T>): IObservableField<T> {
  const $value = observable.box<T | undefined>(undefined, { deep: false });

  const getInit = (): T | undefined => {
    if (init === undefined) {
      return undefined;
    }

    const result: T | undefined = init instanceof Function ? init() : init;

    return recompute ? recompute(result) : result;
  };

  return {
    /**
     * Gets the init value of the observable field.
     *
     * @returns {T | undefined} - The raw value of the observable field.
     */
    getInit,
    /**
     * Gets the raw value of the observable field.
     *
     * @returns {T | undefined} - The raw value of the observable field.
     */
    getRaw(): T | undefined {
      return $value.get();
    },
    /**
     * Gets the value of the observable field.
     *
     * @returns {T | undefined} - The value of the observable field.
     */
    get() {
      const value = $value.get();

      if (init !== undefined && value === undefined) {
        return getInit();
      }

      return recompute ? recompute(value) : value;
    },
    /**
     * Sets the value of the observable field.
     *
     * @param {T} v - The new value to set.
     */
    set(v) {
      runInAction(() => {
        $value.set(v);
        onUpdate?.();
      });
    },
  };
}
