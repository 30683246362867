/* eslint-disable react/no-danger */
import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { sanitize } from '@writerai/text-utils';
import { getTextSelectionFromNode } from '@writerai/dom';
import { Icon, IconVariant, Text, TextSize, Tooltip } from '@writerai/ui-atoms';
import type { ISnippetCreate } from '@writerai/types';
import styles from './styles.module.css';
import { ToggleButton } from '../ToggleButton';

export const SNIPPET_CLICK_TO_COPY_TOOLTIP_TEXT = 'Click to copy snippet to clipboard';
export const COLUMNS_WITH_TAGS_VISIBLE = 3;

const delay = (time: number) => new Promise(resolve => setTimeout(resolve, time));

export interface ISnippetCardProps extends ISnippetCreate {
  id: string;
  title?: string;
  className?: string;
  /** Callback when user choose snippet */
  onSnippetClick: (id: string, snippet: string) => void;
}

const CardHeader: React.FC<{
  shortcut?: string;
}> = ({ shortcut }) => (
  <div
    className={cx(styles.cardHeader, {
      [styles.hidden]: !shortcut,
    })}
  >
    <Icon name={IconVariant.LIGHTNING} className={styles.cardHeaderIcon}></Icon>
    <span className={styles.cardTitle}>{`w.${shortcut}`}</span>
  </div>
);

const Snippet: React.FC<{
  title: string | undefined;
  expand: boolean;
  snippet: string;
  snippetRef: React.RefObject<HTMLElement>;
  tooltipVisible: boolean;
}> = ({ expand, title, snippet, snippetRef, tooltipVisible }) => (
  <Tooltip
    arrow
    title={SNIPPET_CLICK_TO_COPY_TOOLTIP_TEXT}
    placement="right"
    enterDelay={1000}
    disabled={!tooltipVisible}
  >
    <div
      className={cx(styles.snippetContainer, {
        [styles.scrollbar]: expand,
      })}
    >
      <span className={styles.snippetTitle}>{title}</span>
      <span
        className={styles.snippetText}
        ref={snippetRef}
        dangerouslySetInnerHTML={{ __html: sanitize(snippet) || '' }}
      ></span>
    </div>
  </Tooltip>
);

const Description: React.FC<{
  description: string | undefined;
  expand: boolean;
  setExpand: (expand: boolean) => void;
}> = ({ description, expand, setExpand }) => (
  <div
    className={cx(styles.descriptionContainer, {
      [styles.hidden]: !description,
    })}
  >
    <ToggleButton className={styles.seemoreButton} isActive={expand} onClick={() => setExpand(!expand)} />

    {expand && <span className={styles.descriptionText}>{description}</span>}
  </div>
);

export const SnippetCard: React.FC<ISnippetCardProps> = ({
  id,
  snippet,
  shortcut,
  tags = [],
  description,
  className,
  title,
  onSnippetClick,
}) => {
  const [showDescription, setShowDescription] = useState(true);
  const [expand, setExpand] = useState(false);
  const [snippetTooltipVisible, setSnippetTooltipVisible] = useState(true);
  const snippetRef = useRef<HTMLElement>(null);

  const tagsWrapperRef = useRef<HTMLDivElement>(null);
  const [tagsAmountToRender, setTagsAmountToRender] = useState(tags.length);
  const [tagsLeft, setTagsLeft] = useState(0);

  const _onSnippetClicked = async () => {
    setSnippetTooltipVisible(false);
    const clipboardContent = getTextSelectionFromNode(snippetRef.current) || snippet;

    onSnippetClick(id, clipboardContent);

    await delay(5000);
    setSnippetTooltipVisible(true);
  };

  useEffect(() => {
    const tagsContainer = tagsWrapperRef.current;

    if (tagsContainer) {
      const containerWidth = tagsContainer.offsetWidth;
      const style = window.getComputedStyle(tagsContainer);
      const gap = parseFloat(style.gap);
      let widthSum = 0;
      let lineCount = 1;
      let visibleCount = 0;

      tags.some((_, index) => {
        const tagWidth = (tagsContainer.children[index] as HTMLParagraphElement).offsetWidth + gap;
        widthSum += tagWidth;

        if (widthSum > containerWidth) {
          lineCount++;
          widthSum = tagWidth;
        }

        if (lineCount > COLUMNS_WITH_TAGS_VISIBLE) {
          return true;
        }

        visibleCount++;

        return false;
      });

      setTagsAmountToRender(tags.slice(0, visibleCount).length);
      setTagsLeft(tags.length - visibleCount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!snippetRef.current) return;

    const el = snippetRef.current;

    if (!description && el.offsetHeight >= el.scrollHeight) {
      setShowDescription(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snippetRef]);

  return (
    <div className={cx(styles.mainContainer, className)}>
      <div className={cx(styles.container)}>
        <div aria-hidden className={styles.contentContainer} onMouseUp={_onSnippetClicked}>
          <CardHeader shortcut={shortcut} />
          <Snippet
            title={title}
            expand={expand}
            snippet={snippet}
            snippetRef={snippetRef}
            tooltipVisible={snippetTooltipVisible}
          />
        </div>

        <div className={styles.tags} ref={tagsWrapperRef}>
          {tags.map(({ tag }, i) => (
            <Text variant={TextSize.S} className={cx(styles.tag, { [styles.hidden]: i >= tagsAmountToRender })}>
              {tag}
            </Text>
          ))}

          {tagsLeft > 0 && (
            <Text variant={TextSize.S} className={styles.tagsLeft}>
              +{tagsLeft}
            </Text>
          )}
        </div>

        {showDescription && <Description description={description} expand={expand} setExpand={setExpand} />}
      </div>
    </div>
  );
};
