import { action, computed, makeObservable, observable } from 'mobx';

export const SIDEBAR_MAX_MEDIUM_SIZE = 445;
export const SIDEBAR_MAX_SMALL_SIZE = 367;
const DEFAULT_SIDEBAR_WIDTH = SIDEBAR_MAX_MEDIUM_SIZE + 1; // standard size, max medium + 1 will go to standard

/**
 * Represents a size model.
 */
export class SizeModel {
  private readonly $sidebarWidth = observable.box(DEFAULT_SIDEBAR_WIDTH);

  /**
   * Initializes a new instance of the SizeModel class.
   * @param {() => number | undefined} width - The function that returns the width.
   */
  constructor(private width?: () => number | undefined) {
    makeObservable(this, {
      sidebarWidth: computed,
      isStandard: computed,
      isMedium: computed,
      isSmall: computed,

      setSidebarWidth: action.bound,
    });
  }

  /**
   * Gets the current width.
   * @returns {number} The current width.
   */
  get sidebarWidth(): number {
    return this.width?.() || this.$sidebarWidth.get();
  }

  /**
   * Checks if the size is standard.
   * @returns {boolean} True if the size is standard, false otherwise.
   */
  get isStandard(): boolean {
    return !this.isMedium && !this.isSmall;
  }

  /**
   * Checks if the size is medium.
   * @returns {boolean} True if the size is medium, false otherwise.
   */
  get isMedium(): boolean {
    return (
      !!this.sidebarWidth && this.sidebarWidth > SIDEBAR_MAX_SMALL_SIZE && this.sidebarWidth <= SIDEBAR_MAX_MEDIUM_SIZE
    );
  }

  /**
   * Checks if the size is small.
   * @returns {boolean} True if the size is small, false otherwise.
   */
  get isSmall(): boolean {
    return !!this.sidebarWidth && this.sidebarWidth <= SIDEBAR_MAX_SMALL_SIZE;
  }

  /**
   Sets the sidebar width.
   @param width The width to set.
   */
  setSidebarWidth(width: number) {
    this.$sidebarWidth.set(width);
  }
}
