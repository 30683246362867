import React from 'react';
import cx from 'classnames';
import { Heading, HeadingVariant, Text, TextSize, Icon, IconVariant } from '@writerai/ui-atoms';
import styles from './styles.module.css';

export const VisibleIssuesScrollNoticeCard = ({
  isBottom = false,
  isSmallPadding = false,
}: {
  isBottom?: boolean;
  isSmallPadding?: boolean;
}) => (
  <div
    className={cx(styles.listScrollNoticeCard, {
      [styles.listScrollNoticeBottom]: isBottom,
      [styles.smallMargins]: isSmallPadding,
    })}
  >
    <Icon
      name={isBottom ? IconVariant.LIST_SCROLL_DOWN : IconVariant.LIST_SCROLL_UP}
      className={styles.listScrollNoticeCardIcon}
    />
    <div className={styles.listScrollNoticeCardMessage}>
      <Heading variant={HeadingVariant.H5}>Scroll text for more</Heading>
      <Text variant={TextSize.S}>Scroll your document {isBottom ? 'down' : 'up'} to load more suggestions</Text>
    </div>
  </div>
);
