import { Logo, LogoVariant, Text, TextSize } from '@writerai/ui-atoms';
import sadCharacterImg from "../../assets/images/sadCharacter.png";
import styles from "./style.module.scss";

export const NoTeams = () => {
  return (
    <div className={styles.contentHolder}>
      <div className={styles.bannerContainer}>
        <img className={styles.sadIcon} src={sadCharacterImg} alt="Sad Icon" width={90} />
        <div className={styles.heading}>
          <Text variant={TextSize.XXXXL} bold>
            Welcome to Writer!
          </Text>
        </div>
        <div className={styles.body}>
          <Text variant={TextSize.XXXXL} className={styles.bodyText}>
            It looks like you haven’t been added to a Team yet.
          </Text>
          <Text variant={TextSize.XXXXL} className={styles.bodyText}>
            Contact your org admin so they can get you set up.
          </Text>
        </div>
        <div className={styles.containerHeroLogo}>
          <Logo variant={LogoVariant.FULL_BLACK} />
        </div>
      </div>
    </div>
  );
};
