import React, { useState, useEffect } from 'react';
import { SnippetInlineCard } from '@writerai/ui-molecules';
import { getCardPosition } from "./utils";
import { getSnippetMeta, insertSnippet } from "../../utils/editorUtils";

import styles from "./style.module.scss";

const SnippetDisplay = ({ quillRef, mainWrapperRef, onSnippetApply }) => {
  const [isScroll, setScroll] = useState(false);
  const [displayedSnippet, setSnippet] = useState({});

  useEffect(() => {
    if (quillRef) {
      const qullEditor = quillRef.current.editor.root;
      const handler = (event) => {
        const isSnipet = event.target.classList.contains(
          "ql-writer-snippethighlight"
        );
        const isEditor = event.target.hasAttribute("data-gramm");

        if (!isSnipet && !isEditor) {
          setSnippet({});
        }

        if (isSnipet) {
          const snippetBlotData = getSnippetMeta(qullEditor, event.target);

          setSnippet({
            ...snippetBlotData,
            element: event.target,
          });
        }
      };

      qullEditor.addEventListener("mouseover", handler);

      return () => qullEditor.removeEventListener("mouseover", handler);
    }
  }, [quillRef]);

  useEffect(() => {
    if (quillRef && quillRef.current.editor.root) {
      const qullEditor = quillRef.current.editor.root;
      const handler = () => {
        setScroll(!isScroll);
      };

      qullEditor.addEventListener("scroll", handler);

      return () => qullEditor.removeEventListener("scroll", handler);
    }
  }, [isScroll, quillRef]);

  const snippetApply = () => {
    const { editor } = quillRef.current;
    onSnippetApply(displayedSnippet.element.textContent, true);
    insertSnippet(editor, displayedSnippet.element);
    setSnippet({});
  };

  return (
    <React.Fragment>
      {displayedSnippet.snippet && (
        <div
          className={styles.decoration__card}
          style={getCardPosition(217, mainWrapperRef, displayedSnippet.element)}
        >
          <SnippetInlineCard text={displayedSnippet.snippet} onClick={snippetApply} />
        </div>
      )}
    </React.Fragment>
  );
};

export default SnippetDisplay;
