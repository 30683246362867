
const digest = '4232e2afd1f5f4032dbbd9d509ce9ee5415dfa7dce5708620956f0b938c389b5';
const css = `.styles-module__9rwBRHI {
  margin: 12px 0;
}

.styles-module__9rwBRHI:last-child {
  margin: 12px 0 0;
}
`;

export default {"issueCard":"styles-module__9rwBRHI"};
export { css, digest };
  