
const digest = 'e7e809b38d34abcaab9cfc20560d1ac2f5fd8b28c6d21bd76576dc37b7c64020';
const css = `.styles-module__MlE841u {
  display: flex;
  align-items: center;
  border-radius: 4px;
  background-color: var(--classic-grey-1);
  font-size: 19px;
}

.styles-module__0Lm6VRv {
  margin: 0 8px;
  color: var(--classic-grey-2);
}

.styles-module__0Lm6VRv svg path {
  fill: var(--classic-grey-2);
}

.styles-module__wl8SisG {
  width: 100%;
  border: 0;
  background-color: transparent;
  font-size: var(--font-size-m);
  line-height: 28px;
  outline: 0;
}

.styles-module__wl8SisG::placeholder {
  color: var(--classic-grey-2);
}
`;

export default {"container":"styles-module__MlE841u","searchIcon":"styles-module__0Lm6VRv","searchbar":"styles-module__wl8SisG"};
export { css, digest };
  