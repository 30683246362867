import { Quill } from 'react-quill';
import Delta from 'quill-delta';
import { CustomQuillClipboard } from "./CustomQuillClipboard";
import { formatLineBreaks } from "../editorUtils";

export class Clipboard extends CustomQuillClipboard {
  onTableCellPaste(range, { text, html }) {
    const [line] = this.quill.getLine(range.index);
    const lineFormats = line.formats();
    const formats = this.quill.getFormat(range.index);
    let pastedDelta = this.convert({ text, html }, formats);

    pastedDelta = formatLineBreaks(pastedDelta, lineFormats);

    const delta = new Delta()
      .retain(range.index)
      .delete(range.length)
      .concat(pastedDelta);
    this.quill.updateContents(delta, Quill.sources.USER);
    this.quill.setSelection(
      delta.length() - range.length,
      Quill.sources.SILENT,
    );
    this.quill.scrollIntoView();
  }

  onCapturePaste(e) {
    if (e.defaultPrevented || !this.quill.isEnabled()) return;
    const range = this.quill.getSelection(true);

    // Process pasting in table-cell-line before
    const [thisLeaf] = this.quill.getLine(range.index);
    if (thisLeaf && thisLeaf.domNode?.className === 'qlbt-cell-line') {
      e.preventDefault();
      const html = e.clipboardData.getData('text/html');
      const text = e.clipboardData.getData('text/plain');
      const files = Array.from(e.clipboardData.files || []);
      if (!html && files.length > 0) {
        this.quill.uploader.upload(range, files);
      } else {
        this.onTableCellPaste(range, { html, text });
      }
      return;
    }

    super.onCapturePaste(e);
  }
}
