
const digest = 'eae6c38364db66f6fc72154ddbfdb37b0189087ab81ae15526e7e45890fcb5c9';
const css = `.styles-module__Usv4CNk {
  position: relative;
  border-radius: 12px;
}

.styles-module__sTtLIA3 {
  position: absolute;
  z-index: 3;
  top: 28px;
  left: 0;
  width: 421px;
  border: 1px solid var(--classic-grey-1);
  border-radius: 8px;
  background-color: var(--classic-white);
  box-shadow: var(--writer-section-shadow);
}

.styles-module__Jh6qsVc {
  display: flex;
  max-width: 135px !important;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 8px;
}

.styles-module__FAavSOo {
  padding: 0 19px;
}

.styles-module__FAavSOo:last-child {
  border-width: 0;
}

.styles-module__FAavSOo:hover {
  background-color: var(--writer-blue-1);
}

.styles-module__JfTlWHU {
  display: flex;
  padding: 18px 0;
  border-bottom: var(--gray-border);
  cursor: pointer;
  gap: 20px;
}

.styles-module__OV-4Uof {
  display: flex;
  min-width: 105px;
  max-width: 105px;
  height: 28px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border: 2px solid transparent;
  border-radius: 5px;
  color: var(--black);
  cursor: pointer;
  text-transform: uppercase;
  white-space: nowrap;
}

.styles-module__OV-4Uof:hover {
  cursor: pointer;
}

.styles-module__znYU-gt {
  margin-left: 8px;
}

.styles-module__H2h1YV7 {
  background-color: var(--writer-green-3);
}

.styles-module__FAavSOo:hover .styles-module__H2h1YV7 {
  border-color: var(--writer-green-5);
}

.styles-module__1aTvYuF {
  background-color: var(--classic-orange-1);
}

.styles-module__FAavSOo:hover .styles-module__1aTvYuF {
  border-color: var(--classic-orange-2);
}

.styles-module__LlVvGrF {
  background-color: var(--writer-yellow);
}

.styles-module__vmA2JE7 {
  display: grid;
  grid-template-columns: 170px auto;
}

.styles-module__qqKkA2n {
  width: 152px;
}

.styles-module__XcE4n4I {
  min-width: 152px;
  max-width: 152px;
  background-color: var(--writer-yellow-1);
}

.styles-module__FAavSOo:hover .styles-module__XcE4n4I {
  border-color: #ffc46b;
}

.styles-module__Jh6qsVc.styles-module__XcE4n4I > span {
  margin: 0 -11px 0 5px;
}

.styles-module__FAavSOo:hover .styles-module__LlVvGrF {
  border-color: var(--terms);
}
`;

export default {"container":"styles-module__Usv4CNk","popup":"styles-module__sTtLIA3","dropdownPlate":"styles-module__Jh6qsVc","termTypeSection":"styles-module__FAavSOo","termTypeOption":"styles-module__JfTlWHU","termTypeLabel":"styles-module__OV-4Uof","termTypeLabelIcon":"styles-module__znYU-gt","approved":"styles-module__H2h1YV7","banned":"styles-module__1aTvYuF","pending":"styles-module__LlVvGrF","termTypeOptionText":"styles-module__vmA2JE7","termTypeLabelWrapper":"styles-module__qqKkA2n","useCarefully":"styles-module__XcE4n4I"};
export { css, digest };
  