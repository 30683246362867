import type { ChangeEvent, KeyboardEvent, RefObject } from 'react';
import React, { useEffect, useState } from 'react';
import cx from 'classnames';

import styles from './styles.module.css';
import { MuiTextareaAutosize } from '../mui';
import { Icon, IconVariant } from '../Icon';

export interface IAutoSizeTextareaProps {
  /** Set additional css class */
  className?: string;
  /** Set inline css styles */
  style?: React.CSSProperties;
  /** Children passed on as React child */
  children?: React.ReactNode;
  /** Input's ID */
  id: string;
  /** Input's ref */
  inputRef?: RefObject<HTMLTextAreaElement>;
  /** Input value */
  value?: string;
  /** Input's placeholder */
  placeholder?: string;
  /** hide popup on option click */
  disabled?: boolean;
  /** onChange function for Input */
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  /** onKeyDown function for Input */
  onKeyDown?: (e: KeyboardEvent<HTMLTextAreaElement>) => void;
  /** input error text */
  error?: boolean;
  /** set focus on input */
  autoFocus?: boolean;
  /** minimum number of rows to display */
  minRows?: string | number;
  /** maximum number of rows to display */
  maxRows?: string | number;
}

export const AutoSizeTextarea: React.FC<IAutoSizeTextareaProps> = ({
  id,
  inputRef,
  value,
  className,
  onChange,
  error,
  placeholder,
  disabled,
  autoFocus,
  ...props
}) => {
  const [inputValue, setInputValue] = useState(value);
  const _handleChangeInput = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(e.target.value);
    onChange?.(e);
  };
  useEffect(() => setInputValue(value), [value]);

  return (
    <div
      className={cx(styles.textareaContainerGrowWrap, {
        [styles.error]: error,
      })}
    >
      <MuiTextareaAutosize
        disabled={disabled}
        className={cx(styles.textareaContainerItem, className)}
        {...{ id, placeholder }}
        {...props}
        value={inputValue}
        autoFocus={autoFocus}
        onChange={_handleChangeInput}
      />
      <Icon name={IconVariant.RESIZE_TEXTAREA} className={styles.resizeIcon} />
    </div>
  );
};
