import { useCallback } from "react";
import isEqual from "lodash/isEqual";
import { IssueFlag, IssueType } from "@writerai/common-utils";
import { InlineIssueCard } from "@writerai/ui-sidebar";

import { useMemoCompare } from "../../content/hooks/useMemoCompare";

import { InlineDictionaryCard } from "../InlineDictionaryCard";
import { getIssueCardPayload } from "./utils";

const Card = ({ issue, cardClicked, cardShow, applySuggestion, flagSuggestion }) => {
  const onApplySuggestion = (replacement) => {
    cardClicked(false);
    applySuggestion(replacement, issue);
  };
  const issueCardPayload = useMemoCompare(issue, isEqual, getIssueCardPayload);
  const onMarkIssueAsWrong = useCallback((comment) => {
    cardClicked(false);
    flagSuggestion(issue, IssueFlag.WRONG, comment);
  }, [cardClicked, flagSuggestion, issue]);

  const onDeleteIssueClick = useCallback(() => {
    cardClicked(false);
    flagSuggestion(issue, IssueFlag.IGNORE);
  }, [cardClicked, flagSuggestion, issue]);

  if (issue.issueType === IssueType.DICTIONARY) {
    return (
      <InlineDictionaryCard
        highlight={issue.highlight}
        subHighlight={issue.meta?.pos}
        examples={issue.meta?.examples}
        description={issueCardPayload.description}
        category={issue.category}
      />
    );
  }

  return (
    <InlineIssueCard
      isInverted={cardShow}
      onApplySuggestion={onApplySuggestion}
      onMarkIssueAsWrong={onMarkIssueAsWrong}
      onDeleteIssueClick={onDeleteIssueClick}
      useDropdownPortal={false}
      {...issueCardPayload}
    />
  );
};

export default Card;
