
const digest = 'bdf952995a4f4e1d2fa71992143bdf1be0dbba886fbb10e8ed63e9356a52d153';
const css = `.styles-module__1AqTstk {
  font-family: var(--font-default) !important;
}

.styles-module__rm09unK {
  background-color: #d6fff3;
  font-weight: var(--font-medium-weight);
}

.styles-module__1RH7a91 {
  display: none;
}
`;

export default {"container":"styles-module__1AqTstk","added":"styles-module__rm09unK","removed":"styles-module__1RH7a91"};
export { css, digest };
  