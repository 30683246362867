import { ApiError } from './ApiError';

export class ApiStreamError extends ApiError {
  constructor(text: string, response: Response) {
    super(text, response);
    this.name = 'ApiStreamError';
    Object.setPrototypeOf(this, ApiStreamError.prototype);
  }

  get text() {
    return this.error as string;
  }

  get json() {
    try {
      const error = JSON.parse(this.error as string);

      return error;
    } catch {
      return {
        error: this.error as string,
      };
    }
  }
}
