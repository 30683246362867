import { render } from 'react-dom';
import { ConfigAppSDK, FieldAppSDK, DialogAppSDK, init, locations } from '@contentful/app-sdk';
import '@contentful/forma-36-react-components/dist/styles.css';
import '@contentful/forma-36-fcss/dist/styles.css';
import '@contentful/forma-36-tokens/dist/css/index.css';

import "./main.css";
import { LocalhostWarning } from "./containers/LocalhostWarning";
import Config from "./containers/Config";
import Field from "./containers/Field";
import Dialog from "./containers/Dialog";

const root = document.getElementById('root');

if (window.self === window.top) {
  // @ts-ignore
  render(<LocalhostWarning />, root);
} else {
  init((sdk) => {
    const ComponentLocationSettings = [
      {
        location: locations.LOCATION_APP_CONFIG,
        component: <Config sdk={sdk as ConfigAppSDK} />,
      },
      {
        location: locations.LOCATION_ENTRY_FIELD,
        component: <Field sdk={sdk as FieldAppSDK} />,
      },
      {
        location: locations.LOCATION_DIALOG,
        component: <Dialog sdk={sdk as DialogAppSDK} />,
      },
    ];

    ComponentLocationSettings.forEach((componentLocationSetting) => {
      if (sdk.location.is(componentLocationSetting.location)) {
        // @ts-ignore
        render(componentLocationSetting.component, root);
      }
    });
  });
}
