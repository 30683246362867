export function inRange(
  itemFrom: number,
  _itemUntil: number,
  visibleRange: (readonly [start: number, end: number])[],
): boolean {
  for (const [from, until] of visibleRange) {
    // we match intersection too
    if (from <= itemFrom && itemFrom + 1 <= until) {
      return true;
    }
  }

  return false;
}
