import React, { type ReactNode } from 'react';
import cx from 'classnames';

import { Enum } from '@writerai/utils';

import {
  Text,
  TextSize,
  TextColor,
  Button,
  ButtonTypes,
  Icon,
  IconVariant,
  Tooltip,
  useCopy,
} from '@writerai/ui-atoms';

import styles from './CopyButton.module.css';

export const CopyButtonTypes = new Enum('text', 'icon');

export interface ICopyButtonProps {
  type?: typeof CopyButtonTypes.type;
  value: string | number;
  className?: string;
  text?: string;
  copiedText?: string;
  tooltipTitle?: string;
  onCopy?: () => void;
  disabled?: boolean;
}

const CopyButtonComponents = CopyButtonTypes.hash<
  (props: {
    text: string;
    copiedText: string;
    isCopied: boolean;
    className?: string;
    tooltipTitle?: string;
    onCopyClick: () => void;
    disabled?: boolean;
  }) => ReactNode
>({
  text: ({ isCopied, className, onCopyClick, text, copiedText, disabled }) => (
    <Button
      icon={
        isCopied ? (
          <Icon className={styles.copyButtonCheckmark} name={IconVariant.GREEN1_CHECKMARK} width={18} height={18} />
        ) : null
      }
      type={ButtonTypes.BLACK}
      className={cx(className, styles.copyButton)}
      onClick={onCopyClick}
      disabled={disabled}
    >
      {isCopied ? copiedText : text}
    </Button>
  ),
  icon: ({ isCopied, className, onCopyClick, tooltipTitle, text, disabled }) => (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      className={cx(className, styles.copyButtonIcon, {
        [styles.disabled]: disabled,
      })}
      onClick={onCopyClick}
      role="button"
      tabIndex={0}
    >
      <Tooltip
        disabled={disabled}
        placement="bottom"
        title={
          <Text variant={TextSize.M} color={TextColor.WHITE} bold>
            {tooltipTitle}
          </Text>
        }
      >
        <div className={styles.wrapper}>
          <div className={styles.iconWrapper}>
            {isCopied ? (
              <Icon name={IconVariant.CHECKMARK} width={16} height={16} color={TextColor.BLACK} />
            ) : (
              <Icon name={IconVariant.COPY_PASTE} width={18} height={18} color={TextColor.BLACK} />
            )}
          </div>

          {text && (
            <div className={styles.titleWrapper}>
              <Text variant={TextSize.XL} color={TextColor.BLACK}>
                {text}
              </Text>
            </div>
          )}
        </div>
      </Tooltip>
    </div>
  ),
});

export const CopyButton = ({
  type = CopyButtonTypes.enum.text,
  className,
  text = 'Copy',
  copiedText = 'Copied',
  tooltipTitle = 'Copy to clipboard',
  value,
  onCopy,
  disabled,
}: ICopyButtonProps) => {
  const { isCopied, onCopyClick } = useCopy({ value, resetInterval: 3000, onCopyCb: onCopy });

  const component = CopyButtonComponents[type];

  if (!component) {
    return null;
  }

  return (
    <>
      {component({
        text,
        copiedText,
        tooltipTitle,
        isCopied,
        className,
        onCopyClick,
        disabled,
      })}
    </>
  );
};
