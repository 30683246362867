
const digest = 'e353495e171c866077fa38df4e8b5dcdd272fc960771098580efc33698abed4a';
const css = `.CopyButton-module__i8OYLsv {
  margin-right: 10px;
}

.CopyButton-module__PiM-jiO {
  position: relative;
  width: max-content;
  height: 40px;
  border-radius: 25px;
  background-color: var(--writer-blue-2);
  padding: 6px;
  cursor: pointer;
  user-select: none;

  &.CopyButton-module__9lJD8lK {
    opacity: 0.5;
    pointer-events: none;
  }

  .CopyButton-module__49UlydC {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
  }

  .CopyButton-module__oijSeks {
    position: relative;
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    overflow: hidden;
    background-color: var(--writer-blue-3);
  }

  .CopyButton-module__eSv-ln1 {
    padding-right: 6px;
    position: relative;
  }
}
`;

export default {"copyButtonCheckmark":"CopyButton-module__i8OYLsv","copyButtonIcon":"CopyButton-module__PiM-jiO","disabled":"CopyButton-module__9lJD8lK","wrapper":"CopyButton-module__49UlydC","iconWrapper":"CopyButton-module__oijSeks","titleWrapper":"CopyButton-module__eSv-ln1"};
export { css, digest };
  