/**
 * Function for setting clipboardData for source (event or window)
 * @param source
 * @param params
 * @return {void}
 */
export function setClipboardData<T = unknown>(
  source: T & { clipboardData: DataTransfer | null | undefined },
  { text, html }: { text?: string; html?: string },
): void {
  if (text) {
    source.clipboardData?.setData('text/plain', text);
    source.clipboardData?.setData('Text', text); // IE mimetype
  }

  if (html) {
    source.clipboardData?.setData('text/html', html);
  }
}
