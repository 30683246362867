const types = {
  LOGOUT: 'auth/LOGOUT',
  LOGIN_FAILED: 'auth/LOGIN_ERROR',
  RESET_ERRORS: 'auth/RESET_ERRORS',
  LOGIN_SUCCESS: 'auth/LOGIN_SUCCESS',
  LOGIN_FULLFILED: 'auth/AUTH_FULLFILED',
  LOGIN_PENDING: 'auth/LOGIN_PENDING',
  LOGIN_STOP: 'auth/LOGIN_STOP',
  SET_TOKEN: 'auth/SET_TOKEN',
  SET_CURRENT_ORGANIZATION: 'auth/SET_CURRENT_ORGANIZATION',
  SET_CURRENT_TEAM: 'auth/SET_CURRENT_TEAM',
  UPDATE_PROFILE_DATA: 'auth/UPDATE_PROFILE_DATA',
};

export default types;
