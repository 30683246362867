
const digest = 'd8e5489cc5dfd4a6ec15cef06df1b0a6f5932f950aaa78c4b31cfe958e5c7f56';
const css = `.styles-module__rClojxs {
  display: flex;
  justify-content: center;
  width: auto;
  height: auto;
  border-radius: 8px;
  margin: 15px 15px 5px;
  background-color: #fff;
}

.styles-module__---3hqU {
  min-width: 62px;
}

.styles-module__rClojxs.styles-module__XV0yI5k {
  margin: 15px 0 5px;
}

.styles-module__rClojxs.styles-module__rTXQ1II {
  margin-bottom: 15px;
}

.styles-module__r09E2s6 {
  margin: 6px 0 0 8px;
  max-width: 156px;
}
`;

export default {"listScrollNoticeCard":"styles-module__rClojxs","listScrollNoticeCardIcon":"styles-module__---3hqU","smallMargins":"styles-module__XV0yI5k","listScrollNoticeBottom":"styles-module__rTXQ1II","listScrollNoticeCardMessage":"styles-module__r09E2s6"};
export { css, digest };
  