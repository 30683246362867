
const digest = 'f11203ec51cddce6ad615926c13f283b8057f0740719a8cbd6f822dfaf03cbff';
const css = `.styles-module__jOQnPTy {
  display: flex;
  width: calc(100% - 40px);
  flex-direction: column;
  padding: 15px 20px;
  margin: 10px 20px;
  box-shadow: var(--writer-section-shadow);
}

.styles-module__jOQnPTy button {
  font-size: 13px;
}

.styles-module__NUMaIQu {
  margin: 13px 0 17px !important;
}

.styles-module__NUMaIQu a {
  color: black;
}

.styles-module__yuzK2a3 {
  margin-top: 20px;
}

.styles-module__yuzK2a3 p {
  display: flex;
  margin: 10px 0 !important;
}

.styles-module__yuzK2a3 i {
  margin-right: 17px;
}
`;

export default {"expiredContainer":"styles-module__jOQnPTy","expiredDescription":"styles-module__NUMaIQu","prosList":"styles-module__yuzK2a3"};
export { css, digest };
  