
const digest = 'f5676f19e0601328fe4275905f0b702d92bc91e2a4068b2153cc3cf80c602e05';
const css = `.styles-module__E6YCbzK {
  overflow: visible;
  width: 100%;
  min-width: var(--inline-sug-card-min-width);
  max-width: var(--inline-sug-card-max-width);
  box-sizing: border-box;
  padding: 11px 17px 13px;
  border-radius: 8px;
  background-color: var(--white);
  box-shadow: 0 5.4px 32.5px 0 #e5ebf5;
  color: var(--black);
}

.styles-module__FUWJUVJ:not(.styles-module__2SwfMRV) {
  display: grid;
  padding: 6px 17px 13px;
  grid-auto-flow: dense;
}

.styles-module__g9yMxCG {
  display: flex;
  flex-direction: column;
}

.styles-module__-kD-gZR {
}

.styles-module__FUWJUVJ:not(.styles-module__2SwfMRV) > .styles-module__11vFOHd {
  order: 1;
}

.styles-module__FUWJUVJ:not(.styles-module__2SwfMRV) > .styles-module__-kD-gZR {
  order: 2;
  margin: -21px 0 -2px;
}

.styles-module__FUWJUVJ:not(.styles-module__2SwfMRV) > .styles-module__g9yMxCG {
  flex-direction: column-reverse;
  order: 3;
}

.styles-module__FUWJUVJ:not(.styles-module__2SwfMRV) .styles-module__ujB-owh {
  margin-bottom: 11px;
}

.styles-module__ujB-owh {
  color: var(--classic-grey-3);
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 1.3px;
  text-transform: uppercase;
}

.styles-module__2UaBVIf {
  width: calc(100% + 17px) !important;
}

.styles-module__pO-VbDj {
  background-color: transparent;
}

.styles-module__xTjvNZ- {
  margin-top: 12px;
}

.styles-module__7UjRx1x {
  padding: 11px 17px 8px;
}

.styles-module__7UjRx1x .styles-module__xwRB9RC {
  display: inline-block;
  vertical-align: middle;
}

.styles-module__kkaUd9j {
  padding: 17px 17px 13px 6px;
}

.styles-module__7UjRx1x .styles-module__yGz7cLz {
  margin-top: -27px;
  margin-right: 0;
  margin-left: auto;
}

/* No description with only category */
.styles-module__u7gAAu9 {
  align-items: center;
  justify-content: space-between;
}

.styles-module__u7gAAu9 .styles-module__xTjvNZ- {
  margin-top: 0;
}

/* Multiple Suggestion Card */
.styles-module__4GzkQS- {
  margin-top: 0;
}

.styles-module__jCDjbrZ {
  max-width: 400px;
  padding: 0;
  display: flex;
  flex-direction: column;

  &.styles-module__LiuMjIt {
    flex-direction: column-reverse;
  }
}

.styles-module__NU1OXwe {
  padding: 12px 14px;
}

.styles-module__Fw7LrXr {
  margin-top: 0 !important;
}

.styles-module__R-E6Zpf {
  padding: 20px 20px 20px 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.styles-module__UsxCFpK {
  border-top: 1px solid var(--writer-blue-2);
  border-bottom: 1px solid var(--writer-blue-2);
  padding: 10px 14px;
  font-size: 14px;
  line-height: 28px;
  font-weight: 400;
}

.styles-module__-QtzyVh {
  transition: background-color 0.3s ease;
}

.styles-module__GNWmMa1 {
  transition: background-color 0.3s ease;
  cursor: pointer;

  &:hover {
    background-color: var(--writer-green-3);

    .styles-module__-QtzyVh {
      background-color: var(--writer-green-4);
    }
  }
}

/* Single Suggestion Card */
.styles-module__2SwfMRV {
  display: grid;
  width: 100%;
  max-width: 100%;
  padding: 0;
  border-radius: 5px;
  grid-template-columns: 1fr 0 auto;
}

.styles-module__2SwfMRV .styles-module__xTjvNZ- {
  height: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 3px 7px 4px;
  margin-top: 0;
}

/* Don't use term Card */
.styles-module__zdMllBs .styles-module__ujB-owh {
  color: var(--classic-orange-2);
}

.styles-module__kcw46Cd {
  padding-bottom: 10px;
  margin-top: 7px;
}

.styles-module__djoxbGz {
  padding-left: 5px;
  color: var(--classic-grey-3);
  font-weight: 400;
}
`;

export default {"mainContainer":"styles-module__E6YCbzK","mainContainerInverted":"styles-module__FUWJUVJ","compactMode":"styles-module__2SwfMRV","mainContainerHero":"styles-module__g9yMxCG","mainContainerBody":"styles-module__-kD-gZR","mainContainerControls":"styles-module__11vFOHd","category":"styles-module__ujB-owh","description":"styles-module__2UaBVIf","transparent":"styles-module__pO-VbDj","controls":"styles-module__xTjvNZ-","mainContainerMultiSuggestion":"styles-module__7UjRx1x","inlineLabel":"styles-module__xwRB9RC","mainContainerMultiSuggestionInverted":"styles-module__kkaUd9j","inlineControls":"styles-module__yGz7cLz","alignDescriptionWithControls":"styles-module__u7gAAu9","multipleSuggestionControls":"styles-module__4GzkQS-","multiLine":"styles-module__jCDjbrZ","multiLineInverted":"styles-module__LiuMjIt","multiLineHeading":"styles-module__NU1OXwe","multiLineControls":"styles-module__Fw7LrXr","multilineControlsContainer":"styles-module__R-E6Zpf","bodyBordered":"styles-module__UsxCFpK","multiLineAdded":"styles-module__-QtzyVh","multiLineDiff":"styles-module__GNWmMa1","dontUseCard":"styles-module__zdMllBs","bannedWords":"styles-module__kcw46Cd","subHighlight":"styles-module__djoxbGz"};
export { css, digest };
  