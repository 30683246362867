import trim from 'lodash/trim';

/**
 * Calculates sentences over the text with positions.
 * @param text - The text to calculate the sentences for.
 * @returns An array of objects containing the sentence text, start position, and end position.
 */
export function getSentences(text: string) {
  const lines = text.split('\n');
  let from = 0;

  return lines.reduce(
    (res, line) => {
      const matches = line.match(/[^.!?]*[.!?]+|[\D\d]+/g);

      if (matches) {
        matches.forEach(match => {
          const until = from + match.length;

          res.push({
            text: match,
            from,
            until,
          });

          from = until;
        });
      }

      from++;

      return res;
    },
    [] as { text: string; from: number; until: number }[],
  );
}
