import type { FC } from 'react';
import React, { useMemo } from 'react';
import { UISidebarModel } from './UISidebarModel';
import { Sidebar, type IUiMixinProps } from './Sidebar';
import type { SidebarModel } from '../../models';

export interface ISidebarPure extends IUiMixinProps {
  model: SidebarModel;
}

/**
 * @deprecated please use Sidebar from @writerai/ui-sidebar
 */
export const SidebarPure: FC<ISidebarPure> = ({ model, ...props }) => {
  const uiModel = useMemo(() => new UISidebarModel({ model }), [model]);

  return <Sidebar {...props} model={uiModel} />;
};
