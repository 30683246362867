/**
 * convert text to ellipsis if it increases after certain length.
 * optionally add ellipsis in middle instead of at the end
 * @param text
 * @param maxLength @default 14
 * @param dotsInMiddleAfterNLength option to add ellipsis in middle after N length
 * @return {string}
 */
export function convertToEllipsis(text: string, maxLength: number, dotsInMiddleAfterNLength?: number): string {
  if (text.length <= maxLength) {
    return text;
  }

  if (dotsInMiddleAfterNLength) {
    const arr = text.split(' ');
    const lengthOfFirstPartOfSubstring = Math.floor(dotsInMiddleAfterNLength * 0.73);
    const lengthOfLastPartOfSubstring = Math.floor(dotsInMiddleAfterNLength * 0.27);
    let initialResult = '';
    let lastResult = '';

    while (initialResult.length + arr[0].length < lengthOfFirstPartOfSubstring) {
      initialResult += `${arr.shift()} `;
    }

    while (lastResult.length + arr[arr.length - 1].length < lengthOfLastPartOfSubstring) {
      lastResult = ` ${arr.pop()}${lastResult}`;
    }

    return `${initialResult.trim()}...${lastResult.trim()}`;
  } else {
    return `${text.slice(0, maxLength)}...`;
  }
}
