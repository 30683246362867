import { useState } from 'react';
import { getLogger } from '../utils/logger';

const LOG = getLogger('useSessionStorageHook');

export const EMAIL_CONFIRMATION_SEND = 'email confirmation send';

export function useSessionStorage<T>(key: string, initialValue: T) {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const storageItem = window.sessionStorage.getItem(key);

      return storageItem ? JSON.parse(storageItem) : initialValue;
    } catch (err) {
      LOG.error(err);

      return initialValue;
    }
  });

  const setValue = (value: T | ((val: T) => T)) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);

      if (value === undefined) {
        window.sessionStorage.removeItem(key);
      } else {
        window.sessionStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (err) {
      LOG.error(err);
    }
  };

  return [storedValue, setValue] as const;
}
