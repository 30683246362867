import React from 'react';
import cx from 'classnames';
import { diffWordsWithSpace } from 'diff';

import styles from './styles.module.css';

export interface TextDiffProps {
  className?: string;
  firstString?: string;
  secondString?: string;
  addedClassName?: string;
  removedClassName?: string;
}

export const TextDiff = ({
  className,
  firstString = '',
  secondString = '',
  addedClassName = '',
  removedClassName = '',
}: TextDiffProps) => (
  <span className={cx(styles.container, className)}>
    {diffWordsWithSpace(firstString, secondString).map((group, index) => {
      const { value, added, removed } = group;

      return (
        <span
          className={cx({
            [styles.added]: added,
            [styles.removed]: removed,
            [addedClassName]: added,
            [removedClassName]: removed,
          })}
          key={`diff-${index}`}
        >
          {value}
        </span>
      );
    })}
  </span>
);
