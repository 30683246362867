
const digest = '22509637ff55c91a594282a30b94be9b92920fcd8801f77ce9afb945bb22960b';
const css = `.styles-module__-8Gg-DP {
  max-height: 100%;
  overflow-y: auto;
}

.styles-module__njgGFXm {
  padding: 30px 37px;
}

.styles-module__h9UsgQk {
  padding: 30px 37px;
  background: black;
}

.styles-module__fB1y4HC {
  margin: 0;
  font-size: 30px;
  font-weight: 600;
  line-height: 1;
}

.styles-module__IoS6sxE {
  color: var(--classic-grey-2);
}

.styles-module__-ne6YR- {
  font-size: 18px;
}

.styles-module__wnkM4Nx {
  color: white;
}

.styles-module__I7dnOrT {
  font-size: 14px;
  font-weight: 400;
}

.styles-module__I7dnOrT ul {
  padding-left: 19px;
}

.styles-module__uAqVB1R {
  display: block;
  margin-bottom: 7px;
}

.styles-module__qmn5grs {
  margin-bottom: 7px;
}

.styles-module__RaxD--H {
  margin-bottom: 30px;
}

.styles-module__Cs8ecy9 {
  padding-left: 33px;
}

.styles-module__QY6xTNb {
  list-style-type: disc;
}

.styles-module__z332yVo {
  display: flex;
}

.styles-module__BC8gbsb {
  width: 50%;
  padding-right: 30px;
  font-size: 13px;
  line-height: 18px;
}

.styles-module__Wnmd70Q {
  position: relative;
  display: inline-block;
  padding: 5px 9px;
  border-radius: 19px;
  margin-top: 0;
  margin-bottom: 5px;
  margin-left: -5px;
  background-color: var(--classic-orange-1);
  font-size: 16px;
  font-weight: bold;
}

.styles-module__la-Ar8m {
  background-color: var(--writer-green-3);
}

.styles-module__bI02CTl {
  padding-bottom: 15px;
  border-bottom: var(--gray-border);
  margin-bottom: 15px;
}

.styles-module__bI02CTl:first-of-type {
  padding-top: 15px;
  border-top: var(--gray-border);
}

.styles-module__W8G1Ecv {
  color: white;
  font-size: 18px;
  font-weight: 600;
}

.styles-module__3-fnsLP {
  margin-bottom: 15px;
}

.styles-module__3-fnsLP a,
.styles-module__3-fnsLP a:visited,
.styles-module__3-fnsLP a:active {
  color: var(--classic-grey-2);
  text-decoration: none;
}

.styles-module__3-fnsLP:hover {
  color: white;
  text-decoration: underline;
}
`;

export default {"container":"styles-module__-8Gg-DP","paddedContent":"styles-module__njgGFXm","fullWidthContent":"styles-module__h9UsgQk","header":"styles-module__fB1y4HC","gray":"styles-module__IoS6sxE","blockHeader":"styles-module__-ne6YR-","whiteHeader":"styles-module__wnkM4Nx","block":"styles-module__I7dnOrT","blockItem":"styles-module__uAqVB1R","blockParagraph":"styles-module__qmn5grs","withBottomMargin":"styles-module__RaxD--H","paddedLeft":"styles-module__Cs8ecy9","list":"styles-module__QY6xTNb","doDont":"styles-module__z332yVo","doDontSection":"styles-module__BC8gbsb","doDontHeader":"styles-module__Wnmd70Q","greenHeader":"styles-module__la-Ar8m","doDontItem":"styles-module__bI02CTl","title":"styles-module__W8G1Ecv","link":"styles-module__3-fnsLP"};
export { css, digest };
  