
const digest = 'f56e839dfbdb686cd7393370ae187fcb068f2d05f732becee314aeb1f4b510e9';
const css = `.styles-module__qOhxNlG {
  display: inline-block;
  line-height: 0;
  pointer-events: none;
  vertical-align: middle;
}

.styles-module__vpotcwM {
  display: grid;
  gap: 40px 40px;
  grid-template-columns: repeat(8, minmax(100px, 1fr));
}

.styles-module__sHNyr9c {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1;
  cursor: pointer;
  text-align: center;
}

.styles-module__sHNyr9c:hover {
  background-color: #f5f5f5;
}

.styles-module__wcKufyZ {
  margin: 10px !important;
  text-align: center !important;
}
`;

export default {"container":"styles-module__qOhxNlG","gridContainer":"styles-module__vpotcwM","gridItem":"styles-module__sHNyr9c","iconText":"styles-module__wcKufyZ"};
export { css, digest };
  