import sortKeys from 'sort-keys';
import Delta from 'quill-delta';
import { sha256 } from 'js-sha256';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import type { IIssue } from '@writerai/types';
import type { RangeStatic } from 'quill';
import type Quill from 'quill';
import { normalizeAndCleanDelta } from './payload';

import { QUILL_FORMAT } from '../constants';

// trigger build

export interface IShiftFromDelta {
  from: number;
  shift: number;
}

/**
 * Checks if delta consist of only 1 insert
 * @param delta Delta
 * @returns boolean
 */
export const isJustOneInsert = (delta: Delta) => {
  if (!delta.ops) {
    return false;
  }

  return delta.ops.filter(op => !!op.insert).length === 1;
};

/**
 * Checks if delta has no transform operations (e.g. only retains without attributes)
 * @param delta Delta
 * @returns boolean
 */
export const isDeltaHasTransformations = (delta: Delta) => {
  if (!delta.ops) {
    return false;
  }

  return delta.ops.some(op => op.delete || op.insert || !isEmpty(op.attributes));
};

/**
 * Checks if delta has at least one insert or one delete operation
 * @param delta Delta
 * @returns boolean
 */
export const hasInsertOrDelete = (delta: Delta) => {
  if (!delta.ops) {
    return false;
  }

  return delta.ops.some(op => op.insert || op.delete);
};

/**
 * Calculates the shifts in text that produced by given delta
 * @param delta Delta
 * @returns { from: number; shift: number; }
 */
export const getShiftFromDelta = (delta: Delta): IShiftFromDelta | undefined => {
  if (!delta.ops) {
    return undefined;
  }

  const shift: IShiftFromDelta = delta.ops.reduce(
    (res, op) => {
      const { from, shift } = res;

      if (op.retain && typeof op.retain === 'number') {
        return { from: from + op.retain, shift };
      }

      if (op.insert) {
        const insertLength = typeof op.insert === 'string' ? op.insert.length : 1;

        return { from, shift: shift + insertLength };
      }

      if (op.delete) {
        return { from, shift: shift - op.delete };
      }

      return res;
    },
    { from: 0, shift: 0 },
  );

  return shift;
};

/**
 * Shifts all issues to given counts if issue is within shift range
 * @param issues Issues to shift
 * @param shift { from: number; shift: number; }
 * @returns New issues list
 */
export const shiftAllIssuesOffsets = (issues: IIssue[], { from, shift }: IShiftFromDelta) =>
  issues.map(issue => {
    if (issue.from < from) {
      return issue;
    }

    const newIssue = {
      ...issue,
      until: issue.until + shift,
      from: issue.from + shift,
    } as IIssue;

    return newIssue;
  });

/**
 * Find issues that intersects (SLOW OPERATION!!!)
 * @param issuesList Issues list
 * @param issuesToCheck Issues to check
 * @param definedSet Set of issues to check
 * @returns
 */
export const findIssuesThatAreIntersect = (issuesList: IIssue[], issuesToCheck: IIssue[], definedSet?: Set<IIssue>) => {
  const result = new Set(definedSet);

  // slow operation TODO:
  issuesToCheck.forEach(issueA => {
    issuesList.forEach(issueB => {
      if (result.has(issueB) || issueA.issueId === issueB.issueId) {
        return;
      }

      if (issuesAreIntersect(issueA, issueB)) {
        result.add(issueB);

        const innerIntersect = findIssuesThatAreIntersect(issuesList, [issueB], result);
        innerIntersect.forEach(issue => result.add(issue));
      }
    });
  });

  return Array.from(result);
};

/**
 * Checks if issues intersects
 * @param issueA First issue
 * @param issueB Second issue
 * @returns boolean
 */
export const issuesAreIntersect = (issueA: IIssue, issueB: IIssue) => {
  if (issueA.from >= issueB.from) {
    // issue A start follows issues B start
    if (issueA.from < issueB.from + issueB.length) {
      // if beginning of issue A is before end of issue B -> intersect
      return true;
    }
  } else if (issueB.from < issueA.from + issueA.length) {
    // if beginning of issue A is before end of issue B -> intersect
    return true;
  }

  return false;
};

/**
 * Calculates checksum over delta.
 * @param delta Delta
 * @returns checksum
 */
export const calculateChecksumOverDelta = (delta: Delta) => {
  const { ops } = new Delta().compose(normalizeAndCleanDelta(delta));
  // sortKeys accepts only plain objects
  const sortedKeys = sortKeys({ ops }, { deep: true });

  const json = JSON.stringify(sortedKeys, (_, value) => {
    // Filtering out properties
    if (isNull(value) || isUndefined(value)) {
      return undefined;
    }

    return value;
  });

  return sha256(json);
};

/**
 * Deletes text in given range
 * @param editor Quill editor ref
 * @param range Quill RangeStatic
 */
export const deleteRange = (editor: Quill, range: RangeStatic) => {
  const deleteDelta = new Delta().retain(range.index).delete(range.length);
  editor.updateContents(deleteDelta, 'user');
  editor.setSelection(range.index, 0);
};

/**
 * Divides text into the parts
 * @param text target text
 * @param parts parts count
 * @returns {string[]} - array of parts
 */
export const divideText = (text: string, parts: number): string[] => {
  const size = Math.ceil(text.length / parts);
  const result: string[] = [];

  for (let index = 0; index < parts; index++) {
    const startIndex = size * index;
    const lastIndex = index === parts - 1 ? text.length : size * (index + 1);
    const part = text.slice(startIndex, lastIndex);
    result.push(part);
  }

  return result;
};

const delay = (ms: number): Promise<void> => new Promise(res => setTimeout(res, ms));

/**
 * Animates text insert by splitting text into the chunks and putting it into the editor with delay
 * example: http://recordit.co/msywwCTKb1
 * @param text text for insert
 * @param fromPosition position to start at
 * @param editor editor ref
 */
export const animateTextInsert = async (text: string, fromPosition: number, editor: Quill) => {
  const parts = divideText(text, 4);
  let lastFrom = fromPosition;

  for (let index = 0; index < parts.length; index++) {
    const chunk = parts[index];

    // eslint-disable-next-line no-await-in-loop
    await delay(400);

    // const applyDelta = new Delta().retain(lastFrom).insert(chunk, { [QUILL_FORMAT.BACKGROUND]: '#e2fdf5' });
    const applyDelta = new Delta().retain(lastFrom).insert(chunk);
    editor.updateContents(applyDelta, 'user');
    editor.setSelection(lastFrom + chunk.length, 0, 'silent');
    lastFrom += chunk.length;
  }

  await delay(500);
  editor.formatText(fromPosition, text.length + 1, QUILL_FORMAT.BACKGROUND, false, 'silent');
};
