export const getSnippetsPageUrl = (
  appRootUrl: string,
  orgId: string | number,
  teamId: string | number,
  queryParams?: Record<string, string>,
): string => {
  let snippetsPage = `${appRootUrl}/organization/${orgId}/team/${teamId}/styleguide/snippets`;

  if (queryParams) {
    const searchParams = new URLSearchParams();
    Object.keys(queryParams).forEach(k => searchParams.append(k, queryParams[k]));
    snippetsPage += `?${searchParams.toString()}`;
  }

  return snippetsPage;
};
