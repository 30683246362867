
const digest = 'd9e55e670ddae5a73bcf909486a3157c7a714a63be898f557e0bd8973fb41dd5';
const css = `.styles-module__UrS7YZ6 {
  max-width: 129px;
}

.styles-module__XwiL84I {
  max-width: 220px;
}
`;

export default {"posInputGroup":"styles-module__UrS7YZ6","posTooltipTextWrapper":"styles-module__XwiL84I"};
export { css, digest };
  