
const digest = '1712c484a25438473dc83097a7a3cf5b34eb09a7b0bfa794064473910986672f';
const css = `.styles-module__0yrQii3 {
  position: fixed;
  z-index: 5;
  top: 0;
  right: 0;
  height: 100%;
  padding: 0;
  background: white;
  transform: translateX(100%);
  transition: transform 0.3s ease;
}

.styles-module__5k4xWHH {
  height: 100%;
  flex: 1;
  border-left: 1px solid var(--writer-graynu-2);
  overflow-y: auto;
  scrollbar-gutter: stable;
}

.styles-module__27yTOwD {
  position: fixed;
  z-index: 1;
  top: 38px;
  display: flex;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: black;
  cursor: pointer;
  transform: translate(-20px);

  &:hover {
    background-color: var(--writer-grey-1);
  }
}

.styles-module__M5WtyPR {
  transform: translateX(0%);
}
`;

export default {"drawer":"styles-module__0yrQii3","content":"styles-module__5k4xWHH","closeButton":"styles-module__27yTOwD","slideIn":"styles-module__M5WtyPR"};
export { css, digest };
  