export enum SuggestionCategoryType {
  PUNCTUATION = 'punctuation',
  PUNCTUATION_CHECKER = 'punctuation-checker',
  ACRONYM = 'acronym',
  CAPITALIZATION = 'capitalization',
  DATE_TIME = 'date-time',
  HEALTHY_COMMUNICATION = 'healthy-communication',
  CONFIDENCE = 'confidence',
  GENDER_INCLUSIVITY = 'gender-inclusivity',
  SENSITIVITY = 'sensitivity',
  DATA_LOSS_PREVENTION = 'data-loss-prevention',
  GLOBAL_COMPLIANCE = 'global-compliance',
  EMOJIS = 'emojis',
  SOCIAL = 'social',
  GRAMMAR_CHECKER = 'grammar-checker',
  SPELL_CHECKER = 'spell-checker',
  PLAGIARISM = 'plagiarism',
  CLARITY = 'clarity',
  READABILITY = 'readability',
  TERMINOLOGY = 'terminology',
  CUSTOM_RULES = 'custom-rules',
}
