import { combineReducers } from 'redux';

import authReducer from '../../redux/auth/reducer';
import appReducer from '../../redux/app/reducer';
import documentsReducer from '../../redux/documents/reducer';

export default function rootReducer() {
  return combineReducers({
    auth: authReducer,
    app: appReducer,
    documents: documentsReducer,
  });
}
