/**
 * Generic class for creating an enumerated type with a fixed set of values.
 * @template TType A string literal type representing the keys of the enumerated type.
 */
export class Enum<TType extends string> implements Iterable<TType> {
  readonly type = null as unknown as TType;

  /**
   * An array of the values in the enumerated type.
   */
  readonly list: TType[];

  /**
   * An object where the keys are the values in the enumerated type, and the values are the keys themselves.
   */
  readonly enum = {} as { [key in TType]: key };

  /**
   * Constructs an instance of Enum.
   * @param {...TType} enumList A list of values to be included in the enumerated type.
   */
  constructor(...enumList: TType[]) {
    this.list = enumList;

    enumList.forEach(val => {
      this.enum[val] = val;
    });
  }

  /**
   * Returns an iterator for the enumerated type.
   */
  [Symbol.iterator]() {
    return this.list[Symbol.iterator]();
  }

  /**
   * Checks whether a given value is a member of the enumerated type.
   * @param {(string|null|undefined)} val The value to check.
   * @returns {val is TType} True if the value is a member of the enumerated type, false otherwise.
   */
  is(val: string | null | undefined): val is TType {
    if (val === undefined || val === null) {
      return false;
    }

    // eslint-disable-next-line no-prototype-builtins
    return this.enum.hasOwnProperty(val);
  }

  /**
   * Gets the value of the enumerated type with the specified key, or returns a default value if the key is not found.
   * @param {(string|null|undefined)} val The key to look up.
   * @param {TType|((val: (string|null|undefined)) => TType)} def The default value to return if the key is not found.
   * @returns {TType} The value of the enumerated type with the specified key, or the default value.
   */
  get(val: string | null | undefined, def: TType | ((val: string | null | undefined) => TType)): TType {
    // eslint-disable-next-line no-nested-ternary
    return this.is(val) ? val : def instanceof Function ? def(val) : def;
  }

  /**
   * Matches the given type with a set of partial matchers and returns the result of the matching function, if any.
   * @param {(string|null|undefined|TType)} type The type to match.
   * @param {Partial<Record<TType, (params: TParams) => O | undefined>>} matchers A set of partial matchers to match the type against.
   * @param {TParams} params The parameters to pass to the matching function.
   * @returns {O | undefined} The result of the matching function, or undefined if no match was found.
   */
  matchPartial<O, TParams>(
    type: string | null | undefined | TType,
    matchers: Partial<Record<TType, (params: TParams) => O | undefined>>,
    params: TParams,
  ) {
    if (!this.is(type)) {
      return undefined;
    }

    return matchers[type]?.(params);
  }

  /**
   * Matches the given type with a set of matchers and returns the result of the matching function.
   * @param {TType} type The type to match.
   * @param {Record<TType, (params: TParams) => O>} matchers A set of matchers to match the type against.
   * @param {TParams} params The parameters to pass to the matching function.
   * @returns {O} The result of the matching function.
   */
  match<O, TParams>(type: TType, matchers: Record<TType, (params: TParams) => O>, params: TParams): O {
    return this.matchPartial(type, matchers, params) as O;
  }

  /**
   * Returns a partial hash for a given object.
   * @template O - The type of the object values.
   * @template TObj - The type of the object.
   * @param {TObj} obj - The object to hash.
   * @returns {TObj} - The hashed object.
   */
  // eslint-disable-next-line class-methods-use-this
  partialHash<O, TObj extends Partial<Record<TType, O>>>(obj: TObj) {
    return obj;
  }

  /**
   * Returns a hash for a given object.
   * @template O - The type of the object values.
   * @param {Record<TType, O>} obj - The object to hash.
   * @returns {Record<TType, O>} - The hashed object.
   */
  // eslint-disable-next-line class-methods-use-this
  hash<O>(obj: Record<TType, O>) {
    return obj;
  }

  /**
   * Resolves a given object to the corresponding enumeration key.
   * @param {Record<TType, () => boolean>} obj - The object to resolve.
   * @param {TType[]} [list=this.list] - The list of keys to check.
   * @returns {TType|undefined} - The resolved key or undefined if not found.
   */
  resolve(obj: Record<TType, () => boolean>, list = this.list) {
    let ret: TType | undefined;
    // we can't use find, because we need to touch every
    // branch for reactive context
    list.forEach(key => {
      if (obj[key]() && !ret) {
        ret = key;
      }
    });

    return ret;
  }
}
