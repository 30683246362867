import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, ButtonTypes, Logo, LogoVariant } from '@writerai/ui-atoms';

import { getUserData } from "../../redux/auth/actions";
import { useAuthBuffer } from "../../content/hooks/useAuthBuffer";

import loginCoverImg from "../../assets/images/loginCover.png";
import styles from "./style.module.scss";

export const LoginForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();

  const onSuccess = (token) => {
    dispatch(getUserData(false, token));
  };
  const { submit, errorState } = useAuthBuffer(onSuccess);

  useEffect(() => {
    if (errorState) {
      setIsSubmitting(false);
    }
  }, [errorState]);

  const handleSubmit = () => {
    const windowRef = window.open();
    setIsSubmitting(true);
    submit(windowRef);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper__sign}>
        <div className={styles.loginForm}>
          <Logo variant={LogoVariant.FULL} className={styles.logo} />
          <h2>
            Sign in to your account
          </h2>
          <img className={styles.loginCover} src={loginCoverImg} alt='' draggable={false} />

          <div className={styles.bottomControls}>
            <p>
              No account?{' '}
              <a href='https://writer.com/plans/?ref=contentful' target='_blank' rel='noreferrer'>
                Sign up
              </a>
            </p>
            <Button
              type={ButtonTypes.BLACK}
              content='Sign in'
              onClick={handleSubmit}
              className={styles.signInButton}
              isLoading={isSubmitting}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
