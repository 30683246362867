/*
import cmudict
import syllables

syl_dict = cmudict.dict()


WRITER_EXCEPTIONS = dict(cosine=2)


def _clean(word: str) -> str:
    word = word.lower()
    if len(word) < 2:
        return word
    # tokenization is janky, so we may have punctuation as part of the word
    if any(word[-1] == c for c in "\".,?;:!%'])}]/-—"):
        word = word[:-1]
    if any(word[0] == c for c in "\"'$@#/([{[-—"):
        word = word[1:]
    return word


def count_syllables(word: str) -> int:
    # STEP 1.
    # Do cleaning
    word = _clean(word)
    # STEP 1.5
    # Linguistic stuff
    # do hyphenated words separately
    if "-" in word and word.replace("-", "").isalpha():
        return sum([count_syllables(w) for w in word.split("-") if len(w)])
    # apostrophe stuff
    # apostrophes only sometimes add syllables
    extra_syllables = 0
    if len(word) > 2 and word[-2] == "'":
        if word[-1] == "s":
            # 's only adds when e.g. Doris's
            # Doris' trailin g' gets cleaned off
            extra_syllables += 1 if word[-3] == "s" else 0
        elif word[-3] not in "aeiouy":
            extra_syllables += 1
        word = word[:-2]
    elif len(word) > 3 and word[-3] == "'":
        # ya'll (1) vs Sam'll (2)
        # also, is we're 1 or 2, WEE-uhr or WEER ?
        word = word[:-3]
        if word[-1] not in "aeiouy":
            extra_syllables += 1
    # STEP 2.
    # Check our own exception list
    if WRITER_EXCEPTIONS.get(word, False):
        return WRITER_EXCEPTIONS[word] + extra_syllables
    # STEP 3.
    # Check CMU dict, if miss, return regex estimate from syllables package
    v = syl_dict[word]
    if len(v) == 0:
        # this dict is dumb and lies
        # it says it has every word, but does not
        # when there isn't an entry, it returns []
        # so we fall back to this rule-based method:
        return syllables.estimate(word) + extra_syllables
    # Step 4.
    # Count syllables from CMU pronunciation
    # this will look like [['P', 'R', 'OW1', 'P', 'EY2', 'N']]
    # counting syllables is equivalent to counting stressed syllables
    # which are the strings ending in a digit
    stressed_syllables = [x for x in v[0] if x[-1].isdigit()]
    return len(stressed_syllables) + extra_syllables
*/
import { cmudict as sylDict } from '@writerai/cmudict';
import { estimate } from './syllable';

const WRITER_EXCEPTIONS: Partial<Record<string, number>> = { cosine: 2 };

const LAST_CHARS_LIST = '".,?;:!%\'])}]/-—';
const FIRST_CHARS_LIST = '"\'$@#/([{[-—';

function clean(aWord: string): string {
  let word = aWord.toLowerCase();

  if (word.length < 2) {
    return word;
  }

  if (LAST_CHARS_LIST.includes(word[word.length - 1])) {
    word = word.slice(0, word.length - 1);
  }

  if (FIRST_CHARS_LIST.includes(word[0])) {
    word = word.slice(1);
  }

  return word;
}

/**
 * Counts the number of syllables in a given word.
 *
 * @param {string} aWord - The word to count the syllables of.
 * @returns {number} - The estimated number of syllables in the word.
 */
export function countSyllables(aWord: string): number {
  // STEP 1.
  // Do cleaning
  let word = clean(aWord);

  // STEP 1.5
  // Linguistic stuff
  // do hyphenated words separately
  if (word.includes('-') && /^[a-z\\-]+$/.test(word)) {
    const sum = word
      .split('-')
      .map(str => (!str.length ? 0 : countSyllables(str)))
      .reduce((sum, num) => sum + num, 0);

    return sum;
  }

  // apostrophe stuff
  // apostrophes only sometimes add syllables
  let extraSyllables = 0;

  if (word.length > 2 && word[word.length - 2] === "'") {
    if (word[word.length - 1] === 's') {
      // 's only adds when e.g. Doris's
      // Doris' trailin g' gets cleaned off
      if (word[word.length - 3] === 's') {
        extraSyllables += 1;
      }
    } else if (!'aeiouy'.includes(word[word.length - 3])) {
      extraSyllables += 1;
    }

    word = word.slice(0, word.length - 2);
  } else if (word.length > 3 && word[word.length] === "'") {
    // ya'll (1) vs Sam'll (2)
    // also, is we're 1 or 2, WEE-uhr or WEER ?
    word = word.slice(0, word.length - 3);

    if ('aeiouy'.includes(word[word.length - 1])) {
      extraSyllables += 1;
    }
  }

  // STEP 2.
  // Check our own exception list
  const except = WRITER_EXCEPTIONS[word];

  if (except) {
    return except + extraSyllables;
  }

  // STEP 3.
  // Check CMU dict, if miss, return regex estimate from syllables package
  /*
    v = syl_dict[word]
    if len(v) == 0:
      # this dict is dumb and lies
      # it says it has every word, but does not
      # when there isn't an entry, it returns []
      # so we fall back to this rule-based method:
      return syllables.estimate(word) + extra_syllables
  */
  const v = sylDict[word];

  if (!v) {
    // this dict is dumb and lies
    // it says it has every word, but does not
    // when there isn't an entry, it returns []
    // so we fall back to this rule-based method:
    return estimate(word) + extraSyllables;
  }

  // Step 4.
  // Count syllables from CMU pronunciation
  // this will look like [['P', 'R', 'OW1', 'P', 'EY2', 'N']]
  // counting syllables is equivalent to counting stressed syllables
  // which are the strings ending in a digit
  /*
  stressed_syllables = [x for x in v[0] if x[-1].isdigit()]
  return len(stressed_syllables) + extra_syllables
  */
  const isDigit = /\d/;
  const stressedSyllables = v[0].reduce((memo, x) => {
    if (isDigit.test(x[x.length - 1])) {
      memo.push(x);
    }

    return memo;
  }, [] as string[]);

  return stressedSyllables.length + extraSyllables;
}
