import types from './constants';

export default function documentsReducer(
  state = {
    issues: [],
    selectedIssueId: '',
    issuesUpdated: false,
    categoryChanged: false
  },
  { type, payload },
) {
  switch (type) {
    case types.SET_ISSUES: {
      const {
        issues,
      } = payload;

      return {
        ...state,
        issues
      };
    }

    case types.SELECT_ISSUE: {
      const {
        selectedIssueId,
      } = payload;

      return {
        ...state,
        selectedIssueId
      };
    }

    case types.SET_CATEGORY: {
      const {
        categoryChanged,
      } = payload;

      return {
        ...state,
        categoryChanged
      };
    }

    default:
      return state;
  }
};
