import React from 'react';
import { Text, TextSize, Icon, IconVariant, Button, ButtonTypes } from '@writerai/ui-atoms';

import styles from './styles.module.css';
import { ExpiredPanel } from '../ExpiredPanel';

interface IFreeCancelledProps {
  isOrgAdmin?: boolean;
  onClickHref?: string;
}

export const FreeCancelled = ({ isOrgAdmin, onClickHref }: IFreeCancelledProps) => (
  <ExpiredPanel
    header="Activate our Free plan"
    description="Your 14-day Writer trial has expired. But we have some good news — we now offer a Free plan! Activate Free
          below to continue using Writer."
  >
    {isOrgAdmin && (
      <Button
        className={styles.cancelledAction}
        type={ButtonTypes.PRIMARY}
        content="Activate Free"
        navTo={onClickHref}
        navTarget="_blank"
      ></Button>
    )}
    <ExpiredPanel.List>
      {['Autocorrect', 'Grammar', 'Spelling', 'Punctuation', 'Data security'].map(feature => (
        <Text variant={TextSize.M}>
          <Icon name={IconVariant.GREEN1_CHECKMARK} width={14} height={14} />
          {feature}
        </Text>
      ))}
    </ExpiredPanel.List>
  </ExpiredPanel>
);
