/**
 * Returns currently selected text from specified node
 * @param {HTMLElement} node
 * @param {boolean} asHtml
 * @return {string | null}
 */

export function getTextSelectionFromNode(node: HTMLElement | null, asHtml = true): string | null {
  const selection = window.document.getSelection();

  if (!node || !selection || selection.type !== 'Range') {
    return null;
  }

  const range = selection.getRangeAt(0);
  const isNodeFullyContainsSelection = node.parentNode?.contains(range.commonAncestorContainer);
  const isSelectionPartiallyContainsNode = selection.containsNode(node, true);

  if (range && isNodeFullyContainsSelection && isSelectionPartiallyContainsNode) {
    if (asHtml) {
      const serializer = new XMLSerializer();
      const contents = selection.getRangeAt(0).cloneContents();
      selection.removeAllRanges();

      return serializer.serializeToString(contents);
    } else {
      return selection.toString();
    }
  } else {
    return null;
  }
}
