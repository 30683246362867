export enum SortFieldTypes {
  CREATION_TIME = 'creationTime',
  MODIFICATION_TIME = 'modificationTime',
  TITLE = 'title',
  BODY = 'body',
  TERM = 'term',
  TYPE = 'type',
  OPENED_BY_ME_TIME = 'openedByMeTime',
  LAST_SEEN = 'lastSeen',
}

export enum SortOrders {
  ASC = 'asc',
  DESC = 'desc',
}
