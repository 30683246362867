/* eslint-disable @typescript-eslint/no-explicit-any */
import type { ISidebarCategory } from './category.types';
import type { ITermExampleBase } from './terms.types';

/**
 * Categories that Issues will be place in
 * Note: The cateogies are fixed between BE and FE so please make sure to check with BE team
 * before making any changes below
 * */
export enum IssueCategory {
  Formality = 'formality',
  CommonMistakes = 'common-mistakes',
  BannedWords = 'banned-words',
  PassiveVoice = 'passive-voice',
  Grammar = 'grammar',
  Gec = 'gec',
  GecGpt3 = 'gec-gpt3',
  Spelling = 'spelling',
  Formatting = 'formatting',
  Acronym = 'acronym',
  OxfordComma = 'oxford-comma',
  Twitter = 'twitter',
  Emojis = 'emojis',
  Gender = 'gender-tone',
  Sensitivity = 'sensitivity',
  Plagiarism = 'plagiarism',
  Liveliness = 'liveliness',
  SentenceComplexity = 'sentence-complexity',
  Vocabulary = 'vocabulary',
  ParagraphLength = 'paragraph-length',
  PlainLanguage = 'plain-language',
  HealthyCommunication = 'healthy-commn',
  Confidence = 'confidence',
  DataLossPrevention = 'data-loss-prevention',
  HateSpeech = 'hate-speech',
  Feedback = 'feedback',
  MlPunctuation = 'ml-punctuation',
  Infinitive = 'infinitive',
  Readability = 'readability',
  UseCarefully = 'use-carefully',

  // issue categories that doesn't belong to any sidebar category
  Dictionary = 'dictionary',
  Claim = 'claim',
  Quote = 'quote',
}

export enum IssueType {
  SPELLING = 'spelling',
  GRAMMAR = 'grammar',
  PASSIVE_VOICE = 'passive-voice',
  FORMALITY = 'formality',
  LIVELINESS = 'liveliness',
  STYLE = 'style',
  EMOJIS = 'emojis',
  GENDER_TONE = 'gender-tone',
  CLARITY = 'clarity',
  COMMON_MISTAKES = 'common-mistakes',
  BANNED_WORDS = 'banned-words',
  PLAIN_LANGUAGE = 'plain-language',
  DATA_LOSS_PREVENTION = 'data-loss-prevention',
  CONTENT_SAFEGUARDS = 'hate-speech',
  FORMATTING = 'formatting',
  TWITTER = 'twitter',
  DICTIONARY = 'dictionary',
  APPROVED = 'approved',
  Inclusivity = 'inclusivity',
  SENSITIVITY = 'sensitivity',
  ACRONYM = 'acronym',
  OXFORD_COMMA = 'oxford-comma',
  UNCLEAR_REFERENCES = 'unclear-references',
  READABILITY = 'readability',
  CLAIM = 'claim',
  QUOTE = 'quote',
  USE_CAREFULLY = 'use-carefully',
  ANY = '*',
}

export type IIssuePlagiarismMatches = {
  similarity: number;
  title: string;
  url: string;
  snippet?: string;
};

export interface IIssueMeta {
  /* subcategories defined in https://qordoba.atlassian.net/wiki/spaces/HOME/pages/979763466/Score+Categories+mapping+to+Scorecards */
  subCategory?: string;
  ruleId?: string;
  category?: TContentScoreRuleCategories;
  posTag?: string;
  pos?: string;
  examples?: ITermExampleBase[];
  /* Header to show instead of the highlight for cases like `complex sentence` */
  header?: 'Complex sentence' | 'Readability';
  /* Which popup to show on click of Learn More button of IssueCard */
  learnMore?: LearnMoreModalType;
  /* Todo: find out what is these two */
  showHighlight?: boolean;
  /* show highlighted paragraph when this boolean is true */
  paragraph?: boolean;
  /* Show/hide Addtoterms button on IssueCard */
  addToTerms?: boolean;
  /* Show/hide AcceptAllChanges button on IssueCard */
  acceptAllChanges?: boolean;
  /* Displays the similarity data for Plagiarism card */
  matches?: Array<IIssuePlagiarismMatches>;
  /* Issue is in beta */
  isBeta?: boolean;
}

// base issue, (currently just w\o category and length which is calculated on the FE)
export interface IFirebaseIssue {
  issueId: string;
  /* which BE service detected the issue */
  issueType: IssueType;
  /* the text that will be shown to a user to describe what the issue is */
  description?: string;
  /* small description to be shown when card is collapsed */
  simpleDescription?: string;
  /* at what index of segment's text the issue starts */
  from: number;
  /* at what index of segment's text the issue ends */
  until: number;
  /* category id of the issue */
  category: IssueCategory;
  /* whether the issue should be hidden from user as it has been whitelisted (for example when issue contains an ignored word from term bank) */
  whitelisted?: any;
  /* suggestions of how to fix the issue, we are currently using only the first one */
  suggestions: string[];
  /* metainformation about the issue, like subcategory, grammar rules info and so on */
  meta?: IIssueMeta;
  /* Issues that comes for beta categories */
  isBeta: boolean;
  /* adds highlight to Issues using the editor content */
  highlight: string;
  requestId: string;
  timestamp: number;
  version: number;
}

export interface IIssueCard {
  /** Category title of the the card */
  title?: string;
  /** Dot color next to the card category name */
  titleColor?: string;
  /** category of the issue */
  category?: string;
  /** Meta prop header which exists to describe the type of issue */
  header?: string;
  /** show/hide the beta label for a category */
  isBeta?: boolean;
  /** shows highlighted text derived from segment + from & until */
  highlight?: string;
  /** shows a subtitle next to highlight. essentially for part-of-speech */
  subHighlight?: string;
  /** `suggestions` prop from api which shows the possible corrections */
  correction?: Array<string>;
  /** description of the issues */
  description?: string;
  /** examples array to be shown after description */
  examples?: Array<ITermExampleBase>;
  /** description to be shown when card is collapsed */
  simpleDescription?: string;
  /** learn more button triggers this type of modal */
  learnMore?: LearnMoreModalType;
  /** shows the card UI in collapsed/uncollapsed form. Default: false */
  collapsed?: boolean;
  /** shows the correction in form of paragraph highlight */
  paragraph?: boolean;
  /** show/hide the accept all changes button for replacing the entire paragraph */
  acceptAllChanges?: boolean;
  /** show/hide add to term bank button */
  addTermAvailable?: boolean;
  /** array of plagiarism data */
  plagiarismMatches?: Array<IIssuePlagiarismMatches>;
  isBulkAcceptAvailable?: boolean;
  isBulkIgnoreAvailable?: boolean;
  similarIssues?: IIssue[];
  onClick?: unknown;
  onDeleteIssueClick?: () => void;
  onBulkDeleteIssues?: () => void;
  onApplySuggestion?: (replacement: string) => Promise<any>;
  onAddToTermBankClick?: (term: string) => void;
  onMarkIssueAsWrong: (comment?: string | null) => void;
  onMarkIssueAsWrongCommentStateChange?: (isOpen: boolean) => void;
  onBulkAcceptSuggestions?: (replacement: string) => void;
  onLearnMoreClicked?: (modalType: LearnMoreModalType) => void;
}

// full issue object
export interface IIssue extends IFirebaseIssue {
  sidebarCategory?: ISidebarCategory;
  length: number;
  segment: string;
}

export interface IIssueStatusPayload extends IIssue {
  issueId: string;
  state: IssueFlag;
  stateDescription: string;
}

export enum IssueCardTypes {
  DEFAULT,
  ACCEPT_ALL_CHANGES = 'accept-all-changes',
  CORRECTION = 'correction',
  SUGGESTION = 'suggestion',
  HEADER_ONLY = 'header-only',
  STRIKETHROUGH_HIGHLIGHT = 'strikethrough-only',
}

export enum LearnMoreModalType {
  PASSIVE_VOICE = 'PASSIVE-VOICE',
  DISABILITY_PEOPLE_FIRST = 'DISABILITY_PEOPLE_FIRST',
  DISABILITY_NEUTRAL_LANGUAGE = 'DISABILITY_NEUTRAL_LANGUAGE',
}

export enum IssueCardType {
  INLINE = 'INLINE',
  SIDEBAR = 'SIDEBAR',
}

export enum IssueFlag {
  WRONG = 'wrong',
  IGNORE = 'reject',
  ACCEPT = 'accept',
  UNCLEAR = 'unclear',
  INAPPROPRIATE = 'inappropriate',
}

type TContentScoreRuleCategories = 'grammar' | 'capitalization' | 'punctuation' | 'datetime';

export enum IssuesPipeline {
  BASIC = 'basic',
  PLAGIARISM = 'plagiarism',
  FULL = 'full',
  CLAIM = 'claim',
}
