
const digest = '13a13c12438e865b56b53d31cb8f67b026a970bc9beba16ed220b0d0ec0472e0';
const css = `.styles-module__BMIWifF {
  display: flex;
  width: 100%;
  height: calc(100% - 50px);
  flex-direction: column;
}

.styles-module__JlCegU7 {
  padding-right: 4px;
  padding-left: 7px;
}

.styles-module__A9SQ--p {
  margin-top: 19px;
}

.styles-module__7VfAA3n {
  position: relative;
  display: flex;
  max-width: 320px;
  flex-direction: column;
  align-items: center;
  align-self: center;
  margin: 110px 20px 0;
  text-align: center;
}

.styles-module__H7TZuLP {
  padding: 12px 0 8px;
  text-align: center !important;
}

.styles-module__PA3-qX0 {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
}

.styles-module__pIpzgS5 {
  border-radius: 28px !important;
  background: var(--writer-graynu-2) !important;
}

.styles-module__pIpzgS5:first-child {
  margin-right: 11px;
}
`;

export default {"container":"styles-module__BMIWifF","containerMini":"styles-module__JlCegU7","enterpriseContainer":"styles-module__A9SQ--p","noIssuesContainer":"styles-module__7VfAA3n","noIssuesTitle":"styles-module__H7TZuLP","noIssuesContainerLoaderContainer":"styles-module__PA3-qX0","noIssuesContainerLoader":"styles-module__pIpzgS5"};
export { css, digest };
  