/**
 * JSX Function to switch between JSX tags
 * Eg:
 *  {rswitch(type, {
 *     'suggestion': <Suggestion />,
 *          default: <div><div/>,
 *  })}
 * @param param: the key which will switch between cases
 * @param cases: Object with cases including default
 * @returns {any}
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const rswitch = (param: any, cases: any) => {
  if (cases[param]) {
    return cases[param];
  }

  return cases.default;
};
