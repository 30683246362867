import React, { useCallback } from 'react';
import cx from 'classnames';
import { observer } from 'mobx-react-lite';
import { BillingProduct, CategoryType, SidebarCategoryStatus } from '@writerai/types';
import { AnalyticsService } from '@writerai/analytics';
import { SkeletonLoader, SkeletonLoaderType } from '@writerai/ui-atoms';
import styles from './styles.module.css';
import { UIIssuesListModel } from './UIIssuesListModel';
import { PaidFeature } from '../../molecules/PaidFeature';

import { NoIssues, NoIssuesVariant } from '../../molecules/NoIssues';
import { EnterpriseCancelled } from '../../molecules/EnterpriseCancelled';
import { TrialExpired } from '../../molecules/TrialExpired';
import { PlanCancelled } from '../../molecules/PlanCancelled';
import { FreeCancelled } from '../../molecules/FreeCancelled';
import { VisibleIssuesList } from '../VisibleIssuesList';

export { UIIssuesListModel };

export interface IssuesListProps {
  model: UIIssuesListModel;
}

const fromParamConfig = {
  [CategoryType.COMPLIANCE]: 'sidebar_compliance',
  [CategoryType.PLAGIARISM]: 'sidebar_plagiarism',
  [CategoryType.CLARITY]: 'sidebar_clarity',
  [CategoryType.INCLUSIVITY]: 'sidebar_inclusivity',
  [CategoryType.STYLE]: 'sidebar_style',
  [CategoryType.TERMS]: 'sidebar_terms',
  [CategoryType.DELIVERY]: 'sidebar_delivery',
} as const;

const UnlockedState: React.FC<{ isShrinked?: boolean }> = ({ children, isShrinked }) => (
  <div
    className={cx(styles.container, {
      [styles.containerMini]: isShrinked,
    })}
  >
    {children}
  </div>
);

export const IssuesList = observer<IssuesListProps>(({ model }) => {
  const {
    analytics,
    selectedCategory,
    isStandard,
    isSmallPadding,
    isLoading,
    isEmptyState,
    isEnterprisePlanCancelled,
    isNoIntegration,
    isTeamTrialExpired,
    isOrgAdmin,
    billingPageHref,
    isTeamPlanCancelled,
    isFreeCancelled,
    activateFreeHref,
    paidFeatureHref,
    currentSidebarIssues,
    modelVisibleIssuesList,
  } = model;

  const onPaidFeatureClick = useCallback(() => {
    if (!selectedCategory) {
      return;
    }

    const activity =
      selectedCategory?.billingProduct === BillingProduct.TEAM
        ? AnalyticsService.EV.startTeamTrial
        : AnalyticsService.EV.purchasePro;
    analytics.track(activity, {
      clicked_from: fromParamConfig[selectedCategory.id as unknown as keyof typeof fromParamConfig],
    });

    window.open(paidFeatureHref, '_blank');
  }, [analytics, selectedCategory, paidFeatureHref]);

  const renderUnlockedStateContent = () => {
    if (isLoading) {
      return (
        <SkeletonLoader
          type={SkeletonLoaderType.ISSUE_CARD}
          repeat={9}
          className={cx(styles.issuesListSkeleton, { [styles.issuesListSkeletonWide]: isSmallPadding })}
        />
      );
    }

    if (isEmptyState) {
      if (isEnterprisePlanCancelled) {
        return <EnterpriseCancelled />;
      } else if (isTeamTrialExpired) {
        return <TrialExpired isOrgAdmin={isOrgAdmin} onClickHref={billingPageHref} />;
      } else if (isTeamPlanCancelled) {
        return <PlanCancelled isOrgAdmin={isOrgAdmin} onClickHref={billingPageHref} />;
      } else if (isFreeCancelled) {
        return <FreeCancelled isOrgAdmin={isOrgAdmin} onClickHref={activateFreeHref} />;
      } else if (isNoIntegration) {
        return <NoIssues variant={NoIssuesVariant.enum.noIntegration} />;
      } else {
        return <NoIssues variant={NoIssuesVariant.enum.emptyDocument} />;
      }
    }

    if (!currentSidebarIssues.length) {
      return <NoIssues variant={NoIssuesVariant.enum.noIssuesFound} description={selectedCategory ? ' ' : ''} />;
    }

    return <VisibleIssuesList model={modelVisibleIssuesList} isSmallPadding={isSmallPadding} />;
  };

  return selectedCategory?.status === SidebarCategoryStatus.LOCKED ? (
    <div className={styles.enterpriseContainer}>
      <PaidFeature selectedCategory={selectedCategory} onClick={onPaidFeatureClick} />
    </div>
  ) : (
    <UnlockedState isShrinked={!isStandard}>{renderUnlockedStateContent()}</UnlockedState>
  );
});
