
const digest = 'b21ec7aee38874a686d7528fb4eac52ae645da7953f0271dbeec941c1aa6f22d';
const css = `.styles-module__yT8LGis {
  word-break: break-word;
}

.styles-module__Xag0n96 {
  position: absolute;
  left: 0;
  width: 100%;
  height: 26px;
}
`;

export default {"correctionLabel":"styles-module__yT8LGis","correctionLabelShadow":"styles-module__Xag0n96"};
export { css, digest };
  