import hexToRgba from 'hex-to-rgba';
import Quill from 'quill';
import { _omit } from 'quill-better-table/src/utils';

import { searchForShiftedIssue } from "./editorUtils";

const Delta = Quill.import('delta');

export const findObject = (array, id) => {
  return array.find(workspace => {
    return workspace.organizationId === id;
  });
};

export const findTeam = (array, id) => {
  return array.find(team => {
    return team.id === id;
  });
};

export const filterTeams = (array, id) => {
  return array.filter(team => {
    return team.organizationId === id;
  });
};

export const highlightWord = (issueId, color) => {
  let selectedIssues = document.querySelectorAll(`span[data-value]`);
  selectedIssues = [...selectedIssues].filter(e => e.dataset.value.includes(issueId));

  const oldIssues = document.querySelectorAll('.focused__issue');
  const unborderedIssues = document.querySelectorAll('span[unborderedIssue]');

  if (unborderedIssues) {
    [...unborderedIssues].forEach(issue => {
      // issue.style.borderBottom = `2px solid ${issue.getAttribute('unborderedIssue')}`;
      issue.removeAttribute('unborderedIssue');
    })
  }

  if (oldIssues) {
    [...oldIssues].forEach(oldIssue => {
      const elementId = oldIssue.getAttribute('data-id');
      oldIssue.classList.remove('focused__issue');
      oldIssue.classList.remove(`focused__issue-style_${elementId}`);
    })
  }

  if (selectedIssues) {
    selectedIssues.forEach((selectedIssue, index) => {
      if (selectedIssues.length && index !== selectedIssues.length - 1) {
        if (selectedIssue.nextSibling?.classList) {
          const { nextSibling } = selectedIssue;

          if (nextSibling.style.borderBottom) {
            nextSibling.setAttribute('unborderedIssue', nextSibling.style.borderBottom.split('solid')[1].slice(1));
            nextSibling.classList.add('focused__issue');
            setupStyleForElement(nextSibling, color, 'background-color');

            // nextSibling.style.borderColor = selectedIssue.style.borderBottom.split('solid')[1].slice(1);
          }
        }
      }

      selectedIssue.classList.add('focused__issue');
      setupStyleForElement(selectedIssue, color, 'background-color');
    });
  }
};

const setupStyleForElement = (element, color, styleName) => {
  const elementId = element.getAttribute('data-id');
  if (elementId && color) {
    const formattedColor = color.includes('rgb') ? `${color.slice(0, -1)}, 0.4)` : hexToRgba(color, 0.4);
    const styleElement = document.createElement('style');
    const className = `focused__issue-style_${elementId}`;
    styleElement.textContent = `.${className} { ${styleName}: ${formattedColor}; }`;
    document.head.appendChild(styleElement);
    element.classList.add(className);
  }
};

const isElementInViewport = (el) => {
  const rect = el.getBoundingClientRect();

  return (
    rect.top - 110 >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= document.documentElement.clientHeight - 50 &&
    rect.right <= document.documentElement.clientWidth
  );
};

export const scroll = (issueId, color, from, editor, forceHighlight = false) => {
  if (forceHighlight || editor?.hasFocus() === false) {
    const elements = editor.root.querySelectorAll(`.issue-${issueId}`);

    if (elements.length && !isElementInViewport(elements[0])) {
      elements[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  highlightWord(issueId, color);
};

export const changeIssue = (issue, type, quillRef, forceHighlight = false) => {
  if (quillRef.current) {
    const { editor } = quillRef.current;
    let newIssue = searchForShiftedIssue(editor, issue);

    if (!newIssue) {
      newIssue = issue;
    }

    const { from, length, sidebarCategory, issueId } = newIssue;

    switch (type) {
      case 'scroll':
        scroll(issueId, sidebarCategory?.color, from, editor, forceHighlight);

        break;

      case 'delete':
        editor.formatText(from, length, 'QA_TEXTHIGHLIGHT_FORMAT_NAME', false, 'api');

        break;

      default:
        editor.formatText(from, length, 'QA_TEXTHIGHLIGHT_FORMAT_NAME', newIssue, 'api');

        break;
    }
  }
};

export const deleteHighlight = (issue, qullEditor) => {
  let spans = document.querySelectorAll(`span[data-value]`);
  spans = [...spans].filter(e => e.dataset.value.includes(issue.issueId));

  if (spans) {
    const userSelection = qullEditor?.getSelection();
    spans.forEach((span) => {
      let spanData = span.getAttribute('data-value').split(',');

      spanData = spanData.filter(data => {
        return data !== issue.issueId
      });

      if (spanData.length === 0) {
        let { parentNode } = span;

        while (span.firstChild) {
          parentNode.insertBefore(span.firstChild, span);
          parentNode.removeChild(span);
        }
      } else {
        span.setAttribute("data-value", spanData.join());
        span.setAttribute("data-id", spanData[0]);
      }
    });
    setTimeout(() => {
      qullEditor?.setSelection(userSelection);
    }, 0);
  }
};

export function matchTableHeader(node, delta) {
  const row = node.parentNode;
  const table = row.parentNode.tagName === 'TABLE'
    ? row.parentNode
    : row.parentNode.parentNode;
  const rows = Array.from(table.querySelectorAll('tr'));
  const cells = Array.from(row.querySelectorAll('th'));
  const rowId = rows.indexOf(row) + 1;
  const cellId = cells.indexOf(node) + 1;
  const colspan = node.getAttribute('colspan') || false
  const rowspan = node.getAttribute('rowspan') || false
  const cellBg = node.getAttribute('data-cell-bg')

  // bugfix: empty table cells copied from other place will be removed unexpectedly
  if (delta.length() === 0) {
    delta = new Delta().insert('\n', {
      'table-cell-line': { row: rowId, cell: cellId, rowspan, colspan, 'cell-bg': cellBg }
    })
    return delta
  }

  delta = delta.reduce((newDelta, op) => {
    if (op.insert && typeof op.insert === 'string') {
      const lines = []
      let insertStr = op.insert
      let start = 0
      for (let i = 0; i < op.insert.length; i++) {
        if (insertStr.charAt(i) === '\n') {
          if (i === 0) {
            lines.push('\n')
          } else {
            lines.push(insertStr.substring(start, i))
            lines.push('\n')
          }
          start = i + 1
        }
      }

      const tailStr = insertStr.substring(start)
      if (tailStr) lines.push(tailStr)

      // bugfix: no '\n' in op.insert, push a '\n' to lines
      if (lines.indexOf('\n') < 0) {
        lines.push('\n')
      }

      lines.forEach(text => {
        text === '\n'
          ? newDelta.insert('\n', { 'table-cell-line': { row: rowId, cell: cellId, rowspan, colspan, 'cell-bg': cellBg } })
          : newDelta.insert(text, op.attributes)
      })
    } else {
      newDelta.insert(op.insert, op.attributes)
    }

    return newDelta
  }, new Delta())

  return delta.reduce((newDelta, op) => {
    if (op.insert && typeof op.insert === 'string' &&
      op.insert.startsWith('\n')) {
      newDelta.insert(op.insert, Object.assign(
        {},
        { 'table-cell-line': { row: rowId, cell: cellId, rowspan, colspan, 'cell-bg': cellBg } }
      ))
    } else {
      newDelta.insert(op.insert, Object.assign(
        {},
        _omit(op.attributes, ['table', 'table-cell-line'])
      ))
    }

    return newDelta
  }, new Delta());
}
