import { Modal } from "@contentful/f36-modal";

import ControlButtons from "../../common/ControlButtons";

import close from "../../assets/icons/close.svg";

import styles from "./style.module.scss";

const CloseModal = ({ isModalShown, changeExitModal, closeDialog }) => (
  <>
    <Modal
      onClose={changeExitModal}
      isShown={isModalShown}
      className={styles.modal}
      size={350}
    >
      <div className={styles.modal__header}>
        <button onClick={changeExitModal}>
          <img src={close} alt="close" />
        </button>
      </div>
      <h4>Do you want to save changes before closing?</h4>
      <ControlButtons closeDialog={closeDialog} />
    </Modal>
  </>
);

export default CloseModal;
