export enum TermType {
  APPROVED = 'approved',
  USE_CAREFULLY = 'useCarefully',
  BANNED = 'banned',
  PENDING = 'pending',
}

export enum PartOfSpeech {
  DEFAULT = '-',
  NOUN = 'noun',
  ADJECTIVE = 'adjective',
  VERB = 'verb',
  ADVERB = 'adverb',
}

export enum TermExampleType {
  Good = 'good',
  Bad = 'bad',
}

export enum CaseSensitiveOptions {
  YES = 'yes',
  NO = 'no',
}

export interface ITermExample extends ITermExampleBase {
  id: number;
  termId: number;
}

export interface ITermMistake extends ITermMistakeBase {
  id: number;
  termId: number;
}

export interface ILinkedTermBE {
  reference: string;
}

export interface ILinkedTerm {
  id: number;
  termId: ITerm['id'];
  linkedTermId: ITerm['id'];
  type: ITerm['type'];
  term: ITerm['term'];
  pos?: ITerm['pos'];
}

export interface ITermTag {
  id: number;
  tag: string;
  termId: number;
  parentTagId: number;
}

export interface ITermUser {
  id: number;
  fullName?: string;
  email: string;
}

export interface ITermBaseProps {
  id: number;
  description: string;
  creationTime: string;
  modificationTime: string;
  createdUser: ITermUser;
  modifiedUser: ITermUser;
  tags: ITermTag[];
}

export interface IApprovedTermExtension {
  capitalize: boolean;
  fixCommonMistakes: boolean;
  fixCase: boolean;
}

export interface ITerm extends ITermBaseProps {
  termBankId: number;
  term: string;
  type: string;
  pos: PartOfSpeech;
  caseSensitive: boolean;
  examples: ITermExample[];
  mistakes: ITermMistake[];
  modificationPersonTime: string;
  highlight: boolean;
  linkedTerms: ILinkedTerm[];
  backlinkedTerms: ILinkedTerm[];
  approvedTermExtension?: IApprovedTermExtension;
}

export interface ICreateTerm {
  models: ITerm[];
}

export interface TableColumnTerm extends ITerm {
  modificationInfo?: string;
}

export interface ITermsTagsListResponse {
  tags: string[];
}

export type ITermMistakeBaseProps = {
  isValid: boolean;
  validationMessage: string;
};

export interface ITermExampleBase {
  example: string;
  type: TermExampleType;
  _error?: string;
}

export interface ITermMistakeBase {
  mistake: string;
  caseSensitive: boolean;
  pos?: PartOfSpeech;
  _props?: ITermMistakeBaseProps;
}

export interface CommonMistakeDataItem extends ITermMistakeBase {
  id: number;
  pos?: PartOfSpeech;
  _error?: string;
}

export interface ITermCreateAndUpdate {
  id?: number;
  reference?: string;
  pos?: PartOfSpeech;
  tags: ITermTag[];
  term: string;
  type: string;
  caseSensitive: boolean;
  highlight: boolean;
  backlinkedTerms: ILinkedTerm[];
  description?: string;
  approvedTermExtension?: IApprovedTermExtension;
  examples: ITermExampleBase[];
  mistakes: ITermMistakeBase[];
  linkedTerms: ILinkedTermBE[];
}

export interface ICreateTermsRequest {
  models: ITermCreateAndUpdate[];
}

export interface ITermImportFail {
  description: string;
  extras: {
    conflict: {
      linkType: string;
      reference?: string | null;
      term: string;
    };
    item: {
      linkType: string;
      reference?: string | null;
      term: string;
    };
  };
  key: string;
}

export interface ICreateTermsWithFails {
  models: ITerm[];
  fails: ITermImportFail[];
}

export interface MultiFieldError {
  message: string;
  term?: string;
  fieldPosition?: number;
}

export enum TermsPopupFields {
  TERM,
  TERM_TYPE,
  POS,
  CASE_SENSITIVE,
  DESCRIPTION,
  EXAMPLE,
  COMMON_MISTAKE,
  TAGS,
  ENABLE_SUGGESTIONS,
  CAPITALIZE,
  SUGGESTED_APPROVED_TERMS,
  FIX_CASE,
  FIX_COMMON_MISTAKES,
}

/* Todo: this should extend from ITerm or ITermCreateAndUpdate. Find the diff */
export interface TAddTermsState {
  id: number;
  term: string;
  termType?: TermType;
  partOfSpeech: PartOfSpeech;
  caseSensitive: boolean;
  description: string;
  example: ITermExampleBase[];
  commonMistake: CommonMistakeDataItem[];
  tags: ITermTag[];
  isLoading?: boolean;
  termError?: string;
  descriptionError?: string;
  examplesError?: MultiFieldError;
  mistakesErrors: MultiFieldError[];
  tagsError?: string;
  enableSuggestions: boolean;
  capitalize: boolean;
  fixCase: boolean;
  fixCommonMistakes: boolean;
  linkedTerms: ILinkedTerm[];
  backlinkedTerms: ILinkedTerm[];
}
