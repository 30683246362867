import React from 'react';
import { ExpiredPanel } from '../ExpiredPanel';

export const EnterpriseCancelled = () => (
  <ExpiredPanel
    header="Your Enterprise plan was cancelled"
    description={
      <>
        Contact Writer Support at{' '}
        <a href="mailto:support@writer.com" target="_blank">
          support@writer.com
        </a>{' '}
        to resubscribe and reactivate Writer.{' '}
      </>
    }
  />
);
