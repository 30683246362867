export enum SharedQueryParam {
  INVALID_SSO_TOKEN = 'invalidToken',
  CLIENT_ID = 'client_id',
  CODE_CHALLENGE = 'code_challenge',
  CODE_CHALLENGE_METHOD = 'code_challenge_method',
  WRITE_KEY = 'write_key',
  REDIRECT_URL = 'redirect_url',
  REDIRECT_URL_CASE = 'redirectUrl',
  REDIRECT_TO = 'redirectTo',
  CONFIRMED = 'confirmed',
  CODE = 'code',
  DELETE_TOKEN = 'delete_token',
  DELETE = 'delete',
  EMAIL = 'email',
  UPDATE = 'update',
  ONBOARDING = 'onboarding',
  TUTORIAL = 'tutorial',
  CREATE = 'create',
  SIGNUP_TYPE = 'signupType',
  SIGNUP_INVITED = 'invited',
  FIRST_SIGNIN = 'firstSignIn',
  EMAIL_MARKETING_CONSENT = 'emailMarketingConsent',
  LC_VIDEO_PATH = 'lcVideoPath',
}
