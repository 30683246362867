// eslint-disable-next-line no-restricted-syntax
import Dompurify from 'dompurify';

export type SanitizeConfig = Dompurify.Config;

const CONFIG: SanitizeConfig = {
  FORBID_TAGS: ['script', 'style', 'link', 'meta', 'base', 'iframe', 'frame', 'frameset', 'object'],
  USE_PROFILES: {
    html: true,
  },
};

export function sanitize(html: string, config = CONFIG): string {
  return Dompurify.sanitize(html, config) as string;
}
