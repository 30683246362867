import { RichTextEditor } from '@contentful/field-editor-rich-text';
import { MarkdownEditor } from '@contentful/field-editor-markdown';
import { SingleLineEditor } from '@contentful/field-editor-single-line';
import { MultipleLineEditor } from '@contentful/field-editor-multiple-line';

const getField = (sdk, isMarkdown) => {
  if (isMarkdown === 'markdown') {
    return <MarkdownEditor isInitiallyDisabled={true} minHeight={'300px'} sdk={sdk} />
  }

  switch (sdk.field.type) {
    case 'RichText':
      return <RichTextEditor sdk={sdk} isInitiallyDisabled={false} />

    case 'Symbol':
      return <SingleLineEditor field={sdk.field} locales={sdk.locales} isInitiallyDisabled={false} withCharValidation={true} />

    default:
      return <MultipleLineEditor field={sdk.field} locales={sdk.locales} isInitiallyDisabled={false} withCharValidation={true} />
  }
};

export default getField;
