
const digest = 'ac17d55d908fa7940944edaa6b8300185d11fc95f6a7998126addf344e422c35';
const css = `.styles-module__7umLrz2 {
  padding: 9px 16px;
  border-radius: 4px;

  /* Todo: position might need to be updated for Docs plugin and/or chrome extension */
  background: var(--black);
  box-shadow: var(--modal-box-shadow);
  color: var(--white);
  font-size: 10px;
  font-weight: 600;
  line-height: 19px;
}
`;

export default {"container":"styles-module__7umLrz2"};
export { css, digest };
  