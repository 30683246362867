
const digest = 'b5db2ff139d9a531ddd3b4e27f1b618a5022351b5b1c166ccd70550b6c094788';
const css = `.styles-module__dCJk0Br {
  overflow: visible;
  width: 100%;
  min-width: var(--inline-sug-card-min-width);
  max-width: var(--inline-sug-card-max-width);
  box-sizing: border-box;
  padding: 11px 17px 13px;
  border-radius: 8px;
  background-color: var(--white);
  box-shadow: 0 5.4px 32.5px 0 #e5ebf5;
  color: var(--black);
}

.styles-module__FdIvh5G {
  margin-top: 12px;
  margin-bottom: 9px;
  color: var(--classic-grey-3);
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 1.3px;
  text-transform: uppercase;
}

.styles-module__KFlbwNF {
  width: calc(100% + 17px) !important;
}

.styles-module__QN2w2NX {
  margin-left: 5px;
  color: var(--classic-blue-4);
  font-size: 13px;
  font-weight: 400;
}
`;

export default {"mainContainer":"styles-module__dCJk0Br","category":"styles-module__FdIvh5G","description":"styles-module__KFlbwNF","subHighlight":"styles-module__QN2w2NX"};
export { css, digest };
  