import types from './constants';

export default function authReducer(
  state = {
    authToken: '',
    isAuthChecked: false,
    isLogged: false,
    isLoading: true,
    user: null,
    error: null,
    organizations: [],
    workspaces: [],
    profileData: null,
    currentWorkspace: null,
    currentOrganization: null,
  },
  { type, payload },
) {
  switch (type) {
    case types.LOGIN_PENDING: {
      return {
        ...state,
        authToken: '',
        isLoading: true,
        error: null,
      };
    }
    case types.LOGIN_STOP: {
      return {
        ...state,
        authToken: '',
        isLoading: false,
      };
    }
    case types.RESET_ERRORS: {
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    }
    case types.LOGIN_FAILED: {
      return {
        ...state,
        authToken: '',
        isLogged: false,
        isLoading: false,
        user: null,
        organizations: null,
        error: payload,
      };
    }
    case types.LOGIN_FULLFILED: {
      const {
        isLogged,
        personaId,
        documentId,
        currentWorkspace,
        currentOrganization,
        subscription,
        workspaces,
        organizations,
        profileData
      } = payload;

      return {
        ...state,
        isLogged,
        personaId,
        documentId,
        currentWorkspace,
        currentOrganization,
        subscription,
        workspaces,
        organizations,
        profileData,
        isLoading: false,
      };
    }
    case types.LOGOUT: {
      return {
        ...state,
        authToken: '',
        isLogged: false,
        profileData: null,
      };
    }
    case types.LOGIN_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLogged: true,
      };
    }
    case types.SET_TOKEN: {
      return {
        ...state,
        authToken: payload,
      };
    }
    case types.SET_CURRENT_ORGANIZATION: {
      const {
        currentOrganization,
        subscription,
        workspaces,
        currentWorkspace
      } = payload;

      return {
        ...state,
        workspaces,
        currentOrganization,
        subscription,
        currentWorkspace
      };
    }
    case types.SET_CURRENT_TEAM: {
      const {
        currentWorkspace,
      } = payload;

      return {
        ...state,
        currentWorkspace
      };
    }
    case types.UPDATE_PROFILE_DATA: {
      const {
        profileData,
      } = payload;

      return {
        ...state,
        profileData
      };
    }
    default:
      return state;
  }
};
