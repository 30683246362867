/**
 * Strips HTML tags from the given text.
 * @param text - The text to strip HTML tags from.
 * @returns The text with HTML tags removed.
 */
export function stripHtml(text?: string): string | undefined {
  if (!text) {
    return text;
  }

  return text.replace(/<[^>]*>/gi, '');
}
