import type { IIssue } from '@writerai/types';
import type { AnalyticsService } from '@writerai/analytics';
import type { UIVisibleIssuesListModel } from '../VisibleIssuesList/UIVisibleIssuesListModel';
import type { SidebarModel, CategoriesModel } from '../../models';

export interface UIIssuesListModelOptions {
  model: UIIssuesListModel['model'];
  modelVisibleIssuesList: UIVisibleIssuesListModel;
  analytics: AnalyticsService;
}

export class UIIssuesListModel {
  readonly modelVisibleIssuesList: UIVisibleIssuesListModel;
  readonly model: Pick<SidebarModel, 'issues' | 'size' | 'categories' | 'sidebarState'>;
  readonly analytics: AnalyticsService;

  // eslint-disable-next-line no-useless-constructor
  constructor({ model, modelVisibleIssuesList, analytics }: UIIssuesListModelOptions) {
    this.model = model;
    this.modelVisibleIssuesList = modelVisibleIssuesList;
    this.analytics = analytics;
  }

  get currentSidebarIssues(): IIssue[] {
    return this.model.issues.currentSidebarIssues;
  }

  get isStandard() {
    return this.model.size.isStandard;
  }

  get isSmallPadding() {
    return this.model.size.isSmall;
  }

  get selectedCategory(): CategoriesModel['selectedCategory'] {
    return this.model.categories.selectedCategory;
  }

  get isLoading() {
    return this.model.sidebarState.isLoading;
  }

  get isEmptyState() {
    return this.model.sidebarState.isEmptyState;
  }

  get isNoIntegration() {
    return this.model.sidebarState.isNoIntegration;
  }

  get isEnterprisePlanCancelled() {
    return this.model.sidebarState.isEnterprisePlanCancelled;
  }

  get isTeamTrialExpired() {
    return this.model.sidebarState.isTeamTrialExpired;
  }

  get isOrgAdmin() {
    return this.model.sidebarState.isOrgAdmin;
  }

  get billingPageHref() {
    return this.model.sidebarState.billingPageHref;
  }

  get isTeamPlanCancelled() {
    return this.model.sidebarState.isTeamPlanCancelled;
  }

  get isFreeCancelled() {
    return this.model.sidebarState.isFreeCancelled;
  }

  get activateFreeHref() {
    return this.model.sidebarState.activateFreeHref;
  }

  get paidFeatureHref() {
    return this.model.sidebarState.paidFeatureHref;
  }
}
