class Defer<T> {
  public readonly resolve!: (data: T) => void;

  public readonly reject!: (err: unknown) => void;

  public readonly promise = new Promise<T>((resolve, reject) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const t = this as any;

    t.resolve = resolve;
    t.reject = reject;
  });
}

export interface IController<T> {
  reject(err: unknown): void;
  resolve(t: T): void;
  close(): void;
}

export interface IStreamIterator<T> {
  ctrl: IController<T>;
  iterator: AsyncIterable<T>;
}

export function streamIterator<T>(fn?: () => Promise<void>): IStreamIterator<T> {
  const list: Array<Defer<T | null>> = [new Defer()];
  const last = () => list[list.length - 1];

  const ctrl: IController<T> = {
    reject(err) {
      last().reject(err);
    },
    resolve(ev) {
      const l = last();
      list.push(new Defer());
      l.resolve(ev);
    },
    close() {
      last().resolve(null);
    },
  };

  const iterator: AsyncIterable<T> = {
    [Symbol.asyncIterator]() {
      let index = 0;
      const next = async (): Promise<IteratorResult<T>> => {
        if (index === 0) {
          fn?.().catch(err => {
            list[list.length - 1].reject(err);
          });
        }

        const value = await list[index].promise;
        index++;

        return value === null ? { done: true, value: undefined } : { done: false, value };
      };

      return { next };
    },
  };

  return { iterator, ctrl };
}
