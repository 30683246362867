import { WHITESPACES_REGEXP } from './constants';

/**
 * Replaces the whitespace characters in a string with a specified replacement string.
 *
 * @param textContent - The string whose whitespace characters are to be replaced.
 * @param replace - The string to use as a replacement for whitespace characters. Defaults to a space character if not provided.
 * @param removeDoubleSeq - A flag indicating whether to remove sequences of more than one 'replace' character. Defaults to `false`.
 *
 * @returns The modified string with whitespace characters replaced.
 */
export function replaceWhitespaces(textContent: string, replace = ' ', removeDoubleSeq = false): string {
  // Replace all whitespace characters in the input string
  const result = textContent.replace(WHITESPACES_REGEXP, replace);

  // If the 'removeDoubleSeq' flag is not set, return the modified string
  if (!removeDoubleSeq) {
    return result;
  }

  // Create a regex pattern that matches sequences of more than one 'replace' character
  const rx = new RegExp(`[${replace}]+`, 'g');

  // Replace any double sequences of 'replace' characters with a single 'replace' character
  return result.replace(rx, replace);
}
