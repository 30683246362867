import React from 'react';
import { Paragraph, TextLink, Note } from '@contentful/forma-36-react-components';

export const LocalhostWarning = () => (
  <Note title="App running outside of Contentful">
    <Paragraph>
      Open{" "}
      <TextLink href="https://www.contentful.com">contentful.com</TextLink> to
      use app.
    </Paragraph>
  </Note>
);
