
const digest = '9830217384a4492a22ab415699061b8c97e7ac33065773d6f46c1e3372f75819';
const css = `.styles-module__m1ODcpJ {
  height: 40px;
  padding: 10px;
  border-radius: var(--default-border-radius);
}

.styles-module__m1ODcpJ:hover {
  background: var(--writer-blue-1);
  transition: var(--background-color-transition);
}

.styles-module__Aug0fNE {
  display: flex;
  flex: 1;
  justify-content: space-between;
}
`;

export default {"statItemButton":"styles-module__m1ODcpJ","statsListItemValue":"styles-module__Aug0fNE"};
export { css, digest };
  