import type { ChangeEvent } from 'react';
import React, { useState } from 'react';
import cx from 'classnames';
import { Enum } from '@writerai/utils';

import styles from './styles.module.css';

export const TPosition = new Enum('left', 'right');

interface RadioButtonProps<T> {
  id?: string;
  /** Radio button value */
  value?: T;
  /** Set additional css class */
  className?: string;
  /** Set inline css styles */
  style?: React.CSSProperties;
  /** Children passed on as React child */
  children?: React.ReactNode;
  /** Is checked */
  checked?: boolean;
  /** Is disabled */
  disabled?: boolean;
  /** Is indeterminate */
  indeterminate?: boolean;
  /** On toggle callback */
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  /** Checkbox position */
  position?: typeof TPosition.type;
  /** Radio button name */
  name?: string;
}

const CheckMark: React.FC<{
  isChecked?: boolean;
  indeterminate?: boolean;
  disabled?: boolean;
  isHovered?: boolean;
}> = ({ isChecked, indeterminate, disabled, isHovered }) => (
  <span
    className={cx(styles.radioButton, {
      [styles.checked]: isChecked,
      [styles.indeterminate]: indeterminate,
      [styles.disabled]: disabled,
    })}
  >
    {((!indeterminate && isChecked) || isHovered) && <span className={styles.smallCircle} />}
    {indeterminate && !isChecked && !isHovered && <span className={styles.dash} />}
  </span>
);

export const RadioButton: React.FC<RadioButtonProps<string>> = ({
  id,
  className,
  children,
  checked,
  disabled = false,
  indeterminate = false,
  onChange,
  value,
  name,
  position = 'left',
  ...props
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const elClass = cx(styles.container, className, { [styles.disabled]: disabled });

  return (
    <label
      htmlFor={id}
      className={elClass}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {position === TPosition.enum.left && (
        <CheckMark isChecked={checked} indeterminate={indeterminate} disabled={disabled} isHovered={isHovered} />
      )}
      <input
        id={id}
        value={value}
        name={name}
        type="radio"
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        {...props}
      />
      {children && children}
      {position === TPosition.enum.right && (
        <CheckMark isChecked={checked} indeterminate={indeterminate} disabled={disabled} isHovered={isHovered} />
      )}
    </label>
  );
};
