import { Enum } from '@writerai/utils';
import type { UserTeamRole } from './auth.types';
import type { ITeam } from './teams.types';

export const UserAuthType = new Enum('Password', 'Saml', 'Social', 'App', 'Admin', 'Firebase');

/**
 * @deprecated use TApplicationUserType
 */
export enum ApplicationUserType {
  INDIVIDUAL = 'individual',
  APPLICATION = 'application',
}

export const TApplicationUserType = new Enum('individual', 'application');

export interface IUserSalesProfile {
  companyType: string;
  employees: number | null;
  industry: string;
  leadStatus: string | null;
  raised: number | null;
  role: string | null;
  title: string;
}

export interface IUserProfile {
  id: number;
  clientId: string;
  avatar: string;
  accountStatus: UserAccountStatus;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string | null;
  jobTitle: string;
  timezone: string;
  phone: string;
  lastOnlineTime: string;
  hasSaml: boolean;
  hasPassword: boolean;
  invited: boolean;
  createdAt?: string;
  authType: typeof UserAuthType.type;
  allowDirectPasswordLogin: boolean;
  allowUserCreation: boolean;
  creator: boolean;
  billingGroupName?: string;
}

export enum UserAccountStatus {
  INVITED = 'invited',
  SIGNED_UP = 'signed_up',
}

/**
 * @deprecated
 */
export interface IUserProfileNotificationsSettings {
  email: boolean;
  frequency: string;
  types: {
    // eslint-disable-next-line camelcase
    calendar_reminders: boolean;
    // eslint-disable-next-line camelcase
    team_activity: boolean;
  };
}

export interface IUserTeam {
  id: ITeam['id'];
  name: ITeam['name'];
  role: UserTeamRole;
}

/**
 * @deprecated
 */
export interface IUserProfileQuestionnaire {
  isSignUpFilled: boolean;
}

export interface IUserPartial {
  email: string;
  fullName: string;
  id: number;
  avatar: string;
}

export interface IUserDetails {
  user: IUserProfile;
  role: UserTeamRole;
  teams?: IUserTeam[];
  approvedByInviter?: boolean;
}

export interface IInviteResponse {
  invitedUsersCount: number;
  existedUsersCount: number;
  invitedUsers: IUserProfile[];
}

export interface ICollaborationUser {
  id: string;
  fullName: string;
  email: string | null;
  avatar?: string;
  isTyping?: boolean;
}

export interface ICollaborationUserWithMeta extends ICollaborationUser {
  sessionId: string;
  lastViewed?: number;
  lastTyping?: number;
}

export interface IQuestionnaireAnswers {
  questionnaire: {
    id: 0;
    userId: 0;
    company: 'string';
    companyRole: 'string';
    useCaseGeneral: 'string';
    useCaseSpecific: 'string';
    useCaseContentType: 'string';
    headAboutUs: 'string';
  } | null;
}
