import React from 'react';
import { IssueLabel } from '@writerai/ui-molecules';

import styles from './styles.module.css';

import Description from './Description';

export const InlineDictionaryCard = ({
  highlight,
  subHighlight,
  description,
  examples,
}) => (
  <div className={styles.mainContainer}>
    <IssueLabel className={styles.issueLabel}>
      {highlight} <span className={styles.subHighlight}>{subHighlight}</span>
    </IssueLabel>

    <div className={styles.category}>Approved Term</div>
    <Description className={styles.description} description={description} examples={examples} />
  </div>
);

export default React.memo(InlineDictionaryCard);
