import returnPageHeader from "./utils.js";

import close from "../../assets/icons/close.svg";

import styles from "./style.module.scss";

const Header = ({ closeWindow, loginType }) => (
  <div className={styles.dialog__header}>
    <h3 data-aos="zoom-in">{returnPageHeader(loginType)}</h3>
    <button onClick={closeWindow}>
      <img src={close} alt="close" />
    </button>
  </div>
);

export default Header;
