import { CUSTOM_IGNORED_TAGS } from './constants';

/**
 * Remove custom ignored tags from text
 * @param {string} text - The text to remove ignored tags from
 * @param {Object} [tags=CUSTOM_IGNORED_TAGS] - The tags to remove
 * @param {string} tags.[tagName] - The name of the tag to remove
 * @returns {string} The updated text with the ignored tags removed
 */
export function removeIgnoredTags(text: string, tags: typeof CUSTOM_IGNORED_TAGS = CUSTOM_IGNORED_TAGS): string {
  if (!text.length || !Object.values(tags).length) {
    return text;
  }

  const parser = new DOMParser();
  // Build XML tree to validate tag matching
  const xmlDoc = parser.parseFromString(`<validate>${text}</validate>`, 'application/xml');

  if (xmlDoc.querySelector('parsererror')) {
    return text;
  }

  // Build miniature DOM
  const doc = parser.parseFromString(text, 'text/html');
  const uniqueLowercaseTags = new Set(Object.values(tags).map(tag => tag.toLowerCase()));

  // Find and remove all the ignored nodes
  uniqueLowercaseTags.forEach(tag => {
    const tagNodes = doc.body.querySelectorAll(tag);

    if (tagNodes.length) {
      tagNodes.forEach(element => element.remove());
    }
  });

  // Return rest of the DOM as a string
  return doc.body.innerHTML;
}
