import { getWords } from './getWords';

/**
 * Returns the number of words in the given text.
 * @param text - The text to count the words in.
 * @returns The number of words in the given text.
 */
export function getWordsCount(text = ''): number {
  if (!text) {
    return 0;
  }

  return getWords(text).length;
}
