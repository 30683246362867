import types from './constants';

export function changeLogin(loginType) {
  return async dispatch => {
    dispatch({
      type: types.SET_LOGIN,
      payload: {
        loginType
      },
    });
  };
}

export function changeField(fieldType) {
  return async dispatch => {
    dispatch({
      type: types.SET_FIELD,
      payload: {
        fieldType
      },
    });
  };
}

export function changeFieldSupportsTables(fieldSupportsTables) {
  return async dispatch => {
    dispatch({
      type: types.SET_FIELD_SUPPORTS_TABLES,
      payload: {
        fieldSupportsTables
      },
    });
  };
}
