import { Quill } from 'react-quill';

const Inline = Quill.import('blots/inline');

export class Embed extends Inline {
  static blotName = 'embed';
  static tagName = 'embed';

  static create(embed) {
    const node = super.create(embed);

    if (embed === true) {
      return super.create(false);
    }

    this.setNodeConfigurations(node, embed);

    return node;
  };

  static setNodeConfigurations(node, embed) {
    node.setAttribute('embedType', embed.nodeType);
    node.setAttribute('embedId', embed.embedId);
    node.setAttribute('type', embed.embedType);
    node.setAttribute('linkType', embed.linkType);
  };
}
