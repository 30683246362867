export const checkVisible = (top, cardHeight) => {
  let isVisible = true;
  const editorHeight = window.innerHeight - 100;

  if (top + cardHeight >= editorHeight) {
    isVisible = 'invert';
  }

  if (!top || top <= 50 || top >= editorHeight) {
    isVisible = false;
  }

  return isVisible;
};

export const getCardPosition = (cardHeight, mainWrapperRef, snippetElement) => {
  if (!snippetElement?.textContent) {
    return ({
      display: 'none'
    });
  }

  const { top, left, bottom } = snippetElement.getBoundingClientRect();

  const visibility = checkVisible(top, cardHeight);

    const style = {
      left: `${left}px`,
    };
    if (mainWrapperRef?.current && visibility === 'invert') {
      style.bottom = `${mainWrapperRef.current.clientHeight - top + 62}px`;
    } else {
      const styleTop = visibility === 'invert' ? top - cardHeight : bottom;
      style.top = `${styleTop - 72}px`;
    }

    return style;
};
