
const digest = '2a84e37982c4387655bacb3ed9653168c21db96617cd69d9d8b4c55bbfb798ac';
const css = `.styles-module__k7jvOfo {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 0 var(--sidebar-horizontal-spacing);
}

.styles-module__dZacTCG {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.styles-module__IeEabm0 {
  position: relative;
  z-index: 3;
  display: flex;
  width: 100%;
  min-height: 238px;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  border-radius: 8px;
  background: var(--classic-grey-1);
  text-align: center;
}

.styles-module__8CggkI0 {
  padding: 4px 10px;
  border-radius: 4px;
  margin: 3px 0 11px;
  background: var(--writer-blue-2);
  line-height: 1.5;
}

.styles-module__g1IVVtY {
  margin-bottom: 11px;
  font-size: var(--font-size-m);
}

.styles-module__LvfXnxL {
  padding-right: 20px;
}
`;

export default {"container":"styles-module__k7jvOfo","containerWrapper":"styles-module__dZacTCG","lockContainer":"styles-module__IeEabm0","billingTypeLabel":"styles-module__8CggkI0","actionButtonContainer":"styles-module__g1IVVtY","actionButton":"styles-module__LvfXnxL"};
export { css, digest };
  