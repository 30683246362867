import { makeObservable, computed, observable, action, runInAction } from 'mobx';
import type { IssuesPipeline } from '@writerai/types';
import { getSnippetsPageUrl } from '@writerai/dom';
import { IntegrationType, BillingProduct, SharedQueryParam, SidebarViewMode } from '@writerai/types';
import { calculateChecksumOverDelta } from '@writerai/quill-delta-utils';
import type { AiAssistantSubscriptionModel } from '@writerai/models';
import type { FirebaseModel } from '../firebase';
import type { IssuesModel } from '../issues';
import type { SizeModel } from '../size';
import type { CategoriesModel } from '../categories';
import type { ISidebarParams } from '../../types';

interface ISidebarStateModelParams {
  documentParams: () =>
    | Pick<
        ISidebarParams,
        | 'appRoot'
        | 'isOrgAdmin'
        | 'useDropdownPortal'
        | 'isSnippetsCategoryHidden'
        | 'isTeamAdmin'
        | 'integrationType'
        | 'organizationId'
        | 'workspaceId'
        | 'sidebarMode'
        | 'teamName'
        | 'documentId'
      >
    | undefined;
  subscription: () => Pick<
    AiAssistantSubscriptionModel,
    'isFree' | 'isTeam' | 'isMultiTeam' | 'isEnterprise' | 'isCancelled' | 'isTrialExpired'
  >;
  size: () => SizeModel;
  firebase: () => Pick<FirebaseModel, 'firebaseIssues' | 'styleguide' | 'contentHashes' | 'documentDelta'>;
  issues: () => Pick<IssuesModel, 'isEmptyState'>;
  categories: () => Pick<CategoriesModel, 'selectedCategory'>;
}

export class SidebarStateModel {
  constructor(private opts: ISidebarStateModelParams) {
    makeObservable(this, {
      enforcedPipeline: observable,

      statsAreVisible: computed,
      hideCategoriesHeader: computed,
      hideSnippets: computed,
      isLoading: computed,
      isStyleguideLoading: computed,
      isTeamPlanCancelled: computed,
      isEnterprisePlanCancelled: computed,
      isTeamTrialExpired: computed,
      isFreeCancelled: computed,
      isEmptyState: computed,
      isPreviewMode: computed,
      billingPageHref: computed,
      paidFeatureHref: computed,
      activateFreeHref: computed,
      newSnippetUrl: computed,
      showTeamName: computed,
      teamName: computed,
      isNoIntegration: computed,
      isPipelineContentOutdated: computed,
      currentContentHash: computed,

      forceSidebarLoading: action.bound,
      setEnforcePipeline: action.bound,
    });
  }

  // by default the pipeline is being changed automatically
  // based on certain conditions (isPlagiarism, isCollaborative etc).
  // Enforced pipeline is the static pipeline that prevents automatic updates
  // it will stay static until setEnforcePipeline resets it
  enforcedPipeline: IssuesPipeline | undefined = undefined;

  private isForceLoaded = observable.box(false);

  setEnforcePipeline(pipeline?: IssuesPipeline) {
    this.enforcedPipeline = pipeline;
  }

  forceSidebarLoading(milliseconds = 4000) {
    this.isForceLoaded.set(true);

    setTimeout(() => runInAction(() => this.isForceLoaded.set(false)), milliseconds);
  }

  get isPipelineContentOutdated() {
    return this.currentContentHash !== this.opts.firebase().contentHashes.data?.claim;
  }

  get currentContentHash() {
    const currentDelta = this.opts.firebase().documentDelta.data;

    if (!currentDelta) {
      return undefined;
    }

    return calculateChecksumOverDelta(currentDelta);
  }

  get isOrgAdmin() {
    return this.opts.documentParams()?.isOrgAdmin || false;
  }

  get useDropdownPortal() {
    return this.opts.documentParams()?.useDropdownPortal || false;
  }

  get isSnippetsCategoryHidden() {
    return this.opts.documentParams()?.isSnippetsCategoryHidden || false;
  }

  get isAlertsCentered() {
    return (
      [
        IntegrationType.CHROME_EXTENSION,
        IntegrationType.FIGMA_PLUGIN,
        IntegrationType.WORD_PLUGIN,
        IntegrationType.OUTLOOK_PLUGIN,
      ].includes(this.opts.documentParams()?.integrationType as IntegrationType) || false
    );
  }

  get isTeamAdmin() {
    return this.opts.documentParams()?.isTeamAdmin || false;
  }

  get integrationType() {
    return this.opts.documentParams()?.integrationType || IntegrationType.DEFAULT;
  }

  get isPreviewMode() {
    return this.opts.documentParams()?.sidebarMode === SidebarViewMode.PREVIEW;
  }

  get teamName() {
    return this.opts.documentParams()?.teamName || '';
  }

  get isNoIntegration() {
    return !this.opts.documentParams()?.documentId;
  }

  get showTeamName() {
    return this.opts.subscription().isEnterprise;
  }

  get canWriteToTerms() {
    return this.opts.subscription().isFree && this.isTeamAdmin;
  }

  get statsAreVisible() {
    if (this.isLoading && (this.opts.size().isSmall || this.opts.size().isMedium)) {
      return false;
    }

    if (this.isTeamPlanCancelled || this.isTeamTrialExpired) {
      return false;
    }

    return true;
  }

  get hideCategoriesHeader() {
    return !this.opts.size().isStandard && this.isLoading;
  }

  get hideSnippets() {
    return !this.opts.size().isStandard && this.isLoading;
  }

  get isLoading() {
    if (this.isNoIntegration) {
      return false;
    }

    return this.isForceLoaded.get() || !this.opts.firebase().firebaseIssues.data || this.isStyleguideLoading;
  }

  get isStyleguideLoading() {
    return !this.opts.firebase().styleguide.data;
  }

  get isTeamPlanCancelled() {
    return this.opts.subscription().isTeam && this.opts.subscription().isCancelled;
  }

  get isEnterprisePlanCancelled() {
    return this.opts.subscription().isEnterprise && this.opts.subscription().isCancelled;
  }

  get isTeamTrialExpired() {
    return this.opts.subscription().isTrialExpired;
  }

  get isFreeCancelled() {
    return this.opts.subscription().isFree && this.opts.subscription().isCancelled;
  }

  get isEmptyState() {
    return (
      this.opts.issues().isEmptyState ||
      this.isTeamTrialExpired ||
      this.isTeamPlanCancelled ||
      this.isFreeCancelled ||
      this.isNoIntegration
    );
  }

  get newSnippetUrl() {
    const params = this.opts.documentParams();

    if (!params) {
      return '';
    }

    return getSnippetsPageUrl(params.appRoot, params.organizationId, params.workspaceId, {
      [SharedQueryParam.CREATE]: 'true',
    });
  }

  get billingPageHref() {
    const params = this.opts.documentParams();

    if (!params) {
      return '';
    }

    return `${params.appRoot.endsWith('/') ? params.appRoot : `${params.appRoot}/`}organization/${
      params.organizationId
    }/admin/billing`;
  }

  get paidFeatureHref() {
    return `${this.billingPageHref}?triggerUpdateTo=${this.opts.categories().selectedCategory?.billingProduct}`;
  }

  get activateFreeHref() {
    return `${this.billingPageHref}?triggerUpdateTo=${BillingProduct.FREE}`;
  }
}
