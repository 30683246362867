import type { BillingProduct } from './billing.types';
import type { IssueCategory } from './issues.types';

export interface ISidebarCategory {
  label: string;
  id: CategoryType;
  color: string;
  categories: IssueCategory[];
  scoreWeight: number;
  scoreEnabled: boolean;
  lockedText: string;
  styleguideSectionUrl?: string;
  billingProduct: BillingProduct;
  status: SidebarCategoryStatus;
}

export enum SidebarCategoryStatus {
  AVAILABLE = 'available',
  INVISIBLE = 'invisible',
  BETA = 'beta',
  LOCKED = 'locked',
  DISABLED = 'disabled', // the status is calculated on the FE and doesn't exist in the BE's enum
}

export enum CategoryType {
  ALL = 'all',
  SPELLING_AND_GRAMMAR = 'grammar-spelling',
  TERMS = 'terms',
  STYLE = 'style',
  CLARITY = 'clarity',
  DELIVERY = 'delivery',
  INCLUSIVITY = 'inclusivity',
  COMPLIANCE = 'compliance',
  PLAGIARISM = 'plagiarism',
  SNIPPETS = 'snippets',
}
