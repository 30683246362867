/* eslint-disable no-undef */
import type { FetchEventSourceInit } from '@fortaine/fetch-event-source';
import type { components } from './schema';
import type { IApiOptions } from './utils';
import { createApi } from './createApi';

export interface IRequestServiceParams extends IApiOptions {
  clientId: string;
  clientVersion: string;
}

export function createRequestService({ clientId, clientVersion, ...options }: IRequestServiceParams) {
  const { api, fetch, fetchEventSource } = createApi(options);

  const request = {
    updateSuggestionsLanguage: ({
      languageCode,
      ...path
    }: {
      organizationId: number;
      teamId: number;
      languageCode: string;
    }) =>
      api.put('/api/styleguide/organization/{organizationId}/team/{teamId}/language', {
        params: { path },
        body: { languageCode },
      }),

    getPortalSettings: (path: { organizationId: number }) =>
      api.get('/api/portal/organizations/{organizationId}/settings', { params: { path } }),

    sendIdentify: ({ traits }: components['schemas']['analytics_dto_IdentifyRequest']) =>
      api.post('/api/analytics/identify', {
        body: { traits },
      }),

    trackEvent: (eventName: string, properties: Record<string, unknown>) =>
      api.post('/api/analytics/track', {
        params: {},
        body: { eventName, properties },
      }),

    trackAnonymousEvent: (eventName: string) =>
      api.post('/api/analytics/anonymous/track', {
        params: {},
        body: { eventName, properties: {} },
      }),

    trackOrganizationEvent: (organizationId: number, eventName: string, properties: Record<string, unknown>) =>
      api.post('/api/analytics/organization/{organizationId}/track', {
        params: { path: { organizationId } },
        body: {
          eventName,
          properties,
        },
      }),

    login: (body: components['schemas']['auth_dto_LoginWithPasswordRequest']) =>
      api.put('/api/auth/login/password', {
        params: {},
        body,
      }),

    sendUsage: (eventType: string) =>
      api.post('/v3/user/usage/event', {
        body: {
          eventType,
          extensionType: clientId,
          extensionVersion: clientVersion,
        },
      }),

    getExtensionConfig: (path: { organizationId: number }) =>
      api.get('/api/organization/v2/{organizationId}/extension/config', {
        params: { path },
      }),

    getWorkspaceById: (path: { workspaceId: number }) =>
      api.get('/api/projects/{workspaceId}', {
        params: { path },
      }),

    fetchWorkspaces: (path: { organizationId: string }) =>
      api.get('/v3/organizations/{organizationId}/workspaces', {
        params: { path },
      }),

    fetchWorkspacesNew: (path: { organizationId: number }) =>
      api.get('/api/organization/v2/{organizationId}/team/my', {
        params: {
          path,
        },
      }),

    fetchStyleGuide: (path: { organizationId: number; projectId: number; personaId: number }) =>
      api.get('/api/styleguide/organization/{organizationId}/project/{projectId}/persona/{personaId}', {
        params: { path },
      }),
    addTermBank: (
      path: { organizationId: number; termBankId: number },
      { body }: { body: components['schemas']['CreateTermsRequest'] },
    ) =>
      api.post('/api/terminology/organization/{organizationId}/term-bank/{termBankId}/term', {
        params: { path },
        body,
      }),

    contextCheckWord: ({
      organizationId,
      preContextText,
      postContextText,
      mode,
      text,
    }: {
      organizationId: number;
      preContextText?: string;
      postContextText?: string;
      mode?: 'proof' | 'spell';
      text: string;
    }) =>
      api.post('/api/autocorrect/v2/{organizationId}/correction', {
        params: {
          path: { organizationId },
        },
        body: {
          preContextText,
          postContextText,
          mode,
          text,
        },
      }),

    getMyOrganization: (_: object, opts: Omit<RequestInit, 'body'> = {}) => api.get('/api/organization/v2/my', opts),

    getProfile: (_: object, opts: Omit<RequestInit, 'body'> = {}) => api.get('/api/user/v2/profile', opts),

    getPermissions: (_: object, opts: Omit<RequestInit, 'body'> = {}) => api.get('/api/auth/permission', opts),

    getSubscription: (path: { organizationId: number }) =>
      api.get('/api/billing/organizations/{organizationId}/subscription', {
        params: {
          path,
        },
      }),

    resendConfirmation: (_: object) => api.get('/api/user/v2/confirmation/resend', {}),

    snippetShortcut: (path: { organizationId: number; teamId: number; shortcut: string }) =>
      api.get('/api/terminology/organization/{organizationId}/team/{teamId}/snippet/shortcut/{shortcut}', {
        params: { path },
      }),

    getSnippetShortcut: ({ shortcuts, ...path }: { organizationId: number; teamId: number; shortcuts: string[] }) =>
      api.put('/api/terminology/organization/{organizationId}/team/{teamId}/snippet/shortcut/get', {
        params: { path },
        body: { shortcuts },
      }),

    setDocumentContent: ({
      organizationId,
      workspaceId,
      personaId,
      documentId,
      ...body
    }: {
      organizationId: number;
      workspaceId: number;
      personaId: number;
      documentId: string;
    } & components['schemas']['content_dto_SaveDocumentRequestV5']) =>
      api.post(
        '/api/content/organization/{organizationId}/workspace/{workspaceId}/persona/{personaId}/document/{documentId}/content',
        {
          params: {
            path: {
              organizationId,
              workspaceId,
              personaId,
              documentId,
            },
          },
          body,
        },
      ),

    reportFragment: ({
      organizationId,
      workspaceId,
      personaId,
      documentId,
    }: {
      organizationId: number;
      workspaceId: number;
      personaId: number;
      documentId: string;
    }) =>
      api.post(
        '/api/content/organization/{organizationId}/workspace/{workspaceId}/persona/{personaId}/document/{documentId}/fragment',
        {
          params: {
            path: {
              organizationId,
              workspaceId,
              personaId,
              documentId,
            },
          },
        },
      ),

    getDomainStatus: (query: { domain: string }) =>
      api.get('/api/organization/v2/extension/domain/status', {
        params: { query },
      }),

    setDocumentDelta: ({
      delta,
      ...path
    }: {
      organizationId: number;
      workspaceId: number;
      personaId: number;
      documentId: string;
      delta: components['schemas']['content_model_DeltaV2'];
    }) =>
      api.post(
        '/api/content/organization/{organizationId}/workspace/{workspaceId}/persona/{personaId}/document/{documentId}/delta/fragmented',
        {
          params: { path },
          body: { delta },
        },
      ),

    reportSnippetApply: (path: { organizationId: number; teamId: number; snippetId: string }) =>
      api.put('/api/terminology/organization/{organizationId}/team/{teamId}/snippet/{snippetId}/apply', {
        params: { path },
      }),

    invalidateAuthToken: (_?: object, opts: Omit<RequestInit, 'body'> = {}) =>
      api.put('/api/auth/token/user/invalidate', opts),

    getReWritePrompts: (path: { organizationId: number; teamId: number }) =>
      api.get('/api/template/organization/{organizationId}/team/{teamId}/rewrite/prompt', {
        params: { path },
      }),

    createAuthBuffer: (_: object) => api.post('/api/auth/buffer', {}),

    getAuthBuffer: (body: components['schemas']['auth_dto_ReadBufferV2Request']) =>
      api.put('/api/auth/buffer/get', {
        body,
      }),

    postAuthAppInit: (body: components['schemas']['auth_dto_InitAppAuthRequest']) =>
      api.post('/api/auth/app/init', {
        body,
      }),

    createAppCodeExchange: (body: components['schemas']['auth_dto_ExchangeAppAuthCodeRequest']) =>
      api.put('/api/auth/app/code/exchange', {
        body,
      }),

    autowriteStream: (
      {
        organizationId,
        teamId,
        ...body
      }: { organizationId: number; teamId: number } & components['schemas']['content_generation_dto_AutoWriteRequest'],
      options: FetchEventSourceInit,
    ) =>
      api.streamPost('/api/generation/organization/{organizationId}/team/{teamId}/autowrite/stream', {
        params: { path: { organizationId, teamId } },
        ...options,
        body,
      }),

    commandGenerateStream: (
      {
        organizationId,
        teamId,
        ...body
      }: {
        organizationId: number;
        teamId: number;
      } & components['schemas']['content_generation_dto_CommandGenerateRequest'],
      options?: FetchEventSourceInit,
    ) =>
      api.streamPost('/api/generation/organization/{organizationId}/team/{teamId}/command/generate/stream', {
        ...options,
        params: {
          path: { organizationId, teamId },
        },
        body,
      }),

    generateCommandContent: (
      {
        organizationId,
        teamId,
        ...body
      }: {
        organizationId: number;
        teamId: number;
      } & components['schemas']['content_generation_dto_CommandGenerateRequest'],
      options?: FetchEventSourceInit,
    ) =>
      api.post('/api/generation/organization/{organizationId}/team/{teamId}/command/generate', {
        ...options,
        params: {
          path: { organizationId, teamId },
        },
        body,
      }),

    getCommandsList: ({ organizationId, teamId }: { organizationId: number; teamId: number }) =>
      api.get('/api/generation/organization/{organizationId}/team/{teamId}/command', {
        params: {
          path: { organizationId, teamId },
        },
      }),

    getUserPrompts: ({ organizationId, teamId }: { organizationId: number; teamId: number }) =>
      api.get('/api/generation/organization/{organizationId}/team/{teamId}/prompt', {
        params: {
          path: { organizationId, teamId },
        },
      }),

    getKnowledgeFiles: ({
      organizationId,
      teamId,
      ...query
    }: {
      organizationId: number;
      teamId: number;
      search?: string;
      id?: string;
      access?: 'private' | 'shared';
      status?: ('queued' | 'processing' | 'completed' | 'error')[];
      sortField?: 'creationTime' | 'size' | 'status' | 'name';
      sortOrder?: 'asc' | 'desc';
      offset?: number;
      limit?: number;
    }) =>
      api.get('/api/media/organization/{organizationId}/team/{teamId}/knowledge', {
        params: {
          path: { organizationId, teamId },
          query,
        },
      }),

    getIssueEnrichment: (path: { organizationId: number; teamId: number; documentId: string; issueId: string }) =>
      api.get('/api/content/organization/{organizationId}/team/{teamId}/document/{documentId}/issue/{issueId}/enrich', {
        params: { path },
      }),

    askKnowledgeGraph: ({
      organizationId,
      teamId,
      ...body
    }: { organizationId: number; teamId: number } & components['schemas']['ask_dto_KnowledgeRequest']) =>
      api.post('/api/ask/organization/{organizationId}/team/{teamId}/knowledge', {
        params: {
          path: { organizationId, teamId },
        },
        body,
      }),

    getRewriteSuggestions: ({
      organizationId,
      teamId,
      ...body
    }: { organizationId: number; teamId: number } & components['schemas']['template_dto_ReWriteRequest']) =>
      api.post('/api/template/organization/{organizationId}/team/{teamId}/rewrite/v2', {
        params: {
          path: { organizationId, teamId },
        },
        body,
      }),
  } as const;

  return {
    clientVersion,
    clientId,
    api,
    fetch,
    fetchEventSource,
    /**
     * @deprecated please use api directly, this api will be deleted in future releases
     */
    request,
  };
}

export type RequestServiceInitialize = ReturnType<typeof createRequestService>;

/**
 * @deprecated please use api directly, this api will be deleted in future releases
 */
export type RequestService = RequestServiceInitialize['request'];
