import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';

import { Icon, IconVariant } from '@writerai/ui-atoms';

import styles from './Textarea.module.css';

// Extend standard textarea props to allow all attributes
interface ITextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  className?: string;
  error?: boolean;
  rows?: number;
  resizable?: boolean;
}

export const Textarea: React.FC<ITextareaProps> = ({
  className,
  error,
  rows,
  resizable = true,
  children,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const showResizeIcon = resizable && !props.disabled;

  useEffect(() => {
    if (resizable && textareaRef.current) {
      textareaRef.current.style.height = 'inherit';
      textareaRef.current.style.height = `${textareaRef.current?.scrollHeight}px`;
    }
  }, [props.value, resizable]);

  return (
    <div
      className={cx(className, styles.textareaWrapper, {
        [styles.hasError]: error,
        [styles.empty]: isEmpty(props.value),
        [styles.disabled]: props.disabled,
        [styles.focused]: isFocused,
        [styles.noResize]: !resizable,
      })}
    >
      <textarea
        ref={textareaRef}
        className={cx(styles.textarea, styles.container)}
        aria-invalid={!!error}
        aria-describedby={error ? 'textarea-error' : undefined}
        rows={rows}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        {...props}
      />
      {showResizeIcon && <Icon name={IconVariant.RESIZE_TEXTAREA} className={styles.resizeIcon} />}
      {children}
    </div>
  );
};
