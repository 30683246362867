type TRouteIdentifier = string | number;

export const SETUP_ROUTES = {
  punctuation: '/organization/:orgId/team/:teamId/setup/suggestions/punctuation',
  writingStyle: '/organization/:orgId/team/:teamId/setup/suggestions/writing-style',
  clarity: '/organization/:orgId/team/:teamId/setup/suggestions/clarity',
  delivery: '/organization/:orgId/team/:teamId/setup/suggestions/delivery',
  inclusivity: '/organization/:orgId/team/:teamId/setup/suggestions/inclusivity',
  compliance: '/organization/:orgId/team/:teamId/setup/suggestions/compliance',
  plagiarism: '/organization/:orgId/team/:teamId/setup/suggestions/plagiarism',
  general: '/organization/:orgId/team/:teamId/setup/suggestions/general',
  customRules: '/organization/:orgId/team/:teamId/setup/suggestions/custom-rules',
  reWrite: '/organization/:orgId/team/:teamId/setup/suggestions/reWrite',
  magicLinks: '/organization/:orgId/team/:teamId/setup/suggestions/magicLinks',
  knowledgeGraph: '/organization/:orgId/team/:teamId/setup/suggestions/knowledge',
  teamPrompts: '/organization/:orgId/team/:teamId/setup/suggestions/team-prompts',
  toPunctuation: (orgId: TRouteIdentifier, teamId: TRouteIdentifier) =>
    `/organization/${orgId}/team/${teamId}/setup/suggestions/punctuation`,
  toWritingStyle: (orgId: TRouteIdentifier, teamId: TRouteIdentifier) =>
    `/organization/${orgId}/team/${teamId}/setup/suggestions/writing-style`,
  toClarity: (orgId: TRouteIdentifier, teamId: TRouteIdentifier) =>
    `/organization/${orgId}/team/${teamId}/setup/suggestions/clarity`,
  toDelivery: (orgId: TRouteIdentifier, teamId: TRouteIdentifier) =>
    `/organization/${orgId}/team/${teamId}/setup/suggestions/delivery`,
  toInclusivity: (orgId: TRouteIdentifier, teamId: TRouteIdentifier) =>
    `/organization/${orgId}/team/${teamId}/setup/suggestions/inclusivity`,
  toCompliance: (orgId: TRouteIdentifier, teamId: TRouteIdentifier) =>
    `/organization/${orgId}/team/${teamId}/setup/suggestions/compliance`,
  toPlagiarism: (orgId: TRouteIdentifier, teamId: TRouteIdentifier) =>
    `/organization/${orgId}/team/${teamId}/setup/suggestions/plagiarism`,
  toGeneral: (orgId: TRouteIdentifier, teamId: TRouteIdentifier) =>
    `/organization/${orgId}/team/${teamId}/setup/suggestions/general`,
  toCustomRules: (orgId: TRouteIdentifier, teamId: TRouteIdentifier) =>
    `/organization/${orgId}/team/${teamId}/setup/suggestions/custom-rules`,
  toReWrite: (orgId: TRouteIdentifier, teamId: TRouteIdentifier) =>
    `/organization/${orgId}/team/${teamId}/setup/suggestions/reWrite`,
  toKnowledge: (orgId: string, teamId: string, fileId?: string) =>
    `/organization/${orgId}/team/${teamId}/setup/suggestions/knowledge${fileId ? `?fileId=${fileId}` : ''}`,
  toMagicLinks: (orgId: TRouteIdentifier, teamId: TRouteIdentifier) =>
    `/organization/${orgId}/team/${teamId}/setup/suggestions/magicLinks`,
  toVoice: (orgId: TRouteIdentifier, teamId: TRouteIdentifier) =>
    `/organization/${orgId}/team/${teamId}/setup/suggestions/voice`,
  toTeamPrompts: (orgId: TRouteIdentifier, teamId: TRouteIdentifier) =>
    `/organization/${orgId}/team/${teamId}/setup/suggestions/team-prompts`,
};
