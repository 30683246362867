import React from 'react';
import cx from 'classnames';
import styles from './styles.module.css';
import { iconsList, type IconVariant } from './iconsList';

export { IconVariant } from './iconsList';

export interface IconProps {
  className?: string;
  style?: React.CSSProperties;
  name: IconVariant;
  width?: number;
  height?: number;
  color?: string;
  onClick?: (e: React.MouseEvent) => void;
}

const addIconName = (
  iconsList: Record<IconVariant, React.FC<React.SVGProps<SVGSVGElement>>>,
): Record<IconVariant, React.FC<React.SVGProps<SVGSVGElement>>> => {
  // @ts-expect-error - no clue why ts-ignore was here, tagging @leo since he is in git blame
  const iconsListWithDataIconName: Record<IconVariant, React.FC<React.SVGProps<SVGSVGElement>>> = {};

  for (const iconName in iconsList) {
    if (Object.prototype.hasOwnProperty.call(iconsList, iconName)) {
      const IconComponent = iconsList[iconName as IconVariant];
      iconsListWithDataIconName[iconName as IconVariant] = (props: React.SVGProps<SVGSVGElement>) => (
        <IconComponent data-icon-name={iconName} {...props} />
      );
    }
  }

  return iconsListWithDataIconName;
};

export const Icon: React.FC<IconProps> = ({ width, height, color, name, className, style, onClick, ...props }) => {
  const iconProps = width && height ? { height, width, color, ...props } : { ...props, color };
  const list = addIconName(iconsList);
  const icon = list[name];

  if (!icon) {
    return null;
  }

  return (
    <i aria-hidden className={cx(styles.container, className)} style={style} onClick={onClick}>
      {icon(iconProps)}
    </i>
  );
};

export default Icon;
