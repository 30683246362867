import { makeObservable, computed } from 'mobx';
import type { TSubscriptionLimit } from '@writerai/types';

import { PromisedModel } from '@writerai/mobx';
import type { components, RequestServiceInitialize } from '@writerai/network';
import { Enum } from '@writerai/utils';

export const TSubscriptionLimitType = new Enum(
  'contentCheck',
  'coWrite',
  'transcript',
  'reWrite',
  'domain',
  'team',
  'user',
  'graph',
  'voice',
);

export interface ILimitsModelParams {
  api: RequestServiceInitialize['api'];
  organizationId: number;
  teamId: number | undefined;
}

export class LimitsModel {
  constructor(private opts: ILimitsModelParams) {
    makeObservable(this, {
      coWrite: computed,
      domain: computed,
      transcript: computed,
      contentCheck: computed,
      graph: computed,
      voice: computed,
      reWrite: computed,
      user: computed,
      isLoaded: computed,
    });
  }

  private readonly $subscriptionLimits: PromisedModel<TSubscriptionLimit[] | undefined> = new PromisedModel({
    name: '$subscriptionLimits',
    load: async () =>
      this.opts.api
        .get('/api/billing/organizations/{organizationId}/subscription/limit', {
          params: {
            path: {
              organizationId: this.opts.organizationId,
            },
          },
        })
        .then(r => r.data),
  });

  private readonly $coWriteLimit = new PromisedModel({
    name: '$coWriteLimit',
    load: async () => this.makeOrgLimitsRequest(TSubscriptionLimitType.enum.coWrite),
  });

  private readonly $domainLimit = new PromisedModel({
    name: '$domainLimit',
    load: async () => this.makeOrgLimitsRequest(TSubscriptionLimitType.enum.domain),
  });

  private readonly $transcriptLimit = new PromisedModel({
    name: '$transcriptLimit',
    load: async () => this.makeOrgLimitsRequest(TSubscriptionLimitType.enum.transcript),
  });

  private readonly $contentCheckLimit = new PromisedModel({
    name: '$contentCheckLimit',
    load: async () => this.makeOrgLimitsRequest(TSubscriptionLimitType.enum.contentCheck),
  });

  private readonly $graphLimit = new PromisedModel({
    name: '$graphLimit',
    load: async () => this.makeTeamLimitsRequest(TSubscriptionLimitType.enum.graph),
  });

  private readonly $voiceLimit = new PromisedModel({
    name: '$voiceLimit',
    load: async () => this.makeTeamLimitsRequest(TSubscriptionLimitType.enum.voice),
  });

  private readonly $reWriteLimit = new PromisedModel({
    name: '$reWriteLimit',
    load: async () => this.makeUserLimitsRequest(TSubscriptionLimitType.enum.reWrite),
  });

  private readonly $userLimit = new PromisedModel({
    name: '$userLimit',
    load: async () => this.makeOrgLimitsRequest(TSubscriptionLimitType.enum.user),
  });

  private readonly $teamLimit = new PromisedModel({
    name: '$teamLimit',
    load: async () => this.makeOrgLimitsRequest(TSubscriptionLimitType.enum.team),
  });

  get isLoaded(): boolean {
    return !!this.$subscriptionLimits.value;
  }

  get coWrite() {
    return this.getLimit(TSubscriptionLimitType.enum.coWrite, this.$coWriteLimit.value);
  }

  get domain() {
    return this.getLimit(TSubscriptionLimitType.enum.domain, this.$domainLimit.value);
  }

  get transcript() {
    return this.getLimit(TSubscriptionLimitType.enum.transcript, this.$transcriptLimit.value);
  }

  get contentCheck() {
    return this.getLimit(TSubscriptionLimitType.enum.contentCheck, this.$contentCheckLimit.value);
  }

  get graph() {
    if (!this.$graphLimit.value) {
      return undefined;
    }

    return this.getLimit(TSubscriptionLimitType.enum.graph, this.$graphLimit.value);
  }

  get voice() {
    if (!this.$voiceLimit.value) {
      return undefined;
    }

    return this.getLimit(TSubscriptionLimitType.enum.voice, this.$voiceLimit.value);
  }

  get reWrite() {
    return this.getLimit(TSubscriptionLimitType.enum.reWrite, this.$reWriteLimit.value);
  }

  get user() {
    return this.getLimit(TSubscriptionLimitType.enum.user, this.$userLimit.value);
  }

  get team() {
    return this.getLimit(TSubscriptionLimitType.enum.team, this.$teamLimit.value);
  }

  reloadSubscriptionLimits = async (): Promise<TSubscriptionLimit[] | undefined> => {
    this.$subscriptionLimits.reload();

    return this.$subscriptionLimits.promise;
  };

  private readonly H_RELOAD = TSubscriptionLimitType.hash({
    coWrite: () => {
      this.$coWriteLimit.reload();

      return this.$coWriteLimit.promise;
    },
    domain: () => {
      this.$domainLimit.reload();

      return this.$domainLimit.promise;
    },
    transcript: () => {
      this.$transcriptLimit.reload();

      return this.$transcriptLimit.promise;
    },
    contentCheck: () => {
      this.$contentCheckLimit.reload();

      return this.$contentCheckLimit.promise;
    },
    graph: () => {
      this.$graphLimit.reload();

      return this.$graphLimit.promise;
    },
    voice: () => {
      this.$voiceLimit.reload();

      return this.$voiceLimit.promise;
    },
    reWrite: () => {
      this.$reWriteLimit.reload();

      return this.$reWriteLimit.promise;
    },
    user: () => {
      this.$userLimit.reload();

      return this.$userLimit.promise;
    },
    team: () => {
      this.$teamLimit.reload();

      return this.$teamLimit.promise;
    },
  });

  reloadLimit = async (limitType: typeof TSubscriptionLimitType.type) => this.H_RELOAD[limitType]();

  private makeOrgLimitsRequest = async (
    creditType:
      | typeof TSubscriptionLimitType.enum.contentCheck
      | typeof TSubscriptionLimitType.enum.coWrite
      | typeof TSubscriptionLimitType.enum.domain
      | typeof TSubscriptionLimitType.enum.transcript
      | typeof TSubscriptionLimitType.enum.user
      | typeof TSubscriptionLimitType.enum.team,
  ) =>
    this.opts.api
      .get('/api/billing/organizations/{organizationId}/usage/{creditType}', {
        params: {
          path: {
            organizationId: this.opts.organizationId,
            creditType,
          },
        },
      })
      .then(r => r.data);

  private makeTeamLimitsRequest = async (
    creditType: typeof TSubscriptionLimitType.enum.graph | typeof TSubscriptionLimitType.enum.voice,
  ) => {
    const { organizationId, teamId } = this.opts;

    if (!teamId) {
      return undefined;
    }

    return this.opts.api
      .get('/api/billing/organizations/{organizationId}/team/{teamId}/usage/{creditType}', {
        params: {
          path: {
            organizationId,
            teamId,
            creditType,
          },
        },
      })
      .then(r => r.data);
  };

  private makeUserLimitsRequest = async (creditType: typeof TSubscriptionLimitType.enum.reWrite) =>
    this.opts.api
      .get('/api/billing/organizations/{organizationId}/usage/{creditType}/user', {
        params: {
          path: {
            organizationId: this.opts.organizationId,
            creditType,
          },
        },
      })
      .then(r => r.data);

  private getLimit(
    limitType: typeof TSubscriptionLimitType.type,
    usage?: components['schemas']['billing_model_UsageItem'],
  ) {
    if (!usage) {
      return undefined;
    }

    const usageForLimit = this.getUsageForLimit(limitType);
    const { value, limit } = usage;

    return {
      exceeded: value >= limit || !!usageForLimit,
      limit,
      value,
      blockedUntil: usageForLimit?.blockedUntil ?? undefined,
    };
  }

  private getUsageForLimit = (
    limitType: typeof TSubscriptionLimitType.type,
  ): components['schemas']['billing_model_ApiSubscriptionLimit'] | undefined => {
    const usageRecord = this.$subscriptionLimits.value;

    if (!usageRecord) {
      return undefined;
    }

    return usageRecord.find(l => l.type === limitType);
  };
}
