import React, { useEffect, useMemo, useState } from 'react';
import type { DropdownOption } from '@writerai/ui-atoms';
import { CaseSensitiveOptions, TermsPopupFields } from '@writerai/types';
import { Text, TextSize } from '@writerai/ui-atoms';
import styles from './styles.module.css';
import { InputGroup, InputTypes } from '../InputGroup';

export const caseSensitiveOptions: DropdownOption[] = [
  { id: CaseSensitiveOptions.YES, name: CaseSensitiveOptions.YES, active: false },
  { id: CaseSensitiveOptions.NO, name: CaseSensitiveOptions.NO, active: false },
];

interface ICaseSensitiveDropdown {
  value: boolean;
  showLabel?: boolean;
  onUpdate?: (val: boolean, fieldType?: TermsPopupFields.CASE_SENSITIVE) => void;
}

export const CaseSensitiveDropdown: React.FC<ICaseSensitiveDropdown> = ({ showLabel, value, onUpdate }) => {
  const [localValue, setLocalValue] = useState<CaseSensitiveOptions>(
    value ? CaseSensitiveOptions.YES : CaseSensitiveOptions.NO,
  );
  const dropdownOptions = useMemo(
    () => caseSensitiveOptions.map(d => ({ ...d, active: d.id === localValue })),
    [localValue],
  );

  const onChangeDropdown = (val: CaseSensitiveOptions) => {
    const _updatedValue = val === CaseSensitiveOptions.YES;
    onUpdate && onUpdate(_updatedValue, TermsPopupFields.CASE_SENSITIVE);
    setLocalValue(val);
  };

  useEffect(() => setLocalValue(value ? CaseSensitiveOptions.YES : CaseSensitiveOptions.NO), [value]);

  return (
    <InputGroup
      name="caseSensitive"
      id="caseSensitive"
      className={styles.caseSensitiveInputGroup}
      inputType={InputTypes.SELECT}
      value={localValue || 'Select'}
      dropdownOptions={dropdownOptions}
      label={
        showLabel ? (
          <Text caps variant={TextSize.XS} className={styles.label}>
            Case Sensitive
          </Text>
        ) : (
          ''
        )
      }
      handleChangeInput={onChangeDropdown}
      disableHelperText
    />
  );
};

export default React.memo(CaseSensitiveDropdown);
