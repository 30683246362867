import type { TApplicationType } from './applications.types';

export const AnalyticsActivity = {
  firstTimeGoogleSSOLogin: '[Signup] First time Google SSO login', // when new signup via Google SSO
  invitedUserSignup: '[Signup] Invited User Signup', // when invited user signup
  startedNewTrial: '[Subscription] Started New Trial', // when new self serv user signup
  userJoined: '[Subscription] Invited User Signed Up', // when the invitee accepts invitation and Joined to Writer
  viewJoinOrCreateOrgPage: '[Domain Discoverability] Viewed Join or Create Own Org Page', // when user opens Join or Create Own Org page
  footerInstallChromeExtClicked: '[Dashboard Footer] Install Chrome Ext Clicked', // when clicks install Chrome Ext button in app footer
  footerInstallWordExtClicked: '[Dashboard Footer] Install Word Clicked', //  when clicks install Word Ext button in app footer
  footerInstallOutlookExtClicked: '[Dashboard Footer] Install Outlook Clicked', //  when clicks install Outlook Ext button in app footer
  installChromeExtClicked: '[Chrome Ext Popup] Install Chrome Ext Clicked', //  when clicks install chrome ext button in Chrome Ext window
  installWordOutlookExtClicked: '[Chrome Ext Popup] Install Word/Outlook Clicked', //  when clicks install word/outlook ext button in Chrome Ext window
  chromeWindowDismissed: '[Chrome Ext Popup] Popup Dismissed', // when clicks close Chrome Ext window
  clickAdminPanel: '[Navigation] Clicked Admin Panel', // when clicks on admin panel button
  clickPurchaseProNow: '[Billing v2] Clicked Purchase Pro Now', // These events are for users who clicked the various “Purchase or Buy Now” CTAs when on Pro Trial OR on Free, to open up the Pro checkout page
  clickPurchaseTeamNow: '[Billing v2] Clicked Purchase Team Now', // These events are for users who clicked the various “Purchase or Buy Now” CTAs when on Team Trial, to open up the Team checkout page
  clickStartTeamTrial: '[Billing v2] Clicked Start Team Trial', // These events are for users who clicked the various “Start Team Trial” buttons
  clickStartProTrial: '[Billing v2] Clicked Start Pro Trial', // These events are for users who clicked the various “Start Pro Trial” buttons
  clickedContactSales: '[Billing v2] Clicked Contact Sales', // These events are for users who clicked the various “Contact sales” buttons that go to Sales page
  signedUp: '[Signup v2] Signed Up', // when user signup
  dashboardViewed: '[Dashboard] Viewed Dashboard', // when user opens Dashboard page
  suggestionsPunctuationViewed: '[Suggestions] Viewed Punctuation', // when user opens suggestions Punctuation page
  suggestionsWritingStyleViewed: '[Suggestions] Viewed Writing Style', // when user opens suggestions  Writing Style page
  suggestionsClarityViewed: '[Suggestions] Viewed Clarity', // when user opens suggestions  Writing Style page
  suggestionsInclusivityViewed: '[Suggestions] Viewed Inclusivity', // when user opens suggestions  Inclusivity page
  suggestionsDeliveryViewed: '[Suggestions] Viewed Delivery', // when user opens suggestions Delivery page
  suggestionsPlagiarismViewed: '[Suggestions] Viewed Plagiarism', // when user opens suggestions Plagiarism page
  suggestionsComplianceViewed: '[Suggestions] Viewed Compliance', // when user opens suggestions Plagiarism page
  suggestionsGeneralViewed: '[Suggestions] Viewed General', // when user opens suggestions General page
  suggestionsCustomRulesViewed: '[Suggestions] Viewed Custom Rules', // when user opens suggestions Custom Rules page
  suggestionsPunctuationUpdated: '[Suggestions] Updated Punctuation', // when user updates suggestions Custom Rules rules
  suggestionsWritingStyleUpdated: '[Suggestions] Updated Writing Style', // when user updates suggestions Writing Style rules
  suggestionsClarityUpdated: '[Suggestions] Updated Clarity', // when user updates suggestions Clarity rules
  suggestionsInclusivityUpdated: '[Suggestions] Updated Inclusivity', // when user updates suggestions Inclusivity rules
  suggestionsDeliveryUpdated: '[Suggestions] Updated Delivery', // when user updates suggestions Delivery rules
  suggestionsPlagiarismUpdated: '[Suggestions] Updated Plagiarism', // when user updates suggestions Plagiarism rules
  suggestionsComplianceUpdated: '[Suggestions] Updated Compliance', // when user updates suggestions Compliance rules
  suggestionsGeneralUpdated: '[Suggestions] Updated General', // when user updates suggestions General data
  // suggestions ReWrite rules
  suggestionsReWriteUpdated: '[Suggestions] Updated ReWrite', // when user updates suggestions
  // suggestions magic links
  magicLinksUpdated: '[Suggestions] Updated Magic Links', // when user updates suggestions General data
  termsViewed: '[Terms] Viewed Terms', // when user opens Terms page
  termAdded: '[Terms] Added a Term', // when user creates a new Term page
  termEdited: '[Terms] Edited a Term', // when user changes a Term page
  snippetsViewed: '[Snippets] Viewed Snippets', // when user opens Snippets page
  snippetAdded: '[Snippets] Added a Snippet', // when user add a new Snippet
  teammateInvited: '[Teammates] Invited a Teammate', // when user invite a new Teammate
  snippetEdited: '[Snippets] Edited a Snippet', // when user opens Snippets page
  teammatesViewed: '[Teammates] Viewed Teammates', // when user opens Teammates page
  profileViewed: '[Profile] Viewed Profile', // when user opens Profile page
  billingViewed: '[Billing] Viewed Billing', // when user opens Billing page
  appsViewed: '[Apps] Viewed Apps', // when user opens Apps page
  autoJoinCreatedOwnOrg: '[Domain Discoverability] Created Own Org', // when user creates own org
  autoJoinSelectJoinOrg: '[Domain Discoverability] Selected Join Existing Org', // when user selects join existing org
  securityViewed: '[Admin] Viewed Security', // when user opens Apps page
  peopleViewed: '[Admin] Viewed People', // when user People Page (under the Admin Panel. Not “Teammates”)
  accountViewed: '[Admin] Viewed Account', // when user opens Account page
  allTeamsViewed: '[Admin] Viewed All Teams', // when user opens All Teams page
  ssoViewed: '[Admin] Viewed Single Sign-on', // when user opens SSO configuration page
  openDocument: '[Docs] Opened a Doc', // when user opens a Document page
  createDocument: '[Docs] Created a New Doc', // when user opens a Document page
  startWithCoWrite: '[Docs] Clicked Start from CoWrite', // when user starts a new document with CoWrite
  onboardingQuestionnaire: '[Signup] Viewed Onboarding Questionnaire', // when user open Onboarding Questionnaire
  onboardingQuestionnairePage2SubmitClicked: '[Signup] Clicked “Let’s go” on Onboarding Questionnaire Page 2', // when user clicks Let’s go button on Onboarding Questionnaire Invite teammates page
  subscriptionStartedNewTeamTrial: '[Subscription] Started New Team Trial', // when user signup with Team Trial plan
  subscriptionStartedNewProTrial: '[Subscription] Started New Pro Trial', // when user signup with Pro Trial plan
  subscriptionStartedNewFreeAccount: '[Subscription] Started New Free Account', // when user signup with Free plan
  clickedChromeExtAnnouncement: '[Global Nav] Clicked Chrome Ext Announcement', // when user clicks on Chrome Announcement Button
  tutorialViewedWelcomeStep: '[Tutorial] Viewed Welcome Step',
  tutorialViewedSuggestionsStep: '[Tutorial] Viewed Suggestions Step',
  tutorialViewedTermsStep: '[Tutorial] Viewed Terms Step',
  tutorialViewedSnippetsStep: '[Tutorial] Viewed Snippets Step',
  tutorialViewedAppsStep: '[Tutorial] Viewed Apps Step',
  tutorialViewedStyleguideStep: '[Tutorial] Viewed Styleguide Step',
  tutorialViewsDemoDocStep: '[Tutorial] Viewed Demo Doc Step',
  tutorialCompleted: '[Tutorial] Completed Tutorial',
  tutorialSkipped: '[Tutorial] Skipped Tutorial',
  tutorialRestarted: '[Tutorial] Restarted Tutorial',
  tutorialExited: '[Tutorial] Exited Tutorial',
  clickedInstallChromeExt: '[Apps] Clicked Install Chrome Ext', // when user clicks on Install Chrome Ext button on Apps page
  clickedInstallWordExt: '[Apps] Clicked Install Word Ext', // when user clicks on Install Word Ext button on Apps page
  clickedInstallOutlookExt: '[Apps] Clicked Install Outlook Ext', // when user clicks on Install Outlook Ext button on Apps page
  clickedInstallEdgeExt: '[Apps] Clicked Install Edge Ext', // when user clicks on Install Edge Ext button on Apps page
  clickedInstallMacExt: '[Apps] Clicked Install Mac Ext', // when user clicks on Install Mac Ext button on Apps page
  clickedInstallWinExt: '[Apps] Clicked Install Windows Ext', // when user clicks on Install Win Ext button on Apps page
  clickedInstallContentfulExt: '[Apps] Clicked Install Contentful Ext', // when user clicks on Install Contentful Ext button on Apps page
  clickedInstallFigmaExt: '[Apps] Clicked Install Figma Ext', // when user clicks on Install Figma Ext button on Apps page
  educationalModulesTermsClickedQuickStartVideo: '[Educational Modules] Terms: Clicked Quick Start Video', // when user clicks on Quick Start Video link on Term page
  educationalModulesTermsClickedAddTerm: '[Educational Modules] Terms: Clicked Add a Term', // when user clicks Add Term link on Term page
  educationalModulesTermsClickedImportTerms: '[Educational Modules] Terms: Clicked Import Terms', // when user clicks Add Term link on Term page
  educationalModulesSnippetsClickedQuickStartVideo: '[Educational Modules] Snippets: Clicked Quick Start Video', // when user clicks Quick Start Video link on Snippets page
  educationalModulesSnippetsClickedAddTerm: '[Educational Modules] Snippets: Clicked Add a Term', // when user clicks Add Term on Snippets page

  // Sidebar events
  refreshScore: '[Editor] Refresh Clicked', // when user clicks Refresh score
  startTeamTrial: '[Billing v2] Clicked Start Team Trial', // when user clicks Start Team Trial
  purchasePro: '[Billing v2] Clicked Purchase Pro Now', // when user clicks Purchase pro
  filteredSuggestions: '[Editor] Filtered Suggestions', // when user clicks Purchase pro
  suggestionAccepted: '[Suggestions] Suggestion Accepted', // when user accepts suggestion
  suggestionIgnored: '[Suggestions] Suggestion Ignored', // when user ignores suggestion
  suggestionFlagged: '[Suggestions] Suggestion Flagged', // when user flags suggestion
  suggestionViewed: '[Suggestions] Suggestion Viewed', // when user clicks suggestion (inline or in sidebar)
  suggestionAddedToTermBank: '[Suggestions] Suggestion Added to Terms', // when user adds suggestion to Terms
  snippetClicked: '[Snippets] Clicked on a Snippet ', // when user clicks Snippet
  snippetTriggered: '[Snippets] Triggered Snippet', // when snippet is shown in editor

  // Apps Access page events
  signupViewedAppConsentPage: '[Signup] Viewed App Consent Page', // when user opens App Consent Page
  signupClickedAllowAccessOnAppConsent: '[Signup] Clicked Allow Access on App Consent', // when user clicks Access button on App Consent Page

  // NLG
  contentRephrased: '[NLG] Rephrased Content', // Generates a ReWrite output (either by opening the widget for first time or tabbing between ReWrite options)
  contentRegenerated: '[NLG] Rephrased Content Regenerated', // Clicked the “regenerate” button
  rephrasedContentAccepted: '[NLG] Rephrased Content Accepted', // Clicks an output to automatically accept it
  rephrasedContentCopied: '[NLG] Rephrased Content Copied', // Clicks “Copy to clipboard” button
  triggerAutoWrite: '[NLG] Triggered Keep Writing', // Clicks “Keep writing” button

  coWriteViewedCategoryAll: '[NLG] Viewed Template Library: All',
  coWriteViewedCategoryProduct: '[NLG] Viewed Template Library: Product',
  coWriteViewedCategoryMarketing: '[NLG] Viewed Template Library: Marketing',
  coWriteViewedCategorySupport: '[NLG] Viewed Template Library: Support',
  coWriteViewedCategoryHR: '[NLG] Viewed Template Library: HR',
  coWriteViewedCategoryEmployeeComms: '[NLG] Viewed Template Library: Employee Comms',
  coWriteViewedCategoryAdvertising: '[NLG] Viewed Template Library: Advertising',
  coWriteViewedCategoryRecruiting: '[NLG] Viewed Template Library: Recruiting',
  coWriteViewedCategoryExternalComms: '[NLG] Viewed Template Library: External Comms',
  coWriteViewedCategorySales: '[NLG] Viewed Template Library: Sales',
  coWriteViewedCategoryCustom: '[NLG] Viewed Template Library: Custom',
  coWriteViewedCategoryGeneral: '[NLG] Viewed Template Library: General',
  coWriteViewedCustomTemplateRequestForm: '[NLG] Viewed Custom Template Request Form',
  coWriteContactSalesCustomTemplates: '[NLG] Clicked Contact Sales on Custom Templates',
  coWriteContactSalesSEOBlogBuilder: '[NLG] Clicked Contact Sales on SEO Blog Builder',
  coWriteContactSalesTemplateLibrary: '[NLG] Clicked Contact Sales on Template Library',
  coWriteTemplateSearch: '[NLG] Searched Templates',
  coWriteTemplateOpened: '[NLG] Opened Template',
  coWriteViewedSeoBlogBuilderStep1: '[NLG] Viewed SEO Blog Builder: Step 1',
  coWriteViewedSeoBlogBuilderStep2: '[NLG] Viewed SEO Blog Builder: Step 2',
  coWriteViewedSeoBlogBuilderStep3: '[NLG] Viewed SEO Blog Builder: Step 3',
  coWriteSeoBlogBuilderGeneratedMoreTitles: '[NLG] SEO Blog Builder: Generated More Titles',
  coWriteSeoBlogBuilderGeneratedNewOutline: '[NLG] SEO Blog Builder: Generated New Outline',
  coWriteSeoBlogBuilderGeneratedRegeneratedPoints: '[NLG] SEO Blog Builder: Regenerated Points',
  coWriteSeoBlogBuilderGeneratedGeneratedContent: '[NLG] SEO Blog Builder: Generated Content',
  cowriteAnnouncementDismissed: '[Cowrite Announcement] Popup Dismissed', // Announcement closed
  cowriteAnnouncementClicked: '[Cowrite Announcement] Ideate with Cowrite clicked', // "Ideate with CoWrite clicked"

  coWriteHighlightsOpened: '[NLG] Opened Highlights',
  coWriteHighlightsRegeneratedMeta: '[NLG] Regenerated Meta Description',
  coWriteHighlightsRegeneratedSummary: '[NLG] Regenerated Summary',
  coWriteHighlightsRegeneratedLinkedIn: '[NLG] Regenerated LI Post',
  coWriteHighlightsRegeneratedTwitter: '[NLG] Regenerated Twitter Post',
  coWriteHighlightsCopiedMeta: '[NLG] Copied Meta Description',
  coWriteHighlightsCopiedSummary: '[NLG] Copied Summary',
  coWriteHighlightsCopiedLinkedIn: '[NLG] Copied LI Post',
  coWriteHighlightsCopiedTwitter: '[NLG] Copied Twitter Post',
  magicLinksContactSales: '[NLG] Magic links Upgrade now Clicked',

  commandsOpened: '[Commands] Opened Commands',
  commandsEntered: '[Commands] Entered Freeform Command',
  commandsGeneratedNew: '[Commands] Generated New',
  commandsGeneratedFromText: '[Commands] Generated From Text',
  commandsEnteredReWrite: '[Commands] Entered ReWrite Command',
  commandsSelectedReWrite: '[Commands] Selected a ReWrite Option',
  commandsEnteredKeepWriting: '[Commands] Entered Keep Writing',
  commandsEnteredAskKnowledgeGraph: '[Commands] Entered Ask Knowledge Graph',
  commandsClickedRewrite: '[Commands] Clicked Rewrite',
  commandsCopiedRewriteSuggestion: '[Commands] Copied Rewrite Suggestion',
  commandsReplacedTextWithRewriteSuggestion: '[Commands] Replaced Text with Rewrite Suggestion',
  commandsClickedShowMoreRewriteSuggestions: '[Commands] Clicked Show More Rewrite Suggestions',
  commandsClickedRegenerate: '[Commands] Clicked Regenerate',
  commandsNavigatedTheArrows: '[Commands] Navigated the Arrows',
  commandsClickedKeepWriting: '[Commands] Clicked Keep Writing',
  commandsClickedMenuItem: '[Commands] Selected a Command Option',
  commandsNavigateOutputs: '[Commands] Navigated Previous Outputs',
  commandsClickedInsert: '[Commands] Clicked Insert Button',
  commandsClickedCopy: '[Commands] Clicked Copy Button',
  commandsClosedWidget: '[Commands] Closed Commands Widget',
  commandsClickedRewriteButton: '[Commands] Clicked Rewrite Button',

  billingTeamTrialExtension: '[Subscriptions] Accepted Team Trial Extension',
  magciLinksViewPage: '[Magic Links] Viewed Magic Links Setup',
  magciLinksAddDomain: '[Magic Links] Added a Domain',
  magciLinksValidateDomain: '[Magic Links] Clicked Validate Domain',
  magciLinksManageCrawls: '[Magic Links] Clicked Manage Crawls',
  magciLinksCrawlAll: '[Magic Links] Clicked Crawl Now on All Domains',
  magciLinksCrawl: '[Magic Links] Clicked Crawl Now on a Domain',
  magicLinksUserClickedIcon: '[Magic Links] Opened Magic Links',
  magicLinksClickedSuggestions: '[Magic Links] Accepted Link Suggestion',
  magicLinksHighlightedText: '[Magic Links] Highlighted Editor Text',
  magicLinksSearchedKeyword: '[Magic Links] Searched for Keywords',
  magicLinksAddedCustomURL: '[Magic Links] Added a Custom URL',

  termsClickedImportCSV: '[Terms] Clicked Import CSV',
  termsClickedLearnMore: '[Terms] Clicked Learn more',
  termsUploadedFile: '[Terms] Uploaded a File',
  termsClickedImport: '[Terms] Clicked Import Terms',
  termsImportSucceeded: '[Terms] Import Succeeded',

  snippetsClickedImportCSV: '[Snippets] Clicked Import CSV',
  snippetsClickedLearnMore: '[Snippets] Clicked Learn more',
  snippetsUploadedFile: '[Snippets] Uploaded a File',
  snippetsTriggered: '[Snippets] Triggered a Snippet',
  snippetsClickedImport: '[Snippets] Clicked Import Snippets',
  snippetsImportSucceeded: '[Snippets] Import Succeeded',

  billingGroupClickedLearnMore: '[BillingGroup] Clicked Learn more',
  billingGroupUploadedFile: '[BillingGroup] Uploaded a File',
  billingGroupClickedImport: '[BillingGroup] Clicked Import Snippets',
  billingGroupImportSucceeded: '[BillingGroup] Import Succeeded',

  claimDetectionTriggered: '[Claim Detection] Triggered Claim Detection',
  // Drafts
  viewedDraftsPage: '[Drafts] Viewed Drafts Table',
  viewedDraft: '[Drafts] Viewed Draft',
  previewedDraft: '[Drafts] Previewed Draft',
  filteredDrafts: '[Drafts] Filtered Drafts Table',
  searchedDrafts: '[Drafts] Searched Drafts',
  copyDraft: '[Drafts] Copied Text',
  rateDraft: '[Drafts] Clicked Rate Draft',
  deleteDraft: '[Drafts] Deleted Draft',
  showDraftInputs: '[Drafts] Clicked Show Inputs',
  useDraftInNewDoc: '[Drafts] Used in New Doc',

  // Learning Center
  learningCenterWatchedVideo1: '[Learning Center] Watched Video 1',
  learningCenterWatchedVideo2: '[Learning Center] Watched Video 2',
  learningCenterWatchedVideo3: '[Learning Center] Watched Video 3',
  learningCenterWatchedVideo4: '[Learning Center] Watched Video 4',
  learningCenterWatchedVideo5: '[Learning Center] Watched Video 5',
  learningCenterWatchedVideo6: '[Learning Center] Watched Video 6',
  learningCenterClickedWebinars: '[Learning Center] Clicked Webinars',
  learningCenterClickedGuides: '[Learning Center] Clicked Guides',
  learningCenterClickedSupportCenter: '[Learning Center] Clicked Support Center',
  learningCenterClickedWhatsNew: '[Learning Center] Clicked What’s New',
  learningCenterHidTray: '[Learning Center] Hid Onboarding Video Tray',
  learningCenterOpened: '[Learning Center] Opened Learning Center',
  learningCenterOpenedAskWriterAnything: '[Learning Center] Opened AWA',
  learningCenterOpenedRecaps: '[Learning Center] Opened Recaps',
  learningCenterOpenedBlogBuilder: '[Learning Center] Opened Blog Builder',
  learningCenterViewedWebinars: '[Learning Center] Viewed Webinars page',
  learningCenterClickedWebinarCTA: '[Learning Center] Webinars: Clicked Register/Watch Now',

  // AskWriter V2 TODO: do we need it?
  openedAskWriterSession: '[Ask Writer] Opened Ask Writer Session',
  addedSource: '[Ask Writer] Added a Source',
  removedSource: '[Ask Writer] Removed a Source',
  openedSessionPanel: '[Ask Writer] Opened Sessions Panel',
  enteredMessage: '[Ask Writer] Entered a Message',
  openedPastSourcesModal: '[Ask Writer] Opened Past Sources Modal',
  searchPastSources: '[Ask Writer] Searched Past Sources',
  startedNewSession: '[Ask Writer] Started New Session',
  searchedSessions: '[Ask Writer] Searched Sessions',
  deletedSession: '[Ask Writer] Deleted a Session',
  clearedSession: '[Ask Writer] Cleared a Session',
  viewedContributingSources: '[Ask Writer] Viewed Contributing Sources',
  openedContributingSource: '[Ask Writer] Opened a Contributing Source',
  copiedOutput: '[Ask Writer] Copied an Output',
  clickedSendOutputToDoc: '[Ask Writer] Clicked Send Output to Doc',

  // Chat Apps
  chatAppOpenedSession: '[Chat App] Opened Chat App Session',
  chatAppAddedSource: '[Chat App] Added a Source',
  chatAppRemovedSource: '[Chat App] Removed a Source',
  chatAppOpenedSessionPanel: '[Chat App] Opened Sessions Panel',
  chatAppEnteredMessage: '[Chat App] Entered a Message',
  chatAppOpenedPastSourcesModal: '[Chat App] Opened Past Sources Modal',
  chatAppSearchPastSources: '[Chat App] Searched Past Sources',
  chatAppStartedNewSession: '[Chat App] Started New Session',
  chatAppSearchedSessions: '[Chat App] Searched Sessions',
  chatAppDeletedSession: '[Chat App] Deleted a Session',
  chatAppClearedSession: '[Chat App] Cleared a Session',
  chatAppViewedContributingSources: '[Chat App] Viewed Contributing Sources',
  chatAppOpenedContributingSource: '[Chat App] Opened a Contributing Source',
  chatAppCopiedOutput: '[Chat App] Copied an Output',
  chatAppClickedSendOutputToDoc: '[Chat App] Clicked Send Output to Doc',
  chatAppFlaggedMessage: '[Chat App] Clicked Send Output to Doc',

  // Prompt Library
  viewedPromptLibrary: '[Prompt Library] Viewed Prompt Library',
  copiedPrompt: '[Prompt Library] Copied Prompt',
  insertedPromptInAskWriter: '[Prompt Library] Inserted Prompt in Ask Writer',
  viewedSubcategory: '[Prompt Library] Viewed Sub-Category',
  searchedPromptLibrary: '[Prompt Library] Searched Prompt Library',

  // Chrome Extension events
  autocorrectOn: '[Chrome Ext] Autocorrect Toggled On',
  autocorrectOff: '[Chrome Ext] Autocorrect Toggled Off',
  showSituationalBannerA: '[Chrome Banner A] Viewed Banner',
  clickSignInSituationalBannerA: '[Chrome Banner A] Clicked Sign in',
  showSituationalBannerB: '[Chrome Banner B] Viewed Banner',
  clickSignUpSituationalBannerB: '[Chrome Banner B] Clicked Sign up',
  viewedOnboardingSlide1: '[Chrome Ext] Viewed Onboarding Slide 1',
  viewedOnboardingSlide2: '[Chrome Ext] Viewed Onboarding Slide 2',
  viewedOnboardingSlide3: '[Chrome Ext] Viewed Onboarding Slide 3',
  viewedOnboardingSlide4: '[Chrome Ext] Viewed Onboarding Slide 4',
  viewedOnboardingSlide5: '[Chrome Ext] Viewed Onboarding Slide 5',
  viewedOnboardingSlide6: '[Chrome Ext] Viewed Onboarding Slide 6',
  viewedOnboardingSlide7: '[Chrome Ext] Viewed Onboarding Slide 7',
  activatedSidebar: '[Chrome Ext] Activated Sidebar',
  closedSidebar: '[Chrome Ext] Closed Sidebar',
  extWriterTabEnabled: '[Chrome Ext] Writer Tab Enabled',
  extWriterTabDisabled: '[Chrome Ext] Writer Tab Disabled',
  extOutdatedBrowserTooltipViewed: '[Chrome Ext] Outdated Browser Tooltip Viewed',

  // Application Console
  appConsoleAppDeployed: '[Application Console] App Deployed',
  appConsoleAppDeleted: '[Application Console] App Deleted',
  appConsoleAppUndeployed: '[Application Console] App Undeployed',
  appConsoleAppContentGenerated: '[Application Console] Generate Content',
  appConsoleSessionEndedDeployed: '[Application Console] Session ended with app undeployed',
  appConsoleDraftCreated: '[Application Console] Draft created',
  appConsoleDraftEdited: '[Application Console] Draft edited',
  appConsoleCopyAppClicked: '[Application Console] Copy app clicked',
  appConsoleShareAppClicked: '[Application Console] Share URL clicked',
  appConsoleFilterAppsUsed: '[Application Console] Filter app list used',
  appConsoleInputDeleted: '[Application Console] Input deleted',
  appConsolePromptDeleted: '[Application Console] Prompt deleted',

  // App Library
  appLibraryAllAppsShortcutClicked: '[App Library] Clicked All Apps Shortcut',
  appLibraryAllAppsOpened: '[App Library] Opened All Apps',
  appLibraryCustomAppsOpened: '[App Library] Opened Custom Apps',
  appLibraryFavoritesOpened: '[App Library] Opened Favorites',
  appLibraryFeaturedOpened: '[App Library] Opened Featured',
  appLibraryAppCategoryFilterClicked: '[App Library] Clicked an App Category Filter',
  appLibraryAppSearched: '[App Library] Searched for an App',
  appLibraryAppPreviewed: '[App Library] Previewed App',
  appLibraryAppOpened: '[App Library] Opened App',
  appLibraryAppFavorited: '[App Library] Favorited an App',
  appLibraryGridViewToggled: '[App Library] Toggled to Grid View',
  appLibraryListViewToggled: '[App Library] Toggled to List View',
  appLibraryRelatedAppOpened: '[App Library] Opened a Related App',
  appLibraryAppGuildeViewed: '[App Library] Viewed App Guide',

  // Voice
  voiceAddClicked: '[Voice] Clicked Add a Voice',
  voiceCalibrationSelected: '[Voice] Selected Calibration Method',
  voiceAddedTextForAnalysis: '[Voice] Added Text for Analysis',
  voiceCreated: '[Voice] Created a New Voice',
  voiceDeleted: '[Voice] Deleted Voice',
  voiceEdited: '[Voice] Edited Voice',
  voiceSetAsDefault: '[Voice] Set Voice as Default',

  // TeamPrompts
  createdNewTeamPrompt: '[Team Prompts] Created a new Team Prompt',
  saveTeamPromptsAw: '[Team Prompts] Clicked "Save to Team Prompts" from AW',
  saveTeamPromptsWp: '[Team Prompts] Clicked "Save to Team Prompts" from Writer Prompt Library',
  insertedTeamPrompt: '[Team Prompts] Inserted a Team Prompt',
  copiedTeamPrompt: '[Team Prompts] Copied a Team Prompts',

  // AI Studio People
  aiStudioPeopleInvitedUser: '[AI Studio Admin] Invited user',
  aiStudioPeopleChangedUserRole: '[AI Studio Admin] Changed user role',
  aiStudioPeopleDeletedUser: '[AI Studio Admin] Deleted user',
  aiStudioPeopleRemovedUser: '[AI Studio Admin] Removed user from AI Studio',
  aiStudioPeopleOrgAdminAssigned: '[AI Studio Admin] Org admin assigned',
  aiStudioPeopleOrgAdminUnassigned: '[AI Studio Admin] Org admin unassigned',
  aiStudioAccountOnly: '[AI Studio Subscription] Started New AI Studio only account',
  aiStudioOrgAdminOptedIntoAIStudio: '[AI Studio Subscription] Org admin opted into AI Studio',

  // KnowledgeGraph
  knowledgeGraphViewedPage: '[Knowledge Graph] Viewed Knowledge Graph Page',
  knowledgeGraphAddedGraph: '[Knowledge Graph] Added a Graph',
  knowledgeGraphUploadedFiles: '[Knowledge Graph] Uploaded Files',
  knowledgeGraphSyncedDataConnector: '[Knowledge Graph] Synced a Data Connector',
  knowledgeGraphSelectedAssetsToSync: '[Knowledge Graph] Selected Assets to Sync',

  // OAuth apps
  oAuthAppsViewedPage: '[OAuth Apps] Viewed OAuth apps page',
  oAuthAppsAddedApp: '[OAuth Apps] Added an OAuth app',

  // Templates
  templatesViewedPage: '[AI Studio Templates] Template viewed',
  templatesCopiedPage: '[AI Studio Templates] Template copied',

  // Api apps
  apiAppsApiKeyAdded: '[AI Studio API/Framework Builder] Api key added',
  apiAppsApiKeyRevoked: '[AI Studio API/Framework Builder] Api key revoked',
  apiAppsAppDeleted: '[AI Studio API/Framework Builder] Api app deleted',
  apiAppsCapabilityChanged: '[AI Studio API/Framework Builder] Api capability changed',

  // Framework builder
  frameworkKeyRevoked: '[AI Studio API/Framework Builder] Framework key revoked',
  frameworkKeyAdded: '[AI Studio API/Framework Builder] Framework key added',
  frameworkCapabilityChanged: '[AI Studio API/Framework Builder] Framework capability changed',

  // AI Studio Homepage
  aiStudioHomepageAppCreated: '[AI Studio Homepage] app created',
  aiStudioHomepageAppDeleted: '[AI Studio Homepage] app deleted',
  aiStudioHomepageApiAppCreated: '[AI Studio Homepage] api app created',
  aiStudioHomepageAppImported: '[AI Studio Homepage] app imported',
} as const;

export type TActivityParams = {
  [AnalyticsActivity.aiStudioPeopleInvitedUser]: {
    organization_id: number;
    user_email: string;
    user_id: number;
    invited_user_email: string;
    invited_user_role: string;
  };
  [AnalyticsActivity.aiStudioPeopleChangedUserRole]: {
    organization_id: number;
    user_email: string;
    user_id: number;
    changed_user_id: number;
    changed_user_email: string;
    changed_user_role: string;
  };
  [AnalyticsActivity.aiStudioPeopleDeletedUser]: {
    organization_id: number;
    user_email: string;
    user_id: number;
    deleted_user_email: string;
    deleted_user_id: number;
    deleted_user_role: string;
  };
  [AnalyticsActivity.aiStudioPeopleRemovedUser]: {
    organization_id: number;
    user_email: string;
    user_id: number;
    removed_user_email: string;
    removed_user_id: number;
    removed_user_role: string;
  };
  [AnalyticsActivity.aiStudioPeopleOrgAdminAssigned]: {
    organization_id: number;
    user_email: string;
    user_id: number;
    modified_user_email: string;
  };
  [AnalyticsActivity.aiStudioPeopleOrgAdminUnassigned]: {
    organization_id: number;
    user_email: string;
    user_id: number;
    modified_user_email: string;
  };
  [AnalyticsActivity.aiStudioAccountOnly]: {
    organization_id: number;
    user_email: string;
    user_id: number;
    plan_name: string;
  };
  [AnalyticsActivity.aiStudioOrgAdminOptedIntoAIStudio]: {
    organization_id: number;
    user_email: string;
    user_id: number;
    plan_name: string;
  };
  [AnalyticsActivity.suggestionViewed]: {
    suggestion_category: string;
    suggestion_issue_type: string;
    card_type: string;
    team_id: number;
  };
  [AnalyticsActivity.suggestionAccepted]: {
    suggestion_category: string;
    suggestion_issue_type: string;
    card_type: string;
    team_id: number;
    suggestions_count: number;
  };
  [AnalyticsActivity.suggestionIgnored]: {
    suggestion_category: string;
    suggestion_issue_type: string;
    card_type: string;
    team_id: number;
    suggestions_count: number;
  };
  [AnalyticsActivity.suggestionFlagged]: {
    suggestion_category: string;
    suggestion_issue_type: string;
    card_type: string;
    team_id: number;
  };
  [AnalyticsActivity.filteredSuggestions]: {
    filter_category: string;
    team_id: number;
  };
  [AnalyticsActivity.refreshScore]: Record<string, never>;
  [AnalyticsActivity.snippetClicked]: {
    snippet: string;
    id: string;
    team_id: number;
  };
  [AnalyticsActivity.snippetTriggered]: {
    snippet: string;
    id: string;
    team_id: number;
  };
  [AnalyticsActivity.autoJoinSelectJoinOrg]: {
    access_type: 'request_to_join' | 'immediate';
    auth_method: 'Password' | 'Saml' | 'Social' | 'App' | 'Admin' | 'Firebase';
  };
  [AnalyticsActivity.termsViewed]: {
    team_id: number;
  };
  [AnalyticsActivity.termAdded]: {
    team_id: number;
    term_type: string;
  };
  [AnalyticsActivity.termEdited]: {
    team_id: number;
    term_type: string;
  };
  [AnalyticsActivity.termsUploadedFile]: {
    team_id: number;
  };
  [AnalyticsActivity.snippetAdded]: {
    team_id: number;
  };
  [AnalyticsActivity.snippetEdited]: {
    team_id: number;
  };
  [AnalyticsActivity.snippetsUploadedFile]: {
    team_id: number;
  };
  [AnalyticsActivity.snippetsTriggered]: {
    team_id: number;
  };
  [AnalyticsActivity.userJoined]: {
    team_id?: number;
    signup_method: string;
    plan_name: string;
    subscription_status: string;
  };
  [AnalyticsActivity.subscriptionStartedNewTeamTrial]: {
    team_id: number;
  };
  [AnalyticsActivity.billingViewed]: {
    team_id: number;
  };
  [AnalyticsActivity.clickPurchaseTeamNow]: {
    team_id?: number;
    clicked_from: 'team_people' | 'trial_banner';
  };
  [AnalyticsActivity.clickedContactSales]: {
    clicked_from: 'billing_page' | 'all_teams' | 'custom_rules';
    team_id?: number;
  };
  [AnalyticsActivity.allTeamsViewed]: {
    team_id: number;
  };
  [AnalyticsActivity.peopleViewed]: {
    team_id: number;
  };
  [AnalyticsActivity.contentRephrased]: {
    suggestion_ui: 'rewrite';
    team_id: number;
    prompt: string;
    selectedText: string;
  };
  [AnalyticsActivity.rephrasedContentAccepted]: {
    suggestion_ui: 'rewrite';
    team_id: number;
    prompt: string;
    text: string;
    suggestion: string;
    suggestionId: string;
    newSuggestion: string;
    newSuggestionId: string;
  };
  [AnalyticsActivity.contentRegenerated]: {
    suggestion_ui: 'rewrite';
    team_id: number;
    prompt: string;
    selectedText: string;
    suggestion: string;
    suggestionId: string;
  };
  [AnalyticsActivity.rephrasedContentCopied]: {
    suggestion_ui: 'rewrite';
    selectedText: string;
    prompt: string;
    suggestion: string;
    suggestionId: string;
    team_id: number;
  };
  [AnalyticsActivity.signedUp]: {
    team_id?: number;
    auth_method: string;
    email_consent: boolean;
    plan_name: string;
    subscription_status: string;
  };
  [AnalyticsActivity.coWriteHighlightsOpened]: {
    team_id: number;
  };
  [AnalyticsActivity.coWriteHighlightsRegeneratedSummary]: {
    team_id: number;
  };
  [AnalyticsActivity.coWriteHighlightsRegeneratedMeta]: {
    team_id: number;
  };
  [AnalyticsActivity.coWriteHighlightsRegeneratedLinkedIn]: {
    team_id: number;
  };
  [AnalyticsActivity.coWriteHighlightsRegeneratedTwitter]: {
    team_id: number;
  };
  [AnalyticsActivity.commandsOpened]: {
    team_id: number;
    integration_type: string;
    scenario: string;
    command_name: string;
  };
  [AnalyticsActivity.triggerAutoWrite]: {
    team_id: number;
    trigger_type: 'button' | 'keyboard';
  };
  [AnalyticsActivity.commandsEntered]: {
    integration_type: string;
    scenario: string;
    command_name: string;
    team_id: number;
  };
  [AnalyticsActivity.commandsGeneratedFromText]: {
    integration_type: string;
    scenario: string;
    command_name: string;
    team_id: number;
  };
  [AnalyticsActivity.commandsClickedKeepWriting]: {
    integration_type: string;
    scenario: string;
    command_name: string;
    team_id: number;
  };
  [AnalyticsActivity.commandsEnteredAskKnowledgeGraph]: {
    integration_type: string;
    scenario: string;
    team_id: number;
  };
  [AnalyticsActivity.commandsClickedRegenerate]: {
    integration_type: string;
    scenario: string;
    team_id: number;
  };
  [AnalyticsActivity.commandsNavigatedTheArrows]: {
    integration_type: string;
    scenario: string;
    team_id: number;
  };
  [AnalyticsActivity.commandsClickedRewrite]: {
    integration_type: string;
    scenario: string;
    team_id: number;
  };
  [AnalyticsActivity.commandsCopiedRewriteSuggestion]: {
    integration_type: string;
    scenario: string;
    team_id: number;
  };
  [AnalyticsActivity.commandsReplacedTextWithRewriteSuggestion]: {
    integration_type: string;
    scenario: string;
    team_id: number;
    command_name: string;
  };
  [AnalyticsActivity.commandsClickedShowMoreRewriteSuggestions]: {
    integration_type: string;
    scenario: string;
    team_id: number;
    command_name: string;
  };
  [AnalyticsActivity.magciLinksAddDomain]: {
    clicked_from: string;
    team_id: number;
  };
  [AnalyticsActivity.magicLinksUserClickedIcon]: {
    clicked_from: 'magic-links';
    team_id: number;
  };
  [AnalyticsActivity.appConsoleAppDeployed]: {
    organization_id?: number;
    application_id?: string;
    application_name?: string;
    user_email?: string | null;
  };
  [AnalyticsActivity.appConsoleSessionEndedDeployed]: {
    organization_id?: number;
    application_id?: string;
    application_name?: string;
    user_email?: string | null;
  };
  [AnalyticsActivity.appConsoleDraftCreated]: {
    organization_id?: number;
    original_author_user_email?: string | null;
    user_email?: string | null;
    application_id?: string;
    application_name?: string;
  };
  [AnalyticsActivity.appConsoleDraftEdited]: {
    organization_id?: number;
    original_author_user_email?: string | null;
    user_email?: string | null;
    application_id?: string;
    application_name?: string;
  };
  [AnalyticsActivity.appConsoleAppDeleted]: {
    organization_id?: number;
    user_email?: string | null;
    application_id?: string;
    application_name?: string;
  };
  [AnalyticsActivity.appConsoleAppUndeployed]: {
    organization_id?: number;
    user_email?: string | null;
    application_id?: string;
    application_name?: string;
  };
  [AnalyticsActivity.appConsoleAppContentGenerated]: {
    organization_id?: number;
    most_recent_deploy_user_email?: string | null;
    user_email?: string | null;
    application_id?: string;
    application_name?: string;
  };
  [AnalyticsActivity.appConsoleCopyAppClicked]: {
    organization_id?: number;
    application_id?: string;
    application_name?: string;
    user_email?: string | null;
  };
  [AnalyticsActivity.appConsoleSessionEndedDeployed]: {
    organization_id?: number;
    application_id?: string;
    application_name?: string;
    user_email?: string | null;
  };
  [AnalyticsActivity.appConsoleFilterAppsUsed]: {
    organization_id?: number;
    application_id?: string;
    application_name?: string;
    user_email?: string | null;
    filter_applied: string | null;
  };
  [AnalyticsActivity.appConsoleInputDeleted]: {
    organization_id?: number;
    application_id?: string;
    application_name?: string;
    user_email?: string | null;
    input_type: string | null;
    input_name: string | null;
    inputs_count: number | null;
  };
  [AnalyticsActivity.appConsolePromptDeleted]: {
    organization_id?: number;
    application_id?: string;
    application_name?: string;
    user_email?: string | null;
    input_type: string | null;
    input_name: string | null;
    inputs_count: number | null;
  };

  [AnalyticsActivity.termsClickedLearnMore]: {
    clicked_from: string;
    team_id: number;
  };

  [AnalyticsActivity.snippetsClickedLearnMore]: {
    clicked_from: string;
    team_id: number;
  };

  [AnalyticsActivity.billingGroupClickedLearnMore]: {
    clicked_from: string;
    team_id: number;
  };

  [AnalyticsActivity.termsUploadedFile]: {
    team_id: number;
  };

  [AnalyticsActivity.snippetsUploadedFile]: {
    team_id: number;
  };

  [AnalyticsActivity.billingGroupUploadedFile]: {
    team_id: number;
  };

  [AnalyticsActivity.termsClickedImport]: {
    team_id: number;
  };

  [AnalyticsActivity.snippetsClickedImport]: {
    team_id: number;
  };

  [AnalyticsActivity.billingGroupClickedImport]: {
    team_id: number;
  };

  [AnalyticsActivity.termsImportSucceeded]: {
    team_id: number;
  };

  [AnalyticsActivity.snippetsImportSucceeded]: {
    team_id: number;
  };

  [AnalyticsActivity.billingGroupImportSucceeded]: {
    team_id: number;
  };

  [AnalyticsActivity.createdNewTeamPrompt]: Record<string, never>;

  [AnalyticsActivity.saveTeamPromptsAw]: Record<string, never>;

  [AnalyticsActivity.saveTeamPromptsWp]: Record<string, never>;

  [AnalyticsActivity.insertedTeamPrompt]: Record<string, never>;

  [AnalyticsActivity.copiedTeamPrompt]: Record<string, never>;

  [AnalyticsActivity.extWriterTabEnabled]: {
    triggered_by: 'settings' | 'pop_up';
    rule: 'everywhere' | 'for_a_domain' | 'until_next_visit';
  };
  [AnalyticsActivity.extWriterTabDisabled]: {
    triggered_by: 'settings' | 'pop_up';
    rule: 'everywhere' | 'for_a_domain' | 'until_next_visit';
  };
  [AnalyticsActivity.extOutdatedBrowserTooltipViewed]: Record<string, never>;

  [AnalyticsActivity.templatesViewedPage]: {
    organization_id: number;
    user_email?: string | null;
    user_id: number;
    template_id: string;
  };

  [AnalyticsActivity.templatesCopiedPage]: {
    organization_id: number;
    user_email?: string | null;
    user_id: number;
    template_id: string;
  };

  [AnalyticsActivity.apiAppsApiKeyAdded]: {
    organization_id: number;
    user_email?: string | null;
    user_id: number;
    application_id?: string;
    application_name?: string;
    key_name?: string;
    old_application_name: string;
    application_type: TApplicationType;
  };
  [AnalyticsActivity.apiAppsApiKeyRevoked]: {
    organization_id: number;
    user_email?: string | null;
    user_id: number;
    application_id?: string;
    application_name?: string;
    key_name?: string;
    old_application_name: string;
    application_type: TApplicationType;
  };
  [AnalyticsActivity.apiAppsAppDeleted]: {
    organization_id: number;
    user_email?: string | null;
    user_id: number;
    application_id?: string;
    application_name?: string;
    application_type: TApplicationType;
  };
  [AnalyticsActivity.apiAppsCapabilityChanged]: {
    organization_id: number;
    user_email?: string | null;
    user_id: number;
    application_id?: string;
    application_name?: string;
    capabilities_added?: string[];
    capabilities_removed?: string[];
    application_type: TApplicationType;
  };
  [AnalyticsActivity.frameworkKeyAdded]: {
    organization_id: number;
    user_email?: string | null;
    user_id: number;
    application_id?: string;
    application_name?: string;
    old_application_name?: string;
    key_name?: string;
    application_type: TApplicationType;
  };
  [AnalyticsActivity.frameworkKeyRevoked]: {
    organization_id: number;
    user_email?: string | null;
    user_id: number;
    application_id?: string;
    application_name?: string;
    key_name?: string;
    application_type: TApplicationType;
  };
  [AnalyticsActivity.frameworkCapabilityChanged]: {
    organization_id: number;
    user_email?: string | null;
    user_id: number;
    application_id?: string;
    application_name?: string;
    capabilities_added?: string[];
    capabilities_removed?: string[];
    application_type: TApplicationType;
  };

  [AnalyticsActivity.aiStudioHomepageAppCreated]: {
    organization_id: number;
    user_email?: string | null;
    user_id: number;
    application_id?: string;
    application_type: TApplicationType;
  };

  [AnalyticsActivity.aiStudioHomepageAppDeleted]: {
    organization_id: number;
    user_email?: string | null;
    user_id: number;
    application_id?: string;
    application_type: TApplicationType;
  };

  [AnalyticsActivity.aiStudioHomepageAppImported]: {
    organization_id: number;
    user_email?: string | null;
    user_id: number;
    application_id?: string;
    application_type: TApplicationType;
  };
};

// Represents the structure of the AnalyticsActivity object
export type TAnalyticsActivity = typeof AnalyticsActivity;

// Provides all possible keys of the TAnalyticsActivity type
export type TActivityKeys = keyof TAnalyticsActivity;

// Default structure for activity parameters if they don't match predefined types
export type TDefaultActivityParams = Record<string, unknown>;

// Represents a type for individual analytic events
export type AnalyticEvents = TAnalyticsActivity[TActivityKeys];

/**
 * Represents the event parameters for each analytic event.
 * If a predefined type exists in TActivityParams, it uses that.
 * Otherwise, it defaults to TDefaultActivityParams.
 */
export type TActivityParamsOrAny = {
  [K in AnalyticEvents]: K extends keyof TActivityParams ? TActivityParams[K] : TDefaultActivityParams;
};

/**
 * Interface that defines the structure of parameters
 * required for tracking GTM activities.
 */
export interface IGtmActivityParams {
  event: 'custom-form-submit' | 'custom-page-details';
  userId: string;
  email: string | null;
  eventLabel?: 'Email' | 'Google' | 'SAML SSO';
  eventCategory?: 'Form Submission';
  loggedIn?: string;
  planType?: string;
  isTrial?: string;
  eventLabel2?: string;
  eventAction?: string;
  org?: string;
}
