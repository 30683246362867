
const digest = '94b15da1f49bfcef3bacb019ed6045959744d08a5fda832928a22928b6ba50c3';
const css = `.styles-module__ys-JSYw {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.styles-module__IBdqXFw {
  padding-right: 4px;
  padding-left: 7px;
}

.styles-module__MED8PGq {
  margin-top: 19px;
}

.styles-module__-7Adp9g {
  margin: 10px 20px 0;
}

.styles-module__cAaH-kf {
  margin: 10px var(--sidebar-horizontal-spacing-extra-small) 0;
}
`;

export default {"container":"styles-module__ys-JSYw","containerMini":"styles-module__IBdqXFw","enterpriseContainer":"styles-module__MED8PGq","issuesListSkeleton":"styles-module__-7Adp9g","issuesListSkeletonWide":"styles-module__cAaH-kf"};
export { css, digest };
  