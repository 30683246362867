/**
 * Replaces variables within double curly braces in the given string with the specified replacement word.
 * @param originalString - The string to replace variables in.
 * @param wordToBeReplacedWith - The replacement word to use.
 * @returns The string with variables replaced by the replacement word.
 */
export function replaceVariableWithinStringWithWord(originalString?: string, wordToBeReplacedWith = ''): string | null {
  if (!originalString) {
    return originalString ?? null;
  }

  return originalString.replace(/{{([^}]+)}}/g, wordToBeReplacedWith);
}
