import { computed } from 'mobx';
import { createSidebarApi } from './SidebarApi';
import type { IApiParams, ISidebarClient } from './types';

export const createSidebarClient = ({ request, documentParams }: IApiParams) => {
  const $api = computed(() => {
    const params = documentParams();

    if (!params) {
      return undefined;
    }

    const { documentId } = params;

    if (!documentId) {
      return undefined;
    }

    return createSidebarApi(request, { ...params, documentId });
  });

  return Object.defineProperties({} as ISidebarClient, {
    api: {
      enumerable: true,
      configurable: true,
      get() {
        return $api.get();
      },
    },
  });
};
