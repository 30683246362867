import React from 'react';
import cx from 'classnames';
import TimeAgo from 'react-timeago';
import { UserTeamRole } from '@writerai/types';
import styles from './styles.module.css';
import { Text, TextSize } from '../typography/Text/Text';
import { MuiTableCell, MuiTableRow } from '../mui';

export const HorizontalLine = ({ className = '' }: { className?: string }) => (
  <hr style={{ height: 1, backgroundColor: 'var(--classic-grey-1)', border: 0, margin: 0 }} className={className} />
);

export const DotSeparator = ({ className = '' }: { className?: string }) => (
  <span className={cx(styles.dotSeparator, className)}></span>
);

const timeAgoFormatter = (value: number, unit: string, suffix: string) => {
  if (unit !== 'second') {
    return [value, unit + (value !== 1 ? 's' : ''), suffix].join(' ');
  }

  if (suffix === 'ago') {
    return 'a few seconds ago';
  }

  if (suffix === 'from now') {
    return 'in a few seconds';
  }

  return '';
};

export const WTimeAgo = ({ date }: { date: string }) => (
  <TimeAgo minPeriod={60} date={date} formatter={timeAgoFormatter} title="" />
);

export const MoreButton = ({ className = '' }: { className: string }) => (
  <div
    style={{
      fontWeight: 600,
    }}
    className={className}
  >
    <span>...</span>
  </div>
);

export interface ILabeledTagsProps {
  className?: string;
  children: React.ReactNode;
  color?: string;
  bgColor?: string;
  style?: React.CSSProperties;
}

export const LabelledTags = ({
  className = '',
  children,
  color = 'var(--black)',
  bgColor = 'var(--classic-grey-2)',
  style,
  ...props
}: ILabeledTagsProps) => (
  <div style={{ backgroundColor: bgColor, color, ...style }} className={cx(styles.labelledTags, className)} {...props}>
    {children}
  </div>
);

export const RoleLabel = ({ role }: { role: UserTeamRole }) => {
  const roleLabels = {
    [UserTeamRole.ORG_ADMIN]: { text: 'ORG ADMIN', color: 'var(--writer-green-3)' },
    [UserTeamRole.ADMIN]: { text: 'TEAM ADMIN', color: 'var(--writer-blue-3)' },
    [UserTeamRole.MEMBER]: { text: 'TEAM MEMBER', color: 'var(--classic-grey-2)' },
  };

  const roleLabel = roleLabels[role];

  if (!roleLabel) {
    return null;
  }

  return (
    <LabelledTags bgColor={roleLabel.color}>
      <Text smallCaps variant={TextSize.XS}>
        {roleLabel.text}
      </Text>
    </LabelledTags>
  );
};

export interface IDotLoader {
  compact?: boolean;
  dotClassName?: string;
  className?: string;
  style?: React.CSSProperties;
}

export const DotLoader: React.FC<IDotLoader> = ({ className, dotClassName, compact, style }) => (
  <div className={cx(styles.buttonLoader, className, { [styles.buttonLoaderCompact]: compact })} style={style}>
    <span className={cx(styles.buttonLoaderDot, dotClassName)} />
    <span className={cx(styles.buttonLoaderDot, dotClassName)} />
    <span className={cx(styles.buttonLoaderDot, dotClassName)} />
  </div>
);

export const ExtraDetailRow = ({
  children,
  colspan = 7,
  className = '',
}: {
  children: React.ReactNode;
  colspan?: number;
  className?: string;
}) => (
  <MuiTableRow className={className}>
    <MuiTableCell align="center" colSpan={colspan} className={cx(styles.extraDetailRow)}>
      {children}
    </MuiTableCell>
  </MuiTableRow>
);
