import React from 'react';
import cx from 'classnames';
import { Icon, IconVariant } from '@writerai/ui-atoms';

import styles from './styles.module.css';

export interface ToggleButtonProps {
  className?: string;
  isActive: boolean;
  onClick?: () => void;
}

/**
 * Toggle button to switch from one button state to another
 */
export const ToggleButton: React.FC<ToggleButtonProps> = ({ isActive, className, onClick }) => (
  <div aria-hidden className={cx(styles.container, className)} onClick={onClick}>
    <span className={cx(styles.buttonContainer, styles.seeMore)}>
      Description{' '}
      <span>
        <Icon
          name={IconVariant.BREADCRUMB_ARROW}
          className={cx(styles.icon, [{ [styles.arrowUp]: isActive }])}
          style={{ color: 'black' }}
        />
      </span>
    </span>
  </div>
);
