/**
 * Converts a given number of minutes to hours and minutes format.
 * @param {number} minutes - The number of minutes to convert.
 * @returns {string} The formatted time string in hours and minutes.
 */
export function convertMinsToHrsMins(minutes: number): string {
  const minsInHour = 60;
  const h: string | number = Math.floor(minutes / minsInHour);
  const m: string | number = minutes % minsInHour;
  let output = '';

  if (minutes >= minsInHour) {
    output = `${h} hr ${m} min`;
  } else {
    output = `${m} min`;
  }

  return output;
}
