import React from 'react';
import cx from 'classnames';
import { Enum } from '@writerai/utils';
import styles from './Popover.module.css';
import { useVisible } from '../../hooks/useVisible';
import { Icon, IconVariant } from '../Icon';

interface IPopoverProps {
  trigger: React.ReactNode;
  className?: string;
  children: React.ReactNode;
  panelClassName?: string;
  activeClassName?: string;
  placement?: typeof PopoverPlacement.type;
  showIcon?: boolean;
}

export const PopoverPlacement = new Enum('left', 'right', 'top', 'bottom');

export const Popover: React.FC<IPopoverProps> = ({
  placement = PopoverPlacement.enum.top,
  trigger,
  showIcon = true,
  className,
  activeClassName = '',
  children,
  panelClassName,
}) => {
  const { triggerRef, dropdownRef, isVisible, setIsVisible } = useVisible(false);
  const elClass: string = cx(className, styles.popover, styles[placement], {
    [activeClassName]: isVisible,
  });

  const onClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setIsVisible(!isVisible);
  };

  return (
    <div className={cx(className, styles.container)}>
      <div aria-hidden className={styles.trigger} onClick={onClick} ref={triggerRef}>
        {trigger}
        {showIcon && (
          <Icon className={cx(styles.icon, { [styles.iconInverted]: isVisible })} name={IconVariant.BREADCRUMB_ARROW} />
        )}
      </div>

      {isVisible && (
        <div ref={dropdownRef} className={elClass}>
          <div className={cx(panelClassName, styles.panel)}>{children}</div>
        </div>
      )}
    </div>
  );
};
