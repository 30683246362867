import { CLEAN_ABBRS, CLEAN_ABBRS_2, ABBR_RX } from './constants';

/**
 * Normalizes abbreviations in the given text by replacing them with their full forms.
 * @param text - The text to normalize abbreviations in.
 * @returns The text with normalized abbreviations.
 */
export function normalizeAbbr(text: string) {
  return text.replace(CLEAN_ABBRS, '$2').replace(CLEAN_ABBRS_2, '$2').replace(ABBR_RX, '$1');
}
