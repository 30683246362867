import React from 'react';
import cx from 'classnames';

import styles from './styles.module.css';

import LogoBlack from '../../assets/logo/logoBlack.svg';
import LogoGreen from '../../assets/logo/logoGreen.svg';
import LogoWhite from '../../assets/logo/logoWhite.svg';
import LogoGrey from '../../assets/logo/logoGrey.svg';
import LogoFull from '../../assets/logo/logoFull.svg';
import LogoFullBlack from '../../assets/logo/logoFullBlack.svg';

export interface ILogoProps {
  /** Set custom class name */
  className?: string;
  /** Set custom style */
  style?: React.CSSProperties;
  /** Set the handler to handle click event */
  onClick?: (e: React.MouseEvent) => void;
  /** Set logo image color */
  variant?: LogoVariant;
  /** Route to navigate to */
  navTo?: string;
}
export enum LogoVariant {
  BLACK = 'black',
  GREEN = 'green',
  WHITE = 'white',
  GREY = 'grey',
  FULL = 'full',
  FULL_BLACK = 'full_black',
}

const LogoIcon: React.FC<{
  variant: LogoVariant;
  onIconClick?: (e: React.MouseEvent) => void;
  navTo?: string;
}> = ({ variant, onIconClick, navTo }) => {
  let logoIcon = <LogoBlack />;

  switch (variant) {
    case LogoVariant.GREEN:
      logoIcon = <LogoGreen />;
      break;
    case LogoVariant.WHITE:
      logoIcon = <LogoWhite />;
      break;
    case LogoVariant.GREY:
      logoIcon = <LogoGrey />;
      break;
    case LogoVariant.FULL:
      logoIcon = <LogoFull />;
      break;
    case LogoVariant.FULL_BLACK:
      logoIcon = <LogoFullBlack />;
      break;
    default:
      break;
  }

  if (navTo) {
    const _handleLinkClick = (e: React.MouseEvent) => {
      e.preventDefault();
      onIconClick && onIconClick(e);
    };

    return (
      <a href={navTo} onClick={_handleLinkClick}>
        <span className={styles.wrapper}>{logoIcon}</span>
      </a>
    );
  } else {
    return (
      <span aria-hidden className={styles.wrapper} onClick={onIconClick}>
        {logoIcon}
      </span>
    );
  }
};

export const Logo: React.FC<ILogoProps> = React.forwardRef<HTMLDivElement, ILogoProps>(
  ({ variant = LogoVariant.BLACK, className, style, onClick, navTo, ...props }, ref) => (
    <div ref={ref} className={cx(styles.container, className)} {...props} style={style}>
      <LogoIcon variant={variant} onIconClick={onClick} navTo={navTo} />
    </div>
  ),
);

export default Logo;
