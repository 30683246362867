import styles from "./style.module.scss";

const ControlButtons = ({ closeDialog }) => {
  return (
    <div className={styles.control__buttons}>
      <button onClick={() => closeDialog()}>
        Cancel & close
      </button>
      <button className={styles.buttons__save} onClick={() => closeDialog(true)}>
        Save & close
      </button>
    </div>
  );
};

export default ControlButtons;
