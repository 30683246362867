// eslint-disable-next-line no-undef
export function header2obj(header: HeadersInit) {
  if (header instanceof Headers) {
    const ret: Record<string, string> = {};
    header.forEach((val, key) => {
      ret[key] = val;
    });

    return ret;
  } else if (Array.isArray(header)) {
    const ret: Record<string, string> = {};
    header.forEach(([key, val]) => {
      ret[key] = val;
    });

    return ret;
  } else {
    return header;
  }
}
