
const digest = 'e4dada89d0b37e0e40008b98cbdf59bcb4ce76ef4aac8c72bddeae058e98ee3c';
const css = `.styles-module__4NRcZLQ {
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: space-between;
  padding: 4px 0;
  margin: 2px 0;
  cursor: pointer;
  gap: 8px;
}

.styles-module__Y3pbYHM {
  min-width: 34px;
  padding: 6px 4px;
  border-radius: 4px;
  text-align: center;
}

.styles-module__PwPxfUW {
  background: var(--writer-green-3);
}

.styles-module__2DuIMM- {
  background: var(--writer-blue-2);
}

.styles-module__y2XI0Ke {
  background: var(--classic-purple-1);
}

.styles-module__wZpNhJm {
  background: var(--classic-grey-1);
}
`;

export default {"fleschContainer":"styles-module__4NRcZLQ","fleschValue":"styles-module__Y3pbYHM","valueGreen":"styles-module__PwPxfUW","valueBlue":"styles-module__2DuIMM-","valuePurple":"styles-module__y2XI0Ke","valueGray":"styles-module__wZpNhJm"};
export { css, digest };
  