import React from 'react';

import { observer } from 'mobx-react-lite';
import styles from './styles.module.css';
import { UIIssueCardModel, getDescription } from './UIIssueCardModel';
import { IssueCardMolecule } from '../../molecules/Issue/IssueCard';

export { UIIssueCardModel, getDescription };

export const IssueCard = observer<{ model: UIIssueCardModel }>(({ model }) => {
  const {
    onIssueCardClick,
    onMarkIssueAsWrong,
    onBulkAcceptSuggestions,
    onDeleteIssueClick,
    onBulkDeleteIssues,
    onAddToTermBankClick,
    onApplySuggestion,
    onLearnMoreClicked,
    title,
    collapsed,
    titleColor,
    addTermAvailable,
    description,
    useDropdownPortal,
    highlight,
    suggestions,
    isBeta,
    simpleDescription,
    meta,
    segment,
    issueType,
    category,
    isBulkAcceptAvailable,
    isBulkIgnoreAvailable,
    similarIssues,
  } = model;

  return (
    <div className={styles.issueCard}>
      <IssueCardMolecule
        title={title}
        titleColor={titleColor}
        category={category}
        issueType={issueType}
        header={meta?.header}
        isBeta={isBeta}
        highlight={highlight}
        subHighlight={meta?.pos}
        correction={suggestions}
        description={description}
        examples={meta?.examples}
        learnMore={meta?.learnMore}
        simpleDescription={simpleDescription}
        paragraph={meta?.paragraph}
        addTermAvailable={addTermAvailable}
        acceptAllChanges={meta?.acceptAllChanges}
        plagiarismMatches={meta?.matches}
        isBulkAcceptAvailable={isBulkAcceptAvailable}
        isBulkIgnoreAvailable={isBulkIgnoreAvailable}
        similarIssues={similarIssues}
        collapsed={collapsed}
        onClick={onIssueCardClick}
        onMarkIssueAsWrong={onMarkIssueAsWrong}
        onBulkAcceptSuggestions={onBulkAcceptSuggestions}
        onAddToTermBankClick={onAddToTermBankClick}
        onDeleteIssueClick={onDeleteIssueClick}
        onBulkDeleteIssues={onBulkDeleteIssues}
        onApplySuggestion={onApplySuggestion}
        onLearnMoreClicked={onLearnMoreClicked}
        segment={segment}
        useDropdownPortal={useDropdownPortal}
      />
    </div>
  );
});
