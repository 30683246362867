import type { ReactElement } from 'react';
import React, { useCallback, useMemo } from 'react';
import cx from 'classnames';
import { SidebarCategoryStatus } from '@writerai/types';
import { ColorDot, Icon, IconVariant, Tooltip } from '@writerai/ui-atoms';

import styles from './styles.module.css';

export interface ICategoryProps {
  isHeading?: boolean;
  value?: number;
  label: string;
  selected?: boolean;
  hideCounter?: boolean;
  onClick?: () => void;
  color?: string;
  status?: SidebarCategoryStatus;
  counterUnavailable?: boolean;
  className?: string;
  isSmall?: boolean;
  icon?: ReactElement;
  withoutValue?: boolean;
  disabledTooltipContent?: string | ReactElement;
}

const DISABLED_CATEGORY_COLOR = '#e4e7ed';
const CATEGORY_VALUE_ICON_SIZE = 10;

export const Category: React.FC<ICategoryProps> = ({
  isHeading,
  status,
  value,
  color,
  label,
  onClick,
  selected,
  hideCounter,
  counterUnavailable,
  disabledTooltipContent,
  className,
  icon,
  isSmall,
  withoutValue,
}) => {
  const isLocked = status === SidebarCategoryStatus.LOCKED;
  const isDisabled = status === SidebarCategoryStatus.DISABLED;
  const valueHidden = hideCounter && !isLocked;
  const showTooltip = isSmall || isDisabled;

  const categoryPrefix = useMemo(() => {
    if (icon) {
      return icon;
    }

    if (color) {
      const _color = isDisabled ? DISABLED_CATEGORY_COLOR : color;

      return (
        <ColorDot
          color={_color}
          className={cx({
            [styles.smallColorDot]: isSmall,
          })}
        />
      );
    }

    return null;
  }, [color, icon, isSmall, isDisabled]);

  const categoryValue = useMemo(() => {
    if (isDisabled) {
      return null;
    }

    if (isLocked) {
      const lockedIconWidth = isSmall ? CATEGORY_VALUE_ICON_SIZE : 12;
      const lockedIconHeight = isSmall ? CATEGORY_VALUE_ICON_SIZE : 13;

      return (
        <Icon className={styles.lockIcon} name={IconVariant.LOCK} width={lockedIconWidth} height={lockedIconHeight} />
      );
    }

    if (counterUnavailable && !selected) {
      return '-';
    }

    const checkmarkIconWidth = isSmall ? CATEGORY_VALUE_ICON_SIZE : 13;
    const checkmarkIconHeight = isSmall ? CATEGORY_VALUE_ICON_SIZE : 10;

    return value || <Icon name={IconVariant.CHECKMARK} width={checkmarkIconWidth} height={checkmarkIconHeight} />;
  }, [counterUnavailable, selected, isLocked, value, isDisabled, isSmall]);

  const tooltipContent = () => {
    if (!isDisabled) {
      return label;
    }

    return disabledTooltipContent || 'N\\A';
  };

  const tooltipSlotProps = {
    popper: {
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, -8],
          },
        },
      ],
    },
  };

  const onCategoryClick = useCallback(() => {
    if (!isDisabled) {
      onClick && onClick();
    }
  }, [onClick, isDisabled]);

  return (
    <Tooltip title={tooltipContent()} disabled={!showTooltip} placement="left" slotProps={tooltipSlotProps}>
      <div
        aria-hidden
        className={cx(
          styles.category,
          {
            [styles.selected]: selected,
            [styles.small]: isSmall,
            [styles.disabled]: isDisabled,
            [styles.smallWithoutValue]: withoutValue && isSmall,
          },
          className,
        )}
        onClick={onCategoryClick}
      >
        <span className={cx({ [styles.prefix]: icon || color })}>{categoryPrefix}</span>

        <div className={cx(styles.categoryContent, { [styles.categoryContentColumn]: isSmall && !isHeading })}>
          <div className={cx(styles.text, { [styles.heading]: isHeading })}>
            <span>{label}</span>
          </div>

          {!withoutValue && (
            <div
              className={cx(styles.value, {
                [styles.valueHidden]: valueHidden,
                [styles.headingValue]: isHeading,
              })}
            >
              {categoryValue}
            </div>
          )}
        </div>
      </div>
    </Tooltip>
  );
};
