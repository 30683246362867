import React from 'react';
import { RadioButton, Text, TextColor, TextSize } from '@writerai/ui-atoms';
import styles from './styles.module.css';

export const StatItem: React.FC<{
  className?: string;
  label?: string;
  value?: React.ReactNode;
  checked?: boolean;
  onChange?: () => void;
}> = ({ className, label, value, checked = false, onChange = () => {} }) => (
  <div key={label} className={className}>
    <RadioButton id={label} value={label} checked={checked} onChange={onChange} className={styles.statItemButton}>
      <div className={styles.statsListItemValue}>
        <Text color={TextColor.GREY} variant={TextSize.S}>
          {label}
        </Text>
        <Text color={TextColor.GREY} variant={TextSize.S}>
          {value}
        </Text>
      </div>
    </RadioButton>
  </div>
);
