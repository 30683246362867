import React from 'react';
import { SnackbarProvider } from 'notistack';

import styles from './styles.module.css';

const snackbarStyles = {
  root: styles.root,
  anchorOriginBottomRight: styles.anchorOriginBottomCenter,
  containerAnchorOriginBottomRight: styles.containerAnchorOriginBottomCenter,
  variantSuccess: styles.success,
  variantError: styles.error,
  variantWarning: styles.warning,
  variantInfo: styles.info,
};

export const SnackbarsProvider: React.FC = ({ children }) => (
  <SnackbarProvider
    classes={snackbarStyles}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
  >
    {children}
  </SnackbarProvider>
);

export default SnackbarsProvider;
