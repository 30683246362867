
const digest = 'e70f370879733958cd0667257fec2924b91711aae484a3ab596be4d74d7999d0';
const css = `.styles-module__Kh8GUng {
  width: 100%;
  padding: 12px var(--sidebar-horizontal-spacing) 0 0;
}

.styles-module__0uSENk2 {
  width: 100%;
  padding: 12px 20px 0 0 !important;
}

.styles-module__5A5Sgf4 {
  width: 40px;
  height: 40px;
}

.styles-module__0uSENk2 .styles-module__Gq5UCeU {
  padding: 0;
}

.styles-module__Gq5UCeU {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
  margin-bottom: 12px;
}
`;

export default {"categoriesPanel":"styles-module__Kh8GUng","miniPanel":"styles-module__0uSENk2","scoreContainer":"styles-module__5A5Sgf4","header":"styles-module__Gq5UCeU"};
export { css, digest };
  