
const digest = '2a4ba06ac2e2600f95922b0c858148a373fffe6f016cf803793966febae69fbf';
const css = `.styles-module__P3uMupT {
  position: relative;
  display: flex;
  width: 200px;
  max-height: 240px;
  flex-direction: column;
  padding: 16px 6px 8px 12px;
  border-radius: 8px;
  background: var(--classic-green-2);
  box-shadow: 0 4px 24px var(--issue-card-highlighted-shadow);
  cursor: pointer;
}

.styles-module__P3uMupT::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 182px;
  height: 48px;
  margin: 0 8px;
  background: linear-gradient(180deg, rgb(170 249 225 / 0%) 0%, #aaf9e1 100%);
  content: '';
}

.styles-module__eE7ubts {
  flex: 1;
  padding-right: 6px;
  padding-bottom: 16px;
  overflow-y: auto;
}

.styles-module__eE7ubts::-webkit-scrollbar {
  width: 4px;
}

.styles-module__eE7ubts::-webkit-scrollbar-thumb {
  border: none;
  background: rgb(4 87 88 / 30%);
}

.styles-module__eE7ubts::-webkit-scrollbar-track-piece {
  display: none;
}

.styles-module__sEWIPJy {
  display: flex;
  margin-bottom: 12px;
}

.styles-module__ob-TQBO {
  color: var(--classic-green-5);
}

.styles-module__9cpPpmJ {
  margin-right: 10px;
}

.styles-module__9cpPpmJ path {
  fill: var(--classic-green-5);
}

.styles-module__u45vjqG {
  & li {
    &[data-list='ordered'] {
      list-style-type: decimal;
    }

    &[data-list='bullet'] {
      list-style-type: disc;
    }
  }
}
`;

export default {"snippet":"styles-module__P3uMupT","container":"styles-module__eE7ubts","heading":"styles-module__sEWIPJy","headingText":"styles-module__ob-TQBO","snippetIcon":"styles-module__9cpPpmJ","snippetBody":"styles-module__u45vjqG"};
export { css, digest };
  