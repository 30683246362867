
const digest = '1f8dd87f4cf1cb0bda18d6f50cf7ec6e704c0cc0f22d75c38c58a02bab743d74';
const css = `.DocumentStats-module__O3QcB98 {
  margin: 0 var(--sidebar-horizontal-spacing) 0 0;
}

.DocumentStats-module__TjI5e58 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 0;
  margin: 3px 0;
}

.DocumentStats-module__e-vkbsC {
  padding: 6px 0;
  border-top: var(--gray-border);
  border-bottom: var(--gray-border);
}

.DocumentStats-module__127K1vd {
  min-width: 34px;
  text-align: center;
}
`;

export default {"container":"DocumentStats-module__O3QcB98","statItem":"DocumentStats-module__TjI5e58","baseStatsContainer":"DocumentStats-module__e-vkbsC","value":"DocumentStats-module__127K1vd"};
export { css, digest };
  