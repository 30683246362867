import QuillBetterTable from 'quill-better-table';
import { matchTableHeader } from "../utils";

export class BetterTable extends QuillBetterTable {
  constructor(quill, options) {
    super(quill, options);
    quill.clipboard.matchers = quill.clipboard.matchers.filter(matcher => matcher[0] !== 'th');
    quill.clipboard.addMatcher('th', matchTableHeader);
  }
}
