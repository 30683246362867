import { IssueCategory, type ISidebarCategory } from '@writerai/types';
import type { IObservableValue } from 'mobx';
import { makeObservable, computed, observable, action, comparer } from 'mobx';
import type { SidebarModel } from '../../models';

export class UICategoryModel {
  private readonly $category: IObservableValue<ISidebarCategory>;

  readonly onClick = () => this.model.onSelectCategory(this.category.id);
  readonly setCategory = action((category: ISidebarCategory) => {
    if (!comparer.structural(this.$category.get(), category)) {
      this.$category.set(category);
    }
  });

  constructor(
    readonly model: SidebarModel,
    category: ISidebarCategory,
  ) {
    this.$category = observable.box<ISidebarCategory>(category, { deep: false });

    makeObservable(this, {
      id: computed,
      value: computed,
      selected: computed,
      counterUnavailable: computed,
    });
  }

  get id(): string {
    return this.category.id;
  }

  get value(): number | undefined {
    return this.model.issues.issuesByCategory[this.category.id];
  }

  get selected() {
    return this.model.categories.selectedCategory?.id === this.category.id;
  }

  get counterUnavailable() {
    return this.category.categories.includes(IssueCategory.Plagiarism);
  }

  // not computed

  get category() {
    return this.$category.get();
  }

  get canGoToSettings() {
    return this.model.sidebarState.isTeamAdmin;
  }

  get hideCounter() {
    return this.model.issues.isEmptyState;
  }

  get isSmall() {
    return this.model.size.isSmall;
  }
}
