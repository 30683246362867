
const digest = 'e7afb97cc611d803626fe2ee6a05f8be00ca72a8629f244b99a675d2eb668654';
const css = `/* Description section */
.styles-module__BFoQKHM {
  max-height: 200px;
  padding-right: 16px;
  margin-top: 6px;
  color: var(--black);
  font-size: 11px;
  line-height: 20px;
  overflow-y: scroll;
  white-space: pre-line;
}

.styles-module__BFoQKHM b {
  font-weight: 600;
}

.styles-module__BFoQKHM i {
  font-style: italic;
}

.styles-module__BFoQKHM > span {
  word-break: break-word;
}

.styles-module__KfRn-Ik {
  display: flex;
  margin-top: 10px;
}

.styles-module__qCtnbcP {
  padding-top: 2px;
  margin-right: 10px;
}

.styles-module__qCtnbcP svg {
  font-size: 16px;
}

.styles-module__lJCNhvw::-webkit-scrollbar {
  width: 15px;
}

.styles-module__lJCNhvw::-webkit-scrollbar-track {
  background: transparent;
}

.styles-module__lJCNhvw::-webkit-scrollbar-thumb {
  border: 5px solid var(--white);
  border-radius: 15px;
  background: var(--color-support-lightest);
}
`;

export default {"description":"styles-module__BFoQKHM","examplesContainer":"styles-module__KfRn-Ik","examplesIcon":"styles-module__qCtnbcP","scrollbar":"styles-module__lJCNhvw"};
export { css, digest };
  