/**
 * The user agent ID for Microsoft Edge browser.
 */
const EDGE_AGENT_ID = 'edg';

/**
 * The user agent ID for WebKit-based browsers.
 */
const APPLE_WEBKIT_AGENT_ID = 'applewebkit';

/**
 * The user agent ID for Chrome browser.
 */
const CHROME_AGENT_ID = 'chrome';

/**
 * Checks if the user's browser is Chrome (excluding Edge and WebKit-based browsers).
 * @returns {boolean} True if the browser is Chrome, false otherwise.
 */
export const isChromeBrowser = (): boolean => {
  const userAgent = navigator.userAgent.toLowerCase();

  return (
    userAgent.includes(CHROME_AGENT_ID) &&
    userAgent.includes(APPLE_WEBKIT_AGENT_ID) &&
    !userAgent.includes(EDGE_AGENT_ID)
  );
};

/**
 * Checks if the user's browser is Microsoft Edge (excluding WebKit-based browsers).
 * @returns {boolean} True if the browser is Edge, false otherwise.
 */
export const isEdgeBrowser = (): boolean => {
  const userAgent = navigator.userAgent.toLowerCase();

  return userAgent.includes(EDGE_AGENT_ID);
};

/**
 * Checks if the user's browser uses the WebKit rendering engine (excluding Chrome and Edge).
 * @returns {boolean} True if the browser uses WebKit, false otherwise.
 */
export const isWebkitBrowser = (): boolean => {
  const userAgent = navigator.userAgent.toLowerCase();

  return (
    userAgent.includes(APPLE_WEBKIT_AGENT_ID) &&
    !userAgent.includes(CHROME_AGENT_ID) &&
    !userAgent.includes(EDGE_AGENT_ID)
  );
};

/**
 * Checks if current OS is Macintosh
 * @returns boolean
 */
export const isMacOs = () => navigator.userAgent.toLowerCase().includes('mac');

/**
 * Checks if current OS is Windows
 * @returns boolean
 */
export const isWindowsOs = () => navigator.userAgent.toLowerCase().includes('win');
