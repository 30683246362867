import React, { useMemo } from 'react';

import { IssueLabel } from '@writerai/ui-molecules';
import styles from './styles.module.css';
import { CorrectedWord } from './CorrectedWord';

export const ISSUECARD_SUGGESTIONS_LIMIT = 3;

export interface ICorrectionProps {
  highlight: string;
  correction: string[];
  onApplySuggestion: (text: string) => Promise<unknown>;
  forceArrow?: boolean;
  forceDisableCorrection?: boolean;
  correctionStyles?: object;
}

export const Correction = ({
  highlight,
  correction,
  onApplySuggestion,
  forceArrow,
  forceDisableCorrection,
  correctionStyles,
}: ICorrectionProps) => {
  const error = correction.length !== 0 && correction[0].length === 0;
  const clearSuggestion = () => onApplySuggestion('');

  const CorrectedWords = useMemo(
    () => (
      <>
        {Array.isArray(correction) ? (
          correction
            .slice(0, ISSUECARD_SUGGESTIONS_LIMIT)
            .map((item, index) =>
              item.length ? (
                <CorrectedWord
                  key={`${item}-${highlight}`}
                  index={index}
                  text={item}
                  forceDisableCorrection={forceDisableCorrection}
                  correctionStyles={correctionStyles}
                  onApplySuggestion={onApplySuggestion}
                />
              ) : (
                ''
              ),
            )
        ) : (
          <CorrectedWord
            text={correction}
            forceDisableCorrection={forceDisableCorrection}
            correctionStyles={correctionStyles}
            onApplySuggestion={onApplySuggestion}
          />
        )}
      </>
    ),
    [correction, highlight, onApplySuggestion, correctionStyles, forceDisableCorrection],
  );

  return (
    <>
      {highlight && (
        <div className={styles.correctionLabel}>
          <IssueLabel
            error={error}
            strikethrough
            arrow={error && (correction.length > 1 || forceArrow)}
            style={{ marginTop: '7px', alignItems: 'center' }}
            onClick={error ? clearSuggestion : undefined}
          >
            <span className={styles.correctionLabelShadow} />
            {highlight}
          </IssueLabel>
        </div>
      )}
      {correction && CorrectedWords}
    </>
  );
};
