import React, { useState } from 'react';
import cx from 'classnames';

import { Button, ButtonTypes, Icon, IconVariant, SizeTypes, Text, TextColor, TextSize } from '@writerai/ui-atoms';
import { getWordsCount, wordPluralize } from '@writerai/text-utils';

import { Textarea } from '@writerai/ui-molecules';
import styles from './WrongSuggestionComment.module.css';

const MAX_COMMENT_WORDS = 250;

interface IWrongSuggestionCommentProps {
  className?: string;
  onSubmit: (comment: string) => void;
  onCancel: () => void;
}

export const WrongSuggestionComment: React.FC<IWrongSuggestionCommentProps> = ({ onSubmit, onCancel, className }) => {
  const [comment, setComment] = useState('');
  const commentWordsCount = getWordsCount(comment);
  const isCommentValid = commentWordsCount <= MAX_COMMENT_WORDS;

  const handleSubmit = () => {
    onSubmit(comment);
  };

  return (
    <div className={cx(className, styles.container)}>
      <div className={styles.actionCancel}>
        <Button
          round
          type={ButtonTypes.GRAY}
          size={SizeTypes.SMALL}
          onClick={onCancel}
          className={styles.cancelButton}
          icon={<Icon name={IconVariant.CLOSE} width={14} height={14} />}
        />
      </div>
      <div className={styles.heading}>
        <Text variant={TextSize.XXL} medium>
          Thanks! Any additional comments?
        </Text>
      </div>
      <Textarea
        className={styles.textarea}
        placeholder="Add some context about why this suggestion is incorrect"
        value={comment}
        resizable={false}
        onChange={e => setComment(e.target.value)}
      />
      <div className={styles.counter}>
        <Text
          variant={TextSize.XS}
          extraSmallCaps
          className={styles.counterText}
          color={isCommentValid ? TextColor.BLACK : TextColor.ORANGE}
        >
          {commentWordsCount} / {MAX_COMMENT_WORDS} {wordPluralize(MAX_COMMENT_WORDS, 'word')}
        </Text>
      </div>
      <div className={styles.actions}>
        <Button
          type={ButtonTypes.BLACK}
          size={SizeTypes.SMALL}
          content="Submit"
          icon={<Icon name={IconVariant.FLAG} />}
          onClick={handleSubmit}
          disabled={!isCommentValid}
        />
      </div>
    </div>
  );
};
