/* eslint-disable class-methods-use-this */
import type { AxiosInstance } from 'axios';
import axios from 'axios';

import { H_INTEGRATION_TYPE_TO_CLIENT_ID, IntegrationType } from '@writerai/types';
import { getLogger } from '../logger';

import { AUTH_TOKEN_NAME } from '../constants';
import type { ApiErrorResponse } from '../services/request/_types';

const LOG = getLogger('requestUtil');

export interface IStaticParams {
  organizationId: string;
  workspaceId: string;
  documentId: string;
  personaId: string;
}

/**
 * @deprecated use @writerai/network
 */
export default class Request {
  private static instance: Request = new Request();

  private integrationType: IntegrationType = IntegrationType.DEFAULT;

  private version = '';

  private axiosInstance: AxiosInstance = axios.create();

  constructor() {
    if (Request.instance) {
      throw new Error('Already instantiated use getInstance()');
    }

    Request.instance = this;
    this.axiosInstance.defaults.headers.common['Content-Type'] = 'application/json';
    this.axiosInstance.interceptors.response.use(
      res => res,
      err => {
        try {
          const beError: ApiErrorResponse = err.response?.data;
          let { message } = err;
          let messageKey;

          if (beError && beError.errors) {
            message = beError.errors.length ? beError.errors[0].description : message;
            messageKey = beError.errors.length ? beError.errors[0].key : message;
          }

          switch (err.response?.status) {
            case 403:
              LOG.error('api failed with 403', { url: err.config.url });
              break;
            default:
              LOG.error('api error', { url: err.config.url, statusCode: err.response?.status });
          }

          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject({
            ...err,
            message,
            messageKey,
          });
        } catch (e) {
          return Promise.reject(e);
        }
      },
    );
  }

  public setBaseUrl(baseUrl: string) {
    Request.getAxiosInstance().defaults.baseURL = baseUrl;
  }

  public setIntegrationType(integrationType: IntegrationType) {
    this.integrationType = integrationType;
    Request.getAxiosInstance().defaults.headers.common['x-client'] = this.getClientId();
  }

  public enableRedirectToRootOnAuthenticationFailure() {
    Request.getAxiosInstance().interceptors.response.use(
      res => res,
      err => {
        if (err?.response?.status === 401) {
          LOG.warn(`Token is not valid. Redirect to login.`);
          window.location.href = '/';
        }

        return Promise.reject(err);
      },
    );
  }

  public setVersion(version = '') {
    this.version = version;

    if (version) {
      Request.getAxiosInstance().defaults.headers.common['x-client-ver'] = version;
    }
  }

  static allowCookiesUsage(toBeSet = true) {
    Request.getAxiosInstance().defaults.withCredentials = toBeSet;
  }

  static getInstance(): Request {
    return Request.instance;
  }

  static getAxiosInstance(): AxiosInstance {
    return Request.instance.axiosInstance;
  }

  public setAuthorizationHeaders(token: string) {
    Request.getAxiosInstance().defaults.headers.common[AUTH_TOKEN_NAME] = token;
  }

  public getClientId() {
    return H_INTEGRATION_TYPE_TO_CLIENT_ID[this.integrationType];
  }
}
