import type { ReactNode } from 'react';
import React from 'react';
import cx from 'classnames';
import { Heading, HeadingVariant, Text, TextSize, TextColor } from '@writerai/ui-atoms';
import styles from './styles.module.css';

interface ISelectedCategoryHeaderProps {
  className?: string;
  label: string;
  teamName: string;
  count: number;
  icon?: ReactNode;
  color?: string;
  subTitle?: string;
  isLoading: boolean;
  showTeamName: boolean;
  showHeader: boolean;
}

export const SelectedCategoryHeader: React.FC<ISelectedCategoryHeaderProps> = ({
  className,
  isLoading,
  label,
  count,
  icon,
  color,
  subTitle,
  teamName,
  showTeamName,
  showHeader,
}) => {
  const body = showHeader && (
    <>
      <div className={styles.titleContainer}>
        <div className={styles.header}>
          {icon && <span className={styles.icon}>{icon}</span>}
          <Heading variant={HeadingVariant.H3} style={{ color }}>
            {label}
          </Heading>
        </div>
        {!isLoading ? <span className={cx(styles.issueCount, 'fadeIn')}>{count}</span> : null}
      </div>
      {subTitle && <span className={styles.subTitle}>{subTitle}</span>}
    </>
  );

  const teamNameContent = showTeamName && (
    <Text className={styles.teamName} variant={TextSize.XXS} color={TextColor.GREY2} extraSmallCaps>
      {teamName}
    </Text>
  );

  if (!teamNameContent && !body) {
    return null;
  }

  return (
    <div className={cx(className, styles.container)}>
      <div className={styles.contentHolder}>
        {teamNameContent}
        {body}
      </div>
    </div>
  );
};
