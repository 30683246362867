
const digest = '5aebc075499143f10ea67d186d8eb95088e765997f8fc2abb9b68691c1d70898';
const css = `.styles-module__Di7166W {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 8px;
  user-select: none;

  &.styles-module__YfNigqR {
    color: var(--writer-grey-1);
    pointer-events: none;
  }

  &:hover {
    .styles-module__4iQhE71 {
      border-color: var(--classic-grey-3);
      color: var(--classic-grey-1-5);
    }

    .styles-module__VYdEwyk {
      background-color: var(--classic-grey-1-5);
    }
  }

  /* taken from https://css-tricks.com/inclusively-hidden/ */
  & input {
    position: absolute;
    overflow: hidden;
    width: 1px;
    height: 1px;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    white-space: nowrap;
  }

  .styles-module__4iQhE71 {
    position: relative;
    display: flex;
    overflow: hidden;
    width: 18px;
    height: 18px;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--writer-grey-3);
    border-radius: 50%;
    background-color: var(--classic-white);

    &.styles-module__PO0KSWi {
      border-color: var(--classic-blue-4);
      background-color: var(--classic-blue-4);
      color: var(--classic-white);
    }

    &.styles-module__YfNigqR {
      border-color: var(--writer-graynu-3);

      &.styles-module__PO0KSWi {
        border-color: var(--writer-graynu-3);
        background-color: var(--writer-graynu-3);
        color: var(--classic-white);
      }

      &.styles-module__X6q6JA9 {
        border-color: var(--writer-graynu-3);
        background-color: var(--writer-graynu-3);
        color: var(--classic-white);
      }
    }

    &.styles-module__X6q6JA9 {
      border-color: var(--classic-blue-4);
      background-color: var(--classic-blue-4);
      color: var(--classic-white);
    }
  }

  .styles-module__x3c0lyy {
    display: block;
    width: 6px;
    height: 1px;
    background-color: currentColor;
  }

  .styles-module__VYdEwyk {
    position: relative;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: var(--classic-white);
  }
}
`;

export default {"container":"styles-module__Di7166W","disabled":"styles-module__YfNigqR","radioButton":"styles-module__4iQhE71","smallCircle":"styles-module__VYdEwyk","checked":"styles-module__PO0KSWi","indeterminate":"styles-module__X6q6JA9","dash":"styles-module__x3c0lyy"};
export { css, digest };
  