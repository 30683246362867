import type { IIssue, IssueFlag } from '@writerai/types';
import { CategoryType, IntegrationType, IssuesPipeline, SidebarViewMode } from '@writerai/types';
import type { RequestServiceInitialize } from '@writerai/network';
import { AnalyticsService } from '@writerai/analytics';
import { action, computed, configure, makeObservable, observable, runInAction } from 'mobx';
import { FleschKincaidService } from '@writerai/text-utils';
import { AiAssistantSubscriptionModel } from '@writerai/models';
import type { Emitter } from 'nanoevents';
import { createNanoEvents } from 'nanoevents';
import { PromisedModel } from '@writerai/mobx';
import { requiredObject } from '@writerai/utils';
import { createFirebaseClient, isDocumentIsValid } from '@writerai/firebase';
import { SidebarStateModel } from './SidebarStateModel';
import { FirebaseModel } from '../firebase';
import { IssuesModel } from '../issues';
import { CategoriesModel, type ICategoriesModel } from '../categories';
import { SizeModel } from '../size';
import { DocumentStatsModel } from '../documentStats';
import { SnippetsModel } from '../snippets';
import { CollaborationModel } from '../collaboration';
import type {
  DocumentStatTypes,
  IFirebaseConfig,
  IFirebaseSettings,
  ISidebarEvents,
  ISidebarParams,
} from '../../types';
import { getLogger } from '../../utils/logger';
import type { IIssuesModel } from '../issues/types';
import type { ISidebarClient } from '../../utils/request/types';
import { createSidebarClient } from '../../utils/request/SidebarClient';

const LOG = getLogger('SidebarModel');

export const SNIPPET_SHORTCUT_PREFIX = 'w.';
export const SNIPPETS_LOAD_LIMIT = 50;

export interface ISidebarModelParams {
  firebase: {
    config: IFirebaseConfig;
    settings: IFirebaseSettings;
  };
  params: {
    appRoot: string;
    sidebarMode?: SidebarViewMode;
    integrationType: IntegrationType;
  };
  requestService: RequestServiceInitialize;
  issues?: IIssuesModel;
  categories?: ICategoriesModel;
  analytics?: AnalyticsService;
  eventBus?: Emitter<ISidebarEvents>;
  firebaseModel?: FirebaseModel;
  subscriptionModel?: AiAssistantSubscriptionModel;
}

export class SidebarModel {
  public readonly requestClient: ISidebarClient;

  public readonly analytics: AnalyticsService;

  public readonly firebase: FirebaseModel;

  public issues: IIssuesModel;

  public categories: ICategoriesModel;

  public readonly subscription: AiAssistantSubscriptionModel;

  public readonly size: SizeModel;

  public readonly snippets: SnippetsModel;

  public readonly documentStats: DocumentStatsModel;

  public readonly sidebarState: SidebarStateModel;

  public readonly collaboration: CollaborationModel;

  public readonly eventBus: Emitter<ISidebarEvents>;

  // Dynamic sidebar params
  private sidebarParams = observable.box<ISidebarParams>(
    {
      teamName: '',
      sidebarMode: SidebarViewMode.OPEN,
      organizationId: '',
      workspaceId: '',
      documentId: '',
      personaId: '',
      isTeamAdmin: false,
      isOrgAdmin: false,
      appRoot: '',
    },
    { deep: false },
  );

  $initialPipeline: PromisedModel<IssuesPipeline | undefined>;

  public pipeline: IssuesPipeline | undefined = undefined;

  constructor(opts: ISidebarModelParams) {
    const { firebase, params, requestService, firebaseModel, subscriptionModel } = opts;
    this.requestClient = createSidebarClient({
      request: requestService.api,
      documentParams: () => this.baseParams,
    });

    this.analytics = opts.analytics ?? new AnalyticsService(requestService.api, params.integrationType);
    this.eventBus = opts.eventBus ?? createNanoEvents<ISidebarEvents>();

    this.firebase =
      firebaseModel ??
      new FirebaseModel({
        client: createFirebaseClient({
          config: firebase.config,
          settings: firebase.settings,
          request: requestService.api,
          params: {
            organizationId: () => this.organizationId,
            teamId: () => this.workspaceId,
            documentId: () => this.documentId,
            personaId: this.sidebarParams.get().personaId,
            userId: () => undefined,
          },
        }),
      });

    this.$initialPipeline = new PromisedModel({
      name: '$initialPipeline',
      load: async () => {
        if (!this.documentId) {
          return undefined;
        }

        return this.firebase.api.getDocumentPipeline?.().then(this.initDocumentPipeline);
      },
    });

    this.subscription =
      subscriptionModel ??
      new AiAssistantSubscriptionModel({
        api: requestService.api,
        teamId: () => (this.workspaceId ? Number(this.workspaceId) : undefined),
        organizationId: () => (this.organizationId ? Number(this.organizationId) : undefined),
      });

    this.categories =
      opts.categories ??
      new CategoriesModel({
        organizationId: () => this.organizationId,
        workspaceId: () => this.workspaceId,
        appRoot: () => this.appRoot,
        getStyleGuide: () => this.firebase.styleguide.data,
        getSubscription: () => this.subscription.$subscription.value,
      });

    this.issues =
      opts.issues ??
      new IssuesModel({
        analytics: this.analytics,
        eventBus: this.eventBus,
        apiFlagSuggestionAsWrong: (issue: IIssue, state: IssueFlag, segment: string, comment?: string | null) =>
          this.requestClient.api?.flagSuggestionAsWrong(issue, state, segment, comment) as Promise<void>,
        apiBulkFlagSuggestionsAsWrong: flagSuggestionsResults =>
          this.requestClient.api?.flagSuggestionsAsWrong(flagSuggestionsResults) as Promise<void>,
        apiSuggestionComment: (issue: IIssue, comment: string) =>
          this.requestClient.api?.suggestionComment(issue, comment) as Promise<void>,
        apiReportOnAcceptSuggestion: (replacement: string, issue: IIssue, segment: string) =>
          this.requestClient.api?.reportOnAcceptSuggestion(replacement, issue, segment) as Promise<void>,
        apiBulkReportOnAcceptSuggestions: (replacement: string, issues: IIssue[], segment: string) =>
          this.requestClient.api?.reportOnAcceptSuggestions(replacement, issues, segment) as Promise<void>,
        workspaceId: () => this.workspaceId,
        documentId: () => this.documentId,
        firebaseIssuesData: () => this.firebase.firebaseIssuesData,
        documentContentData: () => this.firebase.documentContentData,
        categories: () => this.categories,
      });

    this.documentStats = new DocumentStatsModel({
      firebase: this.firebase,
      issues: () => this.issues,
      calculateGrade: (text, ignoreListRegex) => new FleschKincaidService().calculateGrade(text, ignoreListRegex),
    });

    this.snippets = new SnippetsModel({
      getSidebarParams: () => this.baseParams,
      api: requestService.api,
      defaultLimit: SNIPPETS_LOAD_LIMIT,
      defaultPrefix: SNIPPET_SHORTCUT_PREFIX,
    });

    this.size = new SizeModel(() => this.width);

    this.collaboration = new CollaborationModel({
      firebase: this.firebase,
    });

    this.sidebarState = new SidebarStateModel({
      documentParams: () => this.baseParams,
      firebase: () => this.firebase,
      subscription: () => this.subscription,
      issues: () => this.issues,
      size: () => this.size,
      categories: () => this.categories,
    });

    this.init(params);

    makeObservable(this, {
      pipeline: observable,
      issues: observable.ref,
      categories: observable.ref,

      baseParams: computed.struct,
      organizationId: computed,
      documentId: computed,
      width: computed,
      workspaceId: computed,
      appRoot: computed,

      init: action.bound,
      setPipeline: action.bound,
    });
  }

  /**
   * Sets the implementation of the SidebarModel with the specified options.
   * we use it to evolutionaly move code to ui-core
   *
   * @param opts - The options to set the implementation.
   * @param opts.issues - The issues model to set.
   * @param opts.categories - The categories model to set.
   */
  setImplementation(opts: Partial<{ issues: IIssuesModel; categories: ICategoriesModel }>) {
    runInAction(() => {
      if (opts.issues) {
        this.issues = opts.issues;
      }

      if (opts.categories) {
        this.categories = opts.categories;
      }
    });
  }

  get appRoot() {
    return this.baseParams?.appRoot;
  }

  get width() {
    return this.baseParams?.width;
  }

  get organizationId() {
    return this.baseParams?.organizationId;
  }

  get documentId() {
    return this.baseParams?.documentId;
  }

  get workspaceId() {
    return this.baseParams?.workspaceId;
  }

  get baseParams() {
    const sidebarParams = this.sidebarParams.get();

    const { documentId, ...requiredParams } = sidebarParams;

    const docId = isDocumentIsValid(documentId) ? documentId : undefined;

    const params = requiredObject<Omit<ISidebarParams, 'documentId'>>(requiredParams, (key, value) => {
      if (!['teamName'].includes(key) && typeof value === 'string') {
        const isValidString = value.length > 0;

        if (!isValidString) {
          LOG.warn(`${key} is not valid, ${value}, expected: not empty string`);
        }

        return isValidString;
      }

      const isValid = value !== undefined && value !== null;

      if (!isValid) {
        LOG.warn(`${key} is not valid, ${value}, expected: not null or undefined`);
      }

      return isValid;
    });

    return params ? { ...params, documentId: docId } : undefined;
  }

  init(params: Partial<ISidebarParams>) {
    const keys = Object.keys(params) as Array<keyof ISidebarParams>;

    const newParams = { ...this.sidebarParams.get() };

    for (const key of keys) {
      // @ts-expect-error - the key is in newParams
      newParams[key] = params[key];
    }

    this.sidebarParams.set(newParams);

    const disableMobxProxy = [IntegrationType.OUTLOOK_PLUGIN, IntegrationType.WORD_PLUGIN].includes(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.sidebarParams.get().integrationType!,
    );

    if (disableMobxProxy) {
      configure({
        useProxies: 'never',
      });
    }
  }

  private initDocumentPipeline = (pipeline: IssuesPipeline | undefined) =>
    runInAction(() => {
      if (!pipeline || (pipeline === IssuesPipeline.FULL && !this.collaboration.isCollaborative)) {
        return this.setPipeline(IssuesPipeline.BASIC);
      } else if (pipeline === IssuesPipeline.PLAGIARISM) {
        this.categories.setCategory(CategoryType.PLAGIARISM);

        return this.setPipeline(IssuesPipeline.PLAGIARISM);
      }

      return this.setPipeline(pipeline);
    });

  async setPipeline(pipeline: IssuesPipeline) {
    if (this.pipeline === pipeline) {
      return pipeline;
    }

    await this.requestClient.api?.changeDocumentPipeline(pipeline);

    this.pipeline = pipeline;

    return this.pipeline;
  }

  onSelectCategory = (categoryType: CategoryType) => {
    this.categories.setCategory(categoryType);

    if (categoryType === CategoryType.PLAGIARISM) {
      this.sidebarState.forceSidebarLoading();
    }

    this.eventBus.emit('onSelectCategory', this.categories.selectedCategory);
    this.analytics.track(AnalyticsService.EV.filteredSuggestions, {
      filter_category: categoryType || 'All',
      team_id: Number(this.workspaceId),
    });
  };

  onRefreshScoreClick = () => {
    this.analytics.track(AnalyticsService.EV.refreshScore, {});
    this.sidebarState.forceSidebarLoading();
    this.requestClient.api?.refreshScore();
    this.eventBus.emit('onRefreshScore');
  };

  onSnippetClick = (id: string, snippet: string) => {
    this.requestClient.api?.reportSnippetApply(id);

    this.analytics.track(AnalyticsService.EV.snippetClicked, {
      id,
      snippet,
      team_id: Number(this.workspaceId),
    });
    this.eventBus.emit('onSnippetClicked', snippet);
  };

  onDocumentStatOptionChange = (statType: typeof DocumentStatTypes.type) => {
    this.eventBus.emit('onChangeStatOption', statType);
  };

  hasEventSubscriber = (event: keyof ISidebarEvents) => !!this.eventBus.events[event]?.length;
}
